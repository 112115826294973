import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query';
import type {
  Error,
  Login,
  Offers,
  Profile,
  PostImage,
  AuthRefresh,
  ResetPassword,
  ApplicationStatus,
  PatchOrganizationDetails,
  PatchOrganizationContactInfoDetails,
  PostApiV1UsersBody,
  User,
  EmailVerification,
  AdminOrganizationDetails,
  PostApiV1OrganizationSearchBody,
  PostApiV1OfferSearchBody,
  AdminOfferControl,
  GetAppVersion,
  UpdateAppVersion,
  PostApiV1LatestOffersBody,
} from '../schemas';
import { customInstance } from '../mutator/custom-instance';
import type { ErrorType, BodyType } from '../mutator/custom-instance';
import { Application } from 'api/schemas/applications';
import { PatchOfferDetails } from 'api/schemas/patchOfferDetails';
import { Offer } from 'api/schemas/offer';
import { Organization } from 'api/schemas/organization';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

// ======================== Login start ===
/**
 * @summary Login a User
 */
export const postApiV1AuthLogin = (login: BodyType<Login>) => {
  const formData = new FormData();
  formData.append('username', login.username);
  formData.append('password', login.password);
  formData.append('scope', login.scope);

  return customInstance<void>({
    url: `/api/v1/auth/login`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getPostApiV1AuthLoginMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1AuthLogin>>,
    TError,
    { data: BodyType<Login> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV1AuthLogin>>,
  TError,
  { data: BodyType<Login> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV1AuthLogin>>,
    { data: BodyType<Login> }
  > = props => {
    const { data } = props ?? {};

    return postApiV1AuthLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV1AuthLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV1AuthLogin>>
>;
export type PostApiV1AuthLoginMutationBody = BodyType<Login>;
export type PostApiV1AuthLoginMutationError = ErrorType<Error>;

/**
 * @summary Login a User
 */
export const usePostApiV1AuthLogin = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1AuthLogin>>,
    TError,
    { data: BodyType<Login> },
    TContext
  >;
}) => {
  const mutationOptions = getPostApiV1AuthLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== Login End ====

// ====================== Me start ====
export const getApiV1UsersMe = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<unknown>(
    { url: `/api/v1/users/me`, method: 'get', signal },
    options,
  );
};

export const getGetApiV1UsersMeQueryKey = () => [`/api/v1/users/me`] as const;

export const getGetApiV1UsersMeQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV1UsersMe>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiV1UsersMe>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getApiV1UsersMe>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1UsersMeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1UsersMe>>> = ({
    signal,
  }) => getApiV1UsersMe(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetApiV1UsersMeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV1UsersMe>>
>;
export type GetApiV1UsersMeQueryError = ErrorType<unknown>;

export const useGetApiV1UsersMe = <
  TData = Awaited<ReturnType<typeof getApiV1UsersMe>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiV1UsersMe>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiV1UsersMeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
// ====================== Me end ====

// ====================== Get Applications by status start ===
export const getApiV1Applications = (
  status: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Application>(
    { url: `/api/v1/applications?status=${status}`, method: 'get', signal },
    options,
  );
};

export const getGetApiV1ApplicationsQueryKey = (status: string) =>
  [`/api/v1/applications?status=${status}`] as const;

export const getGetApiV1ApplicationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV1Applications>>,
  TError = ErrorType<Error>,
>(
  status: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiV1Applications>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getApiV1Applications>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV1ApplicationsQueryKey(status);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV1Applications>>
  > = ({ signal }) => getApiV1Applications(status, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!status, ...queryOptions };
};

export type GetApiV1ApplicationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV1Applications>>
>;
export type GetApiV1ApplicationsQueryError = ErrorType<Error>;

export const useGetApiV1Applications = <
  TData = Awaited<ReturnType<typeof getApiV1Applications>>,
  TError = ErrorType<Error>,
>(
  status: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiV1Applications>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiV1ApplicationsQueryOptions(status, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
// ====================== Get Applications by status End ===

// ====================== Get All Applications ===
export const getApiV1AllApplications = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Application>(
    { url: `/api/v1/applications`, method: 'get', signal },
    options,
  );
};

export const getGetApiV1AllApplicationsQueryKey = () =>
  [`/api/v1/applications`] as const;

export const getGetApiV1AllApplicationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV1AllApplications>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiV1AllApplications>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getApiV1AllApplications>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV1AllApplicationsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV1AllApplications>>
  > = ({ signal }) => getApiV1AllApplications(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetApiV1AllApplicationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV1AllApplications>>
>;
export type GetApiV1AllApplicationsQueryError = ErrorType<Error>;

export const useGetApiV1AllApplications = <
  TData = Awaited<ReturnType<typeof getApiV1AllApplications>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiV1AllApplications>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiV1AllApplicationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
// ====================== Get All Applications End ===

// ====================== Refresh Token start ====
export const postApiV1AuthRefresh = (
  postApiV1AuthRefreshBody: BodyType<AuthRefresh>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/v1/auth/refresh`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV1AuthRefreshBody,
    },
    options,
  );
};

export const getPostApiV1AuthRefreshMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1AuthRefresh>>,
    TError,
    { data: BodyType<AuthRefresh> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV1AuthRefresh>>,
  TError,
  { data: BodyType<AuthRefresh> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV1AuthRefresh>>,
    { data: BodyType<AuthRefresh> }
  > = props => {
    const { data } = props ?? {};

    return postApiV1AuthRefresh(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV1AuthRefreshMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV1AuthRefresh>>
>;
export type PostApiV1AuthRefreshMutationBody = BodyType<AuthRefresh>;
export type PostApiV1AuthRefreshMutationError = ErrorType<Error>;

export const usePostApiV1AuthRefresh = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1AuthRefresh>>,
    TError,
    { data: BodyType<AuthRefresh> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostApiV1AuthRefreshMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== Refresh Token End ====

// ================ Start Patch Application Status ==
export const patchApiV1ApplicationStatus = (
  offer_id: string,
  application_id: string,
  applicationStatus: BodyType<ApplicationStatus>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    {
      url: `/api/v1/offers/${offer_id}/applications/${application_id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: applicationStatus,
    },
    options,
  );
};

export const getPatchApiV1ApplicationStatusMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV1ApplicationStatus>>,
    TError,
    {
      offer_id: string;
      application_id: string;
      data: BodyType<ApplicationStatus>;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiV1ApplicationStatus>>,
  TError,
  {
    offer_id: string;
    application_id: string;
    data: BodyType<ApplicationStatus>;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiV1ApplicationStatus>>,
    {
      offer_id: string;
      application_id: string;
      data: BodyType<ApplicationStatus>;
    }
  > = props => {
    const { offer_id, application_id, data } = props ?? {};

    return patchApiV1ApplicationStatus(
      offer_id,
      application_id,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV1ApplicationStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchApiV1ApplicationStatus>>
>;
export type PatchApiV1ApplicationStatusMutationBody =
  BodyType<ApplicationStatus>;
export type PatchApiV1ApplicationStatusMutationError = ErrorType<unknown>;

export const usePatchApiV1ApplicationStatus = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiV1ApplicationStatus>>,
    TError,
    {
      offer_id: string;
      application_id: string;
      data: BodyType<ApplicationStatus>;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPatchApiV1ApplicationStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

// =================== End Patch Application Status ==

// ================ Start Get Profile ==
export const getApiV1Profile = (
  organization_id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Profile>(
    {
      url: `/api/v1/organizations/${organization_id}`,
      method: 'get',
      signal,
    },
    options,
  );
};

export const getGetApiV1ProfileQueryKey = (organization_id: number) =>
  [`/api/v1/organizations/${organization_id}`] as const;

export const getGetApiV1ProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV1Profile>>,
  TError = ErrorType<Error>,
>(
  organization_id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiV1Profile>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getApiV1Profile>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV1ProfileQueryKey(organization_id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Profile>>> = ({
    signal,
  }) => getApiV1Profile(organization_id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!organization_id, ...queryOptions };
};

export type GetApiV1ProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV1Profile>>
>;
export type GetApiV1ProfileQueryError = ErrorType<Error>;

export const useGetApiV1Profile = <
  TData = Awaited<ReturnType<typeof getApiV1Profile>>,
  TError = ErrorType<Error>,
>(
  organization_id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiV1Profile>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiV1ProfileQueryOptions(organization_id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
// =================== End Start Get Profile ==

// =================== Start Reset Password ==
export const postApiV1AuthPasswordReset = (
  data: BodyType<ResetPassword>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    {
      url: `/api/v1/auth/password/reset`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: data,
    },
    options,
  );
};

export const getPostApiV1AuthPasswordResetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1AuthPasswordReset>>,
    TError,
    { data: BodyType<ResetPassword> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV1AuthPasswordReset>>,
  TError,
  { data: BodyType<ResetPassword> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV1AuthPasswordReset>>,
    { data: BodyType<ResetPassword> }
  > = props => {
    const { data } = props ?? {};

    return postApiV1AuthPasswordReset(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV1AuthPasswordResetMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV1AuthPasswordReset>>
>;
export type PostApiV1AuthPasswordResetMutationBody = BodyType<ResetPassword>;
export type PostApiV1AuthPasswordResetMutationError = ErrorType<unknown>;

export const usePostApiV1AuthPasswordReset = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1AuthPasswordReset>>,
    TError,
    { data: BodyType<ResetPassword> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostApiV1AuthPasswordResetMutationOptions(options);

  return useMutation(mutationOptions);
};
// =================== End Reset Password ==

// =================== Patch Organization Profile info ==
export const patchOrganizationDetails = (
  organizationId: string,
  patchOrgDetails: BodyType<PatchOrganizationDetails>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    {
      url: `/api/v1/organizations/${organizationId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchOrgDetails,
    },
    options,
  );
};

export const getPatchOrganizationDetailsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchOrganizationDetails>>,
    TError,
    { organizationId: string; data: BodyType<PatchOrganizationDetails> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchOrganizationDetails>>,
  TError,
  { organizationId: string; data: BodyType<PatchOrganizationDetails> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchOrganizationDetails>>,
    { organizationId: string; data: BodyType<PatchOrganizationDetails> }
  > = props => {
    const { organizationId, data } = props ?? {};

    return patchOrganizationDetails(organizationId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV1UsersUserIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchOrganizationDetails>>
>;
export type PatchApiV1UsersUserIdMutationBody =
  BodyType<PatchOrganizationDetails>;
export type PatchOrganizationDetailsError = ErrorType<unknown>;

export const usePatchOrganizationDetails = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchOrganizationDetails>>,
    TError,
    { organizationId: string; data: BodyType<PatchOrganizationDetails> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPatchOrganizationDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};
// =================== End Patch Organization Profile info ==

// =================== Patch Organization Contact info ==
export const patchOrganizationContactInfoDetails = (
  organizationId: string,
  organizationDetailId: string,
  patchOrgDetails: BodyType<PatchOrganizationContactInfoDetails>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    {
      url: `/api/v1/organizations/${organizationId}/details/${organizationDetailId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchOrgDetails,
    },
    options,
  );
};

export const getPatchOrganizationContactInfoDetailsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchOrganizationContactInfoDetails>>,
    TError,
    {
      organizationId: string;
      organizationDetailId: string;
      data: BodyType<PatchOrganizationContactInfoDetails>;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchOrganizationContactInfoDetails>>,
  TError,
  {
    organizationId: string;
    organizationDetailId: string;
    data: BodyType<PatchOrganizationContactInfoDetails>;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchOrganizationContactInfoDetails>>,
    {
      organizationId: string;
      organizationDetailId: string;
      data: BodyType<PatchOrganizationContactInfoDetails>;
    }
  > = props => {
    const { organizationId, organizationDetailId, data } = props ?? {};

    return patchOrganizationContactInfoDetails(
      organizationId,
      organizationDetailId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchOrganizationContactInfoDetailMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchOrganizationContactInfoDetails>>
>;
export type PatchPatchOrganizationContactInfoDetailMutationResultMutationBody =
  BodyType<PatchOrganizationContactInfoDetails>;
export type PatchPatchOrganizationContactInfoDetailMutationResultError =
  ErrorType<unknown>;

export const usePatchOrganizationContactInfoDetails = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchOrganizationContactInfoDetails>>,
    TError,
    {
      organizationId: string;
      organizationDetailId: string;
      data: BodyType<PatchOrganizationContactInfoDetails>;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPatchOrganizationContactInfoDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};
// =================== End Patch Organization Contact info ==

// ====================== Get All Offers ===
export const getApiV1Offers = (
  organization_id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Offers>(
    {
      url: `/api/v1/organizations/${organization_id}/offers`,
      method: 'get',
      signal,
    },
    options,
  );
};

export const getGetApiV1OffersQueryKey = (organization_id: number) =>
  [`/api/v1/organizations/${organization_id}/offers`] as const;

export const getGetApiV1OffersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV1Offers>>,
  TError = ErrorType<Error>,
>(
  organization_id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiV1Offers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getApiV1Offers>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApiV1OffersQueryKey(organization_id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Offers>>> = ({
    signal,
  }) => getApiV1Offers(organization_id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!organization_id, ...queryOptions };
};

export type GetApiV1OffersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV1Offers>>
>;
export type GetApiV1OffersQueryError = ErrorType<Error>;

export const useGetApiV1Offers = <
  TData = Awaited<ReturnType<typeof getApiV1Offers>>,
  TError = ErrorType<Error>,
>(
  organization_id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiV1Offers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiV1OffersQueryOptions(organization_id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
// ====================== Get All Offers End ===

// =================== Patch Offer details =====
export const patchOfferDetails = (
  offerId: string,
  patchOffer: BodyType<PatchOfferDetails>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    {
      url: `/api/v1/offers/${offerId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchOffer,
    },
    options,
  );
};

export const getPatchOfferDetailsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchOfferDetails>>,
    TError,
    { offerId: string; data: BodyType<PatchOfferDetails> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchOfferDetails>>,
  TError,
  { offerId: string; data: BodyType<PatchOfferDetails> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchOfferDetails>>,
    { offerId: string; data: BodyType<PatchOfferDetails> }
  > = props => {
    const { offerId, data } = props ?? {};

    return patchOfferDetails(offerId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiV1OfferIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchOfferDetails>>
>;
export type PatchApiV1OfferIdMutationBody = BodyType<PatchOfferDetails>;
export type PatchOfferDetailsError = ErrorType<unknown>;

export const usePatchOfferDetails = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchOfferDetails>>,
    TError,
    { OfferId: string; data: BodyType<PatchOfferDetails> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  // @ts-ignore
  const mutationOptions = getPatchOfferDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};
// =================== End Patch Offer details ==

// ======================== Post Offer Image ===
export const postOfferImage = (
  offerId: string,
  data: BodyType<PostImage>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    {
      url: `/api/v1/offers/${offerId}/images`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data,
    },
    options,
  );
};

export const getPostOfferImageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postOfferImage>>,
    TError,
    { offerId: string; data: BodyType<PostImage> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postOfferImage>>,
  TError,
  { offerId: string; data: BodyType<PostImage> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postOfferImage>>,
    { offerId: string; data: BodyType<PostImage> }
  > = props => {
    const { offerId, data } = props ?? {};

    return postOfferImage(offerId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostOfferImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof postOfferImage>>
>;
export type PostOfferImageMutationBody = BodyType<PostImage>;
export type PostOfferImageError = ErrorType<unknown>;

export const usePostAPIV1OfferImage = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postOfferImage>>,
    TError,
    { OfferId: string; data: BodyType<PostImage> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  // @ts-ignore
  const mutationOptions = getPostOfferImageMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== End Post offer image ====

// ======================== Post Profile Image ===
export const postOrganizationImage = (
  organizationId: string,
  data: BodyType<PostImage>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    {
      url: `/api/v1/organizations/${organizationId}/images`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data,
    },
    options,
  );
};

export const getPostOrganizationImageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postOrganizationImage>>,
    TError,
    { organizationId: string; data: BodyType<PostImage> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postOrganizationImage>>,
  TError,
  { organizationId: string; data: BodyType<PostImage> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postOrganizationImage>>,
    { organizationId: string; data: BodyType<PostImage> }
  > = props => {
    const { organizationId, data } = props ?? {};

    return postOrganizationImage(organizationId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostOrganizationImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof postOrganizationImage>>
>;
export type PostOrganizationImageMutationBody = BodyType<PostImage>;
export type PostOrganizationImageError = ErrorType<unknown>;

export const usePostAPIV1OrganizationImage = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postOrganizationImage>>,
    TError,
    { OrganizationId: string; data: BodyType<PostImage> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  // @ts-ignore
  const mutationOptions = getPostOrganizationImageMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== End Post Organization image ====

// ====================== Start register organization ====
export const postApiV1Users = (
  postApiV1UsersBody: BodyType<PostApiV1UsersBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/api/v1/users`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV1UsersBody,
    },
    options,
  );
};

export const getPostApiV1UsersMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1Users>>,
    TError,
    { data: BodyType<PostApiV1UsersBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV1Users>>,
  TError,
  { data: BodyType<PostApiV1UsersBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV1Users>>,
    { data: BodyType<PostApiV1UsersBody> }
  > = props => {
    const { data } = props ?? {};

    return postApiV1Users(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV1UsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV1Users>>
>;
export type PostApiV1UsersMutationBody = BodyType<PostApiV1UsersBody>;
export type PostApiV1UsersMutationError = ErrorType<Error>;

export const usePostApiV1Users = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1Users>>,
    TError,
    { data: BodyType<PostApiV1UsersBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostApiV1UsersMutationOptions(options);

  return useMutation(mutationOptions);
};

// ====================== End register organization ====

// ====================== Start Resend verification email ====
export const postApiV1UsersUserIdEmailVerificationResend = (
  userId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/api/v1/users/${userId}/email_verification/resend`,
      method: 'post',
    },
    options,
  );
};

export const getPostApiV1UsersUserIdEmailVerificationResendMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1UsersUserIdEmailVerificationResend>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV1UsersUserIdEmailVerificationResend>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV1UsersUserIdEmailVerificationResend>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return postApiV1UsersUserIdEmailVerificationResend(userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV1UsersUserIdEmailVerificationResendMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postApiV1UsersUserIdEmailVerificationResend>>
  >;

export type PostApiV1UsersUserIdEmailVerificationResendMutationError =
  ErrorType<Error>;

export const usePostApiV1UsersUserIdEmailVerificationResend = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1UsersUserIdEmailVerificationResend>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostApiV1UsersUserIdEmailVerificationResendMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== End Resend verification email ====

// ====================== Start email verification check ====
export const getApiV1UsersUserIdEmailVerificationCheck = (
  userId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<EmailVerification>(
    {
      url: `/api/v1/users/${userId}/email_verification/check`,
      method: 'get',
      signal,
    },
    options,
  );
};

export const getGetApiV1UsersUserIdEmailVerificationCheckQueryKey = (
  userId: string,
) => [`/api/v1/users/${userId}/email_verification/check`] as const;

export const getGetApiV1UsersUserIdEmailVerificationCheckQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiV1UsersUserIdEmailVerificationCheck>>,
  TError = ErrorType<Error>,
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiV1UsersUserIdEmailVerificationCheck>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getApiV1UsersUserIdEmailVerificationCheck>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApiV1UsersUserIdEmailVerificationCheckQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiV1UsersUserIdEmailVerificationCheck>>
  > = ({ signal }) =>
    getApiV1UsersUserIdEmailVerificationCheck(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetApiV1UsersUserIdEmailVerificationCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV1UsersUserIdEmailVerificationCheck>>
>;
export type GetApiV1UsersUserIdEmailVerificationCheckQueryError =
  ErrorType<Error>;

export const useGetApiV1UsersUserIdEmailVerificationCheck = <
  TData = Awaited<ReturnType<typeof getApiV1UsersUserIdEmailVerificationCheck>>,
  TError = ErrorType<Error>,
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getApiV1UsersUserIdEmailVerificationCheck>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiV1UsersUserIdEmailVerificationCheckQueryOptions(
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
// ====================== End email verification check ====

// ====================== Start Offer creation ====
export const postApiV1OfferCreation = (
  offer: BodyType<PostApiV1UsersBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/api/v1/admin/offers`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: offer,
    },
    options,
  );
};

export const postApiV1OfferCreationMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1OfferCreation>>,
    TError,
    { data: BodyType<Offer> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV1OfferCreation>>,
  TError,
  { data: BodyType<Offer> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV1OfferCreation>>,
    { data: BodyType<Offer> }
  > = props => {
    const { data } = props ?? {};

    return postApiV1OfferCreation(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV1OfferCreationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV1OfferCreation>>
>;
export type PostApiV1OfferCreationMutationBody = BodyType<Offer>;
export type PostApiV1OfferCreationMutationError = ErrorType<Error>;

export const usePostApiV1OfferCreation = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1OfferCreation>>,
    TError,
    { data: BodyType<Offer> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = postApiV1OfferCreationMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== End Offer creation ====

// ====================== Get all organizations ====

export const getApiAdminOrganizations = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Array<Organization>>(
    {
      url: `/api/v1/admin/organizations`,
      method: 'get',
      signal,
    },
    options,
  );
};

export const getApiAdminOrganizationsQueryKey = () =>
  [`/api/v1/admin/organizations`] as const;

export const getApiAdminOrganizationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiAdminOrganizations>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiAdminOrganizations>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getApiAdminOrganizations>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiAdminOrganizationsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getApiAdminOrganizations>>
  > = ({ signal }) => getApiAdminOrganizations(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetApiAdminOrganizationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiAdminOrganizations>>
>;
export type GetApiAdminOrganizationsQueryError = ErrorType<Error>;

export const useGetApiAdminOrganizations = <
  TData = Awaited<ReturnType<typeof getApiAdminOrganizations>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getApiAdminOrganizations>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiAdminOrganizationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
// ====================== End Get all organizations ====

// ====================== Start Get admin single organization ====

// ====================== Start Get admin single organization ====
export const getAdminOrganizationDetails = (
  organization_id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<AdminOrganizationDetails>(
    {
      url: `/api/v1/admin/organizations/${organization_id}/details`,
      method: 'get',
      signal,
    },
    options,
  );
};

export const getAdminOrganizationDetailsQueryKey = (organization_id: number) =>
  [`/api/v1/admin/organizations/${organization_id}/details`] as const;

export const getAdminOrganizationDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminOrganizationDetails>>,
  TError = ErrorType<Error>,
>(
  organization_id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAdminOrganizationDetails>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getAdminOrganizationDetails>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAdminOrganizationDetailsQueryKey(organization_id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminOrganizationDetails>>
  > = ({ signal }) =>
    getAdminOrganizationDetails(organization_id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!organization_id, ...queryOptions };
};

export type GetAdminOrganizationDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminOrganizationDetails>>
>;
export type GetAdminOrganizationDetailsQueryError = ErrorType<Error>;

export const useGetAdminOrganizationDetails = <
  TData = Awaited<ReturnType<typeof getAdminOrganizationDetails>>,
  TError = ErrorType<Error>,
>(
  organization_id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAdminOrganizationDetails>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminOrganizationDetailsQueryOptions(
    organization_id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

// ====================== End Get admin single organization ====

// ====================== Start Update admin single organization ====
export const putAdminOrganizationDetails = (
  old_email: string,
  organization_id: string,
  details: BodyType<AdminOrganizationDetails>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AdminOrganizationDetails>(
    {
      url: `/api/v1/admin/organizations/${organization_id}/details/${old_email}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: details,
    },
    options,
  );
};

export const putAdminOrganizationDetailsMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAdminOrganizationDetails>>,
    TError,
    {
      organization_id: string;
      old_email: string;
      data: BodyType<AdminOrganizationDetails>;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putAdminOrganizationDetails>>,
  TError,
  {
    old_email: string;
    organization_id: string;
    data: BodyType<AdminOrganizationDetails>;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putAdminOrganizationDetails>>,
    {
      old_email: string;
      organization_id: string;
      data: BodyType<AdminOrganizationDetails>;
    }
  > = props => {
    const { organization_id, old_email, data } = props ?? {};

    return putAdminOrganizationDetails(
      old_email,
      organization_id,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutAdminOrganizationDetailsResult = NonNullable<
  Awaited<ReturnType<typeof putAdminOrganizationDetails>>
>;
export type PutAdminOrganizationDetailsMutationBody =
  BodyType<AdminOrganizationDetails>;
export type putAdminOrganizationDetailsMutationError = ErrorType<Error>;

export const usePutAdminOrganizationDetails = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAdminOrganizationDetails>>,
    TError,
    {
      old_email: string;
      organization_id: string;
      data: BodyType<AdminOrganizationDetails>;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = putAdminOrganizationDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== End Update admin single organization ====

// ====================== Get All Offers ===
export const getAdminOrganizationOffer = (
  organization_id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Offers>(
    {
      url: `/api/v1/admin/organizations/${organization_id}/offers`,
      method: 'get',
      signal,
    },
    options,
  );
};

export const getAdminOrganizationOfferQueryKey = (organization_id: number) =>
  [`/api/v1/admin/organizations/${organization_id}/offers`] as const;

export const getAdminOrganizationOfferQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminOrganizationOffer>>,
  TError = ErrorType<Error>,
>(
  organization_id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAdminOrganizationOffer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getAdminOrganizationOffer>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAdminOrganizationOfferQueryKey(organization_id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminOrganizationOffer>>
  > = ({ signal }) =>
    getAdminOrganizationOffer(organization_id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!organization_id, ...queryOptions };
};

export type GetAdminOrganizationOfferQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminOrganizationOffer>>
>;
export type GetAdminOrganizationOfferQueryError = ErrorType<Error>;

export const useGetAdminOrganizationOffer = <
  TData = Awaited<ReturnType<typeof getAdminOrganizationOffer>>,
  TError = ErrorType<Error>,
>(
  organization_id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAdminOrganizationOffer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminOrganizationOfferQueryOptions(
    organization_id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
// ====================== Get All Offers End ===

// ====================== Start Delete admin single organization ====
export const deleteAdminOrganizationDetails = (
  email: string,
  organization_id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AdminOrganizationDetails>(
    {
      url: `/api/v1/admin/organizations/${organization_id}/details/${email}`,
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
    },
    options,
  );
};

export const deleteAdminOrganizationDetailsMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAdminOrganizationDetails>>,
    TError,
    {
      email: string;
      organization_id: string;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAdminOrganizationDetails>>,
  TError,
  {
    email: string;
    organization_id: string;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAdminOrganizationDetails>>,
    {
      email: string;
      organization_id: string;
    }
  > = props => {
    const { email, organization_id } = props ?? {};

    return deleteAdminOrganizationDetails(
      email,
      organization_id,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAdminOrganizationDetailsResult = NonNullable<
  Awaited<ReturnType<typeof deleteAdminOrganizationDetails>>
>;
export type DeleteAdminOrganizationDetailsMutationBody =
  BodyType<AdminOrganizationDetails>;
export type deleteAdminOrganizationDetailsMutationError = ErrorType<Error>;

export const useDeleteAdminOrganizationDetails = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAdminOrganizationDetails>>,
    TError,
    {
      email: string;
      organization_id: string;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    deleteAdminOrganizationDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== End Delete admin single organization ====

// ====================== Start Accept admin single organization ====
export const acceptAdminOrganizationDetails = (
  organization_id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AdminOrganizationDetails>(
    {
      url: `/api/v1/admin/organizations/${organization_id}/accept`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: null,
    },
    options,
  );
};

export const acceptAdminOrganizationDetailsMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptAdminOrganizationDetails>>,
    TError,
    {
      organization_id: number;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof acceptAdminOrganizationDetails>>,
  TError,
  {
    organization_id: number;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptAdminOrganizationDetails>>,
    {
      organization_id: number;
    }
  > = props => {
    const { organization_id } = props ?? {};

    return acceptAdminOrganizationDetails(organization_id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcceptAdminOrganizationDetailsResult = NonNullable<
  Awaited<ReturnType<typeof acceptAdminOrganizationDetails>>
>;
export type AcceptAdminOrganizationDetailsMutationBody =
  BodyType<AdminOrganizationDetails>;
export type AcceptAdminOrganizationDetailsMutationError = ErrorType<Error>;

export const useAcceptAdminOrganizationDetails = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptAdminOrganizationDetails>>,
    TError,
    {
      organization_id: number;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    acceptAdminOrganizationDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== End Accept admin single organization ====

// ====================== Start Post organization search ====
export const postApiV1OrganizationSearch = (
  body: BodyType<PostApiV1OrganizationSearchBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Array<AdminOrganizationDetails>>(
    {
      url: `/api/v1/admin/organization/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: body,
    },
    options,
  );
};

export const getPostApiV1OrganizationSearchMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1OrganizationSearch>>,
    TError,
    { data: BodyType<PostApiV1OrganizationSearchBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV1OrganizationSearch>>,
  TError,
  { data: BodyType<PostApiV1OrganizationSearchBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV1OrganizationSearch>>,
    { data: BodyType<PostApiV1OrganizationSearchBody> }
  > = props => {
    const { data } = props ?? {};

    return postApiV1OrganizationSearch(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV1OrganizationSearchMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV1OrganizationSearch>>
>;
export type PostApiV1OrganizationSearchMutationBody =
  BodyType<PostApiV1OrganizationSearchBody>;
export type PostApiV1OrganizationSearchMutationError = ErrorType<Error>;

export const usePostApiV1OrganizationSearch = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1OrganizationSearch>>,
    TError,
    { data: BodyType<PostApiV1OrganizationSearchBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostApiV1OrganizationSearchMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== End Post organization search ====

// ====================== Start Post Offer search ====
export const postApiV1OfferSearch = (
  body: BodyType<PostApiV1OfferSearchBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Array<Offer>>(
    {
      url: `/api/v1/admin/offers/search/scope`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: body,
    },
    options,
  );
};

export const getPostApiV1OfferSearchMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1OfferSearch>>,
    TError,
    { data: BodyType<PostApiV1OfferSearchBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiV1OfferSearch>>,
  TError,
  { data: BodyType<PostApiV1OfferSearchBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiV1OfferSearch>>,
    { data: BodyType<PostApiV1OfferSearchBody> }
  > = props => {
    const { data } = props ?? {};

    return postApiV1OfferSearch(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiV1OfferSearchMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV1OfferSearch>>
>;
export type PostApiV1OfferSearchMutationBody =
  BodyType<PostApiV1OfferSearchBody>;
export type PostApiV1OfferSearchMutationError = ErrorType<Error>;

export const usePostApiV1OfferSearch = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1OfferSearch>>,
    TError,
    { data: BodyType<PostApiV1OfferSearchBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostApiV1OfferSearchMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== End Post Offer search ====

// ====================== Admin Offer Pause or Publish start ====
export const postAdminOfferSwitch = (
  postAdminOfferSwitchBody: BodyType<AdminOfferControl>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/v1/admin/offers/status/switch`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: postAdminOfferSwitchBody,
    },
    options,
  );
};

export const getPostAdminOfferSwitchMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminOfferSwitch>>,
    TError,
    { data: BodyType<AdminOfferControl> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminOfferSwitch>>,
  TError,
  { data: BodyType<AdminOfferControl> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminOfferSwitch>>,
    { data: BodyType<AdminOfferControl> }
  > = props => {
    const { data } = props ?? {};

    return postAdminOfferSwitch(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminOfferSwitchMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminOfferSwitch>>
>;
export type PostAdminOfferSwitchMutationBody = BodyType<AdminOfferControl>;
export type PostAdminOfferSwitchMutationError = ErrorType<Error>;

export const usePostAdminOfferSwitch = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminOfferSwitch>>,
    TError,
    { data: BodyType<AdminOfferControl> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostAdminOfferSwitchMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== Admin Offer Pause or Publish End ====

// ====================== Admin App version start ====
export const postAdminAppVersion = (
  request: BodyType<UpdateAppVersion>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/v1/management/app_versions`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: request,
    },
    options,
  );
};

export const getPostAdminAppVersionMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminAppVersion>>,
    TError,
    { data: BodyType<UpdateAppVersion> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminAppVersion>>,
  TError,
  { data: BodyType<UpdateAppVersion> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminAppVersion>>,
    { data: BodyType<UpdateAppVersion> }
  > = props => {
    const { data } = props ?? {};

    return postAdminAppVersion(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminAppVersionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminAppVersion>>
>;
export type PostAdminAppVersionMutationBody = BodyType<UpdateAppVersion>;
export type PostAdminAppVersionMutationError = ErrorType<Error>;

export const usePostAdminAppVersion = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminAppVersion>>,
    TError,
    { data: BodyType<UpdateAppVersion> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostAdminAppVersionMutationOptions(options);

  return useMutation(mutationOptions);
};
// ====================== Admin App version End ====

// ====================== Get latest AppVersion ===
export const getAdminAppVersion = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<GetAppVersion>(
    {
      url: `/api/v1/management/app_versions/latest`,
      method: 'get',
      signal,
    },
    options,
  );
};

export const getAdminAppVersionQueryKey = () =>
  [`/api/v1/management/app_versions/latest`] as const;

export const getAdminAppVersionQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminAppVersion>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminAppVersion>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getAdminAppVersion>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminAppVersionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminAppVersion>>
  > = ({ signal }) => getAdminAppVersion(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAdminAppVersionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminAppVersion>>
>;
export type GetAdminAppVersionQueryError = ErrorType<Error>;

export const useGetAdminAppVersion = <
  TData = Awaited<ReturnType<typeof getAdminAppVersion>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminAppVersion>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminAppVersionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
// ====================== Get latest AppVersion End ===

// ====================== Get latest offers Count Start ===

export const postApiV1LatestOffers = (
  days: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Offers>(
    {
      url: `/api/v1/admin/latest/offers/count/${days}`,
      method: 'get',
      signal,
    },
    options,
  );
};

export const postApiV1LatestOffersQueryKey = (days: number) =>
  [`/api/v1/admin/latest/offers/count/${days}`] as const;

export const postApiV1LatestOffersQueryOptions = <
  TData = Awaited<ReturnType<typeof postApiV1LatestOffers>>,
  TError = ErrorType<Error>,
>(
  days: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof postApiV1LatestOffers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof postApiV1LatestOffers>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? postApiV1LatestOffersQueryKey(days);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof postApiV1LatestOffers>>
  > = ({ signal }) => postApiV1LatestOffers(days, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!days, ...queryOptions };
};

export type postApiV1LatestOffersQueryResult = NonNullable<
  Awaited<ReturnType<typeof postApiV1LatestOffers>>
>;
export type postApiV1LatestOffersQueryError = ErrorType<Error>;

export const usePostApiV1LatestOffers = <
  TData = Awaited<ReturnType<typeof postApiV1LatestOffers>>,
  TError = ErrorType<Error>,
>(
  days: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof postApiV1LatestOffers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = postApiV1LatestOffersQueryOptions(days, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

// ====================== Get latest offers Count End ===
