import React from 'react';
import { EnumButtonType, EnumButtonActionType } from 'constants/enums';
import { colors } from 'constants/colors';
import LActLoader from '../LActLoader';

interface LActButtonProps {
  text: string;
  testID?: string;
  disabled?: boolean;
  textStyle?: string;
  onClick: () => void;
  useLoading?: boolean;
  type?: EnumButtonType;
  containerStyle?: string;
  iconComponent?: React.ReactElement;
  actionType?: EnumButtonActionType | undefined;
}

const LActButton = (props: LActButtonProps): React.ReactElement => {
  const {
    text,
    testID,
    onClick,
    disabled,
    useLoading,
    iconComponent,
    containerStyle,
    textStyle = 'text-white',
    type = EnumButtonType.NORMAL,
    actionType = EnumButtonActionType.BUTTON,
  } = props;

  const DISABLED_BUTTON_BG = disabled && 'opacity-80 hover:bg-ting';

  let buttonType: string =
    'flex flex-1 w-full bg-tint rounded-xl justify-center items-center py-2.5';
  let textColor: string = 'text-white';
  let loaderColor: string = 'white';

  switch (type) {
    case EnumButtonType.OUTLINE:
      buttonType = 'bg-neutral600 border-neutral600 !text-tint';
      textColor = 'text-tint';
      loaderColor = colors.tint;
      break;
    case EnumButtonType.TEXT:
      buttonType = 'bg-white rounded-xl border-2 border-white';
      textColor = textStyle ?? 'text-tint';
      loaderColor = colors.tint;
      break;
  }

  return (
    <button
      onClick={onClick}
      type={actionType}
      disabled={disabled}
      data-testid={testID}
      className={`flex flex-1 w-full bg-tint rounded-xl border-2 border-tint justify-center items-center py-2.5 ${buttonType} ${containerStyle} ${DISABLED_BUTTON_BG}`}
    >
      {useLoading ? (
        <div>
          <LActLoader size="sm" color={loaderColor} />
        </div>
      ) : (
        <div className="flex items-center">
          {iconComponent}
          <span
            className={`text-regular ${textStyle} ${
              iconComponent && 'ml-3.5'
            } ${textColor}`}
          >
            {text}
          </span>
        </div>
      )}
    </button>
  );
};

export default LActButton;
