import React, { memo, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import LActText from 'components/common/LActText';
import LActButton from 'components/common/LActButton';
import LActTextInput from 'components/common/LActTextInput';
import {
  EnumErrorType,
  EnumInputType,
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
} from 'constants/enums';
import { AccessToken } from 'api/schemas';
import { usePostApiV1AuthLogin } from 'api/endpoints/queries';
import { useSwitchTranslation, useAccessTokenStorage } from 'utils';
import { parseTokenStorageObject } from 'utils/global/functions';
import {
  HOME_SCREEN,
  REGISTER_SCREEN,
  RESET_PASSWORD_SCREEN,
} from 'constants/route';
import { IAccessTokenTypes } from 'constants/types';
import { translate } from 'i18n';
import AuthScreenContainer from '../container';
import { useValidationShapeSchema } from './utils';

const LoginScreen = (): React.ReactElement => {
  const i18n = 'screens.login';
  const navigate = useNavigate();
  useSwitchTranslation();
  const [inputMessage, setInputMessage] = useState({
    message: '',
    inputType: '',
  });
  const [isWrongInputResponse, setIsWrongInputResponse] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [secureTextEntry, setSecureTextEntry] = useState<boolean>(true);

  /**
   * @prop
   * Used:
   *  - mutateAsync
   */
  const API_QUERY = usePostApiV1AuthLogin();

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  const { LoginValidationSchema } = useValidationShapeSchema();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginValidationSchema,
    onSubmit: ({ email, password }) => {
      onLoginClicked({ email, password });
    },
  });

  useSwitchTranslation();

  useEffect(() => {
    if (API_QUERY?.isSuccess && tokenStorage?.token?.access_token) {
      setIsLoading(false);
      if (tokenStorage?.token?.access_token) {
        navigate(HOME_SCREEN, {
          replace: true,
        });
      }
    }
  }, [API_QUERY?.isSuccess, navigate, tokenStorage?.token?.access_token]);

  const onEmailChange = (value: string) => {
    if (isWrongInputResponse) {
      setIsWrongInputResponse(false);
    }
    formik.setFieldValue('email', value);
  };

  const onPasswordChange = (value: string) => {
    if (isWrongInputResponse) {
      setIsWrongInputResponse(false);
    }
    formik.setFieldValue('password', value);
  };

  const onLoginClicked = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);

    API_QUERY?.mutateAsync({
      data: {
        username: email.toLowerCase(),
        password: password,
        scope: 'organization',
      },
    })
      // @ts-ignore
      .then((response: AccessToken) => {
        const storeObject = parseTokenStorageObject(response);
        tokenStorage.storeAccessToken(storeObject);
      })
      .catch(error => {
        if (error?.message?.includes('401')) {
          setInputMessage({
            message: translate(`${i18n}.errorMessage.verifyEmail`),
            inputType: EnumInputType.EMAIL,
          });
          setIsLoading(false);
        } else if (error?.message?.includes('403')) {
          setIsWrongInputResponse(true);
          setIsLoading(false);
        }
        setIsLoading(false);
      });
  };

  const onResetPasswordClicked = () => {
    navigate(RESET_PASSWORD_SCREEN);
  };

  const onRegisterClicked = () => {
    navigate(REGISTER_SCREEN);
  };

  const ENABLE_LOGIN_BUTTON =
    formik?.values?.email?.length > 0 &&
    formik?.values?.password?.length > 0 &&
    Object.keys(formik?.errors)?.length === 0
      ? false
      : true;

  const WRONG_INPUTS_ERROR =
    isWrongInputResponse && !formik?.errors?.email && !formik?.errors?.password
      ? true
      : false;

  const EMAIL_MESSAGE_TYPE =
    formik?.errors?.email && formik?.touched?.email
      ? EnumErrorType.ERROR
      : EnumErrorType.ERROR;

  const PASSWORD_MESSAGE_TYPE =
    formik?.errors?.password && formik?.touched?.password
      ? EnumErrorType.ERROR
      : EnumErrorType.ERROR;

  const EMAIL_LABEL_MESSAGE =
    inputMessage?.message?.length > 0
      ? inputMessage?.message
      : formik?.errors?.email && formik?.touched?.email
      ? formik?.errors?.email
      : undefined;

  const PASSWORD_LABEL_MESSAGE =
    formik?.errors?.password && formik?.touched?.password
      ? formik?.errors?.password
      : undefined;

  return (
    <AuthScreenContainer
      style={`flex justify-center items-center mobile:mx-6 mobile:w-full flex-shrink-1`}
    >
      <div className="desktop:w-3/5 mobile:w-full">
        <LActText
          text={translate(`${i18n}.login`)}
          className="text-textPrimary text-center"
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
        />

        <LActText
          text={translate(`${i18n}.description`)}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          className="text-textSecondary text-center text-sm mt-2"
        />

        <LActTextInput
          type={'email'}
          maxLength={250}
          containerStyle="mt-6"
          testID={'email-input'}
          message={EMAIL_LABEL_MESSAGE}
          messageType={EMAIL_MESSAGE_TYPE}
          onBlur={formik.handleBlur('email')}
          onChange={value => onEmailChange(value)}
          placeHolder={translate(`${i18n}.emailPlaceHolder`)}
        />

        <LActTextInput
          isPassword
          maxLength={30}
          containerStyle="mt-3"
          testID={'password-input'}
          message={PASSWORD_LABEL_MESSAGE}
          messageType={PASSWORD_MESSAGE_TYPE}
          onBlur={formik.handleBlur('password')}
          onChange={value => onPasswordChange(value)}
          type={secureTextEntry ? 'password' : 'text'}
          placeHolder={translate(`${i18n}.passwordPlaceHolder`)}
          onIconClicked={() => setSecureTextEntry(!secureTextEntry)}
        />

        {WRONG_INPUTS_ERROR && (
          <LActText
            className={'mt-2 mx-3'}
            textColor={'text-error'}
            textFormatType={EnumTextFormatType.FONT_SIZE_12}
            text={translate(`${i18n}.errorMessage.invalidInputs`)}
          />
        )}

        <span className={'cursor-pointer'} onClick={onResetPasswordClicked}>
          <LActText
            className={'mt-2 mx-3'}
            textColor={'text-neutral400'}
            text={translate(`${i18n}.forgetPassword`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_12}
          />
        </span>

        {/* Buttons container */}
        <div className="mt-16">
          <LActButton
            useLoading={isLoading}
            testID={'login-button'}
            type={EnumButtonType.NORMAL}
            onClick={formik.handleSubmit}
            disabled={ENABLE_LOGIN_BUTTON}
            text={translate(`${i18n}.loginButton`)}
            actionType={EnumButtonActionType.BUTTON}
          />

          <LActButton
            testID={'register-button'}
            type={EnumButtonType.TEXT}
            onClick={onRegisterClicked}
            text={translate(`${i18n}.register`)}
            textStyle={'text-tint font-bold'}
            actionType={EnumButtonActionType.BUTTON}
          />
        </div>
      </div>
    </AuthScreenContainer>
  );
};

export default memo(LoginScreen);
