/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import { ApplicationItems } from 'api/schemas/applicationItems';
import { translate } from 'i18n';
import { LActText, OfferTemplate, VolunteerTemplate } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';

interface SidebarTemplateProps {
  item?: ApplicationItems;
  onApplicationStatusChanged: () => void;
}

const SidebarTemplate = (props: SidebarTemplateProps): React.ReactElement => {
  const i18n = 'screens.home.sidebar';
  const { item, onApplicationStatusChanged } = props;

  return (
    <>
      <VolunteerTemplate
        application={item}
        item={item?.volunteer}
        applicationStatus={item?.status}
        onChangeStatusClicked={onApplicationStatusChanged}
      />

      <div className={'desktop:px-8 mobile:px-2 mt-8'}>
        <LActText
          className={`font-medium`}
          text={translate(`${i18n}.offerTitle`)}
          textFormatType={EnumTextFormatType.FONT_SIZE_24}
        />
        <div
          className={'mobile:flex mobile:justify-center desktop:justify-start'}
        >
          <OfferTemplate item={item?.offer} className={'mt-6'} />
        </div>

        <div></div>
      </div>
    </>
  );
};

export default SidebarTemplate;
