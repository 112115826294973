import i18n from 'i18n-js';
// import * as Localization from "expo-localization"

// if English isn't your default language, move Translations to the appropriate language file.
import de, { Translations } from './de';
import en from './en';

i18n.fallbacks = true;
/**
 * we need always include "*-US" for some valid language codes because when you change the system language,
 * the language code is the suffixed with "-US". i.e. if a device is set to English ("en"),
 * if you change to another language and then return to English language code is now "en-US".
 */
i18n.translations = { en, '*-US': en, de };

// i18n.locale = Localization.locale
/**
 * For in app translation, we can use this value outside of this file
 * and create a provider for different translations
i18n.locale = 'de';
*/

/**
 * In case we want to use RTL, Please uncomment these three lines
 */
// export const isRTL = Localization.isRTL
// I18nManager.allowRTL(isRTL)
// I18nManager.forceRTL(isRTL)

/**
 * Builds up valid keypaths for translations.
 */
export type TxKeyPath = RecursiveKeyOf<Translations>;

// via: https://stackoverflow.com/a/65333050
type RecursiveKeyOf<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `${TKey}`
  >;
}[keyof TObj & (string | number)];

type RecursiveKeyOfInner<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `['${TKey}']` | `.${TKey}`
  >;
}[keyof TObj & (string | number)];

type RecursiveKeyOfHandleValue<
  TValue,
  Text extends string,
> = TValue extends any[]
  ? Text
  : TValue extends object
  ? Text | `${Text}${RecursiveKeyOfInner<TValue>}`
  : Text;
