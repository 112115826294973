import React, { useRef } from 'react';
import { PersonCircle } from 'react-ionicons';
import { translate } from 'i18n';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Volunteer } from 'api/schemas/volunteer';
import { EnumImageType, EnumTextFormatType } from 'constants/enums';
import {
  LActRow,
  LActText,
  LActImage,
  ApplicationStatusTemplate,
} from 'components/index';
import { useCheckPlatform } from 'utils';
import { ApplicationItems } from 'api/schemas/applicationItems';

interface VolunteerPersonalInfoTemplateProps {
  item?: Volunteer;
  applicationStatus?: string;
  application?: ApplicationItems;
  onChangeStatusClicked: () => void;
}

const VolunteerPersonalInfoTemplate = (
  props: VolunteerPersonalInfoTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.home.sidebar';
  const queryClient = useRef(new QueryClient());
  const PLATFORM = useCheckPlatform();
  const { item, applicationStatus, onChangeStatusClicked } = props;

  const ICON_DIMENSIONS = PLATFORM?.isMobileView ? '80px' : '112px';
  return (
    <QueryClientProvider client={queryClient?.current}>
      <div className="desktop:-mt-2 mobile:-mt-4">
        <LActRow spaceBetween className={'items-center'}>
          {item?.imageUri ? (
            <LActImage
              source={item?.imageUri}
              imageType={EnumImageType.IMAGE}
              className={
                'desktop:w-24 desktop:h-24 mobile:w-20 mobile:h-20 rounded-full'
              }
            />
          ) : (
            <PersonCircle
              color={'#1F4043'}
              width={ICON_DIMENSIONS}
              height={ICON_DIMENSIONS}
            />
          )}

          <ApplicationStatusTemplate
            status={applicationStatus}
            onChangeStatusClicked={onChangeStatusClicked}
          />
        </LActRow>

        <div className={'mt-8'}>
          <LActText
            className={'font-medium'}
            testID="volunteer-username"
            textFormatType={EnumTextFormatType.FONT_SIZE_24}
            text={`${translate(`${i18n}.greetings`)} ${item?.firstname} ${
              item?.lastname
            }`}
          />
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default VolunteerPersonalInfoTemplate;
