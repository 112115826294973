import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { translate } from 'i18n';
import {
  LActText,
  LActSidebar,
  ApplicationsSkeletonLoaderTemplate,
} from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import { useApplicationStorage, useSeenNewApplications } from 'utils';
import {
  IApplicationStorageTypes,
  ISeenNewApplicationsTypes,
} from 'constants/types';
import { ApplicationItems } from 'api/schemas/applicationItems';
import ApplicationsTemplate from 'components/templates/applications/applications.list.template';
import SidebarTemplate from '../../home/components/template/sidebar.index';
import { itemIsDraggable, statusDropdownHandler } from '../utils';

interface ApplicationsColumnsProps {
  status: string;
  className?: string;
  isLoading?: boolean;
  onDragStart?: boolean;
  draggingOverStyle?: string;
  item: Array<ApplicationItems>;
  availableDroppableItems: Array<string>;
}

const ApplicationsColumns = (
  props: ApplicationsColumnsProps,
): React.ReactElement => {
  const i18n = 'screens.applications';
  const {
    item,
    status,
    isLoading,
    className,
    onDragStart,
    availableDroppableItems,
  } = props;
  // const [availableItemStyle, setAvailableItemStyle] =
  //   useState<string>('opacity-100');
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);

  const IS_COLUMN_DROPPABLE = availableDroppableItems.includes(status)
    ? false
    : true;

  const availableItemStyle =
    onDragStart && IS_COLUMN_DROPPABLE ? 'opacity-50' : 'opacity-100';

  const { DROP_DOWN_BG_COLOR, DROP_DOWN_TITLE_COLOR, DROP_DOWN_TITLE_TEXT } =
    statusDropdownHandler(status);

  // @ts-ignore
  const seenApplicationsStorage: ISeenNewApplicationsTypes =
    useSeenNewApplications(
      (store: ISeenNewApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  const renderApplicationsTemplate = (
    applicationItems: ApplicationItems,
    index: number,
  ): React.ReactElement => {
    if (!item) return <></>;
    const onApplicationItemsClicked = (id: number) => {
      if (!id) return;
      else {
        if (!seenApplicationsStorage?.ids?.includes(id)) {
          seenApplicationsStorage.storeAppliedOffers(
            seenApplicationsStorage?.ids?.concat(id),
          );
        }
        setIsSidebarVisible(true);
        applicationStorage.saveApplication(applicationItems);
      }
    };

    const onApplicationItemsHovered = () => {
      if (applicationItems?.id !== applicationStorage?.application?.id) {
        applicationStorage.saveApplication(applicationItems);
      }
    };

    const APPLICATION_IS_SEEN = seenApplicationsStorage?.ids?.includes(
      applicationItems?.id,
    );

    const IS_ITEM_DRAGGABLE: boolean = itemIsDraggable(status);

    return (
      <>
        {applicationItems ? (
          <Draggable
            index={index}
            isDragDisabled={IS_ITEM_DRAGGABLE}
            key={`Draggable-${applicationItems?.id}`}
            draggableId={applicationItems?.id.toString()}
          >
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={'hover:cursor-pointer'}
                key={`container-${index}-${index + 1}`}
              >
                <ApplicationsTemplate
                  item={applicationItems}
                  isSeen={APPLICATION_IS_SEEN}
                  onHover={onApplicationItemsHovered}
                  key={`application-${index}-${index + 1}`}
                  onClick={() =>
                    onApplicationItemsClicked(applicationItems?.id)
                  }
                />
              </div>
            )}
          </Draggable>
        ) : null}
      </>
    );
  };

  const ApplicationsItems = (): React.ReactElement => {
    const COLUMN_EMPTY_STATE_STYLE =
      item?.length === 0 &&
      `border-width-1 rounded-3xl color-background h-520 flex flex-col justify-center items-center`;

    return (
      <div
        aria-disabled={IS_COLUMN_DROPPABLE}
        className={`${COLUMN_EMPTY_STATE_STYLE}`}
      >
        {item.length > 0 ? (
          <>
            {item
              ?.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
              ?.map((item: ApplicationItems, index: number) => {
                return (
                  <view key={`ITEMS-${index}`}>
                    {renderApplicationsTemplate(item, index)}
                  </view>
                );
              })}
          </>
        ) : (
          <LActText
            testID="applications-title"
            className={'font-semibold'}
            textColor={'text-neutral400'}
            text={translate(`${i18n}.emptyState`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
          />
        )}
      </div>
    );
  };

  const onCloseSidebar = () => {
    setIsSidebarVisible(false);
  };

  return (
    <div className={`${className} ${availableItemStyle}`}>
      <div className={`rounded-full inline-block ${DROP_DOWN_BG_COLOR}`}>
        <LActText
          text={DROP_DOWN_TITLE_TEXT}
          textColor={DROP_DOWN_TITLE_COLOR}
          textFormatType={EnumTextFormatType.FONT_SIZE_14}
          className={`
            px-4 
            my-2.5 
            text-sm
            inline-block 
            rounded-full 
            duration-300 
            font-semibold
            ${DROP_DOWN_TITLE_COLOR}
          `}
        />
      </div>

      {isLoading ? (
        <div className={'flex flex-col pr-6'}>
          <ApplicationsSkeletonLoaderTemplate
            className={'w-360 h-520 pr-6 mt-4'}
          />
        </div>
      ) : (
        <Droppable
          type="group"
          droppableId={`${status}`}
          isDropDisabled={IS_COLUMN_DROPPABLE}
        >
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`w-360 h-520 pr-6 overflow-scroll pb-4 pt-3`}
            >
              <ApplicationsItems />
            </div>
          )}
        </Droppable>
      )}

      <LActSidebar
        onClose={onCloseSidebar}
        visible={isSidebarVisible}
        children={
          <SidebarTemplate
            item={applicationStorage?.application}
            onApplicationStatusChanged={() => setIsSidebarVisible(false)}
          />
        }
      />
    </div>
  );
};

export default ApplicationsColumns;
