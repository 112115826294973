import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CheckmarkDoneCircleOutline,
  ChevronBackOutline,
  TrashOutline,
} from 'react-ionicons';
import { Offer } from 'api/schemas/offer';
import {
  LActRow,
  LActText,
  LActCard,
  LActButton,
  Breadcrumb,
  LActScreen,
  LActEditableTextInput,
} from 'components/index';
import {
  EnumButtonType,
  EnumCriteriaType,
  EnumTextFormatType,
  EnumOffersFilterType,
} from 'constants/enums';
import { translate } from 'i18n';
import { colors } from 'constants/colors';
import {
  useUserStorage,
  useRefreshToken,
  useSwitchTranslation,
  useAccessTokenStorage,
} from 'utils';
import ScheduleComponent from './templates/schedule/schedule';
import CategoryComponent from './templates/category/category.index';
import LanguageComponent from './templates/languages/languages.index';
import LocationComponent from './templates/location/location.index';
import SkillsComponent from './templates/skills/skills.index';
import { OfferDetailsSchema } from './utils';
import {
  usePatchOfferDetails,
  usePostAdminOfferSwitch,
} from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import {
  IOffersTypes,
  IUserInfoTypes,
  IAccessTokenTypes,
} from 'constants/types';
import useOffersStorage from 'utils/storage/useOffersStorage';
import ImageUploadComponent from './templates/imageUpload/image.index';
import StatusComponent from './templates/status/status.index';

const OffersDetailsScreen = (): React.ReactElement => {
  const i18n = 'screens.home.offers.details';
  useSwitchTranslation();
  const [closeStatusDropdown, setCloseStatusDropdown] = useState<boolean>(true);
  const [message, setMessage] = useState({
    message: '',
    type: '',
    section: '',
  });

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const offersStorage: IOffersTypes = useOffersStorage(
    (store: IOffersTypes | unknown) => store,
  );

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  const PATCH_QUERY = usePatchOfferDetails({
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });
  const {
    state: { item },
  } = useLocation();
  const navigate = useNavigate();

  const SWITCH_STATUS_OFFER_QUERY = usePostAdminOfferSwitch({
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });

  const { id } = item as Offer;

  const [detailsData, setDetailsData] = useState<Offer>(item);

  const formik = useFormik({
    initialValues: {
      title: detailsData.title,
      impact: detailsData.impact,
      goal: detailsData.goal,
    },
    validateOnChange: true,
    validationSchema: OfferDetailsSchema,
    onSubmit: () => {
      onSaveClicked();
    },
  });

  if (!item) {
    navigate(-1);
  }

  useEffect(() => {
    if (
      message?.message &&
      message?.type === 'error' &&
      message?.section === 'impact' &&
      formik?.values?.impact?.length >= 5
    ) {
      setMessage({
        message: '',
        type: '',
        section: '',
      });
    } else if (
      message?.message &&
      message?.type === 'error' &&
      message?.section === 'title' &&
      formik?.values?.title?.length >= 5
    ) {
      setMessage({
        message: '',
        type: '',
        section: '',
      });
    } else if (
      message?.message &&
      message?.type === 'error' &&
      message?.section === 'goal' &&
      formik?.values?.goal?.length >= 5
    ) {
      setMessage({
        message: '',
        type: '',
        section: '',
      });
    }
  }, [
    message?.type,
    message?.message,
    message?.section,
    formik?.values.goal,
    formik?.values.title,
    formik?.values?.impact,
  ]);

  useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
          section: '',
        });
      }, 5000);
    }
  }, [message.type]);

  const updateCurrentOffer = (offer: Offer) => {
    if (!offer) return;

    setDetailsData(offer);

    const updatedOffers = offersStorage?.offers?.map(item => {
      return item.id === offer.id ? offer : item;
    });

    offersStorage.offers = updatedOffers;
  };

  const onEntireScreenClicked = () => {
    if (closeStatusDropdown) {
      setCloseStatusDropdown(!closeStatusDropdown);
    }
  };

  const onSaveClicked = () => {
    if (formik?.values?.title?.length < 5) {
      setMessage({
        message: translate(`${i18n}.errorMessages.title`),
        type: 'error',
        section: 'title',
      });
    } else if (formik?.values?.impact?.length < 5) {
      setMessage({
        message: translate(`${i18n}.errorMessages.impact`),
        type: 'error',
        section: 'impact',
      });
    } else if (formik?.values?.goal?.length < 5) {
      setMessage({
        message: translate(`${i18n}.errorMessages.goal`),
        type: 'error',
        section: 'goal',
      });
    } else {
      let section = '';
      if (formik?.values?.goal !== detailsData.goal) {
        section = 'goal';
      } else if (formik?.values?.impact !== detailsData.impact) {
        section = 'impact';
      } else {
        section = 'title';
      }

      PATCH_QUERY?.mutateAsync({
        offerId: id?.toString(),
        data: {
          goal: formik?.values?.goal,
          title: formik?.values?.title,
          impact: formik?.values?.impact,
        },
      })
        .then((data: any) => {
          setMessage({
            message: translate(`${i18n}.severMessages.successfullyUpdated`),
            type: 'success',
            section,
          });
          updateCurrentOffer(data?.item);
        })
        .catch(error => {
          if (error?.message?.includes('500')) {
            setMessage({
              message: translate(`${i18n}.severMessages.serverError500`),
              type: 'error',
              section,
            });
          }
          setMessage({
            message: translate(`${i18n}.severMessages.serverGeneralError`),
            type: 'error',
            section,
          });
        });
    }
  };
  useRefreshToken({
    error: PATCH_QUERY?.error,
    refetch: onSaveClicked,
    hasError: PATCH_QUERY?.isError,
  });

  const onActivateOfferClicked = () => {
    SWITCH_STATUS_OFFER_QUERY?.mutateAsync({
      data: {
        offerId: detailsData?.id,
        scope: EnumOffersFilterType.PUBLISHED,
      },
    })
      // @ts-ignore
      .then((response: Offer) => {
        if (response) {
          setMessage({
            message: 'Offer was Activated successfully!',
            type: 'success',
            section: 'admin',
          });
        }
      })
      .catch(error => error);
  };

  const onPauseOfferClicked = () => {
    SWITCH_STATUS_OFFER_QUERY?.mutateAsync({
      data: {
        offerId: detailsData?.id,
        scope: EnumOffersFilterType.PAUSED,
      },
    })
      // @ts-ignore
      .then((response: Offer) => {
        if (response) {
          setMessage({
            message: 'Offer was Paused successfully!',
            type: 'success',
            section: 'admin',
          });
        }
      })
      .catch(error => error);
  };

  const languageCriteria = detailsData.requiredCriteria?.filter(
    criteria => criteria?.property?.category === EnumCriteriaType.LANGUAGE,
  );

  const skillsCriteria = detailsData.requiredCriteria?.filter(
    criteria =>
      criteria?.property?.category === EnumCriteriaType.CHARACTERISTIC,
  );

  const ACCESS_TOKEN = tokenStorage?.token?.access_token;

  const IS_ADMIN_USER =
    userStorage?.user?.email === process.env.REACT_APP_LETSACT_USER;
  const ADMIN_HAS_PAUSED_OFFER = detailsData?.isPausedByAdmin;

  return (
    <LActScreen onEntireScreenClicked={onEntireScreenClicked}>
      <LActRow spaceBetween className={'-mr-4 items-center mt-7'}>
        <div>
          <LActButton
            type={EnumButtonType.TEXT}
            onClick={() => navigate(-1)}
            textStyle={'text-tint text-sm'}
            text={translate(`${i18n}.backButton`)}
            iconComponent={
              <ChevronBackOutline
                width="14px"
                height="14px"
                style={{
                  marginTop: -1,
                  marginRight: -6,
                }}
                color={colors.palette.primaryDark}
              />
            }
          />
        </div>
        <div>
          <Breadcrumb
            routes={[
              translate(`${i18n}.breadcrumb.home`),
              translate(`${i18n}.breadcrumb.offer`),
            ]}
          />
        </div>
      </LActRow>

      <>
        {IS_ADMIN_USER && (
          <LActRow>
            <LActButton
              useLoading={false}
              testID="logout-button"
              text={'Activate Offer'}
              type={EnumButtonType.TEXT}
              containerStyle={'text-md mt-6'}
              textStyle={'font-bold text-success'}
              onClick={onActivateOfferClicked}
              iconComponent={
                <CheckmarkDoneCircleOutline
                  color={colors.success}
                  width="24px"
                  height="24px"
                />
              }
            />

            <LActButton
              useLoading={false}
              text={'Pause Offer'}
              testID="logout-button"
              type={EnumButtonType.TEXT}
              onClick={onPauseOfferClicked}
              containerStyle={'text-sm mt-6'}
              textStyle={'font-bold text-error'}
              iconComponent={
                <TrashOutline color={colors.error} width="24px" height="24px" />
              }
            />
          </LActRow>
        )}

        {message.message && message.section === 'admin' && (
          <LActText
            text={message.message}
            textColor={'text-green'}
            className="mt-6 font-semibold"
            textFormatType={EnumTextFormatType.FONT_SIZE_16}
          />
        )}
      </>

      <LActRow spaceBetween className="items-center mt-8">
        <LActText
          text={translate(`${i18n}.title`)}
          textFormatType={EnumTextFormatType.FONT_SIZE_32}
          className="mt-6 font-semibold mobile:text-2xl desktop:text-3xl"
        />
        {IS_ADMIN_USER ? null : (
          <StatusComponent
            offerID={id}
            accessToken={ACCESS_TOKEN}
            status={detailsData?.status}
            disabled={ADMIN_HAS_PAUSED_OFFER}
            useCloseDropdown={closeStatusDropdown}
            onItemUpdated={offer => updateCurrentOffer(offer)}
            onPresentDropdown={isOpen => setCloseStatusDropdown(isOpen)}
          />
        )}
      </LActRow>

      {ADMIN_HAS_PAUSED_OFFER && (
        <LActText
          textColor="text-orange"
          className="mt-6 font-semibold"
          text={translate(`${i18n}.pausedOfferNotice`)}
          textFormatType={EnumTextFormatType.FONT_SIZE_24}
        />
      )}

      <div className="mt-10">
        <div
          className={
            'desktop:flex desktop:flex-row desktop:mr-2 mobile:flex-col'
          }
        >
          <div className={'desktop:w-1/2 mobile:w-full desktop:mr-6'}>
            {/* Title section */}
            <LActCard className="shadow-md p-0 p-4">
              <LActText
                className="font-semibold"
                text={translate(`${i18n}.titleSection`)}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
              />
              <LActEditableTextInput
                value={formik?.values?.title}
                onUpdateClicked={onSaveClicked}
                testID={'editable-offer-title-text'}
                onChange={formik?.handleChange('title')}
                successfullyUpdated={PATCH_QUERY?.isLoading}
                className={'font-semibold text-xl mt-3 w-full'}
                isLoading={
                  formik?.values?.title !== detailsData.title &&
                  PATCH_QUERY?.isLoading
                    ? true
                    : false
                }
                onCancelEditing={() =>
                  formik.setFieldValue('title', detailsData.title)
                }
              />

              {message?.message && message?.section === 'title' ? (
                <LActText
                  text={message?.message}
                  className={'font-semibold mt-6'}
                  textFormatType={EnumTextFormatType.FONT_SIZE_16}
                  textColor={
                    message.type === 'error' ? 'text-error' : 'text-green'
                  }
                />
              ) : undefined}
            </LActCard>

            {/* Impact section */}
            <LActCard className="shadow-md p-0 p-4 mt-4">
              <LActText
                className="font-semibold"
                text={translate(`${i18n}.impactSection`)}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
              />
              <div>
                <LActEditableTextInput
                  multiline
                  value={formik?.values?.impact}
                  testID={'editable-desc-text'}
                  onUpdateClicked={onSaveClicked}
                  textColor={'text-textSecondary'}
                  textNumberOfLines={'line-clamp-20'}
                  onChange={formik?.handleChange('impact')}
                  successfullyUpdated={PATCH_QUERY?.isLoading}
                  isLoading={
                    formik?.values?.impact !== detailsData.impact &&
                    PATCH_QUERY?.isLoading
                      ? true
                      : false
                  }
                  className={`leading-8 font-semibold text-xl mt-3 w-full ${
                    detailsData.impact?.length > 102 ? 'h-content' : 'h-full'
                  }`}
                  onCancelEditing={() =>
                    formik.setFieldValue('impact', detailsData.impact)
                  }
                />
              </div>

              {message?.message && message?.section === 'impact' && (
                <LActText
                  text={message?.message}
                  className={'font-semibold mt-6'}
                  textFormatType={EnumTextFormatType.FONT_SIZE_16}
                  textColor={
                    message.type === 'error' ? 'text-error' : 'text-green'
                  }
                />
              )}
            </LActCard>

            {/* Goal section */}
            <LActCard className="shadow-md p-0 p-4 mt-4">
              <LActText
                className="font-semibold"
                text={translate(`${i18n}.goalSection`)}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
              />
              <div>
                <LActEditableTextInput
                  multiline
                  value={formik?.values?.goal}
                  testID={'editable-desc-text'}
                  onUpdateClicked={onSaveClicked}
                  textColor={'text-textSecondary'}
                  textNumberOfLines={'line-clamp-20'}
                  onChange={formik?.handleChange('goal')}
                  successfullyUpdated={PATCH_QUERY?.isLoading}
                  isLoading={
                    formik?.values?.goal !== detailsData.goal &&
                    PATCH_QUERY?.isLoading
                      ? true
                      : false
                  }
                  onCancelEditing={() =>
                    formik.setFieldValue('impact', detailsData.goal)
                  }
                  className={`leading-8 font-semibold text-xl mt-3 w-full ${
                    detailsData.goal?.length > 102 ? 'h-content' : 'h-full'
                  }`}
                />
              </div>

              {message?.message && message?.section === 'goal' && (
                <LActText
                  text={message?.message}
                  className={'font-semibold mt-6'}
                  textFormatType={EnumTextFormatType.FONT_SIZE_16}
                  textColor={
                    message.type === 'error' ? 'text-error' : 'text-green'
                  }
                />
              )}
            </LActCard>
          </div>

          <LActCard className="shadow-md p-0 p-4 mobile:mt-6 desktop:mt-0 desktop:w-1/2 desktop:h-4/6 mobile:w-full">
            <ImageUploadComponent
              offerID={id}
              accessToken={ACCESS_TOKEN}
              image={detailsData?.picture}
              onItemUpdated={offer => updateCurrentOffer(offer)}
            />
          </LActCard>
        </div>

        {/* Categories section */}
        <LActCard className="shadow-md p-0 p-4 mt-4">
          <CategoryComponent
            offerID={id}
            accessToken={ACCESS_TOKEN}
            category={detailsData.category}
            onItemUpdated={offer => updateCurrentOffer(offer)}
          />
        </LActCard>

        {/* Schedule section */}
        <LActCard className="shadow-md p-0 p-4 mt-4">
          <ScheduleComponent
            offerID={id}
            accessToken={ACCESS_TOKEN}
            schedule={detailsData.schedule}
            onItemUpdated={offer => updateCurrentOffer(offer)}
          />
        </LActCard>

        {/* Location section */}
        <LActCard className="shadow-md p-0 p-4 mt-4">
          <LocationComponent
            city={detailsData.city}
            offerID={id}
            accessToken={ACCESS_TOKEN}
            street={detailsData.street}
            zipCode={detailsData.zipCode}
            locationType={detailsData.locationType}
            onItemUpdated={offer => updateCurrentOffer(offer)}
          />
        </LActCard>

        {/* Languages section */}
        <LActCard className="shadow-md p-0 p-4 mt-4">
          <LanguageComponent
            offerID={id}
            accessToken={ACCESS_TOKEN}
            languages={languageCriteria}
            onItemUpdated={offer => updateCurrentOffer(offer)}
          />
        </LActCard>

        {/* Soft skills section */}
        <LActCard className="shadow-md p-0 p-4 mt-4">
          <SkillsComponent
            offerID={id}
            skills={skillsCriteria}
            accessToken={ACCESS_TOKEN}
            onItemUpdated={offer => updateCurrentOffer(offer)}
          />
        </LActCard>
      </div>
    </LActScreen>
  );
};

export default OffersDetailsScreen;
