import React from 'react';
import { GlobeOutline } from 'react-ionicons';
import { colors } from 'constants/colors';

interface HeaderLanguageSwitchTemplateProps {
  className?: string;
  onClick: () => void;
}

const HeaderLanguageSwitchTemplate = (
  props: HeaderLanguageSwitchTemplateProps,
): React.ReactElement => {
  const { onClick, className } = props;

  return (
    <span className={`cursor-pointer ${className}`}>
      <GlobeOutline
        width="18px"
        height="18px"
        onClick={onClick}
        color={colors.palette.grey700}
      />
    </span>
  );
};

export default HeaderLanguageSwitchTemplate;
