export const CATEGORIES_OPTIONS = [
  {
    categoryKey: 'environment_and_nature',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/environment_and_nature.png',
    id: 1,
    valueDe: 'Umwelt & Naturschutz',
    valueEn: 'Environment & Nature',
  },
  {
    categoryKey: 'animal_welfare',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/animal_welfare.png',
    id: 2,
    valueDe: 'Tierschutz',
    valueEn: 'Animal welfare',
  },
  {
    categoryKey: 'children_and_youth',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/children_and_youth.png',
    id: 3,
    valueDe: 'Kinder & Jugend',
    valueEn: 'Children & Youth',
  },
  {
    categoryKey: 'education_and_language',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/education_and_language.png',
    id: 4,
    valueDe: 'Bildung & Sprache',
    valueEn: 'Education & Language',
  },
  {
    categoryKey: 'refuge_and_migration',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/refuge_and_migration.png',
    id: 5,
    valueDe: 'Flucht & Migration',
    valueEn: 'Refuge & Migration',
  },
  {
    categoryKey: 'lgbtqia',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/lgbtqia.png',
    id: 6,
    valueDe: 'LGBTQIA+',
    valueEn: 'LGBTQIA+',
  },
  {
    categoryKey: 'democracy_and_human_rights',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/democracy_and_human_rights.png',
    id: 7,
    valueDe: 'Demokratie & Menschenrechte',
    valueEn: 'Democracy & Human rights',
  },
  {
    categoryKey: 'elderly_people',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/elderly_people.png',
    id: 8,
    valueDe: 'Leben im Alter',
    valueEn: 'Elderly people',
  },
  {
    categoryKey: 'health',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/health.png',
    id: 9,
    valueDe: 'Gesundheit',
    valueEn: 'Health',
  },
  {
    categoryKey: 'poverty_and_homelessness',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/poverty_and_homelessness.png',
    id: 10,
    valueDe: 'Armut & Obdachlosigkeit',
    valueEn: 'Poverty & Homelessness',
  },
  {
    categoryKey: 'people_with_disabilities',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/people_with_disabilities.png',
    id: 11,
    valueDe: 'Menschen mit Behinderungen',
    valueEn: 'People with disabilities',
  },
  {
    categoryKey: 'equality',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/equality.png',
    id: 12,
    valueDe: 'Soziale Gerechtigkeit & Ungleichheit',
    valueEn: 'Equality',
  },
  {
    categoryKey: 'rescue_and_disaster_protection',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/rescue_and_disaster_protection.png',
    id: 13,
    valueDe: 'Rettung & Katastrophenschutz',
    valueEn: 'Rescue & Disaster protection',
  },
  {
    categoryKey: 'death_and_grief_support',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/death_and_grief_support.png',
    id: 14,
    valueDe: 'Sterbe- & Trauerbegleitung',
    valueEn: 'Death & Grief support',
  },
  {
    categoryKey: 'arts_culture',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/arts_culture.png',
    id: 15,
    valueDe: 'Kunst & Kultur',
    valueEn: 'Arts & Culture',
  },
  {
    categoryKey: 'sports_and_recreation',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/sports_and_recreation.png',
    id: 16,
    valueDe: 'Sport & Freizeit',
    valueEn: 'Sports & Recreation',
  },
  {
    categoryKey: 'civic_engagement',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/poverty_and_homelessness.png',
    id: 17,
    valueDe: 'Bürgerschaftliches Engagement',
    valueEn: 'Civic engagement',
  },
];
