import React, { useEffect, useState } from 'react';
import Lodash from 'lodash';
import { Criteria } from 'api/schemas/criteria';
import {
  useRefreshToken,
  useSwitchTranslation,
  useLanguageSwitchStorage,
} from 'utils';
import { ILanguageSwitchTypes } from 'constants/types';
import {
  EnumButtonType,
  EnumLanguageType,
  EnumTextFormatType,
  EnumButtonActionType,
  EnumCriteriaType,
} from 'constants/enums';
import { SKILLS_OPTIONS } from './utils';
import { LActButton, LActRow, LActText } from 'components/index';
import { translate } from 'i18n';
import { usePatchOfferDetails } from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { Offer } from 'api/schemas/offer';

interface SkillsComponentProps {
  offerID: number;
  accessToken: string;
  skills: Array<Criteria>;
  onItemUpdated: (item: Offer) => void;
}

const SkillsComponent = (props: SkillsComponentProps): React.ReactElement => {
  const i18n = 'screens.home.offers.details';
  const { skills, offerID, accessToken, onItemUpdated } = props;
  useSwitchTranslation();
  const [criteria, setCriteria] = useState<Array<Criteria>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  const PATCH_QUERY = usePatchOfferDetails({
    ...parseBearerToken(accessToken ? decryptData(accessToken) : ''),
  });

  useEffect(() => {
    if (skills) {
      const selectedSkills = skills?.map(item => {
        return item?.property.value;
      });

      setSelectedItems(selectedSkills);
      setCriteria(skills);
    }
  }, [skills]);

  useEffect(() => {
    if (message.message) {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
        });
      }, 4000);
    }
  }, [message?.message, message.type]);

  // @ts-ignore
  const languageSwitchStorage: ILanguageSwitchTypes = useLanguageSwitchStorage(
    (store: ILanguageSwitchTypes | unknown) => store,
  );

  const onItemClicked = (skill: string, id: number) => {
    if (selectedItems?.includes(skill)) {
      const updatedSkill = selectedItems?.filter((item: string) => {
        return item !== skill;
      });
      setSelectedItems(updatedSkill);

      const updatedCriteria = criteria.filter(item => {
        return item.property.value !== skill;
      });
      setCriteria(updatedCriteria);
    } else {
      setSelectedItems(selectedItems.concat(skill));

      setCriteria(
        criteria.concat({
          id,
          property: {
            category: EnumCriteriaType.CHARACTERISTIC,
            value: skill,
          },
        }),
      );
    }
  };

  const onSaveClicked = () => {
    const PATCH_DATA: any = [];
    criteria.map(item => {
      return PATCH_DATA.push({
        category: EnumCriteriaType.CHARACTERISTIC,
        value: item?.property?.value,
      });
    });

    PATCH_QUERY?.mutateAsync({
      offerId: offerID?.toString(),
      data: {
        requiredCharacteristics: PATCH_DATA,
      },
    })
      .then((data: any) => {
        onItemUpdated(data?.item);
        setMessage({
          message: translate(`${i18n}.severMessages.successfullyUpdated`),
          type: 'success',
        });
      })
      .catch(error => {
        if (error?.message?.includes('500')) {
          setMessage({
            message: translate(`${i18n}.severMessages.serverError500`),
            type: 'error',
          });
        }
        setMessage({
          message: translate(`${i18n}.severMessages.serverGeneralError`),
          type: 'error',
        });
      });
  };
  useRefreshToken({
    error: PATCH_QUERY?.error,
    refetch: onSaveClicked,
    hasError: PATCH_QUERY?.isError,
  });

  const selectedSkills =
    skills &&
    skills?.map(item => {
      return item?.property.value;
    });

  const disabledSaveButton =
    selectedItems.length > 0 && Lodash.isEqual(selectedSkills, selectedItems);

  return (
    <>
      <LActRow className={'items-center'} spaceBetween>
        <div>
          <LActText
            className="font-semibold"
            text={translate(`${i18n}.softSkillSection`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
          />
        </div>
        <div>
          <LActButton
            testID={'login-button'}
            onClick={onSaveClicked}
            type={EnumButtonType.TEXT}
            useLoading={PATCH_QUERY?.isLoading}
            text={translate(`${i18n}.saveButton`)}
            actionType={EnumButtonActionType.BUTTON}
            containerStyle={'items-end py-0 bg-transparent border-0'}
            textStyle={`text-tint ${disabledSaveButton && 'opacity-50'}`}
          />
        </div>
      </LActRow>

      <div className="flex flex-wrap justify-center justify-evenly items-center desktop:px-20">
        {SKILLS_OPTIONS?.map((item, index) => {
          const BG_SELECTED = selectedItems?.includes(item?.key)
            ? 'bg-tint text-white'
            : 'bg-white text-textSecondary';

          const TEXT_COLOR_SELECTED = selectedItems?.includes(item?.key)
            ? 'text-white font-semibold'
            : 'text-tint';

          const title =
            languageSwitchStorage?.language === EnumLanguageType.GERMAN
              ? item?.titleDE
              : item?.titleEN;

          return (
            <span
              key={`category-badge-${index}`}
              onClick={() => onItemClicked(item.key, item?.id)}
              className={`cursor-pointer px-3 py-2 mx-1 rounded-full mt-3 ${BG_SELECTED} ${TEXT_COLOR_SELECTED}`}
            >
              {title}
            </span>
          );
        })}
      </div>

      {message?.message && (
        <LActText
          text={message?.message}
          className={'font-semibold mt-6'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          textColor={message.type === 'error' ? 'text-error' : 'text-green'}
        />
      )}
    </>
  );
};

export default SkillsComponent;
