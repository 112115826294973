import React, { useState } from 'react';
import { LActButton, LActCard, LActRow, LActTextInput } from 'components/index';
import {
  EnumErrorType,
  EnumButtonType,
  EnumButtonActionType,
  EnumSchedulingFrequency,
} from 'constants/enums';
import { TxKeyPath, translate } from 'i18n';
import { AVAILABILITY_OPTIONS } from './utils';
import { useOfferCreationStorage, useSwitchTranslation } from 'utils';
import { IOfferCreationTypes } from 'constants/types';

type Props = {
  lastStep: number;
  currentStep: number;
  onNextClicked: () => void;
};

const OfferCreationScheduleTemplate = (props: Props): React.ReactElement => {
  const i18n = 'screens.offerCreation.schedule';
  useSwitchTranslation();
  const { lastStep, currentStep, onNextClicked } = props;
  const [minHoursMessage, setMinHoursMessage] = useState<string>();
  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );
  const schedule = offerCreationStorage?.offer?.schedule;

  const [minHours, setMinHours] = useState<number>(schedule?.minHours);
  const [selectedFrequency, setSelectedFrequency] = useState({
    id: schedule?.id,
    frequency: schedule?.frequency,
    hasWeekDays:
      schedule?.frequency !== EnumSchedulingFrequency.FLEXIBLE && true,
    selectedWeekDays: schedule?.weekdays,
    minHours: minHours,
  });

  const CURRENT_STATE_AVAILABILITY = {
    frequency: selectedFrequency?.frequency,
    id: selectedFrequency?.id,
    minHours: minHours,
    weekdays: selectedFrequency?.selectedWeekDays,
  };

  const onWeekdayItemsPressed = (name: string, id: number) => {
    if (selectedFrequency?.selectedWeekDays?.includes(name)) {
      /**
       * Deletes the selected days from the list
       */
      const updatedWeekDays = selectedFrequency?.selectedWeekDays?.filter(
        day => {
          return day !== name;
        },
      );
      setSelectedFrequency({
        id: id?.toString(),
        frequency: selectedFrequency.frequency,
        hasWeekDays: selectedFrequency.hasWeekDays,
        selectedWeekDays: updatedWeekDays,
        minHours: schedule?.minHours,
      });
    } else {
      setSelectedFrequency({
        id: id?.toString(),
        frequency: selectedFrequency.frequency,
        hasWeekDays: selectedFrequency.hasWeekDays,
        selectedWeekDays: selectedFrequency.selectedWeekDays.concat(name),
        minHours: schedule?.minHours,
      });
    }
  };
  const onFrequencyItemsPressed = (
    id: number,
    frequency: string,
    hasWeekDays: boolean,
  ) => {
    if (selectedFrequency.frequency === frequency) {
      const hasWeekDays =
        schedule.frequency === frequency && schedule.weekdays?.length > 0
          ? true
          : false;
      const selectedWeekDays =
        schedule.frequency === frequency && schedule.weekdays?.length > 0
          ? schedule.weekdays
          : [];
      setSelectedFrequency({
        id: id?.toString(),
        frequency: frequency,
        hasWeekDays: hasWeekDays,
        selectedWeekDays: selectedWeekDays,
        minHours: schedule?.minHours,
      });
    } else {
      setSelectedFrequency({
        id: id?.toString(),
        frequency,
        hasWeekDays,
        selectedWeekDays: [],
        minHours: schedule?.minHours,
      });
    }

    const offer = {
      ...offerCreationStorage.offer,

      schedule: {
        frequency: '',
        weekdays: [],
        minHours: 0,
        id: '',
      },
    };

    offerCreationStorage.storeOffers(offer);
  };

  const onMinHoursChange = (hours: string) => {
    setMinHours(Number(hours));
    if (Number(hours) === 0) {
      setMinHoursMessage(translate(`${i18n}.wrongMinHours`));
    } else {
      setMinHoursMessage('');
    }
  };

  const handleOnNextClicked = () => {
    const offer = {
      ...offerCreationStorage.offer,

      schedule: CURRENT_STATE_AVAILABILITY,
    };

    offerCreationStorage.storeOffers(offer);

    onNextClicked();
  };

  const BUTTON_IS_DISABLED =
    selectedFrequency?.frequency === EnumSchedulingFrequency.FLEXIBLE
      ? false
      : selectedFrequency?.selectedWeekDays?.length > 0 && minHours
      ? false
      : true;

  return (
    <div>
      <LActCard className="overflow-scroll">
        {AVAILABILITY_OPTIONS.map((item, index) => {
          const topMargin = index > 0 ? 'mt-2' : '';

          const selectedFrequencyStyle =
            selectedFrequency.frequency !== item.key
              ? 'bg-white rounded-xl w-full'
              : 'bg-tint rounded-xl w-full';

          const selectedFrequencyTextStyle =
            selectedFrequency.frequency !== item.key ? '' : 'text-white';

          return (
            <div key={index}>
              <button
                onClick={() =>
                  onFrequencyItemsPressed(
                    Number(schedule?.id),
                    item.key,
                    item?.weekdays ? true : false,
                  )
                }
                className={`px-4 py-2 mt-3 ${selectedFrequencyStyle} ${topMargin}`}
              >
                <span className={`font-semibold ${selectedFrequencyTextStyle}`}>
                  {translate(
                    `${i18n}.availability.${item.frequency}` as TxKeyPath,
                  )}
                </span>
              </button>

              {selectedFrequency.hasWeekDays &&
              selectedFrequency.frequency === item.key ? (
                <div className="ml-4 flex flex-col justify-center items-center">
                  <div className="text-gray-700 mt-3">
                    {translate(`${i18n}.availability.weekdaysTitle`)}
                  </div>

                  <div className="flex space-x-2">
                    {item?.weekdays &&
                      item?.weekdays.map((days, index) => {
                        const selectedWeekdayStyle =
                          !selectedFrequency?.selectedWeekDays?.includes(
                            days.key,
                          )
                            ? 'bg-white'
                            : 'bg-tint';

                        const selectedFrequencyTextStyle =
                          !selectedFrequency?.selectedWeekDays?.includes(
                            days.key,
                          )
                            ? 'text-tint'
                            : 'text-white';

                        const nameOfTheDay = translate(
                          `${i18n}.availability.weekDays.${days?.name}` as TxKeyPath,
                        ) as string;

                        const weekDayName = nameOfTheDay
                          ?.slice(0, 2)
                          .toLowerCase();

                        return (
                          <button
                            key={index}
                            onClick={() =>
                              onWeekdayItemsPressed(
                                days.key,
                                Number(schedule?.id),
                              )
                            }
                            className={`w-10 h-10 p-2 rounded-full mt-2 mb-2 ${selectedWeekdayStyle}`}
                          >
                            <span
                              className={`font-semibold ${selectedFrequencyTextStyle}`}
                            >
                              {weekDayName.charAt(0).toUpperCase() +
                                weekDayName.slice(1)}
                            </span>
                          </button>
                        );
                      })}
                  </div>

                  <LActTextInput
                    maxLength={7}
                    type={'text'}
                    focusBG="focus:bg-white"
                    value={minHours?.toString()}
                    onChange={onMinHoursChange}
                    containerStyle="mt-3 bg-white"
                    messageType={EnumErrorType.ERROR}
                    placeHolder={translate(`${i18n}.minHours`)}
                    message={
                      minHoursMessage
                        ? translate(`${i18n}.wrongMinHours`)
                        : undefined
                    }
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </LActCard>

      <div>
        <LActRow className={'relative w-full mt-4 bottom-1 w-2/6'}>
          <LActButton
            testID={'login-button'}
            textStyle={'text-tint'}
            onClick={() => undefined}
            type={EnumButtonType.TEXT}
            actionType={EnumButtonActionType.BUTTON}
            text={translate(`${i18n}.step`) + `  ${currentStep} / ${lastStep}`}
          />

          <LActButton
            testID={'login-button'}
            type={EnumButtonType.NORMAL}
            disabled={BUTTON_IS_DISABLED}
            onClick={handleOnNextClicked}
            text={translate(`${i18n}.nextButton`)}
            actionType={EnumButtonActionType.BUTTON}
          />
        </LActRow>
      </div>
    </div>
  );
};

export default OfferCreationScheduleTemplate;
