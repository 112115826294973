export enum EnumTextFormatType {
  FONT_SIZE_64 = 'H1',
  FONT_SIZE_48 = 'H2',
  FONT_SIZE_40 = 'H3',
  FONT_SIZE_32 = 'H2',
  FONT_SIZE_24 = 'BODY_1',
  FONT_SIZE_16 = 'BODY_2',
  FONT_SIZE_14 = 'BODY_3',
  FONT_SIZE_12 = 'BODY_4',
}

export enum EnumButtonType {
  TEXT = 'text',
  NORMAL = 'normal',
  OUTLINE = 'outline',
}

export enum EnumButtonActionType {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset',
}

export enum EnumErrorType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

export enum EnumImageType {
  IMAGE = 'image',
  BACKGROUND = 'background',
}

export enum EnumInputType {
  EMAIL = 'email',
  ADDRESS = 'address',
  PASSWORD = 'password',
  LAST_NAME = 'lastname',
  FIRST_NAME = 'firstname',
  PHONE_NUMBER = 'phoneNumber',
}

export enum EnumApplicationsType {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CONTACTED = 'CONTACTED',
  IN_REVIEW = 'IN_REVIEW',
}

export enum EnumOrganizationStatusType {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export enum EnumSchedulingFrequency {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  FLEXIBLE = 'FLEXIBLE',
}

export enum EnumSchedulingWeekdays {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export enum EnumOfferLocationType {
  REMOTE = 'REMOTE',
  ONSITE = 'ONSITE',
  PRIVATE = 'PRIVATE',
}

export enum EnumLanguageType {
  GERMAN = 'de',
  ENGLISH = 'en',
}

export enum EnumAlertType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum EnumOffersFilterType {
  ALL = 'ALL',
  FILLED = 'FILLED',
  PAUSED = 'PAUSED',
  EXPIRED = 'EXPIRED',
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED',
}

export enum EnumCriteriaType {
  LANGUAGE = 'language',
  CHARACTERISTIC = 'characteristic',
}

export enum EnumManagementScreenType {
  OFFERS = 'OFFERS',
  OVERVIEW = 'OVERVIEW',
  APP_UPDATE = 'APP_UPDATE',
  ORGANIZATIONS = 'ORGANIZATIONS',
  LAST_OFFERS_NOTIFICATIONS = 'LAST_OFFERS_NOTIFICATIONS',
  EVENT_NOTIFICATIONS = 'EVENT_NOTIFICATIONS',
}

export enum EnumManagementOfferSearchType {
  OFFER_NAME = 'OFFER_NAME',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
}
