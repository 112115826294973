import React, { useEffect, useState } from 'react';
import { LActLoader, LActDropdown, LActRow } from 'components/index';
import { EnumApplicationsType } from 'constants/enums';
import {
  IApplicationsIDsTypes,
  IApplicationStatusTypes,
  IAcceptApplicationsTypes,
  IRejectApplicationsTypes,
  IApplicationStorageTypes,
  IKanbanApplicationsTypes,
  IApplicationAcceptRejectTypes,
} from 'constants/types';
import { statusDropdownHandler } from './utils';
import { usePatchApiV1ApplicationStatus } from 'api/endpoints/queries';
import { IAccessTokenTypes } from 'constants/types';
import {
  useRefreshToken,
  useSwitchTranslation,
  useAccessTokenStorage,
  useApplicationStorage,
  useApplicationsIDsStorage,
  useApplicationRejectStorage,
  useUpdateKanbanApplicationsStorage,
  useTriggerApplicationAcceptRejectStorage,
} from 'utils';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { ApplicationItems } from 'api/schemas/applicationItems';
import useApplicationAcceptStorage from 'utils/storage/useApplicationAcceptStorage';
import { TxKeyPath, translate } from 'i18n';

interface ApplicationStatusTemplateProps {
  status?: string;
  application?: ApplicationItems;
  onChangeStatusClicked: () => void;
}

const ApplicationStatusTemplate = (
  props: ApplicationStatusTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.home.sidebar';
  const { status, onChangeStatusClicked } = props;
  const [statusChangedIDs] = useState<Array<number>>([]);
  const [closeDropdown, setCloseDropdown] = useState<boolean>(false);
  const [selectedItemStatus, setSelectedItemStatus] = useState<string>('');

  useSwitchTranslation();

  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  // @ts-ignore
  const kanbanUpdateStorage: IKanbanApplicationsTypes =
    useUpdateKanbanApplicationsStorage(
      (store: IKanbanApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const acceptApplicationStorage: IAcceptApplicationsTypes =
    useApplicationAcceptStorage(
      (store: IAcceptApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const acceptRejectStorage: IApplicationAcceptRejectTypes =
    useTriggerApplicationAcceptRejectStorage(
      (store: IApplicationAcceptRejectTypes | unknown) => store,
    );

  // @ts-ignore
  const rejectApplicationStorage: IRejectApplicationsTypes =
    useApplicationRejectStorage(
      (store: IRejectApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const applicationsIDsStorage: IApplicationsIDsTypes =
    useApplicationsIDsStorage(
      (store: IApplicationsIDsTypes | unknown) => store,
    );

  const API_QUERY = usePatchApiV1ApplicationStatus({
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });

  const queryAPI = (status: string) => {
    if (!status) return;
    API_QUERY?.mutateAsync({
      offer_id: `${applicationStorage?.application?.offer?.id}`,
      application_id: `${applicationStorage?.application?.id}`,
      data: {
        status: status,
      },
    })
      .then(response => {
        if (response) {
          applicationsIDsStorage.storeApplicationsIDs(
            statusChangedIDs.concat(applicationStorage?.application?.id),
          );
        }
      })
      .catch(error => error);
  };
  useRefreshToken({
    error: API_QUERY?.error,
    hasError: API_QUERY?.isError,
    refetch: () => queryAPI(selectedItemStatus),
  });

  useEffect(() => {
    if (acceptRejectStorage?.status) {
      queryAPI(acceptRejectStorage?.status);
    }
  }, [acceptRejectStorage]);

  const {
    DROP_DOWN_BG_COLOR,
    DROPDOWN_ICON_COLOR,
    DROPDOWN_SHOWS_ICON,
    DROP_DOWN_TITLE_TEXT,
    DROP_DOWN_TITLE_COLOR,
    DROP_DOWN_CHILD_ITEMS,
  } = statusDropdownHandler(status);

  const DropdownItems = (): React.ReactElement => {
    const onItemsClicked = (status: EnumApplicationsType) => {
      setSelectedItemStatus(status);
      if (status === EnumApplicationsType.ACCEPTED) {
        acceptApplicationStorage.showAcceptDialog('SIDEBAR');
        setCloseDropdown(true);
      } else if (status === EnumApplicationsType.REJECTED) {
        rejectApplicationStorage.showRejectDialog('SIDEBAR');
        setCloseDropdown(true);
      } else {
        kanbanUpdateStorage.storeKanbanApplications(
          applicationStorage?.application?.id,
          status,
        );
        setCloseDropdown(true);
        onChangeStatusClicked();
        queryAPI(status);
      }
    };

    return (
      <span className={'pointer-cursor'}>
        {DROP_DOWN_CHILD_ITEMS?.map(
          (value: IApplicationStatusTypes, index: number) => {
            return (
              <button
                key={`${index + value?.id}+1`}
                onClick={() => onItemsClicked(value?.status)}
                className={`
                      py-2 
                      px-4 
                      my-2.5 
                      text-sm 
                      delay-50 
                      transform
                      transition 
                      ease-in-out 
                      inline-block 
                      rounded-full 
                      duration-300 
                      font-semibold 
                      hover:scale-110
                      hover:-translate-y-1
                      ${value?.bgColor} 
                      ${value?.titleColor}`}
              >
                <LActRow className={'items-center'}>
                  <>
                    {translate(`${i18n}.${value?.title}` as TxKeyPath)}
                    {API_QUERY?.isLoading &&
                      value.status === selectedItemStatus && (
                        <span className={'ml-1'}>
                          <LActLoader
                            size={'sm'}
                            color={`${value?.titleColor}`}
                          />
                        </span>
                      )}
                  </>
                </LActRow>
              </button>
            );
          },
        )}
      </span>
    );
  };

  return (
    <div>
      <LActDropdown
        close={closeDropdown}
        showsIcon={DROPDOWN_SHOWS_ICON}
        iconColor={DROPDOWN_ICON_COLOR}
        titleText={DROP_DOWN_TITLE_TEXT}
        titleColor={DROP_DOWN_TITLE_COLOR}
        childComponent={<DropdownItems />}
        containerStyle={DROP_DOWN_BG_COLOR}
      />
    </div>
  );
};

export default ApplicationStatusTemplate;
