import { Translations } from './de';

const en: Translations = {
  responseMessages: {
    error400: 'There is a problem in your input data, Please check them again',
    error401: 'You are not authorized, please login first',
    error403: "You don't have the permission to see this step",
    error404: 'The given email address is not registered.',
    error409: 'The email is in use already, please login instead.',
    error422:
      'There was an operation error with you inputs, please check your Email and Password',
    error500:
      'There was an error on our servers, we are checking them, thanks for your patience :)',
  },
  screens: {
    login: {
      login: 'Login to your letsact account',
      loginButton: 'Login',
      description: 'Log in and take action!',
      errorMessage: {
        invalidEmail: 'Invalid email address',
        verifyEmail: 'Email not verified. Please verify your email',
        invalidPasswordMatch: 'Invalid password',
        invalidPassword:
          'The password must contain at least 8 characters, including at least one uppercase letter and one number.',
        invalidInputs:
          "Whoops! The email or password isn't quite right. \nWant to give it another go or reset it?",
      },
      emailPlaceHolder: 'Enter your E-Mail',
      passwordPlaceHolder: 'Enter your password',
      forgetPassword: 'Forgot password?',
      register: 'Register instead',
    },
    register: {
      titleOne: 'Register your',
      titleTwo: 'organisation',
      description: 'Start your journey today and register your account.',
      registerButton: 'Register',
      loginButton: 'Already have an account? Login',
      emailPlaceHolder: 'Enter your E-Mail',
      passwordPlaceHolder: 'Enter your password',
      orgNamePlaceHolder: 'Organisation name',
      homePageUrlPlaceholder: 'Your website Url with https://',
      passwordInfoMessage:
        'At least 8 characters, a lowercase and an uppercase',
      passwordMatchMessage: 'Awesome, that’s a secure password!',
      homePageUrlMessage:
        'Please enter your Website, Instagram or Linkedin Url',
      errorMessage: {
        invalidOrg: 'Please enter your organisation name',
        invalidEmail: 'Please enter a valid email',
        invalidPasswordMatch:
          'At least 8 characters, including lower case (a-z), upper case (A-Z) and numbers (0-9)',
        userEmailExists:
          'The email is already registered with us. Do you want to login instead?',
        invalidPassword:
          'The password must contain at least 8 characters, including at least one uppercase letter and one number.',
        invalidInputs:
          "Whoops! The email or password isn't quite right. \nWant to give it another go or reset it?",
        invalidOrgName: 'Name characters should be between 3 to 50',
        invalidHomePageUrl: 'Please enter a valid Url',
        invalidHomePageUrlLength: 'Url must contain at least 10 characters',
      },
      welcomeModal: {
        titleFirst: 'Welcome to the',
        titleSecond: 'letsact',
        titleThird: 'universe!',
        letsGoButton: 'Lets Go!',
      },
    },
    checkInbox: {
      titleOne: 'You’ve got mail!',
      titleTwo: 'Please check your inbox',
      description: 'Confirm your email by clicking on the link we sent to',
      continue: 'Alright',
      resendButton: 'Resend link',
      reSending: 'Resending',
      linkSent: 'Link sent',
      linkSendError: "Couldn't send link",
    },
    organizationDetails: {
      titleOne: 'Tell us a bit more',
      titleTwo: 'about your',
      titleThree: 'organisation',
      descriptionPlaceHolder: 'Describe your organisation',
      nextButton: 'Next',
      setupLaterButton: 'Set up later',
      successUpdateMessage: 'Successfully updated',
      unsuccessfulUpdateMessage:
        'There was a problem with updating the data, please try again!',
    },
    organizationContact: {
      titleOne: 'And who is the main',
      titleTwo: 'contact person?',
      description:
        'These contact details will be shown to potential volunteers to get in touch with you.',
      firstName: 'First name',
      lastName: 'Last name',
      phoneNumber: 'Add your phone number',
      successUpdateMessage: 'Successfully updated',
      unsuccessfulUpdateMessage:
        'There was a problem with updating the data, please try again!',
      setupLaterButton: 'Set up later',
      nextButton: 'Finish registration',
      name: 'Name',
      validationErrorMessages: {
        invalidEmail: 'Invalid email address',
        invalidFirstName: 'First name characters should be between 3 to 50',
        invalidLastName: 'Last name characters should be between 3 to 50',
        invalidPhoneNumber:
          'Please enter your phone number without leading 0 or country code',
        invalidPhoneNumberLength: 'Please enter a valid phone number',
        invalidCountryCodeLength:
          'Country code should include at least 2 characters',
        invalidPlus: 'Country code should include +',
        emptyCountryCode: 'Please enter the country code',
      },
    },
    resetPassword: {
      title: 'Forgot password?',
      description: 'Please enter your email to reset your password.',
      placeHolder: 'Your email',
      infoMessage: "We'll send you a link to reset your password",
      resetPassword: 'Reset password',
      loginButton: 'Login',
      errorMessage: {
        invalidEmail: 'Invalid Email Address',
        verifyEmail: 'Email not verified. Please verify your Email',
      },
    },
    resetPasswordCheckInbox: {
      title: 'You’ve got mail! \nPlease check your \ninbox',
      description:
        'Reset your password by clicking on the link we sent to {{email}}. Change email',
      login: 'Login',
    },
    home: {
      verification: {
        title: 'Review in progress!',
        description:
          'To ensure high quality content on our platform, we are currently reviewing your non-profit status. Once the review is complete, we will send you an email. This can take up to 48 hours.',
      },
      applications: {
        openApplications: 'Open Applications',
        manageApplicationsButton: 'Manage all',
        appliedOn: 'Applied on',
        appliedFor: 'Applied for',
        applicationsEmptyState: 'No applications to show!',
        new: 'New',
        emptyState: {
          title: 'No open Applications',
          description:
            'But don’t worry, there are countless Volunteers already waiting in our app.',
        },
        acceptActionModal: {
          title: '{{name}} is on board?',
          description:
            'Remember, you cannot revert this action. So please make sure that this person actually supports your organisation',
          reviewButton: 'Keep volunteer in review',
          acceptButton: "Yes, let's get started!",
        },
        rejectActionModal: {
          title: "It didn't work out with {{name}}?",
          description:
            "Remember, you cannot revert this action. So please make sure, that this person won't support your organisation.",
          rejectButton: "Didn't work out",
          reviewButton: 'Keep volunteer in review',
        },
        acceptModalNotify: {
          title: 'Congratulations!',
          description:
            '{{name}} will make a great impact at your organisation. We wish you a great journey together.',
          approveButton: 'Amazing!',
        },
      },
      offers: {
        title: 'Your offers',
        createButton: 'Create offer',
        flexible: 'Flexible',
        perMonth: 'per month',
        perWeek: 'per week',
        remote: 'Remote',
        hours: {
          one: '1 hour',
          other: '{{count}} hours',
        },
        weekDays: {
          monday: 'Mon',
          tuesday: 'Tue',
          wednesday: 'Wed',
          thursday: 'Thu',
          friday: 'Fri',
          saturday: 'Sat',
          sunday: 'Sun',
        },
        tabs: {
          all: 'All',
          active: 'Active',
          inActive: 'Inactive',
        },
        emptyState: {
          title: `You don't have any offers yet,`,
          description: 'Why not starting with your first one?',
          noFilterResult: 'No offers available',
        },
        status: {
          filled: 'Filled',
          paused: 'Paused',
          expired: 'Expired',
          published: 'Published',
          deleted: 'Delete',
          deleteDone: 'Deleted',
        },
        details: {
          title: 'Edit Offer',
          pausedOfferNotice:
            'Your offer has been paused by Admin, please contact us for more info at: "organisation@letsact.de"',
          breadcrumb: {
            home: 'Home',
            offer: 'Offer',
          },
          errorMessages: {
            goal: 'Minimum text in Goal section must be 5 characters',
            title: 'Minimum text in Title section must be 5 characters',
            impact: 'Minimum text in Impact section must be 5 characters',
            emptyCategorySelected: 'You must select a category!',
            emptyLanguagesSelected: 'You should select at least one language!',
            emptySkillsSelected: 'You should select at least one skill!',
            emptyStreet: 'You should enter street',
            emptyCity: 'You should enter city',
            emptyZipCode: 'You should enter zip code',
            emptyWeekDays: 'Please select at least one day',
            wrongMinHours: 'Please enter valid hours',
            wrongImageSize: 'The maximum size is more than 2MB',
          },
          severMessages: {
            successfullyUpdated: 'Update was successful',
            serverError500: 'Server error',
            serverGeneralError: 'An error happened, please tray again',
            unsupportedContentType:
              'The image type is not supported, please select a JPG or PNG',
          },
          infoMessage: {
            imageSizeInfo: 'The maximum size should be 2MB in JPG or PNG',
          },
          backButton: 'Back',
          saveButton: 'Save',
          showMore: 'Show more',
          showLess: 'Show less',
          titleSection: 'Title',
          impactSection: 'Impact',
          goalSection: 'Goal',
          imageSection: 'Image',
          scheduleSection: 'Schedule',
          categorySection: 'Category',
          languageSection: 'Language',
          locationSection: 'Location',
          softSkillSection: 'Soft Skills',
          minHours: 'Min Hours',

          criteria: {
            languages: {
              German: 'German',
              English: 'English',
              Spanish: 'Spanish',
              French: 'French',
              Arabic: 'Arabic',
              Russian: 'Russian',
              Italian: 'Italian',
              Turkish: 'Turkish',
            },
          },
          availability: {
            monthly: 'Monthly',
            weekly: 'Weekly',
            flexible: 'Flexible',
            weekdaysTitle: 'Specify the days on which you need help',
            weekDays: {
              monday: 'Mo',
              tuesday: 'Tu',
              wednesday: 'We',
              thursday: 'Th',
              friday: 'Fr',
              saturday: 'Sa',
              sunday: 'Su',
            },
          },
          location: {
            street: 'Street',
            city: 'City',
            postCode: 'Zipcode',
          },
        },
      },
      sidebar: {
        greetings: "Hi, I'm",
        offerTitle: 'I’m interested in helping:',
        dropdown: {
          open: 'Open',
          contacted: 'Contacted',
          inContact: 'In contact',
          takingPart: 'Taking part!',
          didntWork: `Didn't work out`,
        },
      },
    },
    offerCreation: {
      title: 'Create Offer',
      step: 'Step',
      skip: 'Skip',
      back: 'Back',
      breadcrumb: {
        home: 'Home',
        offerCreation: 'Creation',
      },
      successOfCreation: 'Your offer is created successfully',
      errorOfCreation: 'Your offer is not created due to an error',
      name: {
        titleOne: 'Make the first',
        titleTwo: 'impression count!',
        nextButton: 'Next',
        step: 'Step',
        title: 'Name',
        goal: 'Goal',
        tasks: 'Impact',
        categoryTitle: 'How can we help you?',
        categoryDescription:
          'Let us figure out the basic information, how we can create the most impact together.',
        categoryAlert: 'You can choose one main category.',
        detailsTitle: 'Make the first impression count!',
        description:
          'Add a nice image and a short title that arouses some interest in the Volunteer world!',
      },
      schedule: {
        title: 'How often should a Volunteer be available?',
        nextButton: 'Next',
        step: 'Step',
        minHours: 'Min Hours',
        wrongMinHours: 'Please enter valid hours',
        availability: {
          monthly: 'Monthly',
          weekly: 'Weekly',
          flexible: 'Flexible',
          weekdaysTitle: 'Specify the days on which you need help',
          weekDays: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
          },
        },
      },
      location: {
        title: 'Where does your offer take place?',
        nextButton: 'Next',
        step: 'Step',
        street: 'Street',
        city: 'City',
        postCode: 'Zipcode',
        emptyStreet: 'You should enter street',
        emptyCity: 'You should enter city',
        emptyZipCode: 'You should enter zip code',
      },
      language: {
        nextButton: 'Next',
        createButton: 'Create',
        step: 'Step',
        title: 'Which languages are required?',
        titleSkills: 'What qualities are important to you?',
        description:
          'Every Non-Profit has specific requirements and we appreciate that. So to find the best fit for you, tell us more about what a Volunteer should look like.',
        languages: {
          German: 'German',
          English: 'English',
          Spanish: 'Spanish',
          French: 'French',
          Arabic: 'Arabic',
          Russian: 'Russian',
          Italian: 'Italian',
          Turkish: 'Turkish',
          Others: 'others',
        },
      },
      preview: {
        idealVolunteer: 'You bring along:',
        ourGoal: 'Our Goal',
        yourImpact: 'Your Impact',
        when: 'When?',
        howOften: 'How often?',
        howLong: 'How long?',
        where: 'Where?',
        remote: 'Remote',
        onsite: 'On-site',
        flexible: 'Flexible',
        perMonth: 'per month',
        perWeek: 'per week',
        hours: {
          one: '1 hour',
          other: '{{count}} hours',
        },
      },
    },
    profile: {
      title: 'Your Profile',
      successUpdateMessage: 'Successfully updated',
      unsuccessfulUpdateMessage:
        'There was a problem with updating the data, please try again!',
      back: 'Back',
      breadcrumb: {
        home: 'Home',
        profile: 'Profile',
      },
      uploadImage: 'Upload your image',
      maxImageSize: 'max 2.MB',
      errorMessages: {
        wrongImageSize: 'The maximum size is more than 2MB',
      },
      severMessages: {
        successfullyUpdated: 'Update was successful',
        serverError500: 'Server error',
        serverGeneralError: 'An error happened, please tray again',
        unsupportedContentType:
          'The image type is not supported, please select a JPG or PNG',
      },
      infoMessage: {
        imageSizeInfo: 'The maximum size should be 2MB in JPG or PNG',
      },
    },
    applications: {
      title: 'Manage all applications',
      emptyState: 'No volunteers',
      back: 'Back',
      breadcrumb: {
        home: 'Home',
        applications: 'Applications',
      },
    },
  },
  templates: {
    header: {
      logout: 'Logout',
      english: 'English',
      german: 'German',
      profile: 'Profile',
      management: 'Management',
      supportHelp: 'Help & Support',
      dataPrivacy: 'Data & Privacy',
    },
    contactInfo: {
      name: 'Name',
      successUpdateMessage: 'Successfully updated',
      unsuccessfulUpdateMessage:
        'There was a problem with updating the data, please try again!',
      validationErrorMessages: {
        invalidEmail: 'Invalid email address',
        invalidFirstName: 'First name characters should be between 3 to 50',
        invalidLastName: 'Last name characters should be between 3 to 50',
        invalidCountryCodeLength:
          'Country code should include at least 2 characters',
        invalidPlus: 'Country code should include +',
        emptyCountryCode: 'Please enter the country code',
        invalidPhoneNumber:
          'Please enter your phone number without leading 0 or country code',
        invalidPhoneNumberLength: 'Please enter a valid phone number',
      },
    },
  },
};

export default en;
