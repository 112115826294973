import { EnumApplicationsType } from 'constants/enums';
import { create } from 'zustand';

const useTriggerApplicationAcceptRejectStorage = create(set => ({
  status: undefined,
  triggerStatus: (status: EnumApplicationsType) => set({ status: status }),
  purgeTriggerStatus: () => set({ status: undefined }),
}));

export default useTriggerApplicationAcceptRejectStorage;
