import React, { useEffect, useMemo, useRef, useState } from 'react';
import '@coreui/coreui/dist/css/coreui.min.css';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import Navigation from 'navigation/navigation';
import useSwitchTranslation from 'utils/hooks/useSwitchTranslation';
import {
  useApplicationStorage,
  useApplicationAcceptStorage,
  useApplicationRejectStorage,
  useUpdateKanbanApplicationsStorage,
  useTriggerApplicationAcceptRejectStorage,
} from 'utils';
import {
  IAcceptApplicationsTypes,
  IRejectApplicationsTypes,
  IKanbanApplicationsTypes,
  IApplicationStorageTypes,
  IApplicationAcceptRejectTypes,
} from 'constants/types';
import StatusAcceptedDialogTemplate from 'components/templates/applicationStatus/status.accepted.dialog';
import StatusRejectedDialogTemplate from 'components/templates/applicationStatus/status.rejected.dialog';
import { EnumApplicationsType } from 'constants/enums';
import { ApplicationSuccessModalNotifyTemplate } from './components';
import { delay } from 'utils/global/functions';

function App(): React.ReactElement {
  const queryClient = useRef(new QueryClient());
  const [visibleSuccessNotify, setVisibleSuccessNotify] =
    useState<boolean>(false);
  // @ts-ignore
  const acceptApplicationStorage: IAcceptApplicationsTypes =
    useApplicationAcceptStorage(
      (store: IAcceptApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const rejectApplicationStorage: IRejectApplicationsTypes =
    useApplicationRejectStorage(
      (store: IRejectApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const acceptRejectStorage: IApplicationAcceptRejectTypes =
    useTriggerApplicationAcceptRejectStorage(
      (store: IApplicationAcceptRejectTypes | unknown) => store,
    );

  // @ts-ignore
  const kanbanUpdateStorage: IKanbanApplicationsTypes =
    useUpdateKanbanApplicationsStorage(
      (store: IKanbanApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  useEffect(() => {
    if (acceptRejectStorage.status === EnumApplicationsType.ACCEPTED) {
      delay(500)
        .then(() => setVisibleSuccessNotify(true))
        .catch(error => error);
    }
  }, [acceptRejectStorage?.status]);

  const onCloseViews = () => {
    setVisibleSuccessNotify(false);
    acceptRejectStorage?.purgeTriggerStatus();
  };

  useSwitchTranslation();

  const onAcceptDialogClicked = () => {
    kanbanUpdateStorage.storeKanbanApplications(
      applicationStorage?.application?.id,
      EnumApplicationsType.ACCEPTED,
    );
    acceptRejectStorage.triggerStatus(EnumApplicationsType.ACCEPTED);
    acceptApplicationStorage.purgeAcceptDialog();
  };

  const onRejectDialogClicked = () => {
    kanbanUpdateStorage.storeKanbanApplications(
      applicationStorage?.application?.id,
      EnumApplicationsType.REJECTED,
    );
    acceptRejectStorage.triggerStatus(EnumApplicationsType.REJECTED);
    rejectApplicationStorage.purgeRejectDialog();
  };
  const acceptDialogContainer = useMemo(() => {
    const MODAL_VIEW_FLOW =
      acceptApplicationStorage?.scope === 'KANBAN'
        ? 'justify-center items-center -pb-6'
        : 'desktop:justify-end mobile:justify-center items-center desktop:pr-32';

    return (
      <div
        onClick={() => acceptApplicationStorage.purgeAcceptDialog()}
        className={`bg-dropLayer h-screen w-screen absolute top-0 z-3000 flex flex-1 ${MODAL_VIEW_FLOW}`}
      >
        <StatusAcceptedDialogTemplate
          onAcceptClicked={onAcceptDialogClicked}
          onKeepCurrentStateClicked={() =>
            acceptApplicationStorage.purgeAcceptDialog()
          }
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptApplicationStorage.scope]);

  const rejectDialogContainer = useMemo(() => {
    const MODAL_VIEW_FLOW =
      rejectApplicationStorage?.scope === 'KANBAN'
        ? 'justify-center items-center -pb-6'
        : 'desktop:justify-end mobile:justify-center items-center desktop:pr-32';

    return (
      <div
        onClick={() => rejectApplicationStorage.purgeRejectDialog()}
        className={`bg-dropLayer h-screen w-screen absolute top-0 z-3000 flex flex-1 ${MODAL_VIEW_FLOW}`}
      >
        <StatusRejectedDialogTemplate
          onRejectClicked={onRejectDialogClicked}
          onKeepCurrentStateClicked={() =>
            rejectApplicationStorage.purgeRejectDialog()
          }
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectApplicationStorage.scope]);

  return (
    <div>
      {acceptApplicationStorage.acceptDialogVisible && (
        <>{acceptDialogContainer}</>
      )}

      {rejectApplicationStorage.rejectDialogVisible && (
        <>{rejectDialogContainer}</>
      )}
      {visibleSuccessNotify && (
        <div className={'h-screen w-screen absolute top-0 z-3000 flex flex-1'}>
          <ApplicationSuccessModalNotifyTemplate
            onclose={onCloseViews}
            visible={visibleSuccessNotify}
            userName={
              applicationStorage?.application?.volunteer?.firstname +
                ' ' +
                applicationStorage?.application?.volunteer?.lastname ??
              'Anonymous'
            }
          />
        </div>
      )}

      <QueryClientProvider client={queryClient?.current}>
        <Navigation />
      </QueryClientProvider>
    </div>
  );
}

export default App;
