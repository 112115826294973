import React from 'react';
import { translate } from 'i18n';
import { LActText, LActImage, LActButton } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import WORLD from 'assets/images/icon-world.png';

interface WelcomeMobileViewItemsTemplateProps {
  onClose: () => void;
}
const WelcomeDesktopViewItemsTemplate = (
  props: WelcomeMobileViewItemsTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.register.welcomeModal';
  const { onClose } = props;

  const onCloseBottomSheet = () => {
    onClose();
  };

  return (
    <div
      data-testid={'desktop-view'}
      className={'flex flex-col items-center -mt-4'}
    >
      <LActText
        textColor={'text-textPrimary'}
        text={translate(`${i18n}.titleFirst`)}
        className={'font-semibold mt-3 text-center'}
        textFormatType={EnumTextFormatType.FONT_SIZE_48}
      />
      <LActText
        numberOfLines="line-clamp-4"
        textColor={'text-textSecondary'}
        text={translate(`${i18n}.titleSecond`)}
        textFormatType={EnumTextFormatType.FONT_SIZE_48}
        className={'font-semibold pt-2 pb-2 text-center leading-6 px-1'}
      />
      <LActText
        numberOfLines="line-clamp-4"
        textColor={'text-textSecondary'}
        text={translate(`${i18n}.titleThird`)}
        textFormatType={EnumTextFormatType.FONT_SIZE_48}
        className={'font-semibold mt-2 pb-2 text-center leading-6 px-1'}
      />
      <LActImage source={WORLD} className={'w-60 h-60 justify-center mt-6'} />

      <div className={'w-full mb-8 px-6'}>
        <LActButton
          testID={'continue-button'}
          containerStyle={'w-full'}
          onClick={onCloseBottomSheet}
          text={translate(`${i18n}.letsGoButton`)}
        />
      </div>
    </div>
  );
};

export default WelcomeDesktopViewItemsTemplate;
