import React from 'react';
import { CSpinner } from '@coreui/react';

interface LActLoaderProps {
  color?: string;
  size?: 'sm' | undefined;
  variant?: 'border' | 'grow';
}

const LActLoader = (props: LActLoaderProps): React.ReactElement => {
  const { size, color = 'white', variant } = props;

  return <CSpinner size={size} id="spinner" color={color} variant={variant} />;
};

export default LActLoader;
