import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { USER_STORE_KEYS } from './keys';
import { IUserInfoTypes } from 'constants/types';

const LOCAL_DB = createJSONStorage(() => localStorage);

const useUserStorage = create(
  persist(
    set => ({
      user: undefined,
      purgeUserInfo: () => set({ user: undefined }),
      storeUserInfo: (user: IUserInfoTypes) =>
        set((state: IUserInfoTypes) => ({ user })),
    }),
    {
      name: USER_STORE_KEYS.USER_INFO,
      storage: LOCAL_DB,
    },
  ),
);

export default useUserStorage;
