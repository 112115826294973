import React, { useEffect, useState } from 'react';
import { LActButton, LActCard, LActRow } from 'components/index';
import {
  EnumButtonType,
  EnumCriteriaType,
  EnumLanguageType,
  EnumButtonActionType,
} from 'constants/enums';
import { translate } from 'i18n';
import { ILanguageSwitchTypes, IOfferCreationTypes } from 'constants/types';
import {
  useLanguageSwitchStorage,
  useOfferCreationStorage,
  useSwitchTranslation,
} from 'utils';
import { Criteria } from 'api/schemas/criteria';
import { SKILLS_OPTIONS } from './utils';

type Props = {
  loading?: boolean;
  lastStep: number;
  currentStep: number;
  onNextClicked: (requiredCriteria: Array<Criteria>) => void;
};

const OfferCreationSkillsTemplate = (props: Props): React.ReactElement => {
  const i18n = 'screens.offerCreation.language';
  useSwitchTranslation();
  const { loading, lastStep, currentStep, onNextClicked } = props;

  const [criteria, setCriteria] = useState<Array<Criteria>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );
  // @ts-ignore
  const languageSwitchStorage: ILanguageSwitchTypes = useLanguageSwitchStorage(
    (store: ILanguageSwitchTypes | unknown) => store,
  );

  useEffect(() => {
    if (offerCreationStorage?.offer?.skillsCriteria) {
      const skills = offerCreationStorage?.offer?.skillsCriteria?.filter(
        criteria =>
          criteria?.property?.category === EnumCriteriaType.CHARACTERISTIC,
      );
      const selectedSkills = skills?.map(item => {
        return item?.property.value;
      });
      setSelectedItems(selectedSkills);
      setCriteria(skills);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemClicked = (language: string, id: number) => {
    if (selectedItems?.includes(language)) {
      const updatedLanguage = selectedItems?.filter((item: string) => {
        return item !== language;
      });
      setSelectedItems(updatedLanguage);

      const updatedCriteria = criteria.filter(item => {
        return item.property.value !== language;
      });
      setCriteria(updatedCriteria);
    } else {
      setSelectedItems(selectedItems.concat(language));
      setCriteria(
        criteria.concat({
          id,
          property: {
            category: EnumCriteriaType.CHARACTERISTIC,
            value: language,
          },
        }),
      );
    }
  };

  const handleOnNextClicked = () => {
    const REQUIRED_CRITERIA: Array<Criteria> = [];
    // eslint-disable-next-line array-callback-return
    criteria?.map(item => {
      if (item) {
        REQUIRED_CRITERIA.push(item);
      }
    });
    // eslint-disable-next-line array-callback-return
    offerCreationStorage?.offer?.languageCriteria?.map(item => {
      if (item) {
        REQUIRED_CRITERIA.push(item);
      }
    });

    const offer = {
      ...offerCreationStorage.offer,
      skillsCriteria: criteria,
      requiredCriteria: REQUIRED_CRITERIA,
    };

    offerCreationStorage.storeOffers(offer);

    onNextClicked(REQUIRED_CRITERIA);
  };

  const BUTTON_IS_DISABLED = selectedItems?.length > 0 ? false : true;

  return (
    <div>
      <LActCard className="overflow-scroll">
        <div className="flex flex-wrap justify-center justify-evenly items-center">
          {SKILLS_OPTIONS?.map((item, index) => {
            const BG_SELECTED = selectedItems?.includes(item?.key)
              ? 'bg-tint text-white'
              : 'bg-white text-textSecondary';

            const TEXT_COLOR_SELECTED = selectedItems?.includes(item?.key)
              ? 'text-white font-semibold'
              : 'text-tint';

            const title =
              languageSwitchStorage?.language === EnumLanguageType.GERMAN
                ? item?.titleDE
                : item?.titleEN;

            return (
              <span
                key={`language-badge-${index}`}
                onClick={() => onItemClicked(item?.key, item.id)}
                className={`cursor-pointer px-3 py-2 mx-2 rounded-full mt-3 ${BG_SELECTED} ${TEXT_COLOR_SELECTED}`}
              >
                {title}
              </span>
            );
          })}
        </div>
      </LActCard>

      <div>
        <LActRow className={'relative mt-4 w-full bottom-1 w-2/6'}>
          <LActButton
            testID={'login-button'}
            textStyle={'text-tint'}
            onClick={() => undefined}
            type={EnumButtonType.TEXT}
            actionType={EnumButtonActionType.BUTTON}
            text={translate(`${i18n}.step`) + `  ${currentStep} / ${lastStep}`}
          />

          <LActButton
            useLoading={loading}
            testID={'login-button'}
            type={EnumButtonType.NORMAL}
            disabled={BUTTON_IS_DISABLED}
            onClick={handleOnNextClicked}
            text={translate(`${i18n}.createButton`)}
            actionType={EnumButtonActionType.BUTTON}
          />
        </LActRow>
      </div>
    </div>
  );
};

export default OfferCreationSkillsTemplate;
