export const LANGUAGES_OPTIONS = [
  {
    id: 1,
    value: 'german',
    title: 'German',
  },
  {
    id: 2,
    value: 'english',
    title: 'English',
  },
  {
    id: 3,
    value: 'french',
    title: 'French',
  },
  {
    id: 4,
    value: 'spanish',
    title: 'Spanish',
  },
  {
    id: 5,
    value: 'arabic',
    title: 'Arabic',
  },
  {
    id: 6,
    value: 'russian',
    title: 'Russian',
  },
  {
    id: 7,
    value: 'italian',
    title: 'Italian',
  },
  {
    id: 8,
    value: 'turkish',
    title: 'Turkish',
  },
];
