import { create } from 'zustand';
import { ApplicationItems } from 'api/schemas/applicationItems';
import { createJSONStorage, persist } from 'zustand/middleware';
import { APPLICATIONS } from './keys';

const LOCAL_DB = createJSONStorage(() => localStorage);

const useApplicationStorage = create(
  persist(
    set => ({
      application: {},
      purgeApplication: () => set({ application: undefined }),
      saveApplication: (item: ApplicationItems) => set({ application: item }),
    }),
    {
      name: APPLICATIONS.SINGLE_ITEM_APPLICATION,
      storage: LOCAL_DB,
    },
  ),
);

export default useApplicationStorage;
