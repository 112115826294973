import React from 'react';

interface LActRowProps {
  reverse?: boolean;
  stretch?: boolean;
  className?: string;
  onClick?: () => void;
  alignCenter?: boolean;
  spaceAround?: boolean;
  spaceBetween?: boolean;
  flex?: number | boolean;
  justifyCenter?: boolean;
  children: React.ReactNode;
}

const LActRow = (props: LActRowProps): React.ReactElement => {
  const {
    flex,
    reverse,
    stretch,
    onClick,
    children,
    className,
    spaceAround,
    alignCenter,
    spaceBetween,
    justifyCenter,
  } = props;

  const itemFlex = flex ?? 'flex-1';

  const justifyContent = spaceBetween
    ? 'justify-between'
    : spaceAround
    ? 'justify-around'
    : justifyCenter
    ? 'justify-center'
    : 'flex-start';

  const flexDirection = reverse ? 'flex-row-reverse' : 'flex-row';

  const alignItems = stretch
    ? 'items-stretch'
    : alignCenter
    ? 'items-center'
    : 'items-start';

  return (
    <div
      onClick={onClick}
      className={`flex ${itemFlex} ${flexDirection} ${alignItems} ${justifyContent} ${className}`}
    >
      {children}
    </div>
  );
};

export default LActRow;
