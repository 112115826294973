import React, { useEffect, useState } from 'react';
import Lodash from 'lodash';
import { useRefreshToken, useSwitchTranslation } from 'utils';
import { Schedule } from 'api/schemas/schedule';
import {
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
  EnumSchedulingFrequency,
  EnumErrorType,
} from 'constants/enums';
import { AVAILABILITY_OPTIONS } from './utils';
import { translate } from 'i18n';
import { LActButton, LActRow, LActText, LActTextInput } from 'components/index';
import { usePatchOfferDetails } from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { Offer } from 'api/schemas/offer';

interface ScheduleProps {
  offerID: number;
  schedule: Schedule;
  accessToken: string;
  onItemUpdated: (item: Offer) => void;
}

const ScheduleComponent = (props: ScheduleProps): React.ReactElement => {
  const i18n = 'screens.home.offers.details';
  const { offerID, schedule, accessToken, onItemUpdated } = props;
  useSwitchTranslation();
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });
  const [minHoursMessage, setMinHoursMessage] = useState<string>();
  const [minHours, setMinHours] = useState<number>(schedule?.minHours);
  const [selectedFrequency, setSelectedFrequency] = useState({
    id: schedule?.id,
    frequency: schedule?.frequency,
    hasWeekDays:
      schedule?.frequency !== EnumSchedulingFrequency.FLEXIBLE && true,
    selectedWeekDays: schedule?.weekdays,
    minHours: minHours,
  });

  const PATCH_QUERY = usePatchOfferDetails({
    ...parseBearerToken(accessToken ? decryptData(accessToken) : ''),
  });

  useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
        });
      }, 3000);
    }
  }, [message.type]);

  /**
   * Use-case is for lodash and comparing differences between
   * user object availability and current state availability
   *
   * @abstract
   * The we create the same object as user's because we have
   * different props on the state.
   */
  const CURRENT_STATE_AVAILABILITY = {
    frequency: selectedFrequency?.frequency,
    id: Number(selectedFrequency?.id),
    minHours: minHours,
    weekdays: selectedFrequency?.selectedWeekDays,
  };

  const onWeekdayItemsPressed = (name: string, id: number) => {
    if (message?.message) {
      setMessage({
        message: '',
        type: 'error',
      });
    }
    if (selectedFrequency?.selectedWeekDays?.includes(name)) {
      /**
       * Deletes the selected days from the list
       */
      const updatedWeekDays = selectedFrequency?.selectedWeekDays?.filter(
        day => {
          return day !== name;
        },
      );
      setSelectedFrequency({
        id: id.toString(),
        frequency: selectedFrequency.frequency,
        hasWeekDays: selectedFrequency.hasWeekDays,
        selectedWeekDays: updatedWeekDays,
        minHours: schedule?.minHours,
      });
    } else {
      setSelectedFrequency({
        id: id.toString(),
        frequency: selectedFrequency.frequency,
        hasWeekDays: selectedFrequency.hasWeekDays,
        selectedWeekDays: selectedFrequency.selectedWeekDays.concat(name),
        minHours: schedule?.minHours,
      });
    }
  };
  const onFrequencyItemsPressed = (
    id: number,
    frequency: string,
    hasWeekDays: boolean,
  ) => {
    if (message?.message) {
      setMessage({
        message: '',
        type: '',
      });
    }
    if (selectedFrequency.frequency === frequency) {
      const hasWeekDays =
        schedule.frequency === frequency && schedule.weekdays.length > 0
          ? true
          : false;
      const selectedWeekDays =
        schedule.frequency === frequency && schedule.weekdays.length > 0
          ? schedule.weekdays
          : [];
      setSelectedFrequency({
        id: id.toString(),
        frequency: frequency,
        hasWeekDays: hasWeekDays,
        selectedWeekDays: selectedWeekDays,
        minHours: schedule?.minHours,
      });
    } else {
      setSelectedFrequency({
        id: id.toString(),
        frequency,
        hasWeekDays,
        selectedWeekDays: [],
        minHours: schedule?.minHours,
      });
    }
  };

  const patchQuery = () => {
    const MIN_HOURS =
      selectedFrequency?.frequency === EnumSchedulingFrequency.FLEXIBLE
        ? schedule.minHours
        : minHours;

    PATCH_QUERY?.mutateAsync({
      offerId: offerID?.toString(),
      data: {
        schedule: {
          frequency: selectedFrequency?.frequency,
          weekdays: selectedFrequency?.selectedWeekDays,
          minHours: MIN_HOURS,
        },
      },
    })
      .then((data: any) => {
        setMessage({
          message: translate(`${i18n}.severMessages.successfullyUpdated`),
          type: 'success',
        });
        if (data?.item) {
          onItemUpdated(data?.item);
        }
      })
      .catch(error => {
        if (error?.message?.includes('500')) {
          setMessage({
            message: translate(`${i18n}.severMessages.serverError500`),
            type: 'error',
          });
        }
        setMessage({
          message: translate(`${i18n}.severMessages.serverGeneralError`),
          type: 'error',
        });
      });
  };

  const onMinHoursChange = (hours: string) => {
    setMinHours(Number(hours));
    if (Number(hours) === 0) {
      setMinHoursMessage(translate(`${i18n}.errorMessages.wrongMinHours`));
    } else {
      setMinHoursMessage('');
    }
  };

  const onSaveClicked = () => {
    if (selectedFrequency.frequency === EnumSchedulingFrequency.WEEKLY) {
      if (selectedFrequency.selectedWeekDays.length === 0) {
        setMessage({
          message: translate(`${i18n}.errorMessages.emptyWeekDays`),
          type: 'error',
        });
      } else if (minHours === 0) {
        setMinHoursMessage(translate(`${i18n}.errorMessages.wrongMinHours`));
      } else {
        patchQuery();
      }
    } else if (
      selectedFrequency.frequency === EnumSchedulingFrequency.MONTHLY
    ) {
      if (selectedFrequency.selectedWeekDays.length === 0) {
        setMessage({
          message: translate(`${i18n}.errorMessages.emptyWeekDays`),
          type: 'error',
        });
      } else if (minHours === 0) {
        setMinHoursMessage(translate(`${i18n}.errorMessages.wrongMinHours`));
      } else {
        patchQuery();
      }
    } else {
      patchQuery();
    }
  };
  useRefreshToken({
    error: PATCH_QUERY?.error,
    refetch: onSaveClicked,
    hasError: PATCH_QUERY?.isError,
  });

  const disabledSaveButton = Lodash.isEqual(
    CURRENT_STATE_AVAILABILITY,
    schedule,
  );

  return (
    <div>
      <LActRow className={'items-center'} spaceBetween>
        <div>
          <LActText
            className="font-semibold"
            text={translate(`${i18n}.scheduleSection`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
          />
        </div>
        <div>
          <LActButton
            testID={'login-button'}
            onClick={onSaveClicked}
            type={EnumButtonType.TEXT}
            useLoading={PATCH_QUERY?.isLoading}
            text={translate(`${i18n}.saveButton`)}
            actionType={EnumButtonActionType.BUTTON}
            containerStyle={'items-end py-0 bg-transparent border-0'}
            textStyle={`text-tint ${disabledSaveButton && 'opacity-50'}`}
          />
        </div>
      </LActRow>

      {AVAILABILITY_OPTIONS.map((item, index) => {
        const topMargin = index > 0 ? 'mt-2' : '';

        const selectedFrequencyStyle =
          selectedFrequency.frequency !== item.key
            ? 'bg-white rounded-xl w-full'
            : 'bg-tint rounded-xl w-full';

        const selectedFrequencyTextStyle =
          selectedFrequency.frequency !== item.key ? '' : 'text-white';

        return (
          <div key={index}>
            <button
              onClick={() =>
                onFrequencyItemsPressed(
                  Number(schedule?.id),
                  item.key,
                  item?.weekdays ? true : false,
                )
              }
              className={`px-4 py-2 mt-3 ${selectedFrequencyStyle} ${topMargin}`}
            >
              <span className={`font-semibold ${selectedFrequencyTextStyle}`}>
                {item.frequency}
              </span>
            </button>

            {selectedFrequency.hasWeekDays &&
            selectedFrequency.frequency === item.key ? (
              <div className="ml-4 flex flex-col justify-center items-center">
                <div className="text-gray-700 mt-3">
                  {translate(`${i18n}.availability.weekdaysTitle`)}
                </div>

                <div className="flex space-x-2">
                  {item?.weekdays &&
                    item?.weekdays.map((days, index) => {
                      const selectedWeekdayStyle =
                        !selectedFrequency?.selectedWeekDays?.includes(days.key)
                          ? 'bg-white'
                          : 'bg-tint';

                      const selectedFrequencyTextStyle =
                        !selectedFrequency?.selectedWeekDays?.includes(days.key)
                          ? 'text-tint'
                          : 'text-white';

                      const weekDayName = days?.name?.slice(0, 2).toLowerCase();

                      return (
                        <button
                          key={index}
                          onClick={() =>
                            onWeekdayItemsPressed(
                              days.key,
                              Number(schedule?.id),
                            )
                          }
                          className={`w-10 h-10 p-2 rounded-full mt-2 mb-2 ${selectedWeekdayStyle}`}
                        >
                          <span
                            className={`font-semibold ${selectedFrequencyTextStyle}`}
                          >
                            {weekDayName.charAt(0).toUpperCase() +
                              weekDayName.slice(1)}
                          </span>
                        </button>
                      );
                    })}
                </div>

                <LActTextInput
                  maxLength={7}
                  type={'text'}
                  focusBG="focus:bg-white"
                  value={minHours.toString()}
                  onChange={onMinHoursChange}
                  containerStyle="mt-3 bg-white"
                  messageType={EnumErrorType.ERROR}
                  placeHolder={translate(`${i18n}.minHours`)}
                  message={
                    minHoursMessage
                      ? translate(`${i18n}.errorMessages.wrongMinHours`)
                      : undefined
                  }
                />
              </div>
            ) : null}
          </div>
        );
      })}

      {message?.message && (
        <LActText
          text={message?.message}
          className={'font-semibold mt-6'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          textColor={message.type === 'error' ? 'text-error' : 'text-green'}
        />
      )}
    </div>
  );
};

export default ScheduleComponent;
