import { EnumLanguageType } from 'constants/enums';

const i18n = 'templates.header';

export interface ILanguageItemsType {
  id: number;
  title: any;
  key: string;
  flag: string;
}

export const LANGUAGE_ITEMS = [
  {
    id: 1,
    flag: `🏴󠁧󠁢󠁥󠁮󠁧󠁿 `,
    key: EnumLanguageType.ENGLISH,
    title: `${i18n}.english`,
  },
  {
    id: 2,
    flag: `🇩🇪 `,
    key: EnumLanguageType.GERMAN,
    title: `${i18n}.german`,
  },
];
