import CryptoJS from 'crypto-js';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { AccessToken } from 'api/schemas';
import { TxKeyPath, translate } from 'i18n';
import { EnumOfferLocationType } from 'constants/enums';

export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const encryptData = (data?: string) => {
  return (
    data &&
    CryptoJS.AES.encrypt(
      data?.toString(),
      `${process.env.REACT_APP_LOCAL_STORAGE_KEY}`,
    ).toString()
  );
};

export const decryptData = (data: string) => {
  const bytes = CryptoJS.AES.decrypt(
    data,
    `${process.env.REACT_APP_LOCAL_STORAGE_KEY}`,
  );
  const decryptedData = bytes?.toString(CryptoJS.enc.Utf8);

  return decryptedData;
};

export const parseBearerToken = (token: string) => {
  if (!token) return undefined;
  return {
    request: {
      headers: {
        Authorization: `Bearer ${token}`,
        auth_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  };
};

export const convertDateWithMonthName = (date: string) => {
  if (!date) return `Date not set`;

  const YEAR_TO_PREVIEW = date?.split('T')[0]?.split('-')[0];

  const DAY = date?.split(':')[0]?.split('T')[0]?.split('-')[2];

  return `${DAY}. ${format(new Date(date), 'LLL', {
    locale: de,
  })} ${YEAR_TO_PREVIEW}`;
};

export const parseTokenStorageObject = (
  item: AccessToken,
  refreshToken?: string,
): Object => {
  const REFRESH_TOKEN = refreshToken
    ? `${refreshToken}`
    : `${item?.refresh_token}`;

  const storeObject = {
    expires_in: item?.expires_in,
    access_token: encryptData(item?.access_token),
    expiration_time: item?.expiration_time,
    refresh_token: encryptData(REFRESH_TOKEN),
  };

  return storeObject;
};

export const parseLocationType = (
  city?: string,
  street?: string,
  i18n?: TxKeyPath,
  locationType?: string,
  shouldBreakAddress?: boolean,
): string => {
  switch (locationType) {
    case EnumOfferLocationType.ONSITE:
      const address: string =
        shouldBreakAddress && street && street?.length >= 25
          ? `${street}\n${city}`
          : `${street}, ${city}`;
      return address;

    case EnumOfferLocationType.PRIVATE:
      return `${city}`;

    case EnumOfferLocationType.REMOTE:
      // @ts-ignore
      return translate(`${i18n}.remote`).toString();

    default:
      return '';
  }
};

export const openEmailAppComposer = (email: string): void => {
  if (!email) return;

  var mailtoUrl = 'mailto:' + encodeURIComponent(email);
  window.open(mailtoUrl, '_blank');
};

export const linkToURL = (link: string): void => {
  if (!link) return;
  window.open(link, '_blank');
};

export const responseHandler = (error: number): string => {
  if (!error) return '';
  const i18n = 'responseMessages';

  switch (error) {
    case 400:
      return translate(`${i18n}.error400`);

    case 401:
      return translate(`${i18n}.error401`);

    case 403:
      return translate(`${i18n}.error403`);

    case 404:
      return translate(`${i18n}.error404`);

    case 409:
      return translate(`${i18n}.error409`);

    case 422:
      return translate(`${i18n}.error422`);

    case 500:
      return translate(`${i18n}.error500`);

    default:
      return '';
  }
};

const phoneNumberRegex = /^(\+\d{1,3})\s?(\d{3,})$/;
export const splitPhoneNumberFromCountryCode = (phoneNumber: string) => {
  const match = phoneNumber?.match(phoneNumberRegex);
  if (match) {
    const [, countryCode, localNumber] = match;
    return { countryCode, localNumber };
  } else {
    return null;
  }
};
