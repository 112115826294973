import { create } from 'zustand';

const useWelcomeStorage = create(set => ({
  hasWelcomed: false,
  showWelcomeDialog: (hasWelcomed: boolean) =>
    set({ hasWelcomed: hasWelcomed }),
  purgeWelcomeCompleted: () => set({ hasWelcomed: false }),
}));

export default useWelcomeStorage;
