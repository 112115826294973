import React, { useState, useEffect } from 'react';

type AlertType = 'success' | 'info' | 'warning' | 'error';

interface LActAlertProps {
  type: AlertType;
  message: string;
  duration?: number;
  dismissable?: boolean;
}

const LActAlert = (props: LActAlertProps): React.ReactElement => {
  const { type, message, duration, dismissable } = props;
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (dismissable) {
      timer = setTimeout(() => {
        setVisible(false);
      }, duration);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [dismissable, duration]);

  const closeAlert = () => {
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <div
          className={`fixed right-8 top-8 z-50 transform transition-transform duration-300 opacity-85 ${
            visible ? 'translate-x-0' : 'translate-x-full'
          } ${
            type === 'success'
              ? 'bg-green text-white font-bold'
              : type === 'info'
              ? 'bg-neutral400 text-white font-bold'
              : type === 'warning'
              ? 'bg-orange text-white font-bold'
              : type === 'error'
              ? 'bg-error text-white font-bold'
              : ''
          } rounded-xl shadow-md p-3 w-[35%] flex flex-col`}
        >
          {dismissable && (
            <button
              className="self-end -mt-2 text-white hover:text-black"
              onClick={closeAlert}
            >
              &#x2715;
            </button>
          )}
          <span className={`line-clamp-2 inline ${dismissable && 'mt-px'}`}>
            {message}
          </span>
        </div>
      )}
    </>
  );
};

export default LActAlert;
