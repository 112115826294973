import React from 'react';
import { IOfferCreationTypes, IOrganizationInfoTypes } from 'constants/types';
import {
  useOfferCreationStorage,
  useOrganizationStorage,
  useSwitchTranslation,
} from 'utils';
import { LActCard, LActImage, LActRow, LActText } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import { translate } from 'i18n';
import OfferCreationSchedulingPreview from './components/scheduling';
import {
  CONST_DEFAULT_ORG_IMAGE,
  CONST_DEFAULT_OFFER_IMAGE,
} from 'constants/constants';

const OfferCreationPreview = (): React.ReactElement => {
  const i18n = 'screens.offerCreation.preview';
  useSwitchTranslation();
  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  const COVER_IMAGE = offerCreationStorage?.offer?.demoPic;
  const ORGANIZATION_TITLE = offerCreationStorage?.offer?.title;
  const ORGANIZATION_LOGO = organizationStorage?.organization?.imageUri
    ? organizationStorage?.organization?.imageUri
    : CONST_DEFAULT_ORG_IMAGE;
  const ORGANIZATION_NAME = organizationStorage?.organization?.name;
  const LANGUAGE_CRITERIA = offerCreationStorage?.offer?.languageCriteria;
  const GOAL = offerCreationStorage?.offer?.goal;
  const IMPACT = offerCreationStorage?.offer?.impact;
  const OFFER_ITEM = offerCreationStorage?.offer;
  const SCHEDULING = offerCreationStorage?.offer?.schedule;

  return (
    <LActCard className="flex flex-col w-full  p-0 shadow-2xl bg-white pb-4 mb-4">
      <div>
        <LActImage
          source={COVER_IMAGE ?? CONST_DEFAULT_OFFER_IMAGE}
          className={
            'w-full h-72 rounded-tl-3xl rounded-tr-3xl z-0 object-cover'
          }
        />
      </div>

      <div
        className={
          'bg-white -mt-14 px-4 rounded-tl-2xl rounded-tr-2xl z-50 relative'
        }
      >
        {/* Organization details */}
        <>
          <LActText
            className={'mt-6 pt-3'}
            text={ORGANIZATION_TITLE}
            textFormatType={EnumTextFormatType.FONT_SIZE_24}
            textColor={'text-textPrimary text-regular text-center'}
          />

          <LActRow className="items-center justify-center mt-3">
            <LActImage
              source={ORGANIZATION_LOGO}
              className={'w-8 h-8 rounded-full object-cover'}
            />

            <LActText
              className="pt-1 ml-3"
              text={ORGANIZATION_NAME}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
              textColor={'text-textPrimary text-regular text-center'}
            />
          </LActRow>
        </>

        {/* Criteria */}
        {LANGUAGE_CRITERIA && (
          <>
            <LActCard className={'mt-4'}>
              <LActText
                className="pt-1 ml-3"
                text={translate(`${i18n}.idealVolunteer`)}
                textColor={'text-textPrimary text-regular'}
                textFormatType={EnumTextFormatType.FONT_SIZE_24}
              />

              <>
                {LANGUAGE_CRITERIA?.slice(0, 3)?.map((criteria, index) => {
                  if (!criteria) return undefined;
                  return (
                    <LActText
                      className="pt-1 ml-3 line-clamp-3"
                      key={`Criteria-creation-${index}`}
                      textColor={'text-textSecondary text-regular'}
                      textFormatType={EnumTextFormatType.FONT_SIZE_16}
                      text={
                        criteria?.property?.value?.charAt(0)?.toUpperCase() +
                        criteria?.property?.value?.slice(1)?.toLowerCase()
                      }
                    />
                  );
                })}
              </>
            </LActCard>
          </>
        )}

        {/* Scheduling Location Section */}
        {SCHEDULING && (
          <LActCard className="mt-4">
            <OfferCreationSchedulingPreview
              offerItem={OFFER_ITEM}
              scheduling={SCHEDULING}
            />
          </LActCard>
        )}

        {/* GOAL Section */}
        {GOAL && (
          <>
            <LActCard className={'mt-4'}>
              <LActText
                className="pt-1 ml-3"
                text={translate(`${i18n}.ourGoal`)}
                textColor={'text-textPrimary text-regular'}
                textFormatType={EnumTextFormatType.FONT_SIZE_24}
              />

              <LActText
                text={GOAL}
                numberOfLines={'line-clamp-6'}
                className="pt-1 ml-3 line-clamp-7"
                textColor={'text-textSecondary text-regular'}
                textFormatType={EnumTextFormatType.FONT_SIZE_16}
              />
            </LActCard>
          </>
        )}

        {/* IMPACT Section */}
        {IMPACT && (
          <>
            <LActCard className={'mt-4'}>
              <LActText
                className="pt-1 ml-3 "
                text={translate(`${i18n}.yourImpact`)}
                textColor={'text-textPrimary text-regular'}
                textFormatType={EnumTextFormatType.FONT_SIZE_24}
              />

              <LActText
                text={IMPACT}
                numberOfLines={'line-clamp-6'}
                className="pt-1 ml-3 line-clamp-7"
                textColor={'text-textSecondary text-regular'}
                textFormatType={EnumTextFormatType.FONT_SIZE_16}
              />
            </LActCard>
          </>
        )}
      </div>
    </LActCard>
  );
};

export default OfferCreationPreview;
