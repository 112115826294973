import React from 'react';

interface LActDividerProps {
  className?: string;
}

const LActDivider = (props: LActDividerProps): React.ReactElement => {
  const { className } = props;

  return <div className={`h-px bg-neutral600 rounded-full ${className}`} />;
};

export default LActDivider;
