/* eslint-disable react-hooks/exhaustive-deps */
import { IUserInfoTypes } from 'constants/types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStorage } from 'utils';
import {
  LActRow,
  LActText,
  LActCard,
  LActBadge,
  LActImage,
  LActLoader,
  LActTextInput,
} from 'components/index';
import { usePostApiV1OrganizationSearch } from 'api/endpoints/queries';
import { Organization } from 'api/schemas/organization';
import { PersonCircle, ChevronForwardOutline } from 'react-ionicons';
import {
  EnumImageType,
  EnumErrorType,
  EnumTextFormatType,
  EnumOrganizationStatusType,
} from 'constants/enums';
import { colors } from 'constants/colors';
import { MANAGEMENT_DETAILS_SCREEN } from 'constants/route';
import { AdminOrganizationDetails } from 'api/schemas';
import useGetAdminOrganizationsPagination from './api/useGetAdminOrganizationsPagination';
import { CPagination, CPaginationItem } from '@coreui/react';

const ManagementOrganizationScreen = (): React.ReactElement => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] =
    useState<Array<AdminOrganizationDetails>>();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [pagination, setPagination] = useState<Array<number>>([]);
  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  const itemsPerPage = 12;

  const { data, isLoading, totalPages, totalItems } =
    useGetAdminOrganizationsPagination({
      size: itemsPerPage,
      page: paginationPage,
    });

  const ORGANIZATION_SEARCH_QUERY = usePostApiV1OrganizationSearch({
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });

  //   @ts-ignore
  const total = Math.ceil(totalPages / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visiblePagination = pagination.slice(
    startIndex,
    startIndex + itemsPerPage,
  );

  useEffect(() => {
    if (data && totalPages) {
      const PAGINATION_ITEMS = [];

      for (let index = 0; index <= totalPages; index++) {
        PAGINATION_ITEMS.push(index);
      }
      setPagination(PAGINATION_ITEMS);
    }
  }, [data, totalPages]);

  useEffect(() => {
    if (searchQuery) {
      ORGANIZATION_SEARCH_QUERY?.mutateAsync({
        data: {
          name: searchQuery,
        },
      })
        // @ts-ignore
        .then(response => {
          setSearchResults(response);
        })
        .catch(error => error);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (userStorage?.user?.email !== process.env.REACT_APP_LETSACT_USER) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div>
        <div className={'flex mt-20 justify-center items-center'}>
          <LActLoader color={'tint'} />
        </div>
      </div>
    );
  }

  const onOrganizationItemClicked = (
    orgId: string,
    orgName: string,
    orgStatus: string,
    profilePic: string,
    orgWebPage: string,
    description: string,
  ) => {
    navigate(MANAGEMENT_DETAILS_SCREEN, {
      state: {
        orgId,
        orgName,
        orgStatus,
        profilePic,
        orgWebPage,
        description,
      },
    });
  };

  return (
    <div className="pl-6">
      <>
        <LActTextInput
          type={'text'}
          maxLength={250}
          testID={'org-name-input'}
          containerStyle={'mt-6 w-1/2'}
          placeHolder={'Search by organization name'}
          messageType={EnumErrorType.ERROR}
          onChange={value => setSearchQuery(value)}
          message={
            searchQuery && searchResults?.length === 0
              ? 'No results found!'
              : ''
          }
        />

        {searchQuery?.length === 0 && (
          <CPagination
            align="center"
            style={{ marginTop: 32 }}
            aria-label="Page navigation example"
          >
            <CPaginationItem
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </CPaginationItem>

            {visiblePagination?.map((item: any) => {
              const ACTIVE_ITEM = paginationPage === item;

              if (item === 0) return <></>;
              return (
                <CPaginationItem
                  active={ACTIVE_ITEM}
                  onClick={() => setPaginationPage(item)}
                >
                  {item}
                </CPaginationItem>
              );
            })}

            <CPaginationItem
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === total}
            >
              Next
            </CPaginationItem>
          </CPagination>
        )}

        <div className={`${searchQuery?.length > 0 && 'pt-6'}`}>
          <span className={`font-bold`}>
            Total Organizations:{' '}
            <span className="text-xl border-b-2 border-orange">
              {totalItems}
            </span>
          </span>
        </div>

        <div
          className={
            'mt-6 desktop:grid desktop:gap-6 desktop:grid-container desktop:grid-cols-2'
          }
        >
          {searchQuery?.length > 0 ? (
            <>
              {searchResults?.map((item: any, index) => {
                const ORG_NAME = item?.name?.length ? item?.name : '*No Name*';
                const STATUS = item?.status;
                const BADGE_BG_COLOR =
                  STATUS === EnumOrganizationStatusType.PENDING
                    ? 'bg-orange'
                    : STATUS === EnumOrganizationStatusType.ACTIVE
                    ? 'bg-success'
                    : STATUS === EnumOrganizationStatusType.DELETED
                    ? 'bg-red'
                    : 'bg-neutral400';

                if (STATUS === EnumOrganizationStatusType.DELETED) {
                  return undefined;
                }
                return (
                  <LActCard
                    className="cursor-pointer"
                    onClick={() =>
                      onOrganizationItemClicked(
                        item?.id,
                        item.name,
                        item?.status,
                        item?.imageUri,
                        item?.url,
                        item?.description,
                      )
                    }
                    key={`management-org-${index}-${index + 1}`}
                  >
                    <LActBadge
                      title={STATUS}
                      className={`${BADGE_BG_COLOR} float-right desktop:-mt-7.5 mobile:-mt-7 desktop:-mr-11 font-bold mobile:absolute mobile:right-4 desktop:relative`}
                    />
                    <LActRow
                      className={`mobile:justify-between mobile:items-center`}
                    >
                      <div className={'flex flex-row'}>
                        {item?.imageUri ? (
                          <LActImage
                            imageType={EnumImageType.IMAGE}
                            source={item?.imageUri}
                            className={'w-14 h-14 rounded-full'}
                          />
                        ) : (
                          <PersonCircle
                            color={'#1F4043'}
                            width={'62px'}
                            height={'62px'}
                          />
                        )}

                        <div
                          className={
                            'ml-3 items-center mobile:pt-2 desktop:pt-0'
                          }
                        >
                          <LActText
                            text={ORG_NAME}
                            numberOfLines={'line-clamp-1'}
                            textColor={'text-textPrimary'}
                            textFormatType={EnumTextFormatType.FONT_SIZE_16}
                            className={
                              'font-medium desktop:pt-3 desktop:text-2xl mobile:text-base mobile:semi-bold self-center font-medium w-52'
                            }
                          />
                        </div>
                      </div>

                      <div className={'desktop:flex-none desktop:hidden'}>
                        <ChevronForwardOutline
                          width={'16px'}
                          color={colors.palette.grey300}
                        />
                      </div>
                    </LActRow>
                  </LActCard>
                );
              })}
            </>
          ) : (
            <>
              {data?.map((item: Organization, index: number) => {
                const ORG_NAME = item?.name?.length ? item?.name : '*No Name*';
                const STATUS = item?.status;
                const BADGE_BG_COLOR =
                  STATUS === EnumOrganizationStatusType.PENDING
                    ? 'bg-orange'
                    : STATUS === EnumOrganizationStatusType.ACTIVE
                    ? 'bg-success'
                    : STATUS === EnumOrganizationStatusType.DELETED
                    ? 'bg-red'
                    : 'bg-neutral400';

                if (STATUS === EnumOrganizationStatusType.DELETED) {
                  return undefined;
                }

                return (
                  <LActCard
                    className="cursor-pointer"
                    onClick={() =>
                      onOrganizationItemClicked(
                        item?.id,
                        item.name,
                        item?.status,
                        item?.imageUri,
                        item?.url,
                        item?.description,
                      )
                    }
                    key={`management-org-${index}-${index + 1}`}
                  >
                    <LActBadge
                      title={STATUS}
                      className={`${BADGE_BG_COLOR} float-right desktop:-mt-7.5 mobile:-mt-7 desktop:-mr-11 font-bold mobile:absolute mobile:right-4 desktop:relative`}
                    />
                    <LActRow
                      className={`mobile:justify-between mobile:items-center`}
                    >
                      <div className={'flex flex-row'}>
                        {item?.imageUri ? (
                          <LActImage
                            imageType={EnumImageType.IMAGE}
                            source={item?.imageUri}
                            className={'w-14 h-14 rounded-full'}
                          />
                        ) : (
                          <PersonCircle
                            color={'#1F4043'}
                            width={'62px'}
                            height={'62px'}
                          />
                        )}

                        <div
                          className={
                            'ml-3 items-center mobile:pt-2 desktop:pt-0'
                          }
                        >
                          <LActText
                            text={ORG_NAME}
                            numberOfLines={'line-clamp-1'}
                            textColor={'text-textPrimary'}
                            textFormatType={EnumTextFormatType.FONT_SIZE_16}
                            className={
                              'font-medium desktop:pt-3 desktop:text-2xl mobile:text-base mobile:semi-bold self-center font-medium w-55'
                            }
                          />
                        </div>
                      </div>

                      <div className={'desktop:flex-none desktop:hidden'}>
                        <ChevronForwardOutline
                          width={'16px'}
                          color={colors.palette.grey300}
                        />
                      </div>
                    </LActRow>
                  </LActCard>
                );
              })}
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default ManagementOrganizationScreen;
