import React, { useEffect, useState } from 'react';
import { LActButton, LActCard, LActRow } from 'components/index';
import {
  EnumButtonType,
  EnumCriteriaType,
  EnumButtonActionType,
} from 'constants/enums';
import { TxKeyPath, translate } from 'i18n';
import { IOfferCreationTypes } from 'constants/types';
import { useOfferCreationStorage, useSwitchTranslation } from 'utils';
import { Criteria } from 'api/schemas/criteria';
import { LANGUAGES_OPTIONS } from './utils';

type Props = {
  lastStep: number;
  currentStep: number;
  onNextClicked: () => void;
};

const OfferCreationLanguageTemplate = (props: Props): React.ReactElement => {
  const i18n = 'screens.offerCreation.language';
  useSwitchTranslation();
  const { lastStep, currentStep, onNextClicked } = props;

  const [criteria, setCriteria] = useState<Array<Criteria>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );

  useEffect(() => {
    if (offerCreationStorage?.offer?.languageCriteria) {
      const skills = offerCreationStorage?.offer?.languageCriteria?.filter(
        criteria => criteria?.property?.category === EnumCriteriaType.LANGUAGE,
      );
      const selectedSkills = skills?.map(item => {
        return item?.property.value;
      });
      setSelectedItems(selectedSkills);
      setCriteria(skills);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemClicked = (language: string, id: number) => {
    if (selectedItems?.includes(language)) {
      const updatedLanguage = selectedItems?.filter((item: string) => {
        return item !== language;
      });
      setSelectedItems(updatedLanguage);

      const updatedCriteria = criteria.filter(item => {
        return item.property.value !== language;
      });
      setCriteria(updatedCriteria);
    } else {
      setSelectedItems(selectedItems.concat(language));
      setCriteria(
        criteria.concat({
          id,
          property: {
            category: EnumCriteriaType.LANGUAGE,
            value: language,
          },
        }),
      );
    }
  };
  const handleOnNextClicked = () => {
    const offer = {
      ...offerCreationStorage.offer,
      languageCriteria: criteria,
    };

    offerCreationStorage.storeOffers(offer);

    onNextClicked();
  };

  const BUTTON_IS_DISABLED = selectedItems?.length > 0 ? false : true;

  return (
    <div>
      <LActCard className="overflow-scroll">
        <div className="flex flex-wrap justify-center justify-evenly items-center">
          {LANGUAGES_OPTIONS?.map((lang, index) => {
            const BG_SELECTED = selectedItems?.includes(lang?.value)
              ? 'bg-tint text-white'
              : 'bg-white text-textSecondary';

            const TEXT_COLOR_SELECTED = selectedItems?.includes(lang?.value)
              ? 'text-white font-semibold'
              : 'text-tint';

            const title =
              lang?.value[0]?.toUpperCase() +
              lang?.value?.slice(1)?.toLowerCase();

            return (
              <span
                key={`language-badge-${index}`}
                onClick={() => onItemClicked(lang?.value, lang.id)}
                className={`cursor-pointer px-3 py-2 mx-2 rounded-full mt-3 ${BG_SELECTED} ${TEXT_COLOR_SELECTED}`}
              >
                {translate(`${i18n}.languages.${title}` as TxKeyPath)}
              </span>
            );
          })}
        </div>
      </LActCard>

      <div>
        <LActRow className={'relative mt-4 w-full w-2/6'}>
          <LActButton
            testID={'login-button'}
            textStyle={'text-tint'}
            onClick={() => undefined}
            type={EnumButtonType.TEXT}
            actionType={EnumButtonActionType.BUTTON}
            text={translate(`${i18n}.step`) + `  ${currentStep} / ${lastStep}`}
          />

          <LActButton
            testID={'login-button'}
            type={EnumButtonType.NORMAL}
            disabled={BUTTON_IS_DISABLED}
            onClick={handleOnNextClicked}
            text={translate(`${i18n}.nextButton`)}
            actionType={EnumButtonActionType.BUTTON}
          />
        </LActRow>
      </div>
    </div>
  );
};

export default OfferCreationLanguageTemplate;
