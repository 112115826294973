import React from 'react';
import { PersonCircle } from 'react-ionicons';
import { translate } from 'i18n';
import { useApplicationStorage } from 'utils';
import { IApplicationStorageTypes } from 'constants/types';
import { LActRow, LActText, LActImage, LActButton } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import GIVING_HAND from 'assets/images/giving-hand.png';
import { CloseCircleSharp } from 'react-ionicons';
import { colors } from 'constants/colors';

interface NotifyMobileViewItemsTemplateProps {
  onClose: () => void;
  userName: string;
}

const NotifyMobileViewItemsTemplate = (
  props: NotifyMobileViewItemsTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.home.applications.acceptModalNotify';
  const { onClose, userName } = props;
  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  const onCloseBottomSheet = () => {
    onClose();
  };

  return (
    <div
      className={
        'flex flex-col bg-white rounded-tl-3xl rounded-tr-3xl h-3/4 self-end'
      }
    >
      <div className="mt-3 mr-3 cursor-pointer self-end">
        <CloseCircleSharp
          width="24px"
          height="24px"
          onClick={onCloseBottomSheet}
          color={colors.palette.grey700}
        />
      </div>
      <div className={'flex flex-col items-center -mt-2.5'}>
        <LActRow className={'items-center'}>
          {applicationStorage?.application?.volunteer?.imageUri ? (
            <LActImage
              source={applicationStorage?.application?.volunteer?.imageUri}
              className={'w-20 h-20 justify-center rounded-full object-contain'}
            />
          ) : (
            <PersonCircle width={'93px'} height={'93px'} color={'#1F4043'} />
          )}
          <LActImage
            className={'w-20 h-20 justify-center rounded-full -ml-6'}
            source={
              applicationStorage?.application?.offer?.organization?.imageUri
            }
          />
        </LActRow>

        <LActText
          textColor={'text-textPrimary'}
          text={translate(`${i18n}.title`)}
          className={'font-semibold mt-3 text-center'}
          textFormatType={EnumTextFormatType.FONT_SIZE_32}
        />
        <LActText
          numberOfLines="line-clamp-4"
          textColor={'text-textSecondary'}
          text={translate(`${i18n}.description`, {
            name: userName,
          })}
          textFormatType={EnumTextFormatType.FONT_SIZE_14}
          className={'font-normal mt-2 text-center leading-6 px-6'}
        />

        <LActImage
          source={GIVING_HAND}
          className={'w-60 h-60 justify-center mt-6'}
        />

        <div className={'w-full mb-8 px-6'}>
          <LActButton
            testID={'continue-button'}
            containerStyle={'w-full'}
            onClick={onCloseBottomSheet}
            text={translate(`${i18n}.approveButton`)}
          />
        </div>
      </div>
    </div>
  );
};

export default NotifyMobileViewItemsTemplate;
