import React from 'react';
import { translate } from 'i18n';
import { convertDateWithMonthName } from 'utils/global/functions';
import { LActText } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import { ApplicationItems } from 'api/schemas/applicationItems';
import { useSwitchTranslation } from 'utils';

interface ApplicationsAppliedOnTemplateProps {
  item: ApplicationItems;
}

const ApplicationsAppliedOnTemplate = (
  props: ApplicationsAppliedOnTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.home.applications';
  const { item } = props;

  useSwitchTranslation();

  return (
    <div
      className={
        'desktop:mt-6 mobile:mt-0 flex mobile:flex-row desktop:flex-col'
      }
    >
      <LActText
        testID={'applied-on-text'}
        numberOfLines={'line-clamp-1'}
        textFormatType={EnumTextFormatType.FONT_SIZE_12}
        text={translate(`${i18n}.appliedOn`)}
        className={
          'desktop:text-neutral400 desktop:text-xs mobile:text-xs mobile:text-textSecondary'
        }
      />

      <LActText
        numberOfLines={'line-clamp-1'}
        textFormatType={EnumTextFormatType.FONT_SIZE_16}
        text={convertDateWithMonthName(item?.createdAt)}
        className={
          'desktop:textSecondary desktop:text-base mobile:text-xs mobile:textSecondary mobile:ml-1 desktop:ml-0 font-semibold'
        }
      />
    </div>
  );
};

export default ApplicationsAppliedOnTemplate;
