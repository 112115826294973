import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginScreen from 'screens/auth/login/login.index';
import RegisterScreen from 'screens/auth/register/register.index';
import VerifyEmailScreen from 'screens/auth/register/register.checkInbox';
import RegisterOrgDetailsScreen from 'screens/auth/register/organization/register.org.details';
import RegisterOrgContactInfoScreen from 'screens/auth/register/organization/register.org.contact';
import HomeScreen from 'screens/home/home.index';
import ResetPasswordScreen from 'screens/auth/resetPassword/resetPassword.index';
import ApplicationsScreen from 'screens/applications/applications.index';
import ProfileScreen from 'screens/profile/profile.index';
import AccessTokenCheckProvider from 'components/providers/AccessTokenCheckProvider/index';
import OffersDetailsScreen from 'screens/home/components/template/offers/details/offers.details';
import OfferCreationScreen from 'screens/offerCreation/offer.creation.index';
import ManagementScreen from 'screens/management/management.index';
import ManagementOrganizationDetailsScreen from 'screens/management/details/organization.details';
import OfferDetailsDeepLinkScreen from 'screens/deepLinks/offer.details.deep.link';

import {
  GLOBAL,
  HOME_SCREEN,
  LOGIN_SCREEN,
  PROFILE_SCREEN,
  REGISTER_SCREEN,
  MANAGEMENT_SCREEN,
  ORG_CONTACT_SCREEN,
  APPLICATIONS_BOARD,
  ORG_DETAILS_SCREEN,
  VERIFY_EMAIL_SCREEN,
  RESET_PASSWORD_SCREEN,
  OFFERS_DETAILS_SCREEN,
  OFFERS_CREATION_SCREEN,
  MANAGEMENT_DETAILS_SCREEN,
  OFFER_DETAILS_DEEP_LINK_SCREEN,
} from 'constants/route';

const Navigator = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="*" element={<p>Not Found</p>} />
      <Route path={GLOBAL} element={<AccessTokenCheckProvider />} />
      <Route path={LOGIN_SCREEN} element={<LoginScreen />} />
      <Route path={REGISTER_SCREEN} element={<RegisterScreen />} />
      <Route path={VERIFY_EMAIL_SCREEN} element={<VerifyEmailScreen />} />
      <Route path={ORG_DETAILS_SCREEN} element={<RegisterOrgDetailsScreen />} />
      <Route
        path={ORG_CONTACT_SCREEN}
        element={<RegisterOrgContactInfoScreen />}
      />
      <Route path={RESET_PASSWORD_SCREEN} element={<ResetPasswordScreen />} />
      <Route path={HOME_SCREEN} element={<HomeScreen />} />
      <Route path={PROFILE_SCREEN} element={<ProfileScreen />} />
      <Route path={APPLICATIONS_BOARD} element={<ApplicationsScreen />} />
      <Route path={OFFERS_CREATION_SCREEN} element={<OfferCreationScreen />} />
      <Route path={OFFERS_DETAILS_SCREEN} element={<OffersDetailsScreen />} />
      <Route path={MANAGEMENT_SCREEN} element={<ManagementScreen />} />
      <Route
        path={MANAGEMENT_DETAILS_SCREEN}
        element={<ManagementOrganizationDetailsScreen />}
      />
      <Route
        path={`${OFFER_DETAILS_DEEP_LINK_SCREEN}/:id`}
        element={<OfferDetailsDeepLinkScreen />}
      />
    </Routes>
  );
};

export default Navigator;
