import React from 'react';
import { translate } from 'i18n';
import { LActText, LActImage, LActButton } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import WORLD from 'assets/images/icon-world.png';
import { CloseCircleSharp } from 'react-ionicons';
import { colors } from 'constants/colors';

interface WelcomeMobileViewItemsTemplateProps {
  onClose: () => void;
}

const WelcomeMobileViewItemsTemplate = (
  props: WelcomeMobileViewItemsTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.register.welcomeModal';
  const { onClose } = props;

  const onCloseBottomSheet = () => {
    onClose();
  };

  return (
    <div
      className={
        'flex flex-col bg-white rounded-tl-3xl rounded-tr-3xl h-3/4 self-end w-full'
      }
    >
      <div className="mt-3 mr-3 cursor-pointer self-end">
        <CloseCircleSharp
          width="24px"
          height="24px"
          onClick={onCloseBottomSheet}
          color={colors.palette.grey700}
        />
      </div>

      <div className={'flex flex-col items-center -mt-2.5'}>
        <LActText
          textColor={'text-textPrimary'}
          text={translate(`${i18n}.titleFirst`)}
          className={'font-semibold mt-3 text-center'}
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
        />
        <LActText
          numberOfLines="line-clamp-4"
          textColor={'text-textSecondary'}
          text={translate(`${i18n}.titleSecond`)}
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
          className={'font-semibold mt-2 text-center leading-6 px-1'}
        />
        <LActText
          numberOfLines="line-clamp-4"
          textColor={'text-textSecondary'}
          text={translate(`${i18n}.titleThird`)}
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
          className={'font-semibold mt-2 text-center leading-6 px-1'}
        />
        <LActImage source={WORLD} className={'w-60 h-60 justify-center mt-6'} />

        <div className={'w-full mb-8 px-6'}>
          <LActButton
            testID={'continue-button'}
            containerStyle={'w-full'}
            onClick={onCloseBottomSheet}
            text={translate(`${i18n}.letsGoButton`)}
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeMobileViewItemsTemplate;
