import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { IAccessTokenTypes } from 'constants/types';
import { USER_STORE_KEYS } from './keys';

const LOCAL_DB = createJSONStorage(() => localStorage);

const useAccessTokenStorage = create(
  persist(
    set => ({
      token: undefined,
      purgeAccessToken: () => set({ token: undefined }),
      storeAccessToken: (token: IAccessTokenTypes) => set(() => ({ token })),
    }),
    {
      name: USER_STORE_KEYS.ACCESS_TOKEN,
      storage: LOCAL_DB,
    },
  ),
);

export default useAccessTokenStorage;
