/* eslint-disable no-useless-escape */
import { translate } from 'i18n';
import * as yup from 'yup';

const i18n = 'templates.contactInfo.validationErrorMessages';

export const ManagementContactInfoSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      {
        message: translate(`${i18n}.invalidEmail`),
      },
    )
    .required(translate(`${i18n}.invalidEmail`)),

  phoneNumber: yup
    .string()
    .matches(/^[1-9][0-9]*$/, '')
    .min(8, () => translate(`${i18n}.invalidPhoneNumberLength`))
    .required(translate(`${i18n}.invalidEmail`)),

  firstname: yup
    .string()
    .min(3, () => translate(`${i18n}.invalidFirstName`))
    .required(translate(`${i18n}.invalidFirstName`)),

  lastname: yup
    .string()
    .min(3, () => translate(`${i18n}.invalidLastName`))
    .required(translate(`${i18n}.invalidLastName`)),
});
