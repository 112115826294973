import { create } from 'zustand';
import { IKanbanApplicationsTypes } from 'constants/types';

const useUpdateKanbanApplicationsStorage = create(set => ({
  data: {
    applicationID: 0,
    updatedStatus: '',
  },
  purgeKanbanApplications: () =>
    set({
      data: {
        applicationID: 0,
        updatedStatus: '',
      },
    }),
  storeKanbanApplications: (applicationID: number, updatedStatus: string) =>
    set((state: IKanbanApplicationsTypes) => ({
      data: {
        applicationID: applicationID,
        updatedStatus: updatedStatus,
      },
    })),
}));

export default useUpdateKanbanApplicationsStorage;
