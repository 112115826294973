import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { OFFERS } from './keys';
import { Offer } from 'api/schemas/offer';

const LOCAL_DB = createJSONStorage(() => localStorage);

const useOffersStorage = create(
  persist(
    set => ({
      offers: [],
      purgeOffers: () => set({ offers: [] }),
      storeOffers: (offers: Array<Offer>) => set(() => ({ offers })),
    }),
    {
      name: OFFERS.REFETCH_OFFERS,
      storage: LOCAL_DB,
    },
  ),
);

export default useOffersStorage;
