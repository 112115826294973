import React, { useMemo, useState } from 'react';
import { translate } from 'i18n';
import { useNavigate } from 'react-router-dom';
import { ChevronBackOutline } from 'react-ionicons';
import {
  LActRow,
  LActText,
  LActScreen,
  Breadcrumb,
  LActButton,
} from 'components/index';
import {
  EnumButtonType,
  EnumErrorType,
  EnumTextFormatType,
  EnumOffersFilterType,
} from 'constants/enums';
import { colors } from 'constants/colors';
import OfferCreationDetailsTemplate from './templates/offer.creation.details';
import OfferCreationCategoryTemplate from './templates/offer.creation.category';
import OfferCreationScheduleTemplate from './templates/offer.creation.schedule';
import OfferCreationLocationTemplate from './templates/offer.creation.location';
import OfferCreationLanguageTemplate from './templates/offer.creation.language';
import OfferCreationSkillsTemplate from './templates/offer.creation.skills';
import OfferCreationPreview from './templates/offer.creation.preview';
import {
  IAccessTokenTypes,
  IOfferCreationTypes,
  IOrganizationInfoTypes,
} from 'constants/types';
import {
  useRefreshToken,
  useSwitchTranslation,
  useAccessTokenStorage,
  useOrganizationStorage,
  useOfferCreationStorage,
} from 'utils';
import {
  usePostAPIV1OfferImage,
  usePostApiV1OfferCreation,
} from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { Criteria } from 'api/schemas/criteria';
import { LetsAPICooperation } from './templates/utils';

const OfferCreationScreen = (): React.ReactElement => {
  const i18n = 'screens.offerCreation';
  const ALL_STEPS: number = 6;
  const { currentLanguage } = useSwitchTranslation();
  const navigate = useNavigate();
  const [steps, setSteps] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    type: string;
    message: string;
  }>({
    type: '',
    message: '',
  });

  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  const PATCH_QUERY = usePostAPIV1OfferImage({
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });

  // Important
  const CREATE_OFFER = usePostApiV1OfferCreation({
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });

  const onBackClicked = () => {
    offerCreationStorage?.purgeOffers();
    navigate(-1);
  };

  const onNextClicked = () => {
    if (steps >= 6) return;

    setSteps(prevState => {
      return prevState + 1;
    });
  };

  const onBackStepClicked = () => {
    if (steps === 1) return;

    setSteps(prevState => {
      return prevState - 1;
    });
  };

  const onUploadImage = (offerID: number) => {
    if (!offerCreationStorage?.offer?.picture) return;

    const formData = new FormData();
    formData.append('file', offerCreationStorage?.offer?.picture);

    PATCH_QUERY.mutateAsync({
      offerId: offerID?.toString(),
      data: formData,
    })
      .then((data: any) => {
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const onCreateClicked = (requiredCriteria?: Array<Criteria>) => {
    if (!offerCreationStorage?.offer) return;

    const CRITERIA: any = [];
    requiredCriteria?.map(item => {
      return CRITERIA.push({
        category: item?.property?.category,
        value: item?.property?.value,
      });
    });

    setIsLoading(true);

    CREATE_OFFER?.mutateAsync({
      data: {
        isPausedByAdmin: false,
        title: offerCreationStorage?.offer?.title,
        goal: offerCreationStorage?.offer?.goal,
        impact: offerCreationStorage?.offer?.impact,
        ageLimit: 0,
        maxParticipants: 0,
        status: EnumOffersFilterType.PUBLISHED,
        locationType: offerCreationStorage?.offer?.locationType,
        street: offerCreationStorage?.offer?.street,
        zipCode: offerCreationStorage?.offer?.zipCode,
        city: offerCreationStorage?.offer?.city,
        country: offerCreationStorage?.offer?.country,
        organizationId: organizationStorage.organization.id,
        categoryId: offerCreationStorage?.offer?.category?.id,
        positionAmount: 0,
        allowedPendingApplications: 0,
        requiredCriteria: CRITERIA,
        schedule: {
          frequency: offerCreationStorage?.offer?.schedule?.frequency,
          weekdays: offerCreationStorage?.offer?.schedule?.weekdays,
          minHours: offerCreationStorage?.offer?.schedule?.minHours,
        },
      },
    })
      .then((response: any) => {
        setMessage({
          type: EnumErrorType.SUCCESS,
          message: translate(`${i18n}.successOfCreation`),
        });
        if (response?.item) {
          onUploadImage(response?.item?.id);

          setTimeout(() => {
            LetsAPICooperation(offerCreationStorage?.offer);
          }, 2500);

          setSteps(1);
          offerCreationStorage?.purgeOffers();

          setMessage({
            type: '',
            message: '',
          });

          setIsLoading(false);
        }
      })
      .catch(error => {
        setMessage({
          type: EnumErrorType.ERROR,
          message: translate(`${i18n}.errorOfCreation`),
        });
        setIsLoading(false);
      });
  };
  useRefreshToken({
    refetch: onCreateClicked,
    error: CREATE_OFFER?.error,
    hasError: CREATE_OFFER?.isError,
  });

  const StepsTemplate = useMemo(() => {
    switch (steps) {
      case 1:
        return (
          <>
            <LActText
              textColor={'text-textPrimary'}
              className={'text-center px-4'}
              numberOfLines={'line-clamp-4'}
              text={translate(`${i18n}.name.detailsTitle`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_24}
            />
            <LActText
              textColor={'text-neutral400'}
              numberOfLines={'line-clamp-4'}
              className={'text-center mb-4 mt-1 px-4'}
              text={translate(`${i18n}.name.description`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
            />
            <OfferCreationDetailsTemplate
              lastStep={ALL_STEPS}
              currentStep={steps}
              onNextClicked={onNextClicked}
            />
          </>
        );
      case 2:
        return (
          <>
            <LActText
              textColor={'text-textPrimary'}
              className={'text-center px-4'}
              numberOfLines={'line-clamp-4'}
              text={translate(`${i18n}.name.categoryTitle`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_24}
            />
            <LActText
              textColor={'text-neutral400'}
              numberOfLines={'line-clamp-4'}
              className={'text-center mt-1 px-4'}
              text={translate(`${i18n}.name.categoryDescription`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
            />
            <LActText
              textColor={'text-neutral400'}
              className={'text-center font-bold mb-4 mt-1 px-4'}
              text={translate(`${i18n}.name.categoryAlert`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
            />

            <OfferCreationCategoryTemplate
              lastStep={ALL_STEPS}
              currentStep={steps}
              onNextClicked={onNextClicked}
            />
          </>
        );
      case 3:
        return (
          <>
            <LActText
              textColor={'text-textPrimary'}
              numberOfLines={'line-clamp-4'}
              className={'text-center mb-4 px-4'}
              text={translate(`${i18n}.schedule.title`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_24}
            />

            <OfferCreationScheduleTemplate
              lastStep={ALL_STEPS}
              currentStep={steps}
              onNextClicked={onNextClicked}
            />
          </>
        );
      case 4:
        return (
          <>
            <LActText
              textColor={'text-textPrimary'}
              numberOfLines={'line-clamp-4'}
              className={'text-center mb-4 px-4'}
              text={translate(`${i18n}.location.title`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_24}
            />
            <OfferCreationLocationTemplate
              lastStep={ALL_STEPS}
              currentStep={steps}
              onNextClicked={onNextClicked}
            />
          </>
        );
      case 5:
        return (
          <>
            <LActText
              textColor={'text-textPrimary'}
              className={'text-center px-4'}
              numberOfLines={'line-clamp-4'}
              text={translate(`${i18n}.language.title`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_24}
            />
            <LActText
              textColor={'text-neutral400'}
              numberOfLines={'line-clamp-4'}
              className={'text-center mb-4 mt-1 px-4'}
              text={translate(`${i18n}.language.description`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
            />

            <OfferCreationLanguageTemplate
              lastStep={ALL_STEPS}
              currentStep={steps}
              onNextClicked={onNextClicked}
            />
          </>
        );
      case 6:
        return (
          <>
            <LActText
              textColor={'text-textPrimary'}
              className={'text-center px-4'}
              text={translate(`${i18n}.language.titleSkills`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_24}
            />

            <OfferCreationSkillsTemplate
              lastStep={ALL_STEPS}
              currentStep={steps}
              loading={isLoading}
              onNextClicked={(requiredCriteria: Array<Criteria>) =>
                onCreateClicked(requiredCriteria)
              }
            />
          </>
        );
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, isLoading, currentLanguage]);

  return (
    <LActScreen>
      <LActRow spaceBetween className={'-mr-4 items-center mt-7'}>
        <div>
          <LActButton
            onClick={onBackClicked}
            type={EnumButtonType.TEXT}
            textStyle={'text-tint text-sm'}
            text={translate(`${i18n}.back`)}
            iconComponent={
              <ChevronBackOutline
                width="14px"
                height="14px"
                style={{
                  marginTop: -1,
                  marginRight: -6,
                }}
                color={colors.palette.primaryDark}
              />
            }
          />
        </div>
        <div>
          <Breadcrumb
            routes={[
              translate(`${i18n}.breadcrumb.home`),
              translate(`${i18n}.breadcrumb.offerCreation`),
            ]}
          />
        </div>
      </LActRow>

      <div className="mt-8">
        <LActText
          className={'mt-6'}
          text={translate(`${i18n}.title`)}
          textColor={
            'text-textPrimary text-regular desktop:flex desktop:flex-1 mobile:hidden mobile:text-2xl desktop:text-3xl'
          }
          textFormatType={EnumTextFormatType.FONT_SIZE_48}
        />
      </div>

      <div className={'desktop:flex desktop:flex-row mobile:flex-col mt-8'}>
        <div className={'desktop:w-3/5 mobile:w-full'}>
          <LActRow spaceBetween className={'-mr-4 items-center'}>
            <div>
              {steps > 1 ? (
                <LActButton
                  type={EnumButtonType.TEXT}
                  onClick={onBackStepClicked}
                  textStyle={'text-tint text-sm'}
                  text={translate(`${i18n}.step`) + ` ${steps - 1}`}
                  iconComponent={
                    <ChevronBackOutline
                      width="14px"
                      height="14px"
                      style={{
                        marginTop: -1,
                        marginRight: -6,
                      }}
                      color={colors.palette.primaryDark}
                    />
                  }
                />
              ) : (
                <LActButton
                  disabled
                  type={EnumButtonType.TEXT}
                  onClick={onBackStepClicked}
                  textStyle={'text-white text-sm'}
                  text={translate(`${i18n}.step`) + ` ${steps - 1}`}
                />
              )}
            </div>
          </LActRow>
          <div className="overflow-scroll">{StepsTemplate}</div>

          {message?.message && (
            <LActText
              text={message?.message}
              className={'font-semibold mt-6'}
              textFormatType={EnumTextFormatType.FONT_SIZE_16}
              textColor={
                message.type === EnumErrorType.ERROR
                  ? 'text-error'
                  : 'text-green'
              }
            />
          )}
        </div>

        <div className="desktop:overflow-y-auto desktop:flex desktop:w-full mobile:hidden mobile:invisible desktop:flex desktop:visible justify-center">
          <div className="desktop:flex desktop:w-2/4 desktop:visible mobile:mt-6 desktop:mt-10 desktop:mt-0 mobile:hidden mobile:invisible">
            <OfferCreationPreview />
          </div>
        </div>
      </div>
    </LActScreen>
  );
};

export default OfferCreationScreen;
