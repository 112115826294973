import React from 'react';
import { Offer } from 'api/schemas/offer';
import { LActBadge, LActImage, LActRow, LActText } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import OfferSchedulingTemplate from './offer.scheduling';
import { CONST_DEFAULT_ORG_IMAGE } from 'constants/constants';

interface OfferTemplateProps {
  item?: Offer;
  status?: string;
  className?: string;
  onClick?: () => void;
  badgeBGColor?: string;
  badgeTextColor?: string;
  badgeClassName?: string;
}

const OfferTemplate = (props: OfferTemplateProps): React.ReactElement => {
  const {
    item,
    status,
    onClick,
    className,
    badgeBGColor,
    badgeTextColor,
    badgeClassName,
  } = props;

  const ORG_IMAGE = item?.organization?.imageUri
    ? item?.organization?.imageUri
    : CONST_DEFAULT_ORG_IMAGE;

  return (
    <div
      onClick={onClick}
      className={`${className} rounded-3xl bg-white shadow-xl`}
    >
      <LActImage
        testID="cover-image"
        source={item?.picture}
        className={'rounded-tl-3xl rounded-tr-3xl h-48 w-full object-cover'}
      />

      <div className={'px-6 pt-4 pb-6'}>
        <LActText
          text={item?.title}
          className="font-extrabold"
          numberOfLines={'line-clamp-6 w-64'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
        />

        <OfferSchedulingTemplate
          offer={item}
          className={'mt-2.5'}
          item={item?.schedule}
        />

        <LActRow className={'mt-4 items-center'}>
          <LActImage
            testID="org-image"
            source={ORG_IMAGE}
            className={'rounded-full w-8 h-8'}
          />
          <LActText
            textColor={'text-grayDark'}
            numberOfLines={'line-clamp-1'}
            text={item?.organization?.name}
            className={'font-extrabold mt-px ml-2'}
            textFormatType={EnumTextFormatType.FONT_SIZE_16}
          />
        </LActRow>
      </div>

      {status && (
        <LActBadge
          title={status}
          bgColor={badgeBGColor}
          textColor={badgeTextColor}
          className={`absolute -top-4 -right-4 ${badgeClassName}`}
        />
      )}
    </div>
  );
};

export default OfferTemplate;
