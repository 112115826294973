import React from 'react';
import { LActButton, LActText } from 'components/index';
import { translate } from 'i18n';
import { IApplicationStorageTypes } from 'constants/types';
import { useApplicationStorage } from 'utils';
import { EnumButtonType, EnumTextFormatType } from 'constants/enums';

interface StatusAcceptedDialogTemplateProps {
  onAcceptClicked: () => void;
  onKeepCurrentStateClicked: () => void;
}

const StatusAcceptedDialogTemplate = (
  props: StatusAcceptedDialogTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.home.applications.acceptActionModal';
  const { onAcceptClicked, onKeepCurrentStateClicked } = props;

  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  const VOLUNTEER_FIRST_NAME =
    applicationStorage?.application?.volunteer?.firstname ?? '';

  return (
    <div className={'w-80 shadow-xl p-6 rounded-3xl bg-white justify-center'}>
      <LActText
        textColor={'text-textPrimary'}
        text={translate(`${i18n}.title`, {
          name: VOLUNTEER_FIRST_NAME,
        })}
        className={'font-semibold text-center'}
        textFormatType={EnumTextFormatType.FONT_SIZE_24}
      />

      <LActText
        numberOfLines={'line-clamp-4'}
        textColor={'text-textSecondary'}
        text={translate(`${i18n}.description`)}
        className={'text-center mt-2.5 leading-6'}
        textFormatType={EnumTextFormatType.FONT_SIZE_14}
      />

      <LActButton
        testID={'accept-button'}
        onClick={onAcceptClicked}
        containerStyle={'p-2 mt-6 text-sm z-3000'}
        text={translate(`${i18n}.acceptButton`)}
      />

      <LActButton
        testID={'cancel-button'}
        type={EnumButtonType.TEXT}
        containerStyle={'p-2 mt-1 z-3000'}
        onClick={onKeepCurrentStateClicked}
        text={translate(`${i18n}.reviewButton`)}
        textStyle={'text-tint text-sm font-semibold'}
      />
    </div>
  );
};

export default StatusAcceptedDialogTemplate;
