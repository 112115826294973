import React from 'react';
import { Volunteer } from 'api/schemas/volunteer';
import ContactInfoTemplate from '../contactInfo/contactInfo.index';
import VolunteerPersonalInfoTemplate from './volunteer.personalInfo';
import { ApplicationItems } from 'api/schemas/applicationItems';

interface VolunteerTemplateProps {
  item?: Volunteer;
  applicationStatus?: string;
  application?: ApplicationItems;
  onChangeStatusClicked: () => void;
}

const VolunteerTemplate = (
  props: VolunteerTemplateProps,
): React.ReactElement => {
  const { item, application, applicationStatus, onChangeStatusClicked } = props;

  return (
    <div className={'desktop:px-8 mobile:px-2'}>
      <VolunteerPersonalInfoTemplate
        item={item}
        application={application}
        applicationStatus={applicationStatus}
        onChangeStatusClicked={onChangeStatusClicked}
      />
      <ContactInfoTemplate
        item={item}
        className={'mt-6'}
        refetch={() => undefined}
      />
    </div>
  );
};

export default VolunteerTemplate;
