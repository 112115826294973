import { useEffect } from 'react';
import i18n from 'i18n-js';
import { ILanguageSwitchTypes } from 'constants/types';
import { useLanguageSwitchStorage } from 'utils';

const useSwitchTranslation = () => {
  // @ts-ignore
  const languageSwitchStorage: ILanguageSwitchTypes = useLanguageSwitchStorage(
    (store: ILanguageSwitchTypes | unknown) => store,
  );

  useEffect(() => {
    if (languageSwitchStorage.language) {
      i18n.locale = languageSwitchStorage?.language;
    }
  }, [languageSwitchStorage.language]);

  return {
    currentLanguage: languageSwitchStorage?.language,
  };
};

export default useSwitchTranslation;
