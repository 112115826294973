import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CloseOutline,
  RefreshOutline,
  FileTrayOutline,
  CheckmarkOutline,
  PaperPlaneOutline,
} from 'react-ionicons';
import {
  usePostApiV1AuthLogin,
  useGetApiV1UsersUserIdEmailVerificationCheck,
  usePostApiV1UsersUserIdEmailVerificationResend,
} from 'api/endpoints/queries';
import { translate } from 'i18n';
import { IAccessTokenTypes, IUserInfoTypes } from 'constants/types';
import {
  useUserStorage,
  useSwitchTranslation,
  useAccessTokenStorage,
} from 'utils';
import {
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
} from 'constants/enums';
import { colors } from 'constants/colors';
import { LActButton, LActText } from 'components/index';
import AuthScreenContainer from '../container';
import { ORG_DETAILS_SCREEN } from 'constants/route';
import { parseTokenStorageObject } from 'utils/global/functions';

const CheckInboxScreen = (): React.ReactElement => {
  const i18n = 'screens.checkInbox';
  useSwitchTranslation();
  const navigate = useNavigate();
  const {
    state: { password },
  } = useLocation();
  const [resendHasError, setResendHasError] = useState<boolean>(false);

  useEffect(() => {
    if (!password) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );
  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );
  const API_QUERY_LOGIN = usePostApiV1AuthLogin();
  const API_QUERY_CHECK_VERIFICATION =
    useGetApiV1UsersUserIdEmailVerificationCheck(
      userStorage?.user?.id.toString(),
    );
  const API_QUERY_RESEND = usePostApiV1UsersUserIdEmailVerificationResend();

  const RESEND_BUTTON_ICON_SOURCE = API_QUERY_RESEND?.isLoading
    ? 'reloadOutline'
    : API_QUERY_RESEND?.isSuccess
    ? 'checkMarkOutline'
    : 'sendOutline';

  const RESEND_BUTTON_TEXT = API_QUERY_RESEND?.isLoading
    ? translate(`${i18n}.reSending`)
    : API_QUERY_RESEND?.isSuccess
    ? translate(`${i18n}.linkSent`)
    : translate(`${i18n}.resendButton`);

  const DISABLE_CONTINUE_BUTTON =
    !API_QUERY_CHECK_VERIFICATION?.data?.emailVerified &&
    !API_QUERY_LOGIN?.isSuccess
      ? true
      : false;

  const navigateOnOrgDetailsScreen = () => {
    navigate(ORG_DETAILS_SCREEN, {
      replace: true,
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!API_QUERY_CHECK_VERIFICATION?.data?.emailVerified) {
        API_QUERY_CHECK_VERIFICATION?.refetch().then(data => {
          if (data?.data?.emailVerified) {
            clearInterval(interval);
            API_QUERY_LOGIN?.mutateAsync({
              data: {
                username: userStorage?.user?.email,
                password: password,
                scope: 'organization',
              },
            })
              // @ts-ignore
              .then((response: AccessToken) => {
                const storeObject = parseTokenStorageObject(response);
                tokenStorage.storeAccessToken(storeObject);
                navigateOnOrgDetailsScreen();
              })
              .catch(error => error);
          }
        });
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResendButtonPressed = () => {
    API_QUERY_RESEND?.mutateAsync({ userId: userStorage?.user?.id.toString() })
      .then(data => data)
      .catch(error => setResendHasError(true));
  };

  const ResendErrorState = useMemo(() => {
    return (
      <LActButton
        testID={'register-button'}
        type={EnumButtonType.TEXT}
        textStyle={'text-error font-bold'}
        onClick={() => setResendHasError(false)}
        actionType={EnumButtonActionType.BUTTON}
        text={translate(`${i18n}.linkSendError`)}
        iconComponent={
          <CloseOutline color={colors.error} height="18px" width="18px" />
        }
      />
    );
  }, []);

  const ResendTextButtonIcon = useMemo(() => {
    return (
      <>
        {RESEND_BUTTON_ICON_SOURCE === 'sendOutline' && (
          <PaperPlaneOutline width="18px" height="18px" color={colors.tint} />
        )}

        {RESEND_BUTTON_ICON_SOURCE === 'checkMarkOutline' && (
          <CheckmarkOutline width="18px" height="18px" color={colors.tint} />
        )}

        {RESEND_BUTTON_ICON_SOURCE === 'reloadOutline' && (
          <RefreshOutline width="18px" height="18px" color={colors.tint} />
        )}
      </>
    );
  }, [RESEND_BUTTON_ICON_SOURCE]);

  return (
    <AuthScreenContainer
      style={`flex justify-center items-center mobile:mx-6 mobile:w-full flex-shrink-1`}
    >
      <div className="desktop:w-3/5 mobile:w-full">
        <div className="flex justify-center">
          <FileTrayOutline height="96px" width="96px" color={colors.tint} />
        </div>

        <LActText
          text={translate(`${i18n}.titleOne`)}
          className="text-textPrimary text-center mt-3"
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
        />
        <LActText
          text={translate(`${i18n}.titleTwo`)}
          className="text-textPrimary text-center"
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
        />

        <LActText
          text={translate(`${i18n}.description`, {
            email: userStorage?.user?.email,
          })}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          className="text-textSecondary text-center text-sm mt-2"
        />

        {/* Buttons container */}
        <div className="mt-16">
          <LActButton
            testID={'login-button'}
            type={EnumButtonType.NORMAL}
            disabled={DISABLE_CONTINUE_BUTTON}
            text={translate(`${i18n}.continue`)}
            onClick={navigateOnOrgDetailsScreen}
            actionType={EnumButtonActionType.BUTTON}
          />

          {resendHasError ? (
            <>{ResendErrorState}</>
          ) : (
            <LActButton
              text={RESEND_BUTTON_TEXT}
              testID={'register-button'}
              type={EnumButtonType.TEXT}
              onClick={onResendButtonPressed}
              textStyle={'text-tint font-bold'}
              iconComponent={ResendTextButtonIcon}
              actionType={EnumButtonActionType.BUTTON}
            />
          )}
        </div>
      </div>
    </AuthScreenContainer>
  );
};

export default CheckInboxScreen;
