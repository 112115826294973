import React, { useState } from 'react';
import { usePostApiV1LatestOffers } from 'api/endpoints/queries';
import { LActRow, LActText, LActButton, LActTextInput } from 'components/index';
import { SendOutline } from 'react-ionicons';
import { colors } from 'constants/colors';
import { delay } from 'utils/global/functions';
import axios from 'axios';
import { EnumButtonActionType, EnumButtonType } from 'constants/enums';

const ManagementNotificationsScreen = (): React.ReactElement => {
  const [latestCreatedOffersDate, setLatestCreatedOffersDate] =
    useState<number>(14);
  const [serverMessage, setServerMessage] = useState<{
    message: string;
    type: string;
  }>({
    message: '',
    type: '',
  });
  const [notificationDescriptionEN, setNotificationDescriptionEN] =
    useState<string>('');
  const [notificationDescriptionDE, setNotificationDescriptionDE] =
    useState<string>('');
  const [canRefetchTheQueries, setCanRefetchTheQueries] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notificationTitleEN, setNotificationTitleEN] = useState<string>('');
  const [notificationTitleDE, setNotificationTitleDE] = useState<string>('');
  const [numberOfOffers, setNumberOfOffers] = useState<number>(0);

  // const LATEST_OFFERS_COUNT_QUERY = usePostApiV1LatestOffers(
  //   latestCreatedOffersDate,
  //   {
  //     query: {
  //       retry: 1,
  //       refetchOnMount: false,
  //       refetchOnWindowFocus: false,
  //       enabled: !!canRefetchTheQueries,
  //     },
  //     request: {
  //       headers: {
  //         api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
  //         access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
  //       },
  //     },
  //   },
  // );

  const onLatestDaysChange = (value: any) => {
    setLatestCreatedOffersDate(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onNotificationTitleENChange = (value: string) => {
    setNotificationTitleEN(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onNotificationDescriptionENChange = (value: string) => {
    setNotificationDescriptionEN(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onNotificationTitleDEChange = (value: string) => {
    setNotificationTitleDE(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onNotificationDescriptionDEChange = (value: string) => {
    setNotificationDescriptionDE(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onFetchQueryClicked = () => {
    // setCanRefetchTheQueries(true);
    // delay(3000).then(() => {
    //   setCanRefetchTheQueries(false);
    // });
    let data = JSON.stringify({
      days: latestCreatedOffersDate,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api-stg.letsact.de/api/v1/admin/latest/offers/count',
      headers: {
        access_token: 'b1bf09f7-7e27-4171-a73f-eceb085409d7',
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios
      .request(config)
      .then(response => {
        setNumberOfOffers(response?.data?.item);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onSendNotificationClicked = () => {
    if (latestCreatedOffersDate === 0) {
      setServerMessage({
        message: 'Please enter the last days',
        type: 'error',
      });
    } else if (notificationTitleEN?.length === 0) {
      setServerMessage({
        message: 'Please enter notification title EN',
        type: 'error',
      });
    } else if (notificationDescriptionEN?.length === 0) {
      setServerMessage({
        message: 'Please enter notification description EN',
        type: 'error',
      });
    } else if (notificationDescriptionDE?.length === 0) {
      setServerMessage({
        message: 'Please enter notification description DE',
        type: 'error',
      });
    } else if (notificationTitleDE?.length === 0) {
      setServerMessage({
        message: 'Please enter notification title DE',
        type: 'error',
      });
    } else {
      setIsLoading(true);
      let data = JSON.stringify({
        titleEn: notificationTitleEN?.trim(),
        descriptionEn: notificationDescriptionEN?.trim(),
        titleDe: notificationTitleDE?.trim(),
        descriptionDe: notificationDescriptionDE?.trim(),
        lastDays: latestCreatedOffersDate,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/notifications/general/notification`,
        headers: {
          access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios
        .request(config)
        .then(response => {
          setServerMessage({
            message: response?.data?.item,
            type: 'success',
          });
          setIsLoading(false);
        })
        .catch(error => {
          setServerMessage({
            message: 'An error happened!',
            type: 'error',
          });
        });
    }
  };

  const OFFERS_COUNT =
    numberOfOffers > 0
      ? // @ts-ignore
        numberOfOffers
      : undefined;

  const ENABLE_SEND_NOTIFICATIONS_BUTTON =
    notificationDescriptionEN?.length === 0 &&
    notificationTitleEN?.length === 0 &&
    notificationDescriptionDE?.length === 0 &&
    notificationTitleDE?.length === 0 &&
    latestCreatedOffersDate === 0
      ? true
      : false;

  return (
    <div className="pl-6">
      <div className={`${OFFERS_COUNT && OFFERS_COUNT > 0 && 'pt-6'}`}>
        <span className={`font-bold`}>
          Total Offers last {latestCreatedOffersDate} days:{' '}
          <span className="text-xl border-b-2 border-orange">
            {OFFERS_COUNT}
          </span>
        </span>
      </div>

      <div className="w-1/2 mr-3 mt-6">
        <LActRow className="items-center">
          <div className="w-full">
            <LActTextInput
              type={'text'}
              placeHolder={'Last days'}
              containerStyle="w-[400px]"
              onChange={onLatestDaysChange}
              value={latestCreatedOffersDate?.toString()}
            />
          </div>

          <div className="cursor-pointer">
            <SendOutline
              width="24px"
              height="24px"
              color={colors.tint}
              onClick={onFetchQueryClicked}
            />
          </div>
        </LActRow>
      </div>

      <div className="mt-16">
        <LActRow>
          <div className="w-1/2">
            <LActTextInput
              type={'text'}
              value={notificationTitleEN}
              containerStyle="w-[400px] mt-3"
              placeHolder={'Notification Title EN'}
              onChange={onNotificationTitleENChange}
            />

            <LActTextInput
              type={'text'}
              containerStyle="w-[400px] mt-3"
              value={notificationDescriptionEN}
              placeHolder={'Notification Description EN'}
              onChange={onNotificationDescriptionENChange}
            />
          </div>

          <div className="w-1/2 ml-6">
            <LActTextInput
              type={'text'}
              value={notificationTitleDE}
              containerStyle="w-[400px] mt-3"
              placeHolder={'Notification Title DE'}
              onChange={onNotificationTitleDEChange}
            />

            <LActTextInput
              type={'text'}
              containerStyle="w-[400px] mt-3"
              value={notificationDescriptionDE}
              placeHolder={'Notification Description DE'}
              onChange={onNotificationDescriptionDEChange}
            />
          </div>
        </LActRow>

        <LActButton
          useLoading={isLoading}
          testID={'login-button'}
          containerStyle={'mt-4'}
          type={EnumButtonType.NORMAL}
          text={'Send General Notification'}
          onClick={onSendNotificationClicked}
          actionType={EnumButtonActionType.BUTTON}
          disabled={ENABLE_SEND_NOTIFICATIONS_BUTTON}
        />

        {serverMessage?.message && (
          <LActText
            text={serverMessage?.message}
            numberOfLines={'line-clamp-2'}
            className={`text-sm ml-3 mt-1 w-full text-semibold`}
            textColor={
              serverMessage?.type === 'error' ? 'text-error' : 'text-success'
            }
          />
        )}
      </div>
    </div>
  );
};

export default ManagementNotificationsScreen;
