import React from 'react';
import { EnumTextFormatType } from 'constants/enums';

interface LActTextProps {
  domID?: string;
  testID?: string;
  textColor?: string;
  onClick?: () => void;
  numberOfLines?: string;
  text: string | undefined;
  className?: string | undefined;
  textFormatType?: EnumTextFormatType;
  ellipsizeMode?: 'truncate' | 'text-ellipsis' | 'text-clip' | undefined;
}

const textBaseStyle: string = 'text-regular color-textPrimary';

const LActText = (props: LActTextProps): React.ReactElement => {
  const {
    text,
    domID,
    onClick,
    className,
    textColor,
    ellipsizeMode,
    textFormatType,
    testID = 'text',
    numberOfLines = 'line-clamp-2',
  } = props;

  let textStyle = textBaseStyle; // Default value
  switch (textFormatType) {
    case EnumTextFormatType.FONT_SIZE_64:
      textStyle = 'text-6xl text-regular font-medium';
      break;
    case EnumTextFormatType.FONT_SIZE_48:
      textStyle = 'text-5xl text-regular font-medium';
      break;
    case EnumTextFormatType.FONT_SIZE_40:
      textStyle = 'text-4xl text-regular font-medium';
      break;
    case EnumTextFormatType.FONT_SIZE_32:
      textStyle = 'text-3xl text-regular font-medium';
      break;
    case EnumTextFormatType.FONT_SIZE_24:
      textStyle = 'text-2xl text-regular font-normal';
      break;
    case EnumTextFormatType.FONT_SIZE_16:
      textStyle = 'text-base text-regular font-normal';
      break;
    case EnumTextFormatType.FONT_SIZE_14:
      textStyle = 'text-sm text-regular font-normal';
      break;
    case EnumTextFormatType.FONT_SIZE_12:
      textStyle = 'text-xs text-regular font-normal';
      break;
  }

  return (
    <span
      id={domID}
      onClick={onClick}
      data-testid={testID}
      className={`${textStyle} ${ellipsizeMode} ${className}  ${numberOfLines} ${textColor}`}
    >
      {text}
    </span>
  );
};

export default LActText;
