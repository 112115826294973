const de = {
  responseMessages: {
    error400:
      'Es gibt ein Problem mit deinen Eingabedaten, bitte überprüfe sie erneut',
    error401: 'Bitte log dich zuerst ein',
    error403: 'Sie haben nicht die Erlaubnis, diesen Schritt zu sehen',
    error404: 'Unter der angegebenen E-Mail ist kein Nutzer registriert.',
    error409:
      'Die E-Mail ist bereits registriert, bitte log dich stattdessen ein.',
    error422:
      'Es ist ein Fehler aufgetreten, bitte überprüfe deine E-Mail und dein Passwort.',
    error500:
      'Es gab einen Fehler auf unseren Servern, den wir gerade überprüfen. Danke für deine Geduld :)',
  },
  screens: {
    login: {
      login: 'In deinen letsact \nAccount einloggen',
      loginButton: 'Einloggen',
      description: 'Jetzt einloggen und aktiv werden',
      errorMessage: {
        invalidEmail: 'Ungültige E-Mail Adresse',
        verifyEmail: 'E-Mail nicht verifiziert. Bitte verifiziere deine E-mail',
        invalidPasswordMatch: 'Ungültiges Passwort',
        invalidPassword:
          'Das Passwort muss mindestens 8 Zeichen enthalten, darunter mindestens ein Großbuchstabe und eine Zahl.',
        invalidInputs:
          'Huch! Die E-Mail oder das Passwort stimmt nicht ganz. \nMöchtest du es noch einmal versuchen?',
      },
      emailPlaceHolder: 'Deine E-Mail',
      passwordPlaceHolder: 'Dein Passwort',
      forgetPassword: 'Passwort vergessen?',
      register: 'Stattdessen registrieren',
    },
    register: {
      titleOne: 'Registriere deine',
      titleTwo: 'Organisation',
      description:
        'Starte deine Reise noch heute und registriere einen Account.',
      registerButton: 'Registrieren',
      loginButton: 'Du hast schon einen Account? Einloggen',
      emailPlaceHolder: 'Deine E-Mail',
      passwordPlaceHolder: 'Dein Passwort',
      orgNamePlaceHolder: 'Name der Organisation',
      homePageUrlPlaceholder: 'Die Url Ihrer Website mit https://',
      passwordInfoMessage:
        'Mindestens 8 Zeichen, ein Kleinbuchstabe und ein Großbuchstabe',
      homePageUrlMessage:
        'Bitte geben Sie Ihre Website, Instagram oder Linkedin Url ein',
      passwordMatchMessage: 'Toll, das ist ein sicheres Passwort!',
      errorMessage: {
        invalidOrg: 'Bitte geben Sie den Namen Ihrer Organisation ein',
        invalidEmail: 'Bitte gib eine gültige E-Mail ein',
        invalidPasswordMatch:
          'Min. 8 Zeichen, inklusive Kleinbuchstaben (a-z), Großbuchstaben (A-Z) und Zahlen (0-9)',
        userEmailExists:
          'Die E-mail ist bereits registriert. Möchtest du dich stattdessen einloggen?',
        invalidPassword:
          'Das Passwort muss mindestens 8 Zeichen enthalten, darunter mindestens ein Großbuchstabe und eine Zahl.',
        invalidInputs:
          'Huch! Die E-Mail oder das Passwort stimmt nicht ganz. \nMöchtest du es noch einmal versuchen?',
        invalidOrgName: 'Der Name sollte zwischen 3 und 50 Zeichen haben.',
        invalidHomePageUrl: 'Bitte geben Sie eine gültige Url ein',
        invalidHomePageUrlLength: 'Url muss mindestens 10 Zeichen enthalten',
      },
      welcomeModal: {
        titleFirst: 'Willkommen im',
        titleSecond: 'letsact',
        titleThird: 'Universum!',
        letsGoButton: `Los geht's!`,
      },
    },
    checkInbox: {
      titleOne: 'Du hast Post! Bitte',
      titleTwo: 'überprüfe deine Inbox',
      description: `Bestätige deine E-Mail, indem du auf den Link klickst, den wir an {{email}} geschickt haben.`,
      continue: 'Alles klar',
      resendButton: 'E-Mail nochmal senden',
      reSending: 'E-Mail unterwegs...',
      linkSent: 'E-Mail gesendet',
      linkSendError: 'E-Mail konnte nicht gesendet werden',
    },
    organizationDetails: {
      titleOne: 'Verrate uns etwas',
      titleTwo: 'mehr über deine',
      titleThree: 'Organisation',
      descriptionPlaceHolder: 'Beschreibe deine Organisation',
      nextButton: 'Weiter',
      setupLaterButton: 'Später',
      successUpdateMessage: 'Erfolgreich aktualisiert',
      unsuccessfulUpdateMessage:
        'Es gab ein Problem bei der Aktualisierung der Daten, bitte versuchen Sie es erneut!',
    },
    organizationContact: {
      titleOne: 'Wer ist der erste Kontakt',
      titleTwo: 'für Freiwillige?',
      description:
        'Diese Daten werden potenziellen Freiwilligen angezeigt, damit sie sich mit dir in Verbindung setzen können.',
      firstName: 'Vorname',
      lastName: 'Nachname',
      phoneNumber: 'Ihre Telefonnummer hinzufügen',
      successUpdateMessage: 'Erfolgreich aktualisiert',
      unsuccessfulUpdateMessage:
        'Es gab ein Problem bei der Aktualisierung der Daten, bitte versuchen Sie es erneut!',
      nextButton: 'Registrierung absenden',
      setupLaterButton: 'Später',
      name: 'Name',
      validationErrorMessages: {
        invalidEmail: 'Ungültige E-Mail Adresse',
        invalidFirstName:
          'Die Anzahl der Zeichen des Vornamens sollte zwischen 3 und 50 liegen',
        invalidLastName: 'Der Nachname sollte zwischen 3 und 50 Zeichen haben',
        invalidPhoneNumber:
          'Bitte gib eine gültige Rufnummer ohne führende 0 oder Ländervorwahl ein.',
        invalidPhoneNumberLength: 'Bitte gib eine gültige Telefonnummer ein.',
        invalidCountryCodeLength:
          'Der Ländercode sollte mindestens 2 Zeichen umfassen',
        invalidPlus: 'Die Landesvorwahl sollte + enthalten',
        emptyCountryCode: 'Gib bitte den Ländercode ein',
      },
    },
    resetPassword: {
      title: 'Passwort vergessen?',
      description:
        'Bitte gib deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen.',
      infoMessage:
        'Wir senden dir einen Link zu, um dein Passwort zurückzusetzen',
      resetPassword: 'Passwort zurücksetzen',
      loginButton: 'Einloggen',
      placeHolder: 'Deine E-Mail',
      errorMessage: {
        invalidEmail: 'Ungültige E-Mail Adresse',
        verifyEmail: 'E-Mail nicht verifiziert. Bitte verifiziere deine E-mail',
      },
    },
    resetPasswordCheckInbox: {
      title: 'Du hast Post! Bitte \nüberprüfe deine Inbox',
      description:
        'Klicke auf den Link, den wir an {{email}} geschickt haben und setze so dein Passwort zurück',
      login: 'Einloggen',
    },
    home: {
      verification: {
        title: 'Überprüfung in Arbeit!',
        description:
          'Um eine hohe Qualität der Inhalte auf unserer Plattform zu gewährleisten, überprüfen wir gerade euren Non-Profit-Status. Wenn die Überprüfung abgeschlossen ist, schicken wir dir eine E-Mail. Dies kann bis zu 48 Stunden dauern.',
      },
      applications: {
        openApplications: 'Offene Bewerbungen',
        manageApplicationsButton: 'Alle verwalten',
        appliedOn: 'Beworben am',
        appliedFor: 'Beworben für',
        applicationsEmptyState: 'Keine Bewerbungen zu zeigen!',
        new: 'Neu',
        emptyState: {
          title: 'Keine offenen Bewerbungen',
          description:
            'Aber keine Sorge, in unserer App warten bereits unzählige Freiwillige.',
        },
        acceptActionModal: {
          title: '{{name}} ist dabei?',
          description:
            'Denke daran, du kannst diese Aktion nicht rückgängig machen. Also stelle sicher, dass diese Person wirklich bei euch mitmacht.',
          reviewButton: 'Sind noch nicht so weit',
          acceptButton: 'Ja, wir legen los!',
        },
        rejectActionModal: {
          title: 'Mit {{name}} hat es nicht geklappt?',
          description:
            'Denke daran, du kannst diese Aktion nicht rückgängig machen. Also stelle sicher, dass diese Person wirklich nicht bei euch mitmacht.',
          rejectButton: 'Hat leider nicht geklappt',
          reviewButton: 'Sind noch nicht so weit',
        },
        acceptModalNotify: {
          title: 'Glückwunsch!',
          description:
            'Wir sind uns sicher, dass {{name}} gemeinsam \nmit euch viel bewegen wird! Viel Glück auf der \ngemeinsamen Reise',
          approveButton: 'Mega!',
        },
      },
      offers: {
        title: 'Deine Angebote',
        createButton: 'Angebot erstellen',
        flexible: 'Flexibel',
        perMonth: 'pro Monat',
        perWeek: 'pro Woche',
        remote: 'Remote',
        hours: {
          one: '1 Stunde',
          other: '{{count}} Stunden',
        },
        weekDays: {
          monday: 'Mo',
          tuesday: 'Di',
          wednesday: 'Mi',
          thursday: 'Do',
          friday: 'Fr',
          saturday: 'Sa',
          sunday: 'So',
        },
        tabs: {
          all: 'Alle',
          active: 'Aktiv',
          inActive: 'Inaktiv',
        },
        emptyState: {
          title: `Sie haben noch keine Angebote.`,
          description: 'Warum nicht mit dem Ersten beginnen?',
          noFilterResult: 'Keine Angebote verfügbar',
        },
        status: {
          filled: 'Gefüllt',
          paused: 'Pausiert',
          expired: 'Abgelaufen',
          published: 'Veröffentlicht',
          deleted: 'Löschen',
          deleteDone: 'Gelöscht',
        },
        details: {
          title: 'Angebot bearbeiten',
          pausedOfferNotice:
            'Dein Angebot wurde vom Admin pausiert. Kontaktiere uns für weitere Informationen unter: ,,organisation@letsact.de"',
          breadcrumb: {
            home: 'Home',
            offer: 'Angebot',
          },
          errorMessages: {
            goal: 'Das Ziel muss mit mindestens 5 Zeichen beschrieben werden',
            title: 'Der Titel muss mindestens 5 Zeichen lang sein',
            impact: 'Die Beschreibung muss mindestens 5 Zeichen lang sein',
            emptyCategorySelected:
              'Die Auswahl genau einer Kategorie ist notwendig',
            emptyLanguagesSelected:
              'Sie sollten mindestens eine Sprache auswählen!',
            emptySkillsSelected:
              'Sie sollten mindestens eine Sprache auswählen!',
            emptyStreet: 'Die Eingabe des Straßennamens ist notwendig',
            emptyCity: 'Die Eingabe des Ortes ist notwendig',
            emptyZipCode: 'Die Eingabe der Postleitzahl ist notwendig',
            emptyWeekDays:
              'Die Auswahl mindestens eines Wochentages ist notwendig',
            wrongMinHours: 'Bitte gültige Stunden eingeben',
            wrongImageSize: 'Die maximale Größe beträgt mehr als 2 MB',
          },
          severMessages: {
            successfullyUpdated: 'Die Aktualisierung war erfolgreich',
            serverError500: 'Server error',
            serverGeneralError:
              'Es ist ein Fehler aufgetreten, bitte wiederholen Sie den Vorgang',
            unsupportedContentType:
              'Der Bildtyp wird nicht unterstützt, bitte wählen Sie ein JPG oder PNG',
          },
          infoMessage: {
            imageSizeInfo:
              'Die maximale Größe sollte 2 MB betragen als JPG oder PNG',
          },
          backButton: 'Zurück',
          saveButton: 'Speichern',
          showMore: 'Mehr anzeigen',
          showLess: 'Weniger anzeigen',
          titleSection: 'Titel',
          impactSection: 'Aufgaben',
          goalSection: 'Ziel',
          imageSection: 'Bilder',
          scheduleSection: 'Zeitplan',
          categorySection: 'Kategorie',
          languageSection: 'Sprache',
          locationSection: 'Standort',
          softSkillSection: 'Soft Skills',
          minHours: 'Min-Stunden',

          criteria: {
            languages: {
              German: 'Deutsch',
              English: 'Englisch',
              Spanish: 'Spanisch',
              French: 'Französisch',
              Arabic: 'Arabisch',
              Russian: 'Russisch',
              Italian: 'Italienisch',
              Turkish: 'Türkisch',
            },
          },
          availability: {
            monthly: 'Monatlich',
            weekly: 'Wöchentlich',
            flexible: 'Flexibel',
            weekdaysTitle: 'An welchen Wochentagen benötigt ihr Hilfe?',
            weekDays: {
              monday: 'Mo',
              tuesday: 'Di',
              wednesday: 'Mi',
              thursday: 'Do',
              friday: 'Fr',
              saturday: 'Sa',
              sunday: 'So',
            },
          },
          location: {
            street: 'Straße',
            city: 'Ort',
            postCode: 'Postleitzahl',
          },
        },
      },
      sidebar: {
        greetings: 'Hi, Ich bin',
        offerTitle: 'Ich würde gerne hier helfen:',
        dropdown: {
          open: 'Offen',
          contacted: 'Kontaktiert',
          inContact: 'Im Austausch',
          takingPart: 'Ist dabei!',
          didntWork: 'Hat nicht geklappt',
        },
      },
    },
    offerCreation: {
      title: 'Angebot erstellen',
      step: 'Schritt',
      skip: 'Überspringen',
      back: 'Zurück',
      breadcrumb: {
        home: 'Home',
        offerCreation: 'Erstellen',
      },
      successOfCreation: 'Ihr Angebot wurde erfolgreich erstellt',
      errorOfCreation: 'Ihr Angebot wird aufgrund eines Fehlers nicht erstellt',
      name: {
        titleOne: 'Der erste',
        titleTwo: 'Eindruck zählt!',
        nextButton: 'Nächster Schritt',
        step: 'Schritt',
        title: 'Titel',
        goal: 'Ziel',
        tasks: 'Aufgaben',
        categoryTitle: 'Wie können wir Dir helfen?',
        categoryDescription:
          'Je mehr Informationen du bereit stellst, desto einfacher wird es, die richtigen Freiwilligen zu finden.',
        categoryAlert: 'Du kannst eine Hauptkategorie wählen.',
        detailsTitle: 'Damit der erste Eindruck zählt!',
        description:
          'Füge ein schönes Bild und einen kurzen Titel hinzu, der das Interesse der Freiwilligen weckt!',
      },
      schedule: {
        title: 'Wie oft sollten Freiwillige zur Verfügung stehen',
        nextButton: 'Nächster Schritt',
        step: 'Schritt',
        minHours: 'Min-Stunden',
        wrongMinHours: 'Bitte gültige Stunden eingeben',
        availability: {
          monthly: 'Monatlich',
          weekly: 'Wöchentlich',
          flexible: 'Flexibel',
          weekdaysTitle: 'An welchen Wochentagen benötigt ihr Hilfe?',
          weekDays: {
            monday: 'Mo',
            tuesday: 'Di',
            wednesday: 'Mi',
            thursday: 'Do',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'So',
          },
        },
      },
      location: {
        title: 'Wo findet dein Angebot statt?',
        nextButton: 'Nächster Schritt',
        step: 'Schritt',
        street: 'Straße',
        city: 'Ort',
        postCode: 'Postleitzahl',
        emptyStreet: 'Die Eingabe des Straßennamens ist notwendig',
        emptyCity: 'Die Eingabe des Ortes ist notwendig',
        emptyZipCode: 'Die Eingabe der Postleitzahl ist notwendig',
      },
      language: {
        nextButton: 'Nächster Schritt',
        createButton: 'Erstellen',
        step: 'Schritt',
        title: 'Welche Sprachen werden benötigt?',
        titleSkills: 'Welche Eigenschaften sind dir wichtig?',
        description:
          'Jede Non-Profit-Organisation hat spezifische Anforderungen, und wir wissen das zu schätzen. Um die beste Lösung für Sie zu finden, erzählen Sie uns mehr darüber, wie ein Freiwilliger aussehen sollte.',
        languages: {
          German: 'Deutsch',
          English: 'Englisch',
          Spanish: 'Spanisch',
          French: 'Französisch',
          Arabic: 'Arabisch',
          Russian: 'Russisch',
          Italian: 'Italienisch',
          Turkish: 'Türkisch',
          Others: 'Andere',
        },
      },
      preview: {
        idealVolunteer: 'Du bringst mit:',
        ourGoal: 'Unser Ziel',
        yourImpact: 'Dein Beitrag',
        when: 'Wann?',
        howOften: 'Wie oft?',
        howLong: 'Wie lange?',
        where: 'Wo?',
        remote: 'Remote',
        onsite: 'Vor-Ort',
        flexible: 'Flexibel',
        perMonth: 'pro Monat',
        perWeek: 'pro Woche',
        hours: {
          one: '1 Stunde',
          other: '{{count}} Stunden',
        },
      },
    },
    profile: {
      title: 'Dein Profil',
      successUpdateMessage: 'Erfolgreich aktualisiert',
      unsuccessfulUpdateMessage:
        'Es gab ein Problem bei der Aktualisierung der Daten, bitte versuchen Sie es erneut!',
      back: 'Zurück',
      breadcrumb: {
        home: 'Home',
        profile: 'Profil',
      },
      uploadImage: 'Ihr Bild hochladen',
      maxImageSize: 'max 2.MB',
      errorMessages: {
        wrongImageSize: 'Die maximale Größe beträgt mehr als 2 MB',
      },
      severMessages: {
        successfullyUpdated: 'Die Aktualisierung war erfolgreich',
        serverError500: 'Server error',
        serverGeneralError:
          'Es ist ein Fehler aufgetreten, bitte wiederholen Sie den Vorgang',
        unsupportedContentType:
          'Der Bildtyp wird nicht unterstützt, bitte wählen Sie ein JPG oder PNG',
      },
      infoMessage: {
        imageSizeInfo:
          'Die maximale Größe sollte 2 MB betragen als JPG oder PNG',
      },
    },
    applications: {
      title: 'Alle Bewerbungen verwalten',
      emptyState: 'Keine Freiwilligen',
      back: 'Zurück',
      breadcrumb: {
        home: 'Home',
        applications: 'Bewerbungen',
      },
    },
  },
  templates: {
    header: {
      logout: 'Abmelden',
      english: 'Englisch',
      german: 'Deutsch',
      profile: 'Profil',
      management: 'Management',
      supportHelp: 'Hilfe & Support',
      dataPrivacy: 'Datenschutzerklärung',
    },
    contactInfo: {
      name: 'Name',
      successUpdateMessage: 'Erfolgreich aktualisiert',
      unsuccessfulUpdateMessage:
        'Es gab ein Problem bei der Aktualisierung der Daten, bitte versuchen Sie es erneut!',
      validationErrorMessages: {
        invalidEmail: 'Ungültige E-Mail Adresse',
        invalidCountryCodeLength:
          'Der Ländercode sollte mindestens 2 Zeichen umfassen',
        invalidPlus: 'Die Landesvorwahl sollte + enthalten',
        emptyCountryCode: 'Gib bitte den Ländercode ein',
        invalidFirstName:
          'Die Anzahl der Zeichen des Vornamens sollte zwischen 3 und 50 liegen',
        invalidLastName: 'Der Nachname sollte zwischen 3 und 50 Zeichen haben',
        invalidPhoneNumber:
          'Bitte gib eine gültige Rufnummer ohne führende 0 oder Ländervorwahl ein.',
        invalidPhoneNumberLength: 'Bitte gib eine gültige Telefonnummer ein.',
      },
    },
  },
};

export default de;
export type Translations = typeof de;
