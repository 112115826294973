import * as yup from 'yup';
import { EnumOfferLocationType } from 'constants/enums';

export const OfferDetailsLocationSchema = yup.object().shape({
  street: yup
    .string()
    .min(20, () => '')
    .required(),
  city: yup
    .string()
    .min(4, () => '')
    .required(),
  zipCode: yup
    .string()
    .min(5, () => '')
    .required(),
});

export const LOCATION_OPTIONS = [
  {
    id: 1,
    titleEN: 'Remote',
    titleDE: 'Remote',
    name: EnumOfferLocationType.REMOTE,
  },
  {
    id: 2,
    titleEN: 'On-site',
    titleDE: 'Vor Ort',
    name: EnumOfferLocationType.ONSITE,
  },
  {
    id: 3,
    titleEN: 'Private',
    titleDE: 'Privat',
    name: EnumOfferLocationType.PRIVATE,
  },
];
