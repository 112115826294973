import {
  EnumManagementOfferSearchType,
  EnumManagementScreenType,
} from 'constants/enums';

export const ManagementMenuItems = [
  {
    id: 1,
    name: 'Organizations',
    type: EnumManagementScreenType.ORGANIZATIONS,
  },
  {
    id: 2,
    name: 'Offers',
    type: EnumManagementScreenType.OFFERS,
  },
  {
    id: 3,
    name: 'Mobile App update',
    type: EnumManagementScreenType.APP_UPDATE,
  },
  {
    id: 4,
    name: 'Latest Offers Notification',
    type: EnumManagementScreenType.LAST_OFFERS_NOTIFICATIONS,
  },
  {
    id: 5,
    name: 'Event-News Notification',
    type: EnumManagementScreenType.EVENT_NOTIFICATIONS,
  },
];

export const OfferSearchRadioButton = [
  {
    id: 1,
    name: 'Offer Name',
    type: EnumManagementOfferSearchType.OFFER_NAME,
  },
  {
    id: 2,
    name: 'Organization Name',
    type: EnumManagementOfferSearchType.ORGANIZATION_NAME,
  },
];
