import React from 'react';
import { LActImage } from 'components/index';
import VISUAL_AUTH from 'assets/images/auth-visual.png';
import { CProgress, CProgressBar } from '@coreui/react';

interface AuthScreenProps {
  style?: string;
  progressValue?: number;
  showProgress?: boolean;
  children: React.ReactNode;
}

const AuthScreenContainer = (props: AuthScreenProps): React.ReactElement => {
  const { children, style, progressValue, showProgress } = props;

  return (
    <div className="flex h-screen scroll-smooth">
      <div className={`${style} flex flex-1 mobile:w-screen`}>{children}</div>

      <div className="desktop:flex desktop:flex-1 desktop:flex-col desktop:justify-center desktop:items-center  mobile:flex-none mobile:hidden">
        {showProgress && (
          <div className={'desktop:w-1/3 px-3 mb-20 absolute top-10'}>
            <CProgress height={4}>
              <CProgressBar animated value={progressValue}></CProgressBar>
            </CProgress>
          </div>
        )}
        <LActImage source={VISUAL_AUTH} />
      </div>
    </div>
  );
};

export default AuthScreenContainer;
