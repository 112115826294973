import { create } from 'zustand';

const useApplicationRejectStorage = create(set => ({
  scope: '',
  rejectDialogVisible: false,
  showRejectDialog: (scope: string) =>
    set({ rejectDialogVisible: true, scope }),
  purgeRejectDialog: () => set({ rejectDialogVisible: false, scope: '' }),
}));

export default useApplicationRejectStorage;
