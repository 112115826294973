import React, { useEffect, useState } from 'react';
import Lodash from 'lodash';
import { Criteria } from 'api/schemas/criteria';
import { LANGUAGES_OPTIONS } from './utils';
import { useRefreshToken, useSwitchTranslation } from 'utils';
import { TxKeyPath, translate } from 'i18n';
import { LActButton, LActRow, LActText } from 'components/index';
import {
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
  EnumCriteriaType,
} from 'constants/enums';
import { usePatchOfferDetails } from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { Offer } from 'api/schemas/offer';

interface LanguageComponentProps {
  offerID: number;
  accessToken: string;
  languages: Array<Criteria>;
  onItemUpdated: (item: Offer) => void;
}

const LanguageComponent = (
  props: LanguageComponentProps,
): React.ReactElement => {
  const i18n = 'screens.home.offers.details.criteria.languages';
  const i18nParent = 'screens.home.offers.details';
  const { offerID, languages, accessToken, onItemUpdated } = props;
  useSwitchTranslation();
  const [criteria, setCriteria] = useState<Array<Criteria>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  const PATCH_QUERY = usePatchOfferDetails({
    ...parseBearerToken(accessToken ? decryptData(accessToken) : ''),
  });

  useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
        });
      }, 3000);
    }
  }, [message.type]);

  useEffect(() => {
    if (languages) {
      const selectedLanguages = languages?.map(item => {
        return item?.property.value;
      });
      setSelectedItems(selectedLanguages);
      setCriteria(languages);
    }
  }, [languages]);

  const onItemClicked = (language: string, id: number) => {
    if (selectedItems?.includes(language)) {
      const updatedLanguage = selectedItems?.filter((item: string) => {
        return item !== language;
      });
      setSelectedItems(updatedLanguage);

      const updatedCriteria = criteria.filter(item => {
        return item.property.value !== language;
      });
      setCriteria(updatedCriteria);
    } else {
      setSelectedItems(selectedItems.concat(language));
      setCriteria(
        criteria.concat({
          id,
          property: {
            category: EnumCriteriaType.LANGUAGE,
            value: language,
          },
        }),
      );
    }
  };

  const onSaveClicked = () => {
    const PATCH_DATA: any = [];
    criteria.map(item => {
      return PATCH_DATA.push({
        category: EnumCriteriaType.LANGUAGE,
        value: item?.property?.value,
      });
    });

    PATCH_QUERY?.mutateAsync({
      offerId: offerID?.toString(),
      data: {
        requiredLanguages: PATCH_DATA,
      },
    })
      .then((data: any) => {
        onItemUpdated(data?.item);
        setMessage({
          message: translate(`${i18nParent}.severMessages.successfullyUpdated`),
          type: 'success',
        });
      })
      .catch(error => {
        if (error?.message?.includes('500')) {
          setMessage({
            message: translate(`${i18nParent}.severMessages.serverError500`),
            type: 'error',
          });
        }
        setMessage({
          message: translate(`${i18nParent}.severMessages.serverGeneralError`),
          type: 'error',
        });
      });
  };
  useRefreshToken({
    error: PATCH_QUERY?.error,
    refetch: onSaveClicked,
    hasError: PATCH_QUERY?.isError,
  });

  const selectedLanguages =
    languages &&
    languages?.map(item => {
      return item?.property.value;
    });

  const disabledSaveButton =
    selectedItems.length > 0 &&
    Lodash.isEqual(selectedLanguages, selectedItems);

  return (
    <>
      <LActRow className={'items-center'} spaceBetween>
        <div>
          <LActText
            className="font-semibold"
            text={translate(`${i18nParent}.languageSection`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
          />
        </div>
        <div>
          <LActButton
            testID={'login-button'}
            onClick={onSaveClicked}
            type={EnumButtonType.TEXT}
            useLoading={PATCH_QUERY?.isLoading}
            text={translate(`${i18nParent}.saveButton`)}
            actionType={EnumButtonActionType.BUTTON}
            containerStyle={'items-end py-0 bg-transparent border-0'}
            textStyle={`text-tint ${disabledSaveButton && 'opacity-50'}`}
          />
        </div>
      </LActRow>

      <div className="flex flex-wrap justify-center justify-evenly items-center desktop:px-20">
        {LANGUAGES_OPTIONS?.map((lang, index) => {
          const BG_SELECTED = selectedItems?.includes(lang?.value)
            ? 'bg-tint text-white'
            : 'bg-white text-textSecondary';

          const TEXT_COLOR_SELECTED = selectedItems?.includes(lang?.value)
            ? 'text-white font-semibold'
            : 'text-tint';

          const title =
            lang?.value[0]?.toUpperCase() +
            lang?.value?.slice(1)?.toLowerCase();

          return (
            <span
              key={`language-badge-${index}`}
              onClick={() => onItemClicked(lang?.value, lang.id)}
              className={`cursor-pointer px-3 py-2 rounded-full mt-3 ${BG_SELECTED} ${TEXT_COLOR_SELECTED}`}
            >
              {translate(`${i18n}.${title}` as TxKeyPath)}
            </span>
          );
        })}
      </div>

      {message?.message && (
        <LActText
          text={message?.message}
          className={'font-semibold mt-6'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          textColor={message.type === 'error' ? 'text-error' : 'text-green'}
        />
      )}
    </>
  );
};

export default LanguageComponent;
