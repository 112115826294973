import React, { useState } from 'react';
import { HeaderTemplate } from 'components/index';

interface LActScreenProps {
  className?: string;
  children: React.ReactNode;
  onEntireScreenClicked?: () => void;
}

const LActScreen = (props: LActScreenProps): React.ReactElement => {
  const { children, className, onEntireScreenClicked } = props;
  const [toggleDropdowns, setToggleDropdowns] = useState<boolean>(false);

  const onToggleDropdowns = () => {
    setToggleDropdowns(!toggleDropdowns);
  };

  return (
    <div
      onClick={onEntireScreenClicked}
      className={`desktop:mx-40 mobile:mx-6 mb-6  ${className}`}
    >
      <HeaderTemplate toggleDropdowns={toggleDropdowns} />
      <div onClick={onToggleDropdowns}>{children}</div>
    </div>
  );
};

export default LActScreen;
