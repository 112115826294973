import React from 'react';
import { PersonCircle, ReorderThreeOutline } from 'react-ionicons';
import { colors } from 'constants/colors';
import { useOrganizationStorage } from 'utils';
import { IOrganizationInfoTypes } from 'constants/types';

interface HeaderSettingsTemplateProps {
  onClick: () => void;
}

const HeaderSettingsTemplate = (
  props: HeaderSettingsTemplateProps,
): React.ReactElement => {
  const { onClick } = props;

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  return (
    <span
      onClick={onClick}
      data-testid={'header-menu'}
      className="bg-neutral600 flex justify-between rounded-full items-center cursor-pointer"
    >
      <div className={'pl-2.5 pr-1 py-1.5'}>
        <ReorderThreeOutline
          width="24px"
          height="24px"
          color={colors.palette.neutral700}
        />
      </div>

      <div className={'-mr-1'}>
        {organizationStorage?.organization?.imageUri ? (
          <img
            src={organizationStorage?.organization?.imageUri}
            style={{ width: 40, height: 40, borderRadius: 100 }}
            alt={
              organizationStorage?.organization?.organizationDetails?.firstname
            }
          />
        ) : (
          <PersonCircle width={'42px'} height={'42px'} color={'#1F4043'} />
        )}
      </div>
    </span>
  );
};

export default HeaderSettingsTemplate;
