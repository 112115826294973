import React from 'react';
import { COffcanvas, COffcanvasBody, COffcanvasHeader } from '@coreui/react';
import { CloseCircleSharp } from 'react-ionicons';
import { useCheckPlatform } from 'utils';
import { colors } from 'constants/colors';
import LActRow from '../LActRow';

interface LActSidebarProps {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const LActSidebar = (props: LActSidebarProps): React.ReactElement => {
  const { visible, onClose, children } = props;
  const PLATFORM = useCheckPlatform();

  return (
    <COffcanvas
      onHide={onClose}
      visible={visible}
      placement={PLATFORM?.isMobileView ? 'bottom' : 'end'}
      className="desktop:rounded-l-3xl desktop:rounded-tr-none mobile:rounded-t-2xl desktop:w-1/3 mobile:h-screen"
    >
      <COffcanvasHeader className="-mb-6">
        <LActRow spaceBetween>
          <div></div>
          <button onClick={onClose}>
            <CloseCircleSharp
              width="24px"
              height="24px"
              color={colors.palette.grey700}
            />
          </button>
        </LActRow>
      </COffcanvasHeader>
      <COffcanvasBody>{children}</COffcanvasBody>
    </COffcanvas>
  );
};

export default LActSidebar;
