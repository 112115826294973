import { EnumOffersFilterType } from 'constants/enums';
import { TxKeyPath } from 'i18n';

export const TABS_ITEMS = [
  {
    id: 1,
    key: EnumOffersFilterType.ALL,
    testID: EnumOffersFilterType.ALL,
    name: 'screens.home.offers.tabs.all' as TxKeyPath,
  },
  {
    id: 2,
    key: EnumOffersFilterType.PUBLISHED,
    testID: EnumOffersFilterType.PUBLISHED,
    name: 'screens.home.offers.tabs.active' as TxKeyPath,
  },
  {
    id: 3,
    key: EnumOffersFilterType.EXPIRED,
    testID: EnumOffersFilterType.EXPIRED,
    name: 'screens.home.offers.tabs.inActive' as TxKeyPath,
  },
];
