import { EnumOffersFilterType } from 'constants/enums';
import { TxKeyPath } from 'i18n';

export const PUBLISHED_DROPDOWN_LIST = [
  {
    id: 1,
    bgColor: 'bg-pink',
    titleColor: 'text-error',
    status: EnumOffersFilterType.EXPIRED,
    title: `screens.home.offers.status.expired` as TxKeyPath,
  },
  {
    id: 2,
    bgColor: 'bg-skyBlue',
    titleColor: 'text-textSecondary',
    status: EnumOffersFilterType.FILLED,
    title: `screens.home.offers.status.filled` as TxKeyPath,
  },
  {
    id: 3,
    bgColor: 'bg-neutral600',
    titleColor: 'text-textSecondary',
    status: EnumOffersFilterType.PAUSED,
    title: `screens.home.offers.status.paused` as TxKeyPath,
  },
  {
    id: 4,
    bgColor: 'bg-orange',
    titleColor: 'text-white',
    status: EnumOffersFilterType.DELETED,
    title: `screens.home.offers.status.deleted` as TxKeyPath,
  },
];

export const EXPIRED_DROPDOWN_LIST = [
  {
    id: 1,
    bgColor: 'bg-greenLight',
    titleColor: 'text-green',
    status: EnumOffersFilterType.PUBLISHED,
    title: `screens.home.offers.status.published` as TxKeyPath,
  },
  {
    id: 2,
    bgColor: 'bg-orange',
    titleColor: 'text-white',
    status: EnumOffersFilterType.DELETED,
    title: `screens.home.offers.status.deleted` as TxKeyPath,
  },
];

export const FILLED_DROPDOWN_LIST = [
  {
    id: 1,
    bgColor: 'bg-greenLight',
    titleColor: 'text-green',
    status: EnumOffersFilterType.PUBLISHED,
    title: `screens.home.offers.status.published` as TxKeyPath,
  },
  {
    id: 2,
    bgColor: 'bg-orange',
    titleColor: 'text-white',
    status: EnumOffersFilterType.DELETED,
    title: `screens.home.offers.status.deleted` as TxKeyPath,
  },
];

export const PAUSED_DROPDOWN_LIST = [
  {
    id: 1,
    bgColor: 'bg-greenLight',
    titleColor: 'text-green',
    status: EnumOffersFilterType.PUBLISHED,
    title: `screens.home.offers.status.published` as TxKeyPath,
  },
  {
    id: 2,
    bgColor: 'bg-pink',
    titleColor: 'text-error',
    status: EnumOffersFilterType.EXPIRED,
    title: `screens.home.offers.status.expired` as TxKeyPath,
  },
  {
    id: 3,
    bgColor: 'bg-skyBlue',
    titleColor: 'text-textSecondary',
    status: EnumOffersFilterType.FILLED,
    title: `screens.home.offers.status.filled` as TxKeyPath,
  },
  {
    id: 4,
    bgColor: 'bg-orange',
    titleColor: 'text-white',
    status: EnumOffersFilterType.DELETED,
    title: `screens.home.offers.status.deleted` as TxKeyPath,
  },
];

export const statusDropdownHandler = (offerStatus: string | undefined) => {
  let DROPDOWN_STATUS = '';
  let DROPDOWN_TEXT_COLOR = '';
  let DROPDOWN_BACKGROUND_COLOR = '';
  let DROPDOWN_CHILD_ITEMS: Array<any> = [];

  switch (offerStatus) {
    case EnumOffersFilterType.PUBLISHED:
      DROPDOWN_TEXT_COLOR = 'text-green';
      DROPDOWN_BACKGROUND_COLOR = 'bg-greenLight';
      DROPDOWN_CHILD_ITEMS = PUBLISHED_DROPDOWN_LIST;
      DROPDOWN_STATUS = `screens.home.offers.status.published` as TxKeyPath;
      break;

    case EnumOffersFilterType.EXPIRED:
      DROPDOWN_TEXT_COLOR = 'text-error';
      DROPDOWN_BACKGROUND_COLOR = 'bg-pink';
      DROPDOWN_CHILD_ITEMS = EXPIRED_DROPDOWN_LIST;
      DROPDOWN_STATUS = `screens.home.offers.status.expired` as TxKeyPath;
      break;

    case EnumOffersFilterType.FILLED:
      DROPDOWN_BACKGROUND_COLOR = 'bg-skyBlue';
      DROPDOWN_TEXT_COLOR = 'text-textSecondary';
      DROPDOWN_CHILD_ITEMS = FILLED_DROPDOWN_LIST;
      DROPDOWN_STATUS = `screens.home.offers.status.filled` as TxKeyPath;
      break;

    case EnumOffersFilterType.PAUSED:
      DROPDOWN_TEXT_COLOR = 'text-textSecondary';
      DROPDOWN_BACKGROUND_COLOR = 'bg-neutral600';
      DROPDOWN_CHILD_ITEMS = PAUSED_DROPDOWN_LIST;
      DROPDOWN_STATUS = `screens.home.offers.status.paused` as TxKeyPath;
      break;

    case EnumOffersFilterType.DELETED:
      DROPDOWN_TEXT_COLOR = 'text-white';
      DROPDOWN_BACKGROUND_COLOR = 'bg-orange';
      DROPDOWN_CHILD_ITEMS = [];
      DROPDOWN_STATUS = `screens.home.offers.status.deleteDone` as TxKeyPath;
      break;

    default:
      break;
  }

  return {
    DROPDOWN_STATUS,
    DROPDOWN_TEXT_COLOR,
    DROPDOWN_CHILD_ITEMS,
    DROPDOWN_BACKGROUND_COLOR,
  };
};
