export const GLOBAL = '/';
export const LOGIN_SCREEN = '/login';
export const REGISTER_SCREEN = '/register';
export const VERIFY_EMAIL_SCREEN = '/register/verify';
export const ORG_DETAILS_SCREEN = '/register/organization-details';
export const ORG_CONTACT_SCREEN = '/register/organization-contact';
export const RESET_PASSWORD_SCREEN = '/reset-password';
export const HOME_SCREEN = '/home';
export const PROFILE_SCREEN = '/profile';
export const APPLICATIONS_BOARD = '/applications';
export const OFFERS_CREATION_SCREEN = '/offers/create';
export const OFFERS_DETAILS_SCREEN = '/offers/details';
export const MANAGEMENT_SCREEN = '/letsact/management';
export const MANAGEMENT_DETAILS_SCREEN = '/letsact/management/details';
export const OFFER_DETAILS_DEEP_LINK_SCREEN = '/app/offer';
