import { Offer } from 'api/schemas/offer';
import axios from 'axios';
import {
  EnumOfferLocationType,
  EnumSchedulingFrequency,
  EnumSchedulingWeekdays,
} from 'constants/enums';

export const CATEGORIES_OPTIONS = [
  {
    categoryKey: 'environment_and_nature',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/environment_and_nature.png',
    id: 1,
    valueDe: 'Umwelt & Naturschutz',
    valueEn: 'Environment & Nature',
  },
  {
    categoryKey: 'animal_welfare',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/animal_welfare.png',
    id: 2,
    valueDe: 'Tierschutz',
    valueEn: 'Animal welfare',
  },
  {
    categoryKey: 'children_and_youth',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/children_and_youth.png',
    id: 3,
    valueDe: 'Kinder & Jugend',
    valueEn: 'Children & Youth',
  },
  {
    categoryKey: 'education_and_language',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/education_and_language.png',
    id: 4,
    valueDe: 'Bildung & Sprache',
    valueEn: 'Education & Language',
  },
  {
    categoryKey: 'refuge_and_migration',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/refuge_and_migration.png',
    id: 5,
    valueDe: 'Flucht & Migration',
    valueEn: 'Refuge & Migration',
  },
  {
    categoryKey: 'lgbtqia',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/lgbtqia.png',
    id: 6,
    valueDe: 'LGBTQIA+',
    valueEn: 'LGBTQIA+',
  },
  {
    categoryKey: 'democracy_and_human_rights',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/democracy_and_human_rights.png',
    id: 7,
    valueDe: 'Demokratie & Menschenrechte',
    valueEn: 'Democracy & Human rights',
  },
  {
    categoryKey: 'elderly_people',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/elderly_people.png',
    id: 8,
    valueDe: 'Leben im Alter',
    valueEn: 'Elderly people',
  },
  {
    categoryKey: 'health',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/health.png',
    id: 9,
    valueDe: 'Gesundheit',
    valueEn: 'Health',
  },
  {
    categoryKey: 'poverty_and_homelessness',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/poverty_and_homelessness.png',
    id: 10,
    valueDe: 'Armut & Obdachlosigkeit',
    valueEn: 'Poverty & Homelessness',
  },
  {
    categoryKey: 'people_with_disabilities',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/people_with_disabilities.png',
    id: 11,
    valueDe: 'Menschen mit Behinderungen',
    valueEn: 'People with disabilities',
  },
  {
    categoryKey: 'equality',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/equality.png',
    id: 12,
    valueDe: 'Soziale Gerechtigkeit & Ungleichheit',
    valueEn: 'Equality',
  },
  {
    categoryKey: 'rescue_and_disaster_protection',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/rescue_and_disaster_protection.png',
    id: 13,
    valueDe: 'Rettung & Katastrophenschutz',
    valueEn: 'Rescue & Disaster protection',
  },
  {
    categoryKey: 'death_and_grief_support',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/death_and_grief_support.png',
    id: 14,
    valueDe: 'Sterbe- & Trauerbegleitung',
    valueEn: 'Death & Grief support',
  },
  {
    categoryKey: 'arts_culture',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/arts_culture.png',
    id: 15,
    valueDe: 'Kunst & Kultur',
    valueEn: 'Arts & Culture',
  },
  {
    categoryKey: 'sports_and_recreation',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/sports_and_recreation.png',
    id: 16,
    valueDe: 'Sport & Freizeit',
    valueEn: 'Sports & Recreation',
  },
  {
    categoryKey: 'civic_engagement',
    defaultImageUri:
      'https://letsact-data-stg.s3.eu-central-1.amazonaws.com/defaults/poverty_and_homelessness.png',
    id: 17,
    valueDe: 'Bürgerschaftliches Engagement',
    valueEn: 'Civic engagement',
  },
];

export const WEEK_DAYS = [
  {
    id: 1,
    key: EnumSchedulingWeekdays.MON,
    name: 'monday',
  },
  {
    id: 2,
    key: EnumSchedulingWeekdays.TUE,
    name: 'tuesday',
  },
  {
    id: 3,
    key: EnumSchedulingWeekdays.WED,
    name: 'wednesday',
  },
  {
    id: 4,
    key: EnumSchedulingWeekdays.THU,
    name: 'thursday',
  },
  {
    id: 5,
    key: EnumSchedulingWeekdays.FRI,
    name: 'friday',
  },
  {
    id: 6,
    key: EnumSchedulingWeekdays.SAT,
    name: 'saturday',
  },
  {
    id: 7,
    key: EnumSchedulingWeekdays.SUN,
    name: 'sunday',
  },
];

export const AVAILABILITY_OPTIONS = [
  {
    id: 1,
    weekdays: WEEK_DAYS,
    key: EnumSchedulingFrequency.WEEKLY,
    frequency: 'weekly',
  },
  {
    id: 2,
    weekdays: WEEK_DAYS,
    key: EnumSchedulingFrequency.MONTHLY,
    frequency: 'monthly',
  },
  {
    id: 3,
    weekdays: undefined,
    key: EnumSchedulingFrequency.FLEXIBLE,
    frequency: 'flexible',
  },
];

export const LOCATION_OPTIONS = [
  {
    id: 1,
    titleEN: 'Remote',
    titleDE: 'Remote',
    name: EnumOfferLocationType.REMOTE,
  },
  {
    id: 2,
    titleEN: 'On-site',
    titleDE: 'Vor Ort',
    name: EnumOfferLocationType.ONSITE,
  },
  {
    id: 3,
    titleEN: 'Private',
    titleDE: 'Privat',
    name: EnumOfferLocationType.PRIVATE,
  },
];

export const LANGUAGES_OPTIONS = [
  {
    id: 1,
    value: 'german',
    title: 'German',
  },
  {
    id: 2,
    value: 'english',
    title: 'English',
  },
  {
    id: 3,
    value: 'french',
    title: 'French',
  },
  {
    id: 4,
    value: 'spanish',
    title: 'Spanish',
  },
  {
    id: 5,
    value: 'arabic',
    title: 'Arabic',
  },
  {
    id: 6,
    value: 'russian',
    title: 'Russian',
  },
  {
    id: 7,
    value: 'italian',
    title: 'Italian',
  },
  {
    id: 8,
    value: 'turkish',
    title: 'Turkish',
  },
  {
    id: 9,
    value: 'others',
    title: 'Others',
  },
];

export const SKILLS_OPTIONS = [
  {
    id: 1,
    titleEN: 'Reliable',
    titleDE: 'Zuverlässig',
    key: 'reliable',
  },
  {
    id: 2,
    titleEN: 'Empathic',
    titleDE: 'Empathisch',
    key: 'empathic',
  },
  {
    id: 3,
    titleEN: 'Resilient',
    titleDE: 'Belastbar',
    key: 'resilient',
  },
  {
    id: 4,
    titleEN: 'Organizational skills',
    titleDE: 'Organisationstalent',
    key: 'organizational_skills',
  },
  {
    id: 5,
    titleEN: 'Hands-On',
    titleDE: 'Hands-On/ Kann zupacken',
    key: 'hands_on',
  },
  {
    id: 6,
    titleEN: 'Creative',
    titleDE: 'Kreativ',
    key: 'creative',
  },
  {
    id: 7,
    titleEN: 'Responsible',
    titleDE: 'Verantwortungsbewusst',
    key: 'responsible',
  },
  {
    id: 8,
    titleEN: 'Independent',
    titleDE: 'Selbstständig',
    key: 'independent',
  },
  {
    id: 9,
    titleEN: 'Patient',
    titleDE: 'Geduldig',
    key: 'patient',
  },
  {
    id: 10,
    titleEN: 'Team Player',
    titleDE: 'Teamfähig',
    key: 'team_player',
  },
  {
    id: 11,
    titleEN: 'Communicative',
    titleDE: 'Kommunikativ',
    key: 'communicative',
  },
  {
    id: 12,
    titleEN: 'Sensitive',
    titleDE: 'Einfühlsam',
    key: 'sensitive',
  },
  {
    id: 13,
    titleEN: 'Unprejudiced',
    titleDE: 'Vorurteilsfrei',
    key: 'unprejudiced',
  },
];

export const LetsAPICooperation = (body: Offer) => {
  let data = JSON.stringify({
    ...body,
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_LETS_API_COOPERATION,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  axios
    .request(config)
    .then(response => {
      // console.log('Success', response.data);
    })
    .catch(error => {
      // console.log('Error', error);
    });
};
