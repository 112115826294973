import React, { useCallback } from 'react';
import i18n from 'i18n-js';
import { translate } from 'i18n';
import { EnumTextFormatType } from 'constants/enums';
import { LActText } from 'components/index';
import { ILanguageItemsType, LANGUAGE_ITEMS } from './utils';
import { ILanguageSwitchTypes } from 'constants/types';
import { useLanguageSwitchStorage } from 'utils';

interface HeaderLanguageSwitchItemsTemplateProps {
  ref?: any;
  className?: string;
}

const HeaderLanguageSwitchItemsTemplate = (
  props: HeaderLanguageSwitchItemsTemplateProps,
): React.ReactElement => {
  const { ref, className } = props;

  // @ts-ignore
  const languageSwitchStorage: ILanguageSwitchTypes = useLanguageSwitchStorage(
    (store: ILanguageSwitchTypes | unknown) => store,
  );
  const onLanguageClicked = useCallback((key: string) => {
    languageSwitchStorage.storeLanguageKey(key);
    i18n.locale = key;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LanguageItems = ({
    item,
  }: {
    item: ILanguageItemsType;
  }): React.ReactElement => {
    const SELECTED_LANGUAGE_BG_COLOR: string =
      languageSwitchStorage?.language === item?.key
        ? 'bg-background'
        : 'bg-white';

    const SELECTED_LANGUAGE_TEXT_COLOR: string =
      languageSwitchStorage?.language === item?.key
        ? 'text-textPrimary'
        : 'text-neutral400';

    return (
      <div
        className={`rounded-lg py-2.5 pl-3 pr-3 ${SELECTED_LANGUAGE_BG_COLOR}`}
        onClick={() => onLanguageClicked(item?.key)}
      >
        <LActText
          text={item.flag + translate(item.title)}
          textColor={SELECTED_LANGUAGE_TEXT_COLOR}
          textFormatType={EnumTextFormatType.FONT_SIZE_14}
          className={`font-semibold cursor-pointer`}
        />
      </div>
    );
  };

  return (
    <div
      ref={ref}
      data-testid={'header-language-items'}
      className={`p-4 rounded-3xl shadow-xl bg-white ${className}`}
    >
      {LANGUAGE_ITEMS?.map((data, index) => {
        return <LanguageItems item={data} key={`language-${index}+1`} />;
      })}
    </div>
  );
};

export default HeaderLanguageSwitchItemsTemplate;
