import React, { useEffect, useRef, useState } from 'react';
import {
  CloseSharp,
  CheckmarkSharp,
  AddCircleOutline,
  CloudUploadOutline,
} from 'react-ionicons';
import { LActImage, LActLoader, LActText } from 'components/index';
import { colors } from 'constants/colors';
import { translate } from 'i18n';
import { EnumTextFormatType } from 'constants/enums';
import { usePostAPIV1OrganizationImage } from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { useOrganizationStorage, useRefreshToken } from 'utils';
import { IOrganizationInfoTypes } from 'constants/types';

interface ProfilePictureTemplateProps {
  image?: string;
  accessToken?: string;
  organizationID?: number;
  onUpdateClicked: () => void;
}

const ProfilePictureTemplate = (
  props: ProfilePictureTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.profile';
  const { image, accessToken, organizationID, onUpdateClicked } = props;
  const inputFileRef = useRef<any>(null);
  const maxFileSizeInBytes = 2 * 1024 * 1024;
  const [file, setFile] = useState<any>();
  const [currentImage, setCurrentImage] = useState<any>(image);
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  const PATCH_QUERY = usePostAPIV1OrganizationImage({
    ...parseBearerToken(accessToken ? decryptData(accessToken) : ''),
  });

  useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
        });
      }, 3000);
    }
  }, [message.type]);

  const onPickImageClicked = () => {
    inputFileRef?.current?.click();
  };

  const handleImagePickerChanges = (event: any) => {
    const selectedFile = event?.target?.files[0];

    if (selectedFile.type === 'image/webp') {
      setMessage({
        message: translate(`${i18n}.severMessages.unsupportedContentType`),
        type: 'error',
      });
    } else {
      if (selectedFile?.size <= maxFileSizeInBytes) {
        // The selected file is 2MB or less
        setMessage({
          message: '',
          type: '',
        });
        if (selectedFile) {
          setFile(selectedFile);
          const reader = new FileReader();
          reader.onload = () => {
            setCurrentImage(reader?.result);
          };
          reader.readAsDataURL(selectedFile);
        }
      } else {
        setMessage({
          message: translate(`${i18n}.errorMessages.wrongImageSize`),
          type: 'error',
        });
      }
    }
  };

  const onCancelClicked = () => {
    setCurrentImage(image);
  };

  const onSaveClicked = () => {
    if (!organizationID) return;

    const formData = new FormData();
    formData.append('file', file);

    PATCH_QUERY.mutateAsync({
      organizationId: organizationID?.toString(),
      data: formData,
    })
      .then((data: any) => {
        setMessage({
          message: translate(`${i18n}.severMessages.successfullyUpdated`),
          type: 'success',
        });
        setCurrentImage(data?.item?.imageUri);
        onUpdateClicked();

        if (!organizationStorage?.organization?.id) {
          organizationStorage.storeOrgInfo(data);
        } else {
          const organization = {
            ...organizationStorage.organization,
            imageUri: data?.item?.imageUri,
          };

          organizationStorage.storeOrgInfo(organization);
        }
      })
      .catch(error => {
        if (error?.message?.includes('500')) {
          setMessage({
            message: translate(`${i18n}.severMessages.serverError500`),
            type: 'error',
          });
        } else if (error?.message?.includes('415')) {
          setMessage({
            message: translate(`${i18n}.severMessages.unsupportedContentType`),
            type: 'error',
          });
        }
        setMessage({
          message: translate(`${i18n}.severMessages.serverGeneralError`),
          type: 'error',
        });
      });
  };
  useRefreshToken({
    refetch: onSaveClicked,
    error: PATCH_QUERY?.error,
    hasError: PATCH_QUERY?.isError,
  });

  return (
    <>
      <div className={'mt-6 flex'} data-testid={'org-image'}>
        {currentImage ? (
          <LActImage
            source={currentImage}
            className={'w-36 h-36 rounded-full shadow-sm object-cover'}
          />
        ) : (
          <div
            onClick={onPickImageClicked}
            className={
              'flex flex-col items-center w-36 h-36 justify-center bg-background rounded-full cursor-pointer'
            }
          >
            <CloudUploadOutline
              width="40px"
              height="40px"
              color={colors.palette.grey700}
            />

            <LActText
              className={'mt-1'}
              text={translate(`${i18n}.uploadImage`)}
              textColor={'text-neutral400 text-semibold '}
              textFormatType={EnumTextFormatType.FONT_SIZE_12}
            />

            <LActText
              className={'mt-1'}
              text={translate(`${i18n}.maxImageSize`)}
              textColor={'text-neutral400 text-regular '}
              textFormatType={EnumTextFormatType.FONT_SIZE_12}
            />
          </div>
        )}
        <div className="flex">
          {currentImage === image ? (
            <>
              <span
                className={
                  'cursor-pointer self-end -ml-12 bg-background rounded-full w-6 h-6 flex items-center justify-center shadow-md'
                }
              >
                <AddCircleOutline
                  width="20px"
                  height="20px"
                  color={colors.tint}
                  onClick={onPickImageClicked}
                />
                <input
                  id={'file'}
                  type={'file'}
                  accept="image/*"
                  ref={inputFileRef}
                  style={{ display: 'none' }}
                  onChange={handleImagePickerChanges}
                />
              </span>
            </>
          ) : (
            <div className="flex bg-error">
              <div
                className={
                  'cursor-pointer self-end -ml-8 bg-background rounded-full w-6 h-6 flex items-center justify-center shadow-md -mr-1'
                }
              >
                <CloseSharp
                  width="20px"
                  height="20px"
                  color={colors.warning}
                  onClick={onCancelClicked}
                />
              </div>
              <div
                className={
                  'cursor-pointer self-end -ml-12 bg-background rounded-full w-6 h-6 flex items-center justify-center shadow-md mr-3'
                }
              >
                <>
                  {PATCH_QUERY?.isLoading ? (
                    <LActLoader size={'sm'} color={colors.background} />
                  ) : (
                    <CheckmarkSharp
                      width="20px"
                      height="20px"
                      color={colors.tint}
                      onClick={onSaveClicked}
                    />
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      {message?.message && (
        <LActText
          text={message?.message}
          className={'font-semibold mt-2'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          textColor={message.type === 'error' ? 'text-error' : 'text-green'}
        />
      )}
    </>
  );
};

export default ProfilePictureTemplate;
