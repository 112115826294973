export enum USER_STORE_KEYS {
  USER_INFO = 'user/userInfo',
  ACCESS_TOKEN = 'user/accessToken',
  ORGANIZATION = 'user/organization',
}

export enum APPLICATIONS {
  SEEN_NEW_APPLICATIONS = 'applications/seen',
  SINGLE_ITEM_APPLICATION = 'applications/application',
}

export enum OFFERS {
  REFETCH_OFFERS = 'offers/items',
  SINGLE_OFFER_CREATION = 'offers/offer/creation',
}

export enum HEADER_STORE_KEYS {
  LANGUAGE_SWITCH = 'header/language',
}
