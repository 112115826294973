import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { HEADER_STORE_KEYS } from './keys';

const LOCAL_DB = createJSONStorage(() => localStorage);

const useLanguageSwitchStorage = create(
  persist(
    set => ({
      language: 'de',
      purgeLanguageKey: () => set({ language: undefined }),
      storeLanguageKey: (language: string) => set(() => ({ language })),
    }),
    {
      name: HEADER_STORE_KEYS.LANGUAGE_SWITCH,
      storage: LOCAL_DB,
    },
  ),
);

export default useLanguageSwitchStorage;
