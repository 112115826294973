/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { usePostApiV1AuthRefresh } from 'api/endpoints/queries';
import { AccessToken } from 'api/schemas';
import {
  delay,
  decryptData,
  parseTokenStorageObject,
} from 'utils/global/functions';
import useAccessTokenStorage from 'utils/storage/useAccessTokenStorage';
import { IAccessTokenTypes } from 'constants/types';

interface RefreshTokenProps {
  error: any;
  hasError: boolean;
  refetch: () => void;
}

const useRefreshToken = (props: RefreshTokenProps) => {
  const { error, refetch, hasError } = props;
  const API_QUERY = usePostApiV1AuthRefresh();

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  useEffect(() => {
    if (hasError && error?.message?.includes('401')) {
      API_QUERY.mutateAsync({
        data: {
          refreshToken: tokenStorage?.token?.refresh_token
            ? decryptData(tokenStorage?.token?.refresh_token)
            : '',
        },
      })
        // @ts-ignore
        .then((response: AccessToken) => {
          const storeObject = parseTokenStorageObject(
            response,
            tokenStorage?.token?.refresh_token
              ? decryptData(tokenStorage?.token?.refresh_token)
              : '',
          );
          tokenStorage.storeAccessToken(storeObject);
          delay(1000).then(() => refetch());
        })
        .catch((error: any) => error);
    }
  }, [hasError]);
};

export default useRefreshToken;
