import { useLayoutEffect, useState } from 'react';

const useCheckPlatform = () => {
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  useLayoutEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (640 >= window.innerWidth) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  return {
    isMobileView: isMobileView,
    isDesktopView: !isMobileView,
  };
};

export default useCheckPlatform;
