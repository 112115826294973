import React, { useEffect, useState } from 'react';
import { ChevronUpOutline, ChevronDownOutline } from 'react-ionicons';
import { EnumTextFormatType } from 'constants/enums';
import { colors } from 'constants/colors';
import LActRow from '../LActRow';
import LActText from '../LActText';

interface LActDropdownProps {
  close?: boolean;
  titleText: string;
  disabled?: boolean;
  iconColor?: string;
  titleColor?: string;
  showsIcon?: boolean;
  containerStyle?: string;
  closeStatusDropdown?: boolean;
  childComponent: React.ReactElement;
  onPresentDropdown?: (isOpen: boolean) => void;
}

const LActDropdown = (props: LActDropdownProps): React.ReactElement => {
  const {
    close,
    disabled,
    iconColor,
    titleText,
    showsIcon,
    containerStyle,
    childComponent,
    onPresentDropdown,
    titleColor = 'text-textSecondary',
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  const DISABLED_STYLE = disabled ? 'opacity-50' : 'opacity-100';

  const openDropdown = () => {
    setOpen(!open);
    if (onPresentDropdown) onPresentDropdown(!open);
  };

  useEffect(() => {
    if (close) {
      setOpen(false);
    }
  }, [close]);

  return (
    <div>
      <button
        disabled={disabled}
        onClick={openDropdown}
        className={`pt-2 pr-2.5 pb-2 pl-4 rounded-full ${containerStyle} ${DISABLED_STYLE}`}
      >
        <LActRow spaceBetween className={'items-center'}>
          <LActText
            text={titleText}
            textColor={titleColor}
            className={'pr-2 font-semibold'}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
          />

          {showsIcon && (
            <>
              {!open ? (
                <span data-testid="chevron-down-icon">
                  <ChevronDownOutline
                    height="18px"
                    width="18px"
                    color={iconColor ?? colors.textSecondary}
                  />
                </span>
              ) : (
                <span data-testid="chevron-up-icon">
                  <ChevronUpOutline
                    height="18px"
                    width="18px"
                    color={iconColor ?? colors.textSecondary}
                  />
                </span>
              )}
            </>
          )}
        </LActRow>
      </button>
      <div>
        {open && (
          <div
            className={
              'bg-white shadow-2xl rounded-3xl  w-56 absolute -ml-36 py-1 pl-5 z-10'
            }
          >
            {childComponent}
          </div>
        )}
      </div>
    </div>
  );
};

export default LActDropdown;
