import React, { useCallback, useState } from 'react';
import {
  MailOutline,
  CallOutline,
  ChevronForwardOutline,
} from 'react-ionicons';
import { useFormik } from 'formik';
import { translate } from 'i18n';
import { OrganizationDetails } from 'api/schemas';
import {
  LActRow,
  LActText,
  LActAlert,
  LActEditableTextInput,
} from 'components/index';
import { colors } from 'constants/colors';
import { EnumTextFormatType } from 'constants/enums';
import {
  decryptData,
  parseBearerToken,
  openEmailAppComposer,
} from 'utils/global/functions';

import { usePatchOrganizationContactInfoDetails } from 'api/endpoints/queries';
import {
  IUserInfoTypes,
  IAccessTokenTypes,
  IOrganizationInfoTypes,
} from 'constants/types';
import {
  useUserStorage,
  useRefreshToken,
  useAccessTokenStorage,
  useOrganizationStorage,
} from 'utils';
import { ProfileContactInfoSchema } from './utils';

interface ProfileContactInfoTemplateProps {
  email?: string;
  userName?: string;
  lastName?: string;
  firstName?: string;
  userImage?: string;
  className?: string;
  refetch: () => void;
  countryCode?: string;
  phoneNumber?: string;
  item?: OrganizationDetails;
  shouldLinkContactInfo?: boolean;
  handleResponseMessage?: (message: string, type: string) => void;
}

const ProfileContactInfoTemplate = (
  props: ProfileContactInfoTemplateProps,
): React.ReactElement => {
  const i18n = 'templates.contactInfo';
  const {
    email,
    refetch,
    lastName,
    className,
    firstName,
    countryCode,
    phoneNumber,
    shouldLinkContactInfo = true,
  } = props;
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  const formik = useFormik({
    initialValues: {
      email: email,
      lastname: lastName,
      firstname: firstName,
      phoneNumber: phoneNumber,
      countryCode: countryCode ? countryCode : '+49',
    },
    validationSchema: ProfileContactInfoSchema,
    onSubmit: () => {
      onSaveClicked();
    },
  });
  const PATCH_QUERY = usePatchOrganizationContactInfoDetails({
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });

  const CURSOR_BEHAVIOR = shouldLinkContactInfo
    ? 'cursor-pointer'
    : 'cursor-text';

  const onEmailClicked = (email: string) => {
    if (!shouldLinkContactInfo) return;
    openEmailAppComposer(email);
  };

  const Alert = useCallback(() => {
    return (
      <LActAlert
        dismissable
        duration={4000}
        message={message.message}
        type={message.type === 'success' ? 'success' : 'error'}
      />
    );
  }, [message.message, message.type]);

  const onSaveClicked = () => {
    if (
      formik.values.email &&
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formik.values.email,
      )
    ) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidEmail`),
        type: 'error',
      });
    } else if (formik.values.firstname && formik.values.firstname?.length < 3) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidFirstName`),
        type: 'error',
      });
    } else if (formik.values.lastname && formik.values.lastname?.length < 3) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidLastName`),
        type: 'error',
      });
    } else if (
      formik?.values?.phoneNumber &&
      formik?.values?.phoneNumber?.length > 4 &&
      !/^[1-9][0-9]*$/.test(formik?.values?.phoneNumber)
    ) {
      setMessage({
        message: translate(
          `${i18n}.validationErrorMessages.invalidPhoneNumberLength`,
        ),
        type: 'error',
      });
    } else if (
      formik.values.countryCode &&
      !/^\+/.test(formik.values.countryCode)
    ) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidPlus`),
        type: 'error',
      });
    } else if (
      formik.values.countryCode &&
      formik.values.countryCode.length === 0
    ) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.emptyCountryCode`),
        type: 'error',
      });
    } else if (
      formik.values.countryCode &&
      formik.values.countryCode.length < 2
    ) {
      setMessage({
        message: translate(
          `${i18n}.validationErrorMessages.invalidCountryCodeLength`,
        ),
        type: 'error',
      });
    } else {
      PATCH_QUERY?.mutateAsync({
        organizationId: userStorage?.user?.organizationId?.toString(),
        organizationDetailId:
          organizationStorage?.organization?.organizationDetails?.id.toString(),
        data: {
          email: formik?.values?.email,
          lastname: formik?.values?.lastname,
          firstname: formik?.values?.firstname,
          phoneNumber: formik?.values?.phoneNumber,
          countryCode: formik?.values?.countryCode,
        },
      })
        .then(response => {
          setMessage({
            message: translate(`${i18n}.successUpdateMessage`),
            type: 'success',
          });
          refetch();
        })
        .catch(error => {
          setMessage({
            message: translate(`${i18n}.unsuccessfulUpdateMessage`),
            type: 'error',
          });
        });
    }

    setTimeout(() => {
      setMessage({
        message: '',
        type: '',
      });
    }, 4000);
  };
  useRefreshToken({
    error: PATCH_QUERY?.error,
    refetch: onSaveClicked,
    hasError: PATCH_QUERY?.isError,
  });

  return (
    <div className={`bg-neutral300 p-4 rounded-3xl ${className}`}>
      <LActRow className={'pb-2 items-center'}>
        <div className={'ml-3'} data-testid={'username-text'}>
          <LActText
            testID={'name-text'}
            numberOfLines={'line-clamp-1'}
            text={translate(`${i18n}.name`)}
            textColor={'text-textSecondary'}
            textFormatType={EnumTextFormatType.FONT_SIZE_12}
          />

          <LActRow className="mt-1">
            <div>
              <LActEditableTextInput
                className={'text-medium'}
                testID={'#firstname'}
                textColor={'text-textPrimary'}
                onUpdateClicked={onSaveClicked}
                value={formik?.values?.firstname}
                textNumberOfLines={'line-clamp-20'}
                successfullyUpdated={PATCH_QUERY?.isLoading}
                onChange={formik?.handleChange('firstname')}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
                isLoading={
                  formik?.values?.firstname !== firstName &&
                  PATCH_QUERY?.isLoading
                }
                onCancelEditing={isCancelled =>
                  formik.setFieldValue('firstname', firstName)
                }
              />
            </div>

            <div>
              <LActEditableTextInput
                testID={'#lastname'}
                className={'text-medium ml-1'}
                textColor={'text-textPrimary'}
                onUpdateClicked={onSaveClicked}
                value={formik?.values?.lastname}
                textNumberOfLines={'line-clamp-20'}
                onChange={formik?.handleChange('lastname')}
                successfullyUpdated={PATCH_QUERY?.isLoading}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
                isLoading={
                  formik?.values?.lastname !== lastName &&
                  PATCH_QUERY?.isLoading
                }
                onCancelEditing={isCancelled =>
                  formik.setFieldValue('lastname', lastName)
                }
              />
            </div>
          </LActRow>
        </div>
      </LActRow>

      <LActRow
        spaceBetween
        className={`p-2 ${CURSOR_BEHAVIOR}`}
        onClick={() => onEmailClicked(formik?.values?.email || '')}
      >
        <LActRow className={'-mt-3 items-center'}>
          <MailOutline color={colors.tint} height="24px" width="24px" />

          <div className={'flex flex-col items-center'}>
            <LActText
              testID={'#email'}
              text={formik?.values?.email}
              textColor={'text-textPrimary'}
              className={'ml-3 text-medium mt-0.5'}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
            />
          </div>
        </LActRow>

        <div className={'desktop:flex-none desktop:hidden'}>
          <ChevronForwardOutline
            width={'16px'}
            color={colors.palette.grey300}
          />
        </div>
      </LActRow>

      <LActRow spaceBetween className={'pt-4 pr-3 pl-2 mt-2'}>
        <LActRow className={'-mt-5'}>
          <CallOutline color={colors.tint} height="24px" width="24px" />
          <LActRow>
            <LActEditableTextInput
              maxLength={6}
              testID={'#phoneNumber'}
              textColor={'text-textPrimary'}
              onUpdateClicked={onSaveClicked}
              className={
                'w-full px-0 bg-neutral300 text-textPrimary mt-0.5 ml-3 text-sm text-medium font-bold'
              }
              textNumberOfLines={'line-clamp-20'}
              value={formik?.values?.countryCode}
              successfullyUpdated={PATCH_QUERY?.isLoading}
              onChange={formik?.handleChange('countryCode')}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
              onCancelEditing={isCancelled =>
                formik.setFieldValue('countryCode', countryCode)
              }
            />
            <LActEditableTextInput
              testID={'#phoneNumber'}
              textColor={'text-textPrimary'}
              onUpdateClicked={onSaveClicked}
              className={
                'w-full px-0 bg-neutral300 text-textPrimary mt-0.5 ml-1 text-sm text-medium font-bold'
              }
              textNumberOfLines={'line-clamp-20'}
              value={formik?.values?.phoneNumber}
              successfullyUpdated={PATCH_QUERY?.isLoading}
              onChange={formik?.handleChange('phoneNumber')}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
              isLoading={
                formik?.values?.phoneNumber !== phoneNumber &&
                PATCH_QUERY?.isLoading
              }
              onCancelEditing={isCancelled =>
                formik.setFieldValue('phoneNumber', phoneNumber)
              }
            />
          </LActRow>
        </LActRow>

        {message?.message && <Alert />}
      </LActRow>
    </div>
  );
};

export default ProfileContactInfoTemplate;
