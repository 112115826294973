import { create } from 'zustand';
import { IApplicationsIDsTypes } from 'constants/types';

const useApplicationsIDsStorage = create(set => ({
  ids: [],
  purgeApplicationsIDs: () => set({ ids: [] }),
  storeApplicationsIDs: (ids: Array<number>) =>
    set((state: IApplicationsIDsTypes) => ({ ids })),
}));

export default useApplicationsIDsStorage;
