const palette = {
  neutral100: '#FFFFFF',
  neutral200: '#FCFCFD',
  neutral300: '#F4F5F6',
  neutral400: '#777E91',
  neutral500: '#978F8A',
  neutral600: '#564E4A',
  neutral700: '#23262F',
  neutral800: '#141416',
  neutral900: '#000000',

  primary: '#2C5B5E',
  primaryDark: '#1F4043',
  primaryLight: '#C1E0CC',

  secondary: '#FFCC00',
  secondary100: '#DCDDE9',
  secondary200: '#BCC0D6',
  secondary300: '#9196B9',
  secondary400: '#626894',
  secondary500: '#41476E',

  accent: '#EA5627',
  accent100: '#FFEED4',
  accent200: '#FFE1B2',
  accent300: '#FDD495',
  accent400: '#FBC878',
  accent500: '#FFBB50',
  accent800: '#843017',

  angry: '#FF2D55',
  angry100: '#FFEAEE',
  angry200: '#F2D6CD',
  angry500: '#C03403',

  gray: '#353945',
  grey100: '#E6E8EC',
  grey200: '#D1D1D6',
  grey300: '#B1B5C3',
  grey700: '#777E90',

  overlay20: 'rgba(25, 16, 21, 0.2)',
  overlay50: 'rgba(25, 16, 21, 0.5)',

  green: '#34BF7A',
} as const;

export const colors = {
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  /**
   * A helper for making something see-thru.
   */
  transparent: 'rgba(0, 0, 0, 0)',
  /**
   * The default text color in many components.
   */
  textPrimary: palette.neutral800,
  /**
   * The default text color in many components.
   */
  textSecondary: palette.gray,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral600,
  /**
   * The default color of the screen background.
   */
  background: palette.neutral100,
  backgroundSecondary: palette.neutral300,
  /**
   * The default border color.
   */
  border: palette.neutral400,
  /**
   * The main tinting color.
   */
  tint: palette.primary,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral300,
  /**
   * Error messages.
   */
  error: palette.angry,
  /**
   * Error Background.
   *
   */
  errorBackground: palette.angry100,
  /**
   * Success.
   *
   */
  success: palette.green,
  orange: palette.accent,
  /**
   * Warning.
   *
   */
  warning: palette.accent,
  linearGradientStart: 'rgba(252, 252, 253, 0.72) 36.52%',
  linearGradientEnd: 'rgba(252, 252, 253, 0.72) 36.52%',
};
