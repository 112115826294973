import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'i18n';
import { useGetApiV1Profile } from 'api/endpoints/queries';
import { ChevronBackOutline } from 'react-ionicons';
import {
  useUserStorage,
  useRefreshToken,
  useCheckAccessToken,
  useSwitchTranslation,
  useAccessTokenStorage,
  useOrganizationStorage,
} from 'utils';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import {
  LActRow,
  LActText,
  LActAlert,
  LActScreen,
  LActButton,
  Breadcrumb,
  LActLoader,
  LActEditableTextInput,
} from 'components/index';
import { EnumButtonType, EnumTextFormatType } from 'constants/enums';
import {
  IUserInfoTypes,
  IAccessTokenTypes,
  IOrganizationInfoTypes,
} from 'constants/types';
import { useFormik } from 'formik';
import { ProfileOrgDataSchema } from './utils';
import usePatchProfileDetails from './patchProfileDetails';
import { useNavigate } from 'react-router-dom';
import { colors } from 'constants/colors';
import ProfilePictureTemplate from './template/profile.image';
import ProfileContactInfoTemplate from './template/profile.contactInfo';

const ProfileScreen = (): React.ReactElement => {
  const i18n = 'screens.profile';
  useCheckAccessToken();
  useSwitchTranslation();
  const navigate = useNavigate();
  const [message] = useState({
    message: '',
    type: 'error',
  });

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  const API_QUERY = useGetApiV1Profile(userStorage?.user?.organizationId, {
    query: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });
  useRefreshToken({
    error: API_QUERY?.error,
    refetch: API_QUERY?.refetch,
    hasError: API_QUERY?.isError,
  });

  const Alert = useCallback(() => {
    return (
      <LActAlert
        dismissable
        duration={4000}
        message={message.message}
        type={message.type === 'success' ? 'success' : 'error'}
      />
    );
  }, [message.message, message.type]);

  const formik = useFormik({
    initialValues: {
      name: API_QUERY?.data?.item?.name,
      description: API_QUERY?.data?.item?.description,
      organizationHomePageUrl: API_QUERY?.data?.item?.url,
    },
    validateOnBlur: true,
    validationSchema: ProfileOrgDataSchema,
    onSubmit: () => {
      onSaveClicked();
    },
  });

  const PATCH_QUERY = usePatchProfileDetails({
    name: formik?.values.name ?? '',
    description: formik?.values.description ?? '',
    url: formik?.values.organizationHomePageUrl ?? '',
  });

  useEffect(() => {
    if (API_QUERY?.data) {
      formik.setFieldValue('name', API_QUERY?.data?.item?.name);
      formik.setFieldValue('description', API_QUERY?.data?.item?.description);

      organizationStorage?.storeOrgInfo(API_QUERY?.data?.item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [API_QUERY?.data]);

  if (API_QUERY?.isLoading) {
    return (
      <LActScreen>
        <div
          data-testid={'loader'}
          className={'flex flex-1 justify-center items-center'}
        >
          <LActLoader data-testid={'loader'} color={'tint'} />
        </div>
      </LActScreen>
    );
  }

  const onSaveClicked = () => {
    PATCH_QUERY?.submitChanges();
    setTimeout(() => {
      API_QUERY?.refetch();
    }, 500);
  };

  const onCancelOrCloseClicked = (isCancelled: boolean) => {
    if (isCancelled) {
      formik.setFieldValue('name', API_QUERY?.data?.item?.name);
      formik.setFieldValue('description', API_QUERY?.data?.item?.description);
    }
  };

  const ITEM = API_QUERY?.data?.item;
  return (
    <LActScreen>
      <LActRow spaceBetween className={'-mr-4 items-center mt-7'}>
        <div>
          <LActButton
            type={EnumButtonType.TEXT}
            onClick={() => navigate(-1)}
            textStyle={'text-tint text-sm'}
            text={translate(`${i18n}.back`)}
            iconComponent={
              <ChevronBackOutline
                width="14px"
                height="14px"
                style={{
                  marginTop: -1,
                  marginRight: -6,
                }}
                color={colors.palette.primaryDark}
              />
            }
          />
        </div>
        <div>
          <Breadcrumb
            routes={[
              translate(`${i18n}.breadcrumb.home`),
              translate(`${i18n}.breadcrumb.profile`),
            ]}
          />
        </div>
      </LActRow>

      <div className="mt-8">
        <LActText
          className={'mt-6'}
          text={translate(`${i18n}.title`)}
          textColor={
            'text-textPrimary text-regular desktop:flex desktop:flex-1 mobile:hidden mobile:text-2xl desktop:text-3xl'
          }
          textFormatType={EnumTextFormatType.FONT_SIZE_48}
        />
      </div>

      <ProfilePictureTemplate
        image={ITEM?.imageUri}
        onUpdateClicked={onSaveClicked}
        accessToken={tokenStorage?.token?.access_token}
        organizationID={userStorage?.user?.organizationId}
      />

      <div
        className={
          'mt-6 desktop:flex desktop:flex-row mobile:flex mobile:flex-col'
        }
      >
        <div
          data-testid={'editable-text'}
          className={'desktop:w-1/2 desktop:mr-3'}
        >
          <LActEditableTextInput
            testID={'editable-name-text'}
            value={formik?.values?.name}
            onUpdateClicked={onSaveClicked}
            className={'font-semibold text-2xl'}
            onChange={formik?.handleChange('name')}
            isLoading={
              formik?.values?.name !== API_QUERY?.data?.item?.name &&
              PATCH_QUERY?.isLoading
            }
            successfullyUpdated={PATCH_QUERY?.isLoading}
            onCancelEditing={isCancelled => onCancelOrCloseClicked(isCancelled)}
          />

          <LActEditableTextInput
            testID={'editable-name-text'}
            onUpdateClicked={onSaveClicked}
            successfullyUpdated={PATCH_QUERY?.isLoading}
            onChange={formik?.handleChange('organizationHomePageUrl')}
            className={'leading-6 w-full text-textSecondary text-base'}
            isLoading={
              formik?.values?.organizationHomePageUrl !==
                API_QUERY?.data?.item?.url && PATCH_QUERY?.isLoading
            }
            value={formik?.values?.organizationHomePageUrl || 'https://...'}
            onCancelEditing={isCancelled => onCancelOrCloseClicked(isCancelled)}
          />

          <LActEditableTextInput
            multiline
            testID={'editable-desc-text'}
            onUpdateClicked={onSaveClicked}
            textColor={'text-textSecondary'}
            value={
              formik?.values?.description ? formik?.values?.description : '...'
            }
            textNumberOfLines={'line-clamp-20'}
            successfullyUpdated={PATCH_QUERY?.isLoading}
            onChange={formik?.handleChange('description')}
            className={'leading-6 w-full text-textSecondary text-base'}
            isLoading={
              formik?.values?.description !==
                API_QUERY?.data?.item?.description && PATCH_QUERY?.isLoading
            }
            onCancelEditing={isCancelled => onCancelOrCloseClicked(isCancelled)}
          />
        </div>

        <ProfileContactInfoTemplate
          shouldLinkContactInfo={false}
          item={ITEM?.organizationDetails}
          refetch={() => API_QUERY?.refetch()}
          email={
            ITEM?.organizationDetails?.email
              ? ITEM?.organizationDetails?.email
              : '...'
          }
          lastName={
            ITEM?.organizationDetails?.lastname
              ? ITEM?.organizationDetails?.lastname
              : '...'
          }
          firstName={
            ITEM?.organizationDetails?.firstname
              ? ITEM?.organizationDetails?.firstname
              : '...'
          }
          phoneNumber={
            ITEM?.organizationDetails?.phoneNumber
              ? ITEM?.organizationDetails?.phoneNumber
              : '...'
          }
          countryCode={
            ITEM?.organizationDetails?.countryCode
              ? ITEM?.organizationDetails?.countryCode
              : '+49'
          }
          className={'desktop:w-1/2 desktop:ml-3 desktop:self-end mobile:mt-6'}
          userName={
            ITEM?.organizationDetails?.firstname +
            ' ' +
            ITEM?.organizationDetails?.lastname
          }
        />
      </div>

      {PATCH_QUERY?.responseMessage && (
        <LActAlert
          dismissable
          duration={4000}
          message={PATCH_QUERY?.responseMessage}
          type={PATCH_QUERY?.responseType === 'success' ? 'success' : 'error'}
        />
      )}

      {message?.message && <Alert />}
    </LActScreen>
  );
};

export default ProfileScreen;
