import { IApplicationStatusTypes } from 'constants/types';
import {
  CONTACTED_DROPDOWN_LIST,
  IN_REVIEW_DROPDOWN_LIST,
  PENDING_DROPDOWN_LIST,
} from 'components/templates/applicationStatus/utils';
import { EnumApplicationsType } from 'constants/enums';
import { translate } from 'i18n';

export const APPLICATIONS_STATUS = [
  'PENDING',
  'CONTACTED',
  'IN_REVIEW',
  'ACCEPTED',
  'REJECTED',
];

const i18n = 'screens.home.sidebar';

export const statusDropdownHandler = (
  applicationStatus: string | undefined,
) => {
  let DROP_DOWN_BG_COLOR: string = '';
  let DROP_DOWN_TITLE_TEXT: string = '';
  let DROP_DOWN_TITLE_COLOR: string = '';
  let DROP_DOWN_CHILD_ITEMS: Array<IApplicationStatusTypes> = [];

  switch (applicationStatus) {
    case EnumApplicationsType.PENDING:
      DROP_DOWN_BG_COLOR = 'bg-neutral600';
      DROP_DOWN_TITLE_COLOR = 'text-textSecondary';
      DROP_DOWN_CHILD_ITEMS = PENDING_DROPDOWN_LIST;
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.open`);
      break;

    case EnumApplicationsType.CONTACTED:
      DROP_DOWN_BG_COLOR = 'bg-skyBlue';
      DROP_DOWN_TITLE_COLOR = 'text-textSecondary';
      DROP_DOWN_CHILD_ITEMS = CONTACTED_DROPDOWN_LIST;
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.contacted`);
      break;

    case EnumApplicationsType.IN_REVIEW:
      DROP_DOWN_BG_COLOR = 'bg-orangeLight';
      DROP_DOWN_TITLE_COLOR = 'text-orangeDark';
      DROP_DOWN_CHILD_ITEMS = IN_REVIEW_DROPDOWN_LIST;
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.inContact`);
      break;

    case EnumApplicationsType.ACCEPTED:
      DROP_DOWN_CHILD_ITEMS = [];
      DROP_DOWN_BG_COLOR = 'bg-greenLight';
      DROP_DOWN_TITLE_COLOR = 'text-green';
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.takingPart`);
      break;

    case EnumApplicationsType.REJECTED:
      DROP_DOWN_CHILD_ITEMS = [];
      DROP_DOWN_BG_COLOR = 'bg-pink';
      DROP_DOWN_TITLE_COLOR = 'text-error';
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.didntWork`);
      break;

    default:
      DROP_DOWN_BG_COLOR = 'bg-neutral600';
      DROP_DOWN_TITLE_COLOR = 'text-textSecondary';
      DROP_DOWN_CHILD_ITEMS = PENDING_DROPDOWN_LIST;
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.open`);
      break;
  }

  return {
    DROP_DOWN_BG_COLOR,
    DROP_DOWN_TITLE_TEXT,
    DROP_DOWN_TITLE_COLOR,
    DROP_DOWN_CHILD_ITEMS,
  };
};

export const itemIsDraggable = (status: string): boolean => {
  if (!status) return false;
  if (status === EnumApplicationsType.ACCEPTED) {
    return true;
  } else if (status === EnumApplicationsType.REJECTED) {
    return true;
  }
  return false;
};

export const availableDroppableStatus = (status: string): Array<string> => {
  if (!status) return [];
  switch (status) {
    case EnumApplicationsType.PENDING:
      const pendingActions = [
        EnumApplicationsType.CONTACTED,
        EnumApplicationsType.IN_REVIEW,
        EnumApplicationsType.ACCEPTED,
        EnumApplicationsType.REJECTED,
        EnumApplicationsType.PENDING,
      ];
      return pendingActions;

    case EnumApplicationsType.CONTACTED:
      const contactedActions = [
        EnumApplicationsType.IN_REVIEW,
        EnumApplicationsType.ACCEPTED,
        EnumApplicationsType.REJECTED,
        EnumApplicationsType.CONTACTED,
      ];
      return contactedActions;

    case EnumApplicationsType.IN_REVIEW:
      const inReviewActions = [
        EnumApplicationsType.ACCEPTED,
        EnumApplicationsType.REJECTED,
        EnumApplicationsType.IN_REVIEW,
      ];
      return inReviewActions;

    default:
      return [];
  }
};
