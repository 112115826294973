import { useState, useEffect } from 'react';
import axios from 'axios';
import { useRefreshToken } from 'utils';

interface PaginationProps {
  page: number;
  size?: number;
  refreshing?: boolean;
  shouldRefreshOffers?: boolean;
}

const useGetAdminOrganizationsPagination = (props: PaginationProps) => {
  const { page, size, refreshing, shouldRefreshOffers } = props;
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>();
  const [responseError, setResponseError] = useState({
    isError: false,
    error: undefined,
  });
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = () => {
    setIsLoading(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    };

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/organizations?page=${page}&size=${size}`,
        config,
      )
      .then(result => {
        setTotalPages(result?.data?.pages);
        setData(result?.data?.items);
        setHasMore(result?.data?.pages > page);
        setIsLoading(false);
        if (!totalItems) {
          setTotalItems(result?.data?.total);
        }
      })
      .catch(error => {
        setIsLoading(false);
        setResponseError({
          isError: true,
          error: error,
        });
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, shouldRefreshOffers, refreshing]);

  /**
   * Refetch the accessToken if the error is 401
   * and refreshes the accessToken
   */
  useRefreshToken({
    refetch: fetchData,
    error: responseError?.error,
    hasError: responseError?.isError,
  });

  return {
    data,
    isLoading,
    hasMore,
    currentPage: page,
    totalPages,
    totalItems,
  };
};

export default useGetAdminOrganizationsPagination;
