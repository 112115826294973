import React from 'react';
import { LActRow, LActText } from 'components/index';
import { translate } from 'i18n';
import { Offer } from 'api/schemas/offer';
import { EnumSchedulingFrequency, EnumTextFormatType } from 'constants/enums';
import { WEEK_DAYS } from '../utils';
import { parseLocationType } from 'utils/global/functions';
import {
  CalendarOutline,
  LocationOutline,
  RepeatOutline,
  TimeOutline,
} from 'react-ionicons';
import { colors } from 'constants/colors';

interface OfferCreationSchedulingPreviewProps {
  offerItem: Offer;
  scheduling: any;
}

const OfferCreationSchedulingPreview = (
  props: OfferCreationSchedulingPreviewProps,
): React.ReactElement => {
  const i18n = 'screens.offerCreation.preview';
  const { offerItem, scheduling } = props;

  return (
    <>
      {scheduling?.frequency === EnumSchedulingFrequency.FLEXIBLE ? (
        <div className="flex justify-between">
          <div>
            <div className="flex ">
              <RepeatOutline color={colors.tint} height="24px" width="24px" />
              <div className="ml-4">
                <LActText
                  text={translate(`${i18n}.howOften`)}
                  textFormatType={EnumTextFormatType.FONT_SIZE_14}
                  className="text-neutral400 text-sm"
                />
                <LActText
                  className="text-textPrimary text-regular font-bold"
                  textFormatType={EnumTextFormatType.FONT_SIZE_16}
                  text={translate(`${i18n}.flexible`)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex">
          <div className="flex">
            <RepeatOutline color={colors.tint} height="24px" width="24px" />
            <div className="ml-4">
              <LActText
                text={translate(`${i18n}.howOften`)}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
                className="text-neutral400 text-sm"
              />
              <LActText
                className="text-textPrimary text-regular font-bold"
                textFormatType={EnumTextFormatType.FONT_SIZE_16}
                text={`1x ${translate(
                  scheduling?.frequency === EnumSchedulingFrequency.WEEKLY
                    ? `${i18n}.perWeek`
                    : `${i18n}.perMonth`,
                )}`}
              />
            </div>
          </div>

          <div className="ml-8 flex">
            <TimeOutline color={colors.tint} height="24px" width="24px" />

            <div className="ml-4">
              <LActText
                text={translate(`${i18n}.howLong`)}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
                className="text-neutral400 text-sm"
              />
              <LActText
                className="text-textPrimary text-regular font-bold"
                textFormatType={EnumTextFormatType.FONT_SIZE_16}
                text={`${translate(`${i18n}.hours`, {
                  count: scheduling?.minHours,
                })}`}
              />
            </div>
          </div>
        </div>
      )}

      {offerItem?.schedule?.frequency !== EnumSchedulingFrequency.FLEXIBLE && (
        <div className="flex mt-4">
          <CalendarOutline color={colors.tint} height="24px" width="24px" />

          <div className="ml-4">
            <LActText
              text={translate(`${i18n}.when`)}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
              className="text-neutral400 text-sm"
            />

            <div className="flex">
              {WEEK_DAYS.map((item: any, index: number) => {
                const isActiveDay = !scheduling?.weekdays.includes(item.key)
                  ? 'text-neutral500'
                  : '';

                return (
                  <LActText
                    key={index.toString()}
                    textColor={isActiveDay}
                    text={item.name.slice(0, 2)}
                    className="text-textPrimary text-regular font-bold mr-2"
                    textFormatType={EnumTextFormatType.FONT_SIZE_16}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}

      <div>
        <LActRow className="justify-between mt-4">
          <div>
            <div className="flex">
              <LocationOutline color={colors.tint} height="24px" width="24px" />

              <div className="ml-4">
                <LActText
                  text={translate(`${i18n}.where`)}
                  textFormatType={EnumTextFormatType.FONT_SIZE_14}
                  className="text-neutral400 text-sm"
                />
                <LActText
                  className="text-textPrimary text-regular font-bold"
                  textFormatType={EnumTextFormatType.FONT_SIZE_16}
                  text={parseLocationType(
                    offerItem?.city,
                    offerItem?.street,
                    i18n,
                    offerItem?.locationType,
                    true,
                  )}
                />
              </div>
            </div>
          </div>
        </LActRow>
      </div>
    </>
  );
};

export default OfferCreationSchedulingPreview;
