import React, { useState } from 'react';
import { LActButton, LActCard, LActRow } from 'components/index';
import {
  EnumButtonActionType,
  EnumButtonType,
  EnumLanguageType,
} from 'constants/enums';
import { translate } from 'i18n';
import { CATEGORIES_OPTIONS } from './utils';
import { ILanguageSwitchTypes, IOfferCreationTypes } from 'constants/types';
import {
  useLanguageSwitchStorage,
  useOfferCreationStorage,
  useSwitchTranslation,
} from 'utils';

type Props = {
  lastStep: number;
  currentStep: number;
  onNextClicked: () => void;
};

const OfferCreationCategoryTemplate = (props: Props): React.ReactElement => {
  const i18n = 'screens.offerCreation.name';
  useSwitchTranslation();
  const { lastStep, currentStep, onNextClicked } = props;
  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );
  const [selectedItem, setSelectedItem] = useState<number>(
    offerCreationStorage?.offer?.category?.id,
  );

  // @ts-ignore
  const languageSwitchStorage: ILanguageSwitchTypes = useLanguageSwitchStorage(
    (store: ILanguageSwitchTypes | unknown) => store,
  );

  const onItemClicked = (category: number) => {
    setSelectedItem(category);
  };

  const handleOnNextClicked = () => {
    const selectedCategory = CATEGORIES_OPTIONS?.filter(
      item => item?.id === Number(selectedItem),
    );

    const offer = {
      ...offerCreationStorage.offer,

      category: selectedCategory[0],
    };

    offerCreationStorage.storeOffers(offer);

    onNextClicked();
  };

  const BUTTON_IS_DISABLED = selectedItem ? false : true;

  return (
    <div>
      <LActCard className="overflow-scroll">
        <div className="flex flex-wrap justify-evenly ">
          {CATEGORIES_OPTIONS?.map((item, index) => {
            const BG_SELECTED =
              item?.id === selectedItem
                ? 'bg-tint text-white'
                : 'bg-white text-textSecondary';

            const TEXT_COLOR_SELECTED =
              item?.id === selectedItem
                ? 'text-white font-semibold'
                : 'text-tint';

            const title =
              languageSwitchStorage?.language === EnumLanguageType.GERMAN
                ? item?.valueDe
                : item?.valueEn;

            return (
              <span
                key={`category-badge-${index}`}
                onClick={() => onItemClicked(item?.id)}
                className={`cursor-pointer px-3 py-2 mx-1 rounded-full mt-3 ${BG_SELECTED} ${TEXT_COLOR_SELECTED}`}
              >
                {title}
              </span>
            );
          })}
        </div>
      </LActCard>

      <div>
        <LActRow className={'relative w-full mt-4 bottom-1 w-2/6'}>
          <LActButton
            testID={'login-button'}
            textStyle={'text-tint'}
            onClick={() => undefined}
            type={EnumButtonType.TEXT}
            actionType={EnumButtonActionType.BUTTON}
            text={translate(`${i18n}.step`) + `  ${currentStep} / ${lastStep}`}
          />

          <LActButton
            testID={'login-button'}
            type={EnumButtonType.NORMAL}
            disabled={BUTTON_IS_DISABLED}
            onClick={handleOnNextClicked}
            text={translate(`${i18n}.nextButton`)}
            actionType={EnumButtonActionType.BUTTON}
          />
        </LActRow>
      </div>
    </div>
  );
};

export default OfferCreationCategoryTemplate;
