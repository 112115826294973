import React from 'react';
import LActText from '../LActText';
import { EnumTextFormatType } from 'constants/enums';

interface LActBadgeProps {
  title: string;
  bgColor?: string;
  textColor?: string;
  className?: string;
  onClick?: () => void;
}

const LActBadge = (props: LActBadgeProps): React.ReactElement => {
  const { title, bgColor, onClick, textColor, className } = props;

  const TEXT_COLOR = textColor ?? 'text-white';
  const BG_COLOR = bgColor ? bgColor : 'bg-orange';

  return (
    <LActText
      text={title}
      onClick={onClick}
      testID={'new-badge'}
      textColor={TEXT_COLOR}
      textFormatType={EnumTextFormatType.FONT_SIZE_12}
      className={`py-1.5 px-3 rounded-full ${BG_COLOR} ${className}`}
    />
  );
};

export default LActBadge;
