import React from 'react';
import { LActCard, LActRow } from 'components/index';

interface OffersSkeletonLoaderTemplateProps {
  className?: string;
}

const OffersSkeletonLoaderTemplate = (
  props: OffersSkeletonLoaderTemplateProps,
): React.ReactElement => {
  const { className } = props;
  const NUMBER_OF_SHOWN_ITEMS = [0, 1, 2, 3, 4, 5];

  return (
    <>
      {NUMBER_OF_SHOWN_ITEMS.map((item, index) => {
        return (
          <LActCard
            key={`skeleton-${item}+${index}`}
            className={`rounded-md p-0 max-w-sm w-full bg-background mobile:mt-3 desktop:mt-0 ${className}`}
          >
            <div className="w-full h-48 animate-pulse bg-skeletonItems rounded-tl-3xl rounded-tr-3xl" />

            <div className="px-6 pt-4 pb-6">
              <div className="animate-pulse bg-skeletonItems h-4 w-full" />

              <LActRow spaceBetween className="mt-3">
                <LActRow className="items-center">
                  <div className="animate-pulse bg-skeletonItems h-4 w-4" />
                  <div className="animate-pulse bg-skeletonItems ml-3 h-3 w-full" />
                </LActRow>

                <LActRow className="items-center ml-3">
                  <div className="animate-pulse bg-skeletonItems h-4 w-4" />
                  <div className="animate-pulse bg-skeletonItems ml-3 h-3 w-10" />
                </LActRow>
              </LActRow>

              <LActRow className="items-center mt-3">
                <div className="animate-pulse bg-skeletonItems h-4 w-4" />
                <div className="animate-pulse bg-skeletonItems ml-3 h-3 w-full mr-8" />
              </LActRow>

              <LActRow className="items-center mt-3">
                <div className="animate-pulse bg-skeletonItems h-4 w-4" />
                <div className="animate-pulse bg-skeletonItems ml-3 h-3 w-full mr-6" />
              </LActRow>

              <LActRow className="items-center mt-3">
                <div>
                  <div className="animate-pulse rounded-full bg-skeletonItems h-10 w-10" />
                </div>
                <div className="animate-pulse bg-skeletonItems ml-3 h-3 w-20 mr-6" />
              </LActRow>
            </div>
          </LActCard>
        );
      })}
    </>
  );
};

export default OffersSkeletonLoaderTemplate;
