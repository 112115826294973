import React, { useEffect, useState } from 'react';
import { LActLoader, LActDropdown, LActRow, LActText } from 'components/index';
import { EnumApplicationsType, EnumTextFormatType } from 'constants/enums';
import { statusDropdownHandler } from './utils';
import { usePatchOfferDetails } from 'api/endpoints/queries';
import { useRefreshToken, useSwitchTranslation } from 'utils';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { ApplicationItems } from 'api/schemas/applicationItems';
import { TxKeyPath, translate } from 'i18n';
import { Offer } from 'api/schemas/offer';

interface StatusComponentProps {
  status: string;
  offerID: number;
  disabled?: boolean;
  accessToken: string;
  useCloseDropdown?: boolean;
  application?: ApplicationItems;
  onItemUpdated: (item: Offer) => void;
  onPresentDropdown: (isOpen: boolean) => void;
}

const StatusComponent = (props: StatusComponentProps): React.ReactElement => {
  const i18n = 'screens.home.offers.details';
  useSwitchTranslation();
  const {
    status,
    offerID,
    disabled,
    accessToken,
    onItemUpdated,
    useCloseDropdown,
    onPresentDropdown,
  } = props;
  const [selectedItemStatus, setSelectedItemStatus] = useState<string>('');
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  const PATCH_QUERY = usePatchOfferDetails({
    ...parseBearerToken(accessToken ? decryptData(accessToken) : ''),
  });

  useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
        });
      }, 3000);
    }
  }, [message.type]);

  const queryAPI = (status: string) => {
    if (!status) return;

    PATCH_QUERY?.mutateAsync({
      offerId: offerID?.toString(),
      data: {
        status,
      },
    })
      .then((data: any) => {
        onItemUpdated(data?.item);
        setMessage({
          message: translate(`${i18n}.severMessages.successfullyUpdated`),
          type: 'success',
        });
      })
      .catch(error => {
        if (error?.message?.includes('500')) {
          setMessage({
            message: translate(`${i18n}.severMessages.serverError500`),
            type: 'error',
          });
        }
        setMessage({
          message: translate(`${i18n}.severMessages.serverGeneralError`),
          type: 'error',
        });
      });
  };
  useRefreshToken({
    error: PATCH_QUERY?.error,
    hasError: PATCH_QUERY?.isError,
    refetch: () => queryAPI(selectedItemStatus),
  });

  const {
    DROPDOWN_STATUS,
    DROPDOWN_TEXT_COLOR,
    DROPDOWN_CHILD_ITEMS,
    DROPDOWN_BACKGROUND_COLOR,
  } = statusDropdownHandler(status);

  const DropdownItems = (): React.ReactElement => {
    const onItemsClicked = (status: EnumApplicationsType) => {
      setSelectedItemStatus(status);
      queryAPI(status);
    };

    return (
      <span aria-disabled={disabled} className={'pointer-cursor'}>
        {DROPDOWN_CHILD_ITEMS?.map((value: any, index: number) => {
          return (
            <button
              disabled={disabled}
              key={`${index + value?.id}+1`}
              onClick={() => onItemsClicked(value?.status)}
              className={`
                      py-2 
                      flex
                      px-4 
                      my-2.5 
                      text-sm
                      flex-col
                      delay-50 
                      transform
                      transition 
                      ease-in-out 
                      inline-block 
                      rounded-full 
                      duration-300 
                      font-semibold 
                      hover:scale-110
                      hover:-translate-y-1
                      ${value?.bgColor} 
                      ${value?.titleColor}`}
            >
              <LActRow className={'items-center'}>
                <>
                  {translate(value?.title as TxKeyPath)}
                  {PATCH_QUERY?.isLoading &&
                    value.status === selectedItemStatus && (
                      <span className={'ml-1'}>
                        <LActLoader
                          size={'sm'}
                          color={`${value?.titleColor}`}
                        />
                      </span>
                    )}
                </>
              </LActRow>
            </button>
          );
        })}

        {message?.message && (
          <LActText
            text={message?.message}
            className={'font-semibold mt-6'}
            textFormatType={EnumTextFormatType.FONT_SIZE_16}
            textColor={message.type === 'error' ? 'text-error' : 'text-green'}
          />
        )}
      </span>
    );
  };

  return (
    <div>
      <LActDropdown
        showsIcon
        disabled={disabled}
        close={!useCloseDropdown}
        titleColor={DROPDOWN_TEXT_COLOR}
        containerStyle={DROPDOWN_BACKGROUND_COLOR}
        titleText={translate(DROPDOWN_STATUS as TxKeyPath)}
        onPresentDropdown={isOpen => onPresentDropdown(isOpen)}
        childComponent={<>{useCloseDropdown ? <DropdownItems /> : undefined}</>}
      />
    </div>
  );
};

export default StatusComponent;
