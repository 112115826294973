import React, { useState } from 'react';
import { LActRow, LActCard, LActButton, LActTextInput } from 'components/index';
import { EnumButtonActionType, EnumButtonType } from 'constants/enums';
import { translate } from 'i18n';
import ImageUpload from './components/imageUpload';
import { IOfferCreationTypes } from 'constants/types';
import { useOfferCreationStorage, useSwitchTranslation } from 'utils';

type Props = {
  lastStep: number;
  currentStep: number;
  onNextClicked: () => void;
};

const OfferCreationDetailsTemplate = (props: Props): React.ReactElement => {
  const i18n = 'screens.offerCreation.name';
  useSwitchTranslation();
  const { lastStep, currentStep, onNextClicked } = props;
  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );
  const [goal, setGoal] = useState<string>(offerCreationStorage?.offer?.goal);
  const [title, setTitle] = useState<string>(
    offerCreationStorage?.offer?.title,
  );
  const [impact, setImpact] = useState<string>(
    offerCreationStorage?.offer?.impact,
  );

  const handleOnNextClicked = () => {
    const offer = {
      ...offerCreationStorage.offer,
      goal: goal ?? '',
      title: title ?? '',
      impact: impact ?? '',
    };

    offerCreationStorage.storeOffers(offer);

    onNextClicked();
  };

  const BUTTON_IS_DISABLED =
    goal &&
    goal?.length > 0 &&
    title &&
    title?.length > 0 &&
    impact &&
    impact?.length > 0
      ? false
      : true;

  return (
    <div>
      <LActCard className="overflow-scroll">
        <ImageUpload image={offerCreationStorage?.offer?.demoPic} />

        <LActTextInput
          type={'text'}
          value={title}
          focusBG="focus:bg-white"
          onChange={value => setTitle(value)}
          placeHolder={translate(`${i18n}.title`)}
          style={`bg-white rounded-lg mt-6 w-full px-3.5`}
        />

        <LActTextInput
          multiLine
          value={goal}
          type={'text'}
          hasFloatingLabel
          multiLineRowsNumber={4}
          focusBG="focus:bg-white"
          onChange={value => setGoal(value)}
          placeHolder={translate(`${i18n}.goal`)}
          style={`bg-white rounded-lg mt-6 w-full h-40 px-3 pt-5`}
        />

        <LActTextInput
          multiLine
          type={'text'}
          value={impact}
          hasFloatingLabel
          multiLineRowsNumber={4}
          focusBG="focus:bg-white"
          onChange={value => setImpact(value)}
          placeHolder={translate(`${i18n}.tasks`)}
          style={`bg-white rounded-lg mt-6 w-full h-40 px-3 py-2 pt-5`}
        />
      </LActCard>

      <div>
        <LActRow className={'relative w-full mt-4 bottom-1 w-2/6'}>
          <LActButton
            testID={'login-button'}
            textStyle={'text-tint'}
            onClick={() => undefined}
            type={EnumButtonType.TEXT}
            actionType={EnumButtonActionType.BUTTON}
            text={translate(`${i18n}.step`) + `  ${currentStep} / ${lastStep}`}
          />

          <LActButton
            testID={'login-button'}
            type={EnumButtonType.NORMAL}
            disabled={BUTTON_IS_DISABLED}
            onClick={handleOnNextClicked}
            text={translate(`${i18n}.nextButton`)}
            actionType={EnumButtonActionType.BUTTON}
          />
        </LActRow>
      </div>
    </div>
  );
};

export default OfferCreationDetailsTemplate;
