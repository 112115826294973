import React, { useState } from 'react';
import { translate } from 'i18n';
import { useFormik } from 'formik';
import { LActButton, LActRow, LActText } from 'components/index';
import AuthScreenContainer from 'screens/auth/container';
import {
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
} from 'constants/enums';
import {
  IUserInfoTypes,
  IAccessTokenTypes,
  IOrganizationInfoTypes,
} from 'constants/types';
import {
  useUserStorage,
  useRefreshToken,
  useSwitchTranslation,
  useAccessTokenStorage,
  useOrganizationStorage,
} from 'utils';
import {
  CallOutline,
  MailOutline,
  ChevronForwardOutline,
} from 'react-ionicons';
import { colors } from 'constants/colors';
import { ProfileContactInfoSchema } from './utils';
import WelcomeTemplate from 'components/templates/welcomeScreen/welcome.index';
import { usePatchOrganizationContactInfoDetails } from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { useNavigate } from 'react-router-dom';
import { HOME_SCREEN } from 'constants/route';

const RegisterOrgContactInfoScreen = (): React.ReactElement => {
  const i18n = 'screens.organizationContact';
  useSwitchTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });
  const [showWelcome, setShowWelcome] = useState<boolean>(false);
  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  const formik = useFormik({
    initialValues: {
      email: organizationStorage?.organization?.organizationDetails?.email,
      lastname:
        organizationStorage?.organization?.organizationDetails?.lastname,
      firstname:
        organizationStorage?.organization?.organizationDetails?.firstname,
      phoneNumber:
        organizationStorage?.organization?.organizationDetails?.phoneNumber,
      countryCode:
        organizationStorage?.organization?.organizationDetails?.countryCode ||
        '+49',
    },
    validationSchema: ProfileContactInfoSchema,
    onSubmit: () => {
      onNextClicked();
    },
  });

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  const PATCH_QUERY = usePatchOrganizationContactInfoDetails({
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });

  const onNavigateToHomeScreen = () => {
    setTimeout(() => {
      navigate(HOME_SCREEN, {
        replace: true,
      });
    }, 1500);
  };

  const onLaterButtonClicked = () => {
    onNavigateToHomeScreen();
  };

  const onNextClicked = () => {
    if (
      formik.values.email &&
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formik.values.email,
      )
    ) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidEmail`),
        type: 'error',
      });
    } else if (formik.values.firstname && formik.values.firstname?.length < 3) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidFirstName`),
        type: 'error',
      });
    } else if (formik.values.lastname && formik.values.lastname?.length < 3) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidLastName`),
        type: 'error',
      });
    } else if (
      formik.values.phoneNumber &&
      !/^[1-9][0-9]*$/.test(formik.values.phoneNumber)
    ) {
      setMessage({
        message: translate(
          `${i18n}.validationErrorMessages.invalidPhoneNumberLength`,
        ),
        type: 'error',
      });
    } else if (
      formik.values.countryCode &&
      !/^\+/.test(formik.values.countryCode)
    ) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidPlus`),
        type: 'error',
      });
    } else if (
      formik.values.countryCode &&
      formik.values.countryCode.length === 0
    ) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.emptyCountryCode`),
        type: 'error',
      });
    } else if (
      formik.values.countryCode &&
      formik.values.countryCode.length < 2
    ) {
      setMessage({
        message: translate(
          `${i18n}.validationErrorMessages.invalidCountryCodeLength`,
        ),
        type: 'error',
      });
    } else {
      PATCH_QUERY?.mutateAsync({
        organizationId: userStorage?.user?.organizationId?.toString(),
        organizationDetailId:
          organizationStorage?.organization?.organizationDetails?.id?.toString() ||
          '',
        data: {
          email: formik?.values?.email,
          lastname: formik?.values?.lastname,
          firstname: formik?.values?.firstname,
          phoneNumber: formik?.values?.phoneNumber,
          countryCode: formik?.values?.countryCode,
        },
      })
        .then((response: any) => {
          organizationStorage.storeOrgInfo(response?.item);
          setMessage({
            message: translate(`${i18n}.successUpdateMessage`),
            type: 'success',
          });
          setShowWelcome(true);
          onNavigateToHomeScreen();
        })
        .catch(error => {
          setMessage({
            message: translate(`${i18n}.unsuccessfulUpdateMessage`),
            type: 'error',
          });
        });
    }

    setTimeout(() => {
      setMessage({
        message: '',
        type: '',
      });
    }, 4000);
  };
  useRefreshToken({
    refetch: onNextClicked,
    error: PATCH_QUERY?.error,
    hasError: PATCH_QUERY?.isError,
  });

  return (
    <>
      <AuthScreenContainer
        showProgress
        progressValue={100}
        style={`flex justify-center items-center mobile:mx-6 mobile:w-full flex-shrink-1`}
      >
        <div className="desktop:w-3/5 mobile:w-full">
          <LActText
            text={translate(`${i18n}.titleOne`)}
            className="text-textPrimary text-center mt-3"
            textFormatType={EnumTextFormatType.FONT_SIZE_40}
          />
          <LActText
            text={translate(`${i18n}.titleTwo`)}
            className="text-textPrimary text-center"
            textFormatType={EnumTextFormatType.FONT_SIZE_40}
          />
          <LActText
            text={translate(`${i18n}.description`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_16}
            className="text-textSecondary text-center text-sm mt-2"
          />

          <div className={`bg-neutral300 p-4 rounded-3xl mt-6`}>
            <LActRow className={'pb-2 items-center'}>
              <div className={'ml-3'} data-testid={'username-text'}>
                <LActText
                  testID={'name-text'}
                  numberOfLines={'line-clamp-1'}
                  text={translate(`${i18n}.name`)}
                  textColor={'text-textSecondary'}
                  textFormatType={EnumTextFormatType.FONT_SIZE_12}
                />

                <LActRow>
                  <div>
                    <input
                      onChange={formik?.handleChange('firstname')}
                      placeholder={translate(`${i18n}.firstName`)}
                      className={
                        'px-0 bg-neutral300 mt-1 text-sm text-regular font-semibold'
                      }
                    />

                    <input
                      onChange={formik?.handleChange('lastname')}
                      placeholder={translate(`${i18n}.lastName`)}
                      className={
                        'px-0 bg-neutral300 mt-1 text-sm text-regular font-semibold'
                      }
                    />
                  </div>
                </LActRow>
              </div>
            </LActRow>

            {organizationStorage?.organization?.organizationDetails?.email && (
              <LActRow spaceBetween className={`p-2`}>
                <LActRow className={'mt-2'}>
                  <MailOutline color={colors.tint} height="24px" width="24px" />

                  <div className={'flex flex-col items-center'}>
                    <LActText
                      testID={'#email'}
                      text={formik?.values?.email}
                      textColor={'text-textPrimary'}
                      className={'ml-3 text-medium mt-0.5'}
                      textFormatType={EnumTextFormatType.FONT_SIZE_14}
                    />
                  </div>
                </LActRow>

                <div className={'desktop:flex-none desktop:hidden'}>
                  <ChevronForwardOutline
                    width={'16px'}
                    color={colors.palette.grey300}
                  />
                </div>
              </LActRow>
            )}

            <LActRow spaceBetween className={'pt-4 pr-3 pl-2 -mt-1'}>
              <LActRow className={''}>
                <CallOutline color={colors.tint} height="24px" width="24px" />
                <LActRow>
                  <input
                    maxLength={6}
                    defaultValue={'+49'}
                    onChange={formik?.handleChange('countryCode')}
                    className={
                      'w-10 bg-neutral300 text-textPrimary mt-0.5 ml-3 text-sm text-regular font-bold'
                    }
                  />
                  <input
                    type="number"
                    maxLength={24}
                    onChange={formik?.handleChange('phoneNumber')}
                    placeholder={translate(`${i18n}.phoneNumber`)}
                    className={
                      'w-full px-0 bg-neutral300 text-textPrimary mt-0.5 ml-1 text-sm text-regular font-bold'
                    }
                  />
                </LActRow>
              </LActRow>
            </LActRow>
          </div>
          {message?.message && (
            <LActText
              text={message?.message}
              className={`${
                message?.type === 'success' ? 'text-green' : 'text-error'
              } mt-2 ml-3`}
              textFormatType={EnumTextFormatType.FONT_SIZE_12}
            />
          )}

          {/* Buttons container */}
          <LActRow className="mt-16">
            <div className={'desktop:w-1/4 mobile:w-1/2 mobile:mr-2'}>
              <LActButton
                testID={'register-button'}
                type={EnumButtonType.TEXT}
                onClick={onLaterButtonClicked}
                textStyle={'text-tint font-bold'}
                actionType={EnumButtonActionType.BUTTON}
                text={translate(`${i18n}.setupLaterButton`)}
              />
            </div>

            <div
              className={'desktop:w-3/4 desktop:ml-6 mobile:w-1/2 mobile:ml-2'}
            >
              <LActButton
                testID={'login-button'}
                onClick={onNextClicked}
                type={EnumButtonType.NORMAL}
                useLoading={PATCH_QUERY?.isLoading}
                text={translate(`${i18n}.nextButton`)}
                actionType={EnumButtonActionType.BUTTON}
              />
            </div>
          </LActRow>
        </div>
      </AuthScreenContainer>

      {showWelcome && (
        <div className={'h-screen w-screen absolute top-0 z-3000 flex flex-1'}>
          <WelcomeTemplate
            visible={showWelcome}
            onCloseClicked={() => setShowWelcome(false)}
          />
        </div>
      )}
    </>
  );
};

export default RegisterOrgContactInfoScreen;
