import React, { useEffect, useRef, useState } from 'react';
import { CloseSharp, CloudUploadOutline } from 'react-ionicons';
import { LActImage, LActText } from 'components/index';
import { colors } from 'constants/colors';
import { translate } from 'i18n';
import { EnumTextFormatType } from 'constants/enums';
import { useOfferCreationStorage } from 'utils';
import { IOfferCreationTypes } from 'constants/types';

interface ImageUploadProps {
  image?: string;
  accessToken?: string;
  organizationID?: number;
}

const ImageUpload = (props: ImageUploadProps): React.ReactElement => {
  const i18n = 'screens.profile';
  const { image } = props;
  const inputFileRef = useRef<any>(null);
  const maxFileSizeInBytes = 2 * 1024 * 1024;
  const [currentImage, setCurrentImage] = useState<any>(image);
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );

  useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
        });
      }, 3000);
    }
  }, [message.type]);

  useEffect(() => {
    if (currentImage) {
      const offer = {
        ...offerCreationStorage.offer,
        demoPic: currentImage,
      };

      offerCreationStorage.storeOffers(offer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage]);

  const onPickImageClicked = () => {
    inputFileRef?.current?.click();
  };

  const handleImagePickerChanges = (event: any) => {
    const selectedFile = event?.target?.files[0];

    if (selectedFile.type === 'image/webp') {
      setMessage({
        message: translate(`${i18n}.severMessages.unsupportedContentType`),
        type: 'error',
      });
    } else {
      if (selectedFile?.size <= maxFileSizeInBytes) {
        // The selected file is 2MB or less
        setMessage({
          message: '',
          type: '',
        });
        if (selectedFile) {
          const reader = new FileReader();
          reader.onload = () => {
            setCurrentImage(reader?.result);
          };

          reader.readAsDataURL(selectedFile);
          const offer = {
            ...offerCreationStorage.offer,
            picture: selectedFile,
          };

          offerCreationStorage.storeOffers(offer);
        }
      } else {
        setMessage({
          message: translate(`${i18n}.errorMessages.wrongImageSize`),
          type: 'error',
        });
      }
    }
  };

  const onCancelClicked = () => {
    setCurrentImage(undefined);

    const offer = {
      ...offerCreationStorage.offer,
      demoPic: undefined,
    };

    offerCreationStorage.storeOffers(offer);
  };

  return (
    <>
      <div className={'flex'} data-testid={'org-image'}>
        {currentImage ? (
          <>
            <LActImage
              source={currentImage}
              className={'w-full h-60 shadow-sm rounded-xl object-top'}
            />

            <div className={'self-end -mb-1.5 ml-1.5'}>
              <div
                className={
                  'cursor-pointer self-end -ml-5 bg-background rounded-full w-6 h-6 flex items-center justify-center shadow-md -mr-1'
                }
              >
                <CloseSharp
                  width="20px"
                  height="20px"
                  color={colors.warning}
                  onClick={onCancelClicked}
                />
              </div>
            </div>
          </>
        ) : (
          <div
            onClick={onPickImageClicked}
            className={
              'flex flex-col items-center w-full py-20 justify-center rounded-xl bg-white cursor-pointer'
            }
          >
            <CloudUploadOutline
              width="40px"
              height="40px"
              color={colors.palette.grey700}
            />

            <LActText
              className={'mt-1'}
              text={translate(`${i18n}.uploadImage`)}
              textColor={'text-neutral400 text-semibold '}
              textFormatType={EnumTextFormatType.FONT_SIZE_12}
            />

            <LActText
              className={'mt-1'}
              text={translate(`${i18n}.maxImageSize`)}
              textColor={'text-neutral400 text-regular '}
              textFormatType={EnumTextFormatType.FONT_SIZE_12}
            />
          </div>
        )}
        <div className="flex">
          {currentImage === image ? (
            <>
              <span
                className={
                  'cursor-pointer self-end -ml-12 rounded-full w-6 h-6 flex items-center justify-center'
                }
              >
                <input
                  id={'file'}
                  type={'file'}
                  accept="image/*"
                  ref={inputFileRef}
                  style={{ display: 'none' }}
                  onChange={handleImagePickerChanges}
                />
              </span>
            </>
          ) : (
            <div className="flex bg-error">
              <div
                className={
                  'cursor-pointer self-end -ml-8 bg-background rounded-full w-6 h-6 flex items-center justify-center shadow-md -mr-1'
                }
              >
                <CloseSharp
                  width="20px"
                  height="20px"
                  color={colors.warning}
                  onClick={onCancelClicked}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {message?.message && (
        <LActText
          text={message?.message}
          className={'font-semibold mt-2'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          textColor={message.type === 'error' ? 'text-error' : 'text-green'}
        />
      )}
    </>
  );
};

export default ImageUpload;
