import React, { useState } from 'react';
import { LActButton, LActCard, LActRow, LActTextInput } from 'components/index';
import {
  EnumErrorType,
  EnumButtonType,
  EnumLanguageType,
  EnumButtonActionType,
  EnumOfferLocationType,
} from 'constants/enums';
import { translate } from 'i18n';
import { ILanguageSwitchTypes, IOfferCreationTypes } from 'constants/types';
import {
  useLanguageSwitchStorage,
  useOfferCreationStorage,
  useSwitchTranslation,
} from 'utils';
import { LOCATION_OPTIONS } from './utils';

type Props = {
  lastStep: number;
  currentStep: number;
  onNextClicked: () => void;
};

const OfferCreationLocationTemplate = (props: Props): React.ReactElement => {
  const i18n = 'screens.offerCreation.location';
  useSwitchTranslation();
  const { lastStep, currentStep, onNextClicked } = props;

  // @ts-ignore
  const languageSwitchStorage: ILanguageSwitchTypes = useLanguageSwitchStorage(
    (store: ILanguageSwitchTypes | unknown) => store,
  );

  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );

  const [locationType, setLocationType] = useState<string>(
    offerCreationStorage?.offer?.locationType ?? EnumOfferLocationType.REMOTE,
  );
  const [city, setCity] = useState<string>(offerCreationStorage?.offer?.city);
  const [street, setStreet] = useState<string>(
    offerCreationStorage?.offer?.street,
  );
  const [zipCode, setZipCode] = useState<string>(
    offerCreationStorage?.offer?.zipCode,
  );

  const onItemClicked = (location: string) => {
    setLocationType(location);
  };

  const handleOnNextClicked = () => {
    const offer = {
      ...offerCreationStorage.offer,
      city: city,
      country: 'DE',
      street: street,
      zipCode: zipCode,
      locationType: locationType,
    };

    offerCreationStorage.storeOffers(offer);

    onNextClicked();
  };

  const BUTTON_IS_DISABLED =
    locationType && locationType === EnumOfferLocationType.REMOTE
      ? false
      : locationType &&
        locationType === EnumOfferLocationType.ONSITE &&
        city?.length > 0 &&
        street?.length > 0 &&
        zipCode?.length > 0
      ? false
      : locationType &&
        locationType === EnumOfferLocationType.PRIVATE &&
        city?.length > 0 &&
        zipCode?.length > 0
      ? false
      : true;

  return (
    <div>
      <LActCard className="overflow-scroll">
        <div className="flex flex-wrap justify-center justify-evenly items-center">
          {LOCATION_OPTIONS?.map((item, index) => {
            const BG_SELECTED =
              item?.name === locationType
                ? 'bg-tint text-white'
                : 'bg-white text-textSecondary';

            const TEXT_COLOR_SELECTED =
              item?.name === locationType
                ? 'text-white font-semibold'
                : 'text-tint';

            const title =
              languageSwitchStorage?.language === EnumLanguageType.GERMAN
                ? item?.titleDE
                : item?.titleEN;

            return (
              <span
                key={`category-badge-${index}`}
                onClick={() => onItemClicked(item.name)}
                className={`cursor-pointer px-3 py-2 mx-1 rounded-full mt-3 ${BG_SELECTED} ${TEXT_COLOR_SELECTED}`}
              >
                {title}
              </span>
            );
          })}
        </div>

        <div className={'flex flex-col items-center'}>
          {locationType !== EnumOfferLocationType.REMOTE && (
            <div className="mt-8 w-full">
              {locationType === EnumOfferLocationType.ONSITE && (
                <LActTextInput
                  value={street}
                  type={'text'}
                  maxLength={30}
                  focusBG="focus:bg-white"
                  containerStyle="mt-3 bg-white"
                  messageType={EnumErrorType.ERROR}
                  placeHolder={translate(`${i18n}.street`)}
                  onChange={value => setStreet(value)}
                  message={
                    street && !street?.length
                      ? translate(`${i18n}.emptyStreet`)
                      : undefined
                  }
                />
              )}

              <LActTextInput
                maxLength={7}
                type={'text'}
                value={zipCode}
                focusBG="focus:bg-white"
                containerStyle="mt-3 bg-white"
                messageType={EnumErrorType.ERROR}
                placeHolder={translate(`${i18n}.postCode`)}
                onChange={value => setZipCode(value)}
                message={
                  zipCode && !zipCode?.length
                    ? translate(`${i18n}.emptyZipCode`)
                    : undefined
                }
              />

              <LActTextInput
                type={'text'}
                value={city}
                maxLength={30}
                focusBG="focus:bg-white"
                containerStyle="mt-3 bg-white"
                messageType={EnumErrorType.ERROR}
                placeHolder={translate(`${i18n}.city`)}
                onChange={value => setCity(value)}
                message={
                  city && !city?.length
                    ? translate(`${i18n}.emptyCity`)
                    : undefined
                }
              />
            </div>
          )}
        </div>
      </LActCard>

      <div>
        <LActRow className={'relative w-full mt-4 bottom-1 w-2/6'}>
          <LActButton
            testID={'login-button'}
            textStyle={'text-tint'}
            onClick={() => undefined}
            type={EnumButtonType.TEXT}
            actionType={EnumButtonActionType.BUTTON}
            text={translate(`${i18n}.step`) + `  ${currentStep} / ${lastStep}`}
          />

          <LActButton
            testID={'login-button'}
            type={EnumButtonType.NORMAL}
            disabled={BUTTON_IS_DISABLED}
            onClick={handleOnNextClicked}
            text={translate(`${i18n}.nextButton`)}
            actionType={EnumButtonActionType.BUTTON}
          />
        </LActRow>
      </div>
    </div>
  );
};

export default OfferCreationLocationTemplate;
