import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ISeenNewApplicationsTypes } from 'constants/types';
import { APPLICATIONS } from './keys';
import { localStorageInitialization } from './utils';

const useSeenNewApplications = create(
  persist(
    set => ({
      ids: [],
      purgeAppliedOffers: () => set({ ids: [] }),
      storeAppliedOffers: (ids: Array<number>) =>
        set((state: ISeenNewApplicationsTypes) => ({ ids })),
    }),
    {
      name: APPLICATIONS.SEEN_NEW_APPLICATIONS,
      storage: localStorageInitialization(),
    },
  ),
);

export default useSeenNewApplications;
