import { useEffect, useState } from 'react';
import { usePatchOrganizationDetails } from 'api/endpoints/queries';
import { IAccessTokenTypes, IUserInfoTypes } from 'constants/types';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { useAccessTokenStorage, useUserStorage } from 'utils';
import { translate } from 'i18n';

interface Props {
  name: string;
  url: string;
  description: string;
}

const usePatchProfileDetails = (props: Props) => {
  const i18n = 'screens.profile';
  const { name, url, description } = props;
  const [message, setMessage] = useState({
    message: '',
    type: 'error',
  });

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  const PATCH_QUERY = usePatchOrganizationDetails({
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });

  const onPatchData = () => {
    PATCH_QUERY?.mutateAsync({
      organizationId: userStorage?.user?.organizationId.toString(),
      data: {
        name: name ?? '',
        url: url ?? '',
        description: description ?? '',
      },
    })
      .then(data => {
        setMessage({
          message: translate(`${i18n}.successUpdateMessage`),
          type: 'success',
        });
      })
      .catch(error => {
        if (error?.message?.includes('500')) {
          setMessage({
            message: translate(`${i18n}.unsuccessfulUpdateMessage`),
            type: 'error',
          });
        }
        setMessage({
          message: translate(`${i18n}.unsuccessfulUpdateMessage`),
          type: 'error',
        });
      });
  };

  useEffect(() => {
    if (PATCH_QUERY?.error?.message?.includes('401')) {
      onPatchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PATCH_QUERY?.error]);

  return {
    responseType: message.type,
    submitChanges: onPatchData,
    responseMessage: message.message,
    isLoading: PATCH_QUERY.isLoading,
  };
};

export default usePatchProfileDetails;
