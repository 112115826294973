import React from 'react';
import { translate } from 'i18n';
import { LActCard, LActText, LActImage } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import ICON_EYE from 'assets/images/icon-eye.png';
import ICON_HEART from 'assets/images/icon-heart.png';
import ICON_MOBILE from 'assets/images/icon-mobile.png';
import ICON_PRAYING_HANDS from 'assets/images/icon-praying.hands.png';

const ApplicationsEmptyState = (): React.ReactElement => {
  const i18n = 'screens.home.applications.emptyState';

  const InfoTextTemplate = (): React.ReactElement => {
    return (
      <>
        <LActText
          text={translate(`${i18n}.title`)}
          className="font-semibold text-xl text-textSecondary text-center"
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
        />
        <LActText
          text={translate(`${i18n}.description`)}
          className="text-sm text-neutral400 text-center"
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
        />
      </>
    );
  };

  const ICON_MOBILE_STYLE = `desktop:ml-4 
      desktop:mt-16 
      desktop:pt-8 
      desktop:mb-8 
      mobile:flex 
      mobile:flex-1 
      mobile:mt
      float-end 
      mobile:-mr-6 
      desktop:h-full
      desktop:w-full
      mobile:overflow-hidden 
      mobile:box-decoration-slice 
      mobile:box-decoration-clone`;

  return (
    <LActCard className={'flex mt-6 desktop:pb-4 mobile:pb-0'}>
      <div className={'desktop:flex flex-1 desktop:flex-row mobile:flex-col'}>
        <LActImage
          source={ICON_HEART}
          className={
            'desktop:ml-9 desktop:mt-8 mobile:-ml-5 mobile:-mt-8 h-full'
          }
        />

        {/* Mobile info text */}
        <div
          className={
            'desktop:flex-none desktop:hidden mobile:flex-1 items-center'
          }
        >
          <InfoTextTemplate />
        </div>

        <div className="mobile:h-24 mobile:w-full mobile:overflow-hidden mobile:rounded-lg desktop:h-full mobile:float-end">
          <LActImage source={ICON_MOBILE} className={ICON_MOBILE_STYLE} />
        </div>

        {/* Desktop info text */}
        <div
          className={
            'mobile:flex-none mobile:hidden desktop:flex desktop:flex-col desktop: justify-center desktop:ml-10 '
          }
        >
          <InfoTextTemplate />
        </div>

        <LActImage
          source={ICON_EYE}
          className={
            'desktop:flex-1 mobile:hidden mobile:flex-none desktop:flex h-28 ml-6'
          }
        />

        <LActImage
          source={ICON_PRAYING_HANDS}
          className={
            'desktop:flex-1 mobile:hidden mobile:flex-none desktop:flex h-24 ml-6 mt-32 desktop:ml-14'
          }
        />
      </div>
    </LActCard>
  );
};

export default ApplicationsEmptyState;
