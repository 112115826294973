import React from 'react';
import { LActRow, LActText } from 'components/index';
import { TimeOutline } from 'react-ionicons';
import { colors } from 'constants/colors';
import { EnumTextFormatType } from 'constants/enums';
import { translate } from 'i18n';

const VerificationInProgressComponent = (): React.ReactElement => {
  const i18n = 'screens.home.verification';

  return (
    <div className={'rounded-lg p-4 relative'}>
      <div className="bg-orange-opacity-10 absolute inset-0 rounded-xl" />
      <LActRow className={'opacity-100 relative z-10'}>
        <TimeOutline color={colors.orange} height="24px" width="24px" />

        <div className="ml-4">
          <LActText
            text={translate(`${i18n}.title`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_16}
            className="font-semibold text-xl text-orange"
          />
          <LActText
            numberOfLines="line-clamp-6"
            className={'text-sm text-orange'}
            text={translate(`${i18n}.description`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_16}
          />
        </div>
      </LActRow>
    </div>
  );
};

export default VerificationInProgressComponent;
