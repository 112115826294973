import React from 'react';
import { useNavigate } from 'react-router-dom';
import { translate } from 'i18n';
import {
  CogOutline,
  RocketOutline,
  LockClosedOutline,
  HelpCircleOutline,
} from 'react-ionicons';
import { EnumButtonType } from 'constants/enums';
import { LActButton, LActActionButton } from 'components/index';
import { LINK_DATA_PRIVACY, LINK_SUPPORT_HELP } from 'constants/constants';
import { linkToURL } from 'utils/global/functions';
import { colors } from 'constants/colors';
import { MANAGEMENT_SCREEN, PROFILE_SCREEN } from 'constants/route';
import { IApplicationStorageTypes, IUserInfoTypes } from 'constants/types';
import { useApplicationStorage, useUserStorage } from 'utils';

interface HeaderSettingsItemsProps {
  ref?: any;
  className?: string;
  isLoggingOut?: boolean;
  onLogoutClicked: () => void;
}

const HeaderSettingsItems = (
  props: HeaderSettingsItemsProps,
): React.ReactElement => {
  const i18n = 'templates.header';
  const navigate = useNavigate();
  const { ref, className, isLoggingOut, onLogoutClicked } = props;

  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  const onProfileButtonClicked = () => {
    applicationStorage?.purgeApplication();
    navigate(PROFILE_SCREEN);
  };

  return (
    <div
      ref={ref}
      data-testid={'header-settings-items'}
      className={`p-4 rounded-3xl shadow-xl bg-white ${className}`}
    >
      <LActActionButton
        leftIcon={
          <CogOutline
            width="24px"
            height="24px"
            color={colors.palette.grey700}
          />
        }
        onClick={onProfileButtonClicked}
        title={translate(`${i18n}.profile`)}
      />

      <LActActionButton
        leftIcon={
          <LockClosedOutline
            width="24px"
            height="24px"
            color={colors.palette.grey700}
          />
        }
        title={translate(`${i18n}.dataPrivacy`)}
        onClick={() => linkToURL(LINK_DATA_PRIVACY)}
      />

      <LActActionButton
        leftIcon={
          <HelpCircleOutline
            width="24px"
            height="24px"
            color={colors.palette.grey700}
          />
        }
        title={translate(`${i18n}.supportHelp`)}
        onClick={() => linkToURL(LINK_SUPPORT_HELP)}
      />

      {userStorage &&
        userStorage?.user?.email === process.env.REACT_APP_LETSACT_USER && (
          <LActActionButton
            leftIcon={
              <RocketOutline color={colors.orange} width="24px" height="24px" />
            }
            textColor={'text-orange'}
            title={translate(`${i18n}.management`)}
            onClick={() => navigate(MANAGEMENT_SCREEN)}
          />
        )}

      <LActButton
        testID="logout-button"
        textStyle={'font-bold'}
        onClick={onLogoutClicked}
        useLoading={isLoggingOut}
        type={EnumButtonType.OUTLINE}
        text={translate(`${i18n}.logout`)}
        containerStyle={'bg-neutral600 border-neutral600 text-sm mt-3'}
      />
    </div>
  );
};

export default HeaderSettingsItems;
