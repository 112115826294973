import * as yup from 'yup';
import { TxKeyPath, translate } from 'i18n';

const i18n = 'screens.resetPassword';

export const useValidationShapeSchema = () => {
  const ForgetPasswordValidationSchema = yup.object().shape({
    email: yup
      .string()
      .lowercase()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        {
          message: `errorMessage.invalidEmail` as TxKeyPath,
        },
      )
      .required(translate(`${i18n}.errorMessage.invalidEmail`)),
  });

  return {
    ForgetPasswordValidationSchema: ForgetPasswordValidationSchema,
  };
};
