import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAccessTokenTypes } from 'constants/types';
import { useAccessTokenStorage } from 'utils';
import { HOME_SCREEN, LOGIN_SCREEN } from 'constants/route';

const AccessTokenCheckProvider = (): React.ReactElement => {
  const navigate = useNavigate();
  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  useEffect(() => {
    if (!tokenStorage?.token?.access_token) {
      navigate(LOGIN_SCREEN, {
        replace: true,
      });
    } else {
      navigate(HOME_SCREEN, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default AccessTokenCheckProvider;
