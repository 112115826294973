/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useGetApiV1Profile, useGetApiV1UsersMe } from 'api/endpoints/queries';
import {
  IUserInfoTypes,
  IAccessTokenTypes,
  IOrganizationInfoTypes,
} from 'constants/types';
import useUserStorage from 'utils/storage/useUserStorage';
import useAccessTokenStorage from 'utils/storage/useAccessTokenStorage';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import useOrganizationStorage from 'utils/storage/useOrganizationStorage';

const useGetUserInfo = () => {
  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );
  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  const API_QUERY = useGetApiV1UsersMe({
    query: {
      enabled: !!tokenStorage?.token?.access_token,
    },
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });

  const ORGANIZATION_API_QUERY = useGetApiV1Profile(
    userStorage?.user?.organizationId,
    {
      query: {
        refetchOnMount: false,
        enabled: !!API_QUERY?.data,
        refetchOnWindowFocus: false,
      },
      ...parseBearerToken(
        tokenStorage?.token?.access_token
          ? decryptData(tokenStorage?.token?.access_token)
          : '',
      ),
    },
  );

  useEffect(() => {
    if (API_QUERY?.data && API_QUERY?.isSuccess) {
      // @ts-ignore
      userStorage?.storeUserInfo(API_QUERY?.data?.item as object);
    }
  }, [API_QUERY?.data, API_QUERY?.isSuccess]);

  useEffect(() => {
    if (ORGANIZATION_API_QUERY?.data && ORGANIZATION_API_QUERY?.isSuccess) {
      organizationStorage?.storeOrgInfo(ORGANIZATION_API_QUERY?.data?.item);
    }
  }, [ORGANIZATION_API_QUERY?.data, ORGANIZATION_API_QUERY?.isSuccess]);
};

export default useGetUserInfo;
