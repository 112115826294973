import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const OfferDetailsDeepLinkScreen = (): React.ReactElement => {
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const id = pathParts[pathParts.length - 1];

    const getStoreURL = () => {
      const platform = navigator.platform.toLowerCase();
      if (
        platform.includes('iphone') ||
        platform.includes('ipad') ||
        platform.includes('mac')
      ) {
        return 'itms-apps://apps.apple.com/de/app/letsact/id1420686606';
      } else if (platform.includes('Android')) {
        return 'market://details?id=com.letsactmobile';
      } else {
        return 'market://details?id=com.letsactmobile';
      }
    };

    if (id) {
      const url = `letsact://open?offer_id=${id}`;
      window.location.href = url;

      setTimeout(() => {
        const storeURL = getStoreURL();
        window.location.href = storeURL;
      }, 2000);
    }
  }, [location.pathname]);

  return (
    <div>
      <iframe
        src="https://letsact.de/"
        title="https://letsact.de/"
        className="w-full h-screen"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      />
    </div>
  );
};

export default OfferDetailsDeepLinkScreen;
