import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LActImage, LActRow } from 'components/index';
import { HOME_SCREEN, LOGIN_SCREEN } from 'constants/route';
import {
  IAccessTokenTypes,
  IApplicationStorageTypes,
  IOfferCreationTypes,
  IOrganizationInfoTypes,
  IUserInfoTypes,
} from 'constants/types';
import {
  useAccessTokenStorage,
  useApplicationStorage,
  useOfferCreationStorage,
  useOrganizationStorage,
  useUserStorage,
} from 'utils';
import { delay } from 'utils/global/functions';
import HeaderSettingsTemplate from '.';
import HeaderSettingsItems from './header.settings.items';
import HeaderLanguageSwitchTemplate from './language';
import HeaderLanguageSwitchItemsTemplate from './language/header.language.items';
import ICON from 'assets/images/icon.png';

interface HeaderTemplateProps {
  toggleDropdowns: boolean;
}

const HeaderTemplate = (props: HeaderTemplateProps): React.ReactElement => {
  const navigate = useNavigate();
  const { toggleDropdowns } = props;
  const settingsRef = useRef<any>(null);
  const languageRef = useRef<any>(null);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showLanguageOptions, setShowLanguageOptions] =
    useState<boolean>(false);

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );
  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  // @ts-ignore
  const offerCreationStorage: IOfferCreationTypes = useOfferCreationStorage(
    (store: IOfferCreationTypes | unknown) => store,
  );

  const handleOutsideClick = (event: any) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target)) {
      // Click occurred outside of the settings, so hide it
      setShowSettings(false);
    } else if (
      languageRef.current &&
      !languageRef.current.contains(event.target)
    ) {
      // Click occurred outside of the settings, so hide it
      setShowLanguageOptions(false);
    }
  };

  useEffect(() => {
    if (showSettings) {
      // Attach the click event listener when the settings are shown
      document.addEventListener('click', handleOutsideClick);
    } else if (showLanguageOptions) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      // Remove the click event listener when the settings are hidden
      document.removeEventListener('click', handleOutsideClick);
    }

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showSettings, showLanguageOptions]);

  useEffect(() => {
    if (showSettings) {
      setShowSettings(false);
    } else if (showLanguageOptions) {
      setShowLanguageOptions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleDropdowns]);

  const onToggleDropDowns = () => {
    if (showSettings) {
      setShowSettings(false);
    } else if (showLanguageOptions) {
      setShowLanguageOptions(false);
    }
  };

  const onLogoClicked = () => {
    navigate(HOME_SCREEN, {
      replace: true,
    });
  };

  const onLogoutClicked = () => {
    setIsLoggingOut(true);
    delay(2000)
      .then(() => {
        userStorage.purgeUserInfo();
        tokenStorage.purgeAccessToken();
        organizationStorage?.purgeOrgInfo();
        offerCreationStorage?.purgeOffers();
        applicationStorage?.purgeApplication();
        setIsLoggingOut(false);
        navigate(LOGIN_SCREEN, {
          replace: true,
        });
      })
      .catch(error => {
        setIsLoggingOut(false);
      });
  };

  const onLanguageSwitchClicked = () => {
    if (showSettings) {
      setShowSettings(false);
    }
    setShowLanguageOptions(!showLanguageOptions);
  };

  const onSettingsClicked = () => {
    if (showLanguageOptions) {
      setShowLanguageOptions(false);
    }
    setShowSettings(!showSettings);
  };

  return (
    <>
      <LActRow
        spaceBetween
        onClick={onToggleDropDowns}
        className={'py-12 items-center'}
      >
        <div>
          <LActImage
            onClick={onLogoClicked}
            source={ICON}
            className={'w-w72 h-14 object-contain cursor-pointer'}
          />
        </div>

        <div className="flex flex-row items-center">
          <HeaderLanguageSwitchTemplate
            className={'mr-3'}
            onClick={onLanguageSwitchClicked}
          />

          <HeaderSettingsTemplate onClick={onSettingsClicked} />
        </div>
      </LActRow>

      {showSettings && (
        <HeaderSettingsItems
          ref={settingsRef}
          isLoggingOut={isLoggingOut}
          onLogoutClicked={onLogoutClicked}
          className={
            'absolute w-72 desktop:right-40 mobile:right-6 -mt-12 z-50 bg-white'
          }
        />
      )}

      {showLanguageOptions && (
        <HeaderLanguageSwitchItemsTemplate
          ref={languageRef}
          className={
            'absolute w-48 desktop:mr-2 desktop:right-60 desktop:-mt-14 mobile:right-20 mobile:mr-8 mobile:-mt-12'
          }
        />
      )}
    </>
  );
};

export default HeaderTemplate;
