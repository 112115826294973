import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate } from 'i18n';
import { Offer } from 'api/schemas/offer';
import { EnumOffersFilterType, EnumTextFormatType } from 'constants/enums';
import { LActText, OfferTemplate } from 'components/index';
import OffersTabs from './offers.tabs';
import { useSwitchTranslation } from 'utils';
import { OFFERS_DETAILS_SCREEN } from 'constants/route';

interface OffersTemplateProps {
  offers: Array<Offer>;
}

const OffersTemplate = (props: OffersTemplateProps): React.ReactElement => {
  const i18n = 'screens.home.offers';
  const { offers } = props;
  const navigate = useNavigate();
  useSwitchTranslation();
  const allOffers = useRef(offers);
  const [filteredItems, setFilteredItems] = useState<Array<Offer>>(
    allOffers?.current,
  );

  const filterItems = (query: string) => {
    if (query === EnumOffersFilterType.ALL) {
      return setFilteredItems(allOffers?.current);
    } else if (query === EnumOffersFilterType.PUBLISHED) {
      const active = offers.filter(
        item => item.status === EnumOffersFilterType.PUBLISHED,
      );
      return setFilteredItems(active);
    } else {
      const inActive = offers.filter(
        item => item.status !== EnumOffersFilterType.PUBLISHED,
      );
      return setFilteredItems(inActive);
    }
  };

  const onActiveOfferClicked = (item: Offer) => {
    if (!item?.status) return;
    else {
      navigate(OFFERS_DETAILS_SCREEN, {
        state: {
          item: item,
        },
      });
    }
  };

  let BADGE_STATUS = '';
  let BADGE_TEXT_COLOR = '';
  let BADGE_BACKGROUND_COLOR = '';

  const handleBadgeStyle = (status: string) => {
    switch (status) {
      case EnumOffersFilterType.PUBLISHED:
        BADGE_TEXT_COLOR = 'text-green';
        BADGE_BACKGROUND_COLOR = 'bg-greenLight';
        BADGE_STATUS = translate(`${i18n}.status.published`);
        break;

      case EnumOffersFilterType.EXPIRED:
        BADGE_TEXT_COLOR = 'text-error';
        BADGE_BACKGROUND_COLOR = 'bg-pink';
        BADGE_STATUS = translate(`${i18n}.status.expired`);
        break;

      case EnumOffersFilterType.FILLED:
        BADGE_TEXT_COLOR = 'text-textSecondary';
        BADGE_BACKGROUND_COLOR = 'bg-skyBlue';
        BADGE_STATUS = translate(`${i18n}.status.filled`);
        break;

      case EnumOffersFilterType.PAUSED:
        BADGE_TEXT_COLOR = 'text-textSecondary';
        BADGE_BACKGROUND_COLOR = 'bg-neutral600';
        BADGE_STATUS = translate(`${i18n}.status.paused`);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <OffersTabs className={'mt-6'} onClick={query => filterItems(query)} />

      {filteredItems?.length > 0 ? (
        <div
          className={
            'mt-6 desktop:grid desktop:grid-rows-2 desktop:gap-14 desktop:grid-container desktop:grid-cols-3'
          }
        >
          <>
            {filteredItems.map((item, index) => {
              handleBadgeStyle(item?.status);
              return (
                <OfferTemplate
                  item={item}
                  status={BADGE_STATUS}
                  key={`Offer-Items-${index}`}
                  badgeTextColor={BADGE_TEXT_COLOR}
                  badgeBGColor={BADGE_BACKGROUND_COLOR}
                  onClick={() => onActiveOfferClicked(item)}
                  badgeClassName={`font-bold`}
                  className={`
                   delay-100 
                   transform
                   transition 
                   ease-in-out 
                   mobile:mt-6 
                   duration-300 
                   desktop:mt-0 
                   cursor-pointer
                   hover:scale-105
                   hover:shadow-md
                   hover:-translate-y-1`}
                />
              );
            })}
          </>
        </div>
      ) : (
        <div className="flex w-full py-6 justify-center">
          <LActText
            testID="applications-title"
            className={'font-semibold'}
            textColor={'text-neutral400'}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
            text={translate(`${i18n}.emptyState.noFilterResult`)}
          />
        </div>
      )}
    </div>
  );
};

export default OffersTemplate;
