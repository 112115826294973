import React, { useEffect, useState } from 'react';
import Lodash from 'lodash';
import {
  useRefreshToken,
  useSwitchTranslation,
  useLanguageSwitchStorage,
} from 'utils';
import { ILanguageSwitchTypes } from 'constants/types';
import {
  EnumErrorType,
  EnumButtonType,
  EnumLanguageType,
  EnumTextFormatType,
  EnumButtonActionType,
  EnumOfferLocationType,
} from 'constants/enums';
import { LOCATION_OPTIONS, OfferDetailsLocationSchema } from './utils';
import { LActRow, LActText, LActButton, LActTextInput } from 'components/index';
import { translate } from 'i18n';
import { useFormik } from 'formik';
import { usePatchOfferDetails } from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { Offer } from 'api/schemas/offer';

interface LocationComponentProps {
  city: string;
  street: string;
  zipCode: string;
  offerID: number;
  accessToken: string;
  locationType: string;
  onItemUpdated: (item: Offer) => void;
}

const LocationComponent = (
  props: LocationComponentProps,
): React.ReactElement => {
  const i18n = 'screens.home.offers.details';
  const {
    city,
    street,
    zipCode,
    offerID,
    accessToken,
    locationType,
    onItemUpdated,
  } = props;
  useSwitchTranslation();
  const [selectedItem, setSelectedItem] = useState<string>(locationType);
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  const formik = useFormik({
    initialValues: {
      city,
      street,
      zipCode,
    },
    validateOnBlur: true,
    validationSchema: OfferDetailsLocationSchema,
    onSubmit: () => {
      onSaveClicked();
    },
  });

  // @ts-ignore
  const languageSwitchStorage: ILanguageSwitchTypes = useLanguageSwitchStorage(
    (store: ILanguageSwitchTypes | unknown) => store,
  );

  const PATCH_QUERY = usePatchOfferDetails({
    ...parseBearerToken(accessToken ? decryptData(accessToken) : ''),
  });

  useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
        });
      }, 3000);
    }
  }, [message.type]);

  const onItemClicked = (location: string) => {
    setSelectedItem(location);
  };

  const patchQuery = () => {
    PATCH_QUERY?.mutateAsync({
      offerId: offerID?.toString(),
      data: {
        city: formik.values?.city,
        street: formik.values?.street,
        zipCode: formik.values.zipCode,
        locationType: selectedItem,
      },
    })
      .then((data: any) => {
        onItemUpdated(data?.item);
        setMessage({
          message: translate(`${i18n}.severMessages.successfullyUpdated`),
          type: 'success',
        });
      })
      .catch(error => {
        if (error?.message?.includes('500')) {
          setMessage({
            message: translate(`${i18n}.severMessages.serverError500`),
            type: 'error',
          });
        }
        setMessage({
          message: translate(`${i18n}.severMessages.serverGeneralError`),
          type: 'error',
        });
      });
  };

  const onSaveClicked = () => {
    if (selectedItem === EnumOfferLocationType.ONSITE) {
      if (
        !formik.values.city?.length ||
        !formik.values.street?.length ||
        !formik.values.zipCode?.length
      ) {
        return;
      } else {
        patchQuery();
      }
    } else if (selectedItem === EnumOfferLocationType.PRIVATE) {
      if (
        formik.values.city?.length === 0 ||
        formik.values.zipCode?.length === 0
      ) {
        return;
      } else {
        patchQuery();
      }
    } else {
      patchQuery();
    }
  };
  useRefreshToken({
    error: PATCH_QUERY?.error,
    refetch: onSaveClicked,
    hasError: PATCH_QUERY?.isError,
  });

  const CURRENT_STATE_AVAILABILITY = {
    city: formik.values?.city,
    street: formik.values?.street,
    zipCode: formik.values.zipCode,
    locationType: selectedItem,
  };

  const ORIGINAL_RESPONSE = {
    city,
    street,
    zipCode,
    locationType,
  };

  const disabledSaveButton = Lodash.isEqual(
    CURRENT_STATE_AVAILABILITY,
    ORIGINAL_RESPONSE,
  );

  return (
    <>
      <LActRow className={'items-center'} spaceBetween>
        <div>
          <LActText
            className="font-semibold"
            text={translate(`${i18n}.locationSection`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
          />
        </div>
        <div>
          <LActButton
            testID={'login-button'}
            onClick={onSaveClicked}
            type={EnumButtonType.TEXT}
            useLoading={PATCH_QUERY?.isLoading}
            text={translate(`${i18n}.saveButton`)}
            actionType={EnumButtonActionType.BUTTON}
            containerStyle={'items-end py-0 bg-transparent border-0'}
            textStyle={`text-tint ${disabledSaveButton && 'opacity-50'}`}
          />
        </div>
      </LActRow>

      <div className="flex flex-wrap justify-center justify-evenly items-center desktop:px-20">
        {LOCATION_OPTIONS?.map((item, index) => {
          const BG_SELECTED =
            item?.name === selectedItem
              ? 'bg-tint text-white'
              : 'bg-white text-textSecondary';

          const TEXT_COLOR_SELECTED =
            item?.name === selectedItem
              ? 'text-white font-semibold'
              : 'text-tint';

          const title =
            languageSwitchStorage?.language === EnumLanguageType.GERMAN
              ? item?.titleDE
              : item?.titleEN;

          return (
            <span
              key={`category-badge-${index}`}
              onClick={() => onItemClicked(item.name)}
              className={`cursor-pointer px-3 py-2 mx-1 rounded-full mt-3 ${BG_SELECTED} ${TEXT_COLOR_SELECTED}`}
            >
              {title}
            </span>
          );
        })}
      </div>

      <div className={'flex flex-col items-center'}>
        {selectedItem !== EnumOfferLocationType.REMOTE && (
          <div className="mt-8 w-1/3">
            {selectedItem === EnumOfferLocationType.ONSITE && (
              <LActTextInput
                value={street}
                type={'text'}
                maxLength={30}
                focusBG="focus:bg-white"
                containerStyle="mt-3 bg-white"
                messageType={EnumErrorType.ERROR}
                placeHolder={translate(`${i18n}.location.street`)}
                onChange={value => formik.setFieldValue('street', value)}
                message={
                  formik.errors.street && !formik.values.street?.length
                    ? translate(`${i18n}.errorMessages.emptyStreet`)
                    : undefined
                }
              />
            )}

            <LActTextInput
              maxLength={7}
              type={'text'}
              value={zipCode}
              focusBG="focus:bg-white"
              containerStyle="mt-3 bg-white"
              messageType={EnumErrorType.ERROR}
              placeHolder={translate(`${i18n}.location.postCode`)}
              onChange={value => formik.setFieldValue('zipCode', value)}
              message={
                formik.errors.zipCode && !formik.values.zipCode?.length
                  ? translate(`${i18n}.errorMessages.emptyZipCode`)
                  : undefined
              }
            />

            <LActTextInput
              type={'text'}
              value={city}
              maxLength={30}
              focusBG="focus:bg-white"
              containerStyle="mt-3 bg-white"
              messageType={EnumErrorType.ERROR}
              placeHolder={translate(`${i18n}.location.city`)}
              onChange={value => formik.setFieldValue('city', value)}
              message={
                formik.errors.city && !formik.values.city?.length
                  ? translate(`${i18n}.errorMessages.emptyCity`)
                  : undefined
              }
            />
          </div>
        )}
      </div>

      {message?.message && (
        <LActText
          text={message?.message}
          className={'font-semibold mt-6'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          textColor={message.type === 'error' ? 'text-error' : 'text-green'}
        />
      )}
    </>
  );
};

export default LocationComponent;
