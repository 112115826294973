import React, { useState } from 'react';
import { useCheckPlatform } from 'utils';
import { LActModal } from 'components/index';
import NotifyMobileViewItemsTemplate from './notify.mobileview';
import NotifyDesktopViewItemsTemplate from './notify.desktopview';

interface ApplicationSuccessModalNotifyTemplateProps {
  visible: boolean;
  userName: string;
  onclose: () => void;
}

const ApplicationSuccessModalNotifyTemplate = (
  props: ApplicationSuccessModalNotifyTemplateProps,
): React.ReactElement => {
  const { visible, onclose, userName } = props;
  const PLATFORM = useCheckPlatform();
  const [mobileViewVisible, setMobileViewVisible] = useState<boolean>(visible);

  const onCloseBottomSheet = () => {
    setMobileViewVisible(false);
    onclose();
  };

  return (
    <>
      {PLATFORM?.isMobileView && mobileViewVisible ? (
        <div
          onClick={onCloseBottomSheet}
          className={
            'bg-dropLayer h-screen h-screen w-screen absolute top-0 left-0 z-3000 flex flex-1'
          }
        >
          <NotifyMobileViewItemsTemplate
            userName={userName}
            onClose={onCloseBottomSheet}
          />
        </div>
      ) : (
        <LActModal
          showsExitButton
          visible={visible}
          onDismiss={onclose}
          children={<NotifyDesktopViewItemsTemplate userName={userName} />}
        />
      )}
    </>
  );
};

export default ApplicationSuccessModalNotifyTemplate;
