import React from 'react';
import { ChevronForwardOutline } from 'react-ionicons';
import { LActRow, LActText } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import { colors } from 'constants/colors';

interface BreadcrumbProps {
  routes: Array<string>;
}

const Breadcrumb = (pros: BreadcrumbProps): React.ReactElement => {
  const { routes } = pros;

  return (
    <LActRow>
      <span className={'flex'}>
        {routes.map((route: string, index: number) => {
          return (
            <LActRow className={'items-center'} key={`Breadcrumb-${index}`}>
              <LActText
                text={route}
                key={`Routes-${index + 1}`}
                textColor={'text-neutral400'}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
              />

              <span className={'mx-2'}>
                {route !== routes[routes?.length - 1] && (
                  <ChevronForwardOutline
                    width="14px"
                    height="14px"
                    color={colors.palette.grey300}
                  />
                )}
              </span>
            </LActRow>
          );
        })}
      </span>
    </LActRow>
  );
};

export default Breadcrumb;
