import React from 'react';

interface LActCardProps {
  className?: string;
  onClick?: () => void;
  onHover?: () => void;
  children: React.ReactNode;
}

export const LActCard = (props: LActCardProps): React.ReactElement => {
  const { onClick, onHover, children, className } = props;
  return (
    <div
      onClick={onClick}
      onMouseOver={onHover}
      className={`flex-1 mobile:px-4 mobile:py-4 desktop:pt-4 rounded-3xl bg-background mobile:w-full ${className}`}
    >
      <div>{children}</div>
    </div>
  );
};

export default LActCard;
