import React, { memo, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { LActText, LActButton, LActTextInput } from 'components/index';
import {
  EnumErrorType,
  EnumAlertType,
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
} from 'constants/enums';
import { AccessToken } from 'api/schemas';
import { usePostApiV1AuthPasswordReset } from 'api/endpoints/queries';
import { responseHandler } from 'utils/global/functions';
import { useSwitchTranslation } from 'utils';
import { translate } from 'i18n';
import AuthScreenContainer from '../container';
import { useValidationShapeSchema } from './utils';
import ResetPasswordCheckInboxScreen from './resetPassword.checkInbox';

const ResetPasswordScreen = (): React.ReactElement => {
  const i18n = 'screens.resetPassword';
  useSwitchTranslation();
  const navigate = useNavigate();

  const [inputMessage, setInputMessage] = useState({
    message: '',
    inputType: '',
  });
  const [isWrongInputResponse, setIsWrongInputResponse] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rendersCheckInbox, setRendersCheckInbox] = useState<boolean>(false);

  /**
   * @prop
   * Used:
   *  - mutateAsync
   */
  const API_QUERY = usePostApiV1AuthPasswordReset();

  const { ForgetPasswordValidationSchema } = useValidationShapeSchema();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ForgetPasswordValidationSchema,
    onSubmit: ({ email }) => {
      onForgetPasswordClicked({ email });
    },
  });

  useEffect(() => {
    if (API_QUERY?.isSuccess) {
    }
  }, [API_QUERY?.isSuccess]);

  const onEmailChange = (value: string) => {
    if (isWrongInputResponse) {
      setIsWrongInputResponse(false);
    }
    formik.setFieldValue('email', value);
  };

  const onForgetPasswordClicked = ({ email }: { email: string }) => {
    setIsLoading(true);
    API_QUERY?.mutateAsync({
      data: {
        email: email.toLowerCase(),
        scope: 'organization',
      },
    })
      // @ts-ignore
      .then((response: AccessToken) => {
        setIsLoading(false);
        // navigateToCheckInboxScreen();
        setRendersCheckInbox(true);
      })
      .catch(error => {
        setIsLoading(false);
        if (error?.response) {
          if (error?.response?.status === 404) {
            // navigateToCheckInboxScreen();
            setRendersCheckInbox(true);
          } else {
            const errorMessage = responseHandler(error?.response?.status);
            setInputMessage({
              inputType: EnumAlertType.ERROR,
              message: errorMessage,
            });
          }
        }
      });
  };

  const onLoginButtonClicked = () => {
    navigate(-1);
  };

  const ENABLE_RESET_PASSWORD_BUTTON =
    formik?.values?.email?.length > 0 &&
    Object.keys(formik?.errors)?.length === 0
      ? false
      : true;

  const EMAIL_MESSAGE_TYPE =
    formik?.errors?.email && formik?.touched?.email
      ? EnumErrorType.ERROR
      : undefined;

  const EMAIL_LABEL_MESSAGE =
    inputMessage?.message?.length > 0
      ? inputMessage?.message
      : formik?.errors?.email && formik?.touched?.email
      ? formik?.errors?.email
      : undefined;

  return (
    <AuthScreenContainer
      style={`flex justify-center items-center mobile:mx-6 mobile:w-full flex-shrink-1`}
    >
      {rendersCheckInbox ? (
        <ResetPasswordCheckInboxScreen email={formik?.values?.email} />
      ) : (
        <div className="desktop:w-3/5 mobile:w-full">
          <LActText
            testID={'title-text'}
            text={translate(`${i18n}.title`)}
            className="text-textPrimary text-center"
            textFormatType={EnumTextFormatType.FONT_SIZE_40}
          />

          <LActText
            testID={'description-text'}
            numberOfLines={'line-clamp-3'}
            text={translate(`${i18n}.description`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_16}
            className="text-textSecondary text-center text-sm mt-2"
          />

          <LActTextInput
            type={'email'}
            maxLength={250}
            containerStyle="mt-6"
            testID={'email-input'}
            message={EMAIL_LABEL_MESSAGE}
            messageType={EMAIL_MESSAGE_TYPE}
            onBlur={formik.handleBlur('email')}
            onChange={value => onEmailChange(value)}
            placeHolder={translate(`${i18n}.placeHolder`)}
          />

          {/* Buttons container */}
          <div className="mt-16">
            <LActButton
              useLoading={isLoading}
              testID={'reset-button'}
              type={EnumButtonType.NORMAL}
              onClick={formik.handleSubmit}
              disabled={ENABLE_RESET_PASSWORD_BUTTON}
              text={translate(`${i18n}.resetPassword`)}
              actionType={EnumButtonActionType.BUTTON}
            />

            <LActButton
              testID={'login-button'}
              type={EnumButtonType.TEXT}
              onClick={onLoginButtonClicked}
              text={translate(`${i18n}.loginButton`)}
              actionType={EnumButtonActionType.BUTTON}
              textStyle={'text-tint text-base text-semibold text-regular'}
            />
          </div>
        </div>
      )}
    </AuthScreenContainer>
  );
};

export default memo(ResetPasswordScreen);
