import React from 'react';
import { useCheckPlatform } from 'utils';
import { LActModal } from 'components/index';
import WelcomeMobileViewItemsTemplate from './welcome.mobileview';
import WelcomeDesktopViewItemsTemplate from './welcome.desktopview';

interface WelcomeTemplateProps {
  visible: boolean;
  onCloseClicked: () => void;
}

const WelcomeTemplate = (props: WelcomeTemplateProps): React.ReactElement => {
  const { visible, onCloseClicked } = props;
  const PLATFORM = useCheckPlatform();

  return (
    <>
      {PLATFORM?.isMobileView && visible && (
        <div
          className={
            'bg-dropLayer h-screen h-screen w-screen absolute top-0 left-0 z-3000 flex flex-1'
          }
        >
          <WelcomeMobileViewItemsTemplate onClose={() => onCloseClicked()} />
        </div>
      )}

      {PLATFORM?.isDesktopView && (
        <LActModal
          showsExitButton
          visible={visible}
          onDismiss={() => undefined}
          children={
            <WelcomeDesktopViewItemsTemplate onClose={() => onCloseClicked()} />
          }
        />
      )}
    </>
  );
};

export default WelcomeTemplate;
