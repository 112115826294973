import { colors } from 'constants/colors';
import { EnumApplicationsType } from 'constants/enums';
import { IApplicationStatusTypes } from 'constants/types';
import { TxKeyPath, translate } from 'i18n';

const i18n = 'screens.home.sidebar';

export const PENDING_DROPDOWN_LIST = [
  {
    id: 1,
    bgColor: 'bg-skyBlue',
    titleColor: 'text-textSecondary',
    status: EnumApplicationsType.CONTACTED,
    title: 'dropdown.contacted' as TxKeyPath,
  },
  {
    id: 2,
    bgColor: 'bg-orangeLight',
    titleColor: 'text-orangeDark',
    status: EnumApplicationsType.IN_REVIEW,
    title: 'dropdown.inContact' as TxKeyPath,
  },
  {
    id: 3,
    bgColor: 'bg-greenLight',
    titleColor: 'text-green',
    status: EnumApplicationsType.ACCEPTED,
    title: 'dropdown.takingPart' as TxKeyPath,
  },
  {
    id: 4,
    bgColor: 'bg-pink',
    titleColor: 'text-error',
    status: EnumApplicationsType.REJECTED,
    title: 'dropdown.didntWork' as TxKeyPath,
  },
];

export const CONTACTED_DROPDOWN_LIST = [
  {
    id: 1,
    bgColor: 'bg-orangeLight',
    titleColor: 'text-orangeDark',
    status: EnumApplicationsType.IN_REVIEW,
    title: 'dropdown.inContact' as TxKeyPath,
  },
  {
    id: 2,
    bgColor: 'bg-greenLight',
    titleColor: 'text-green',
    status: EnumApplicationsType.ACCEPTED,
    title: `dropdown.takingPart` as TxKeyPath,
  },
  {
    id: 3,
    bgColor: 'bg-pink',
    titleColor: 'text-error',
    status: EnumApplicationsType.REJECTED,
    title: 'dropdown.didntWork' as TxKeyPath,
  },
];

export const IN_REVIEW_DROPDOWN_LIST = [
  {
    id: 1,
    bgColor: 'bg-greenLight',
    titleColor: 'text-green',
    status: EnumApplicationsType.ACCEPTED,
    title: 'dropdown.takingPart' as TxKeyPath,
  },
  {
    id: 2,
    bgColor: 'bg-pink',
    titleColor: 'text-error',
    status: EnumApplicationsType.REJECTED,
    title: 'dropdown.didntWork' as TxKeyPath,
  },
];

export const statusDropdownHandler = (
  applicationStatus: string | undefined,
) => {
  let DROP_DOWN_BG_COLOR: string = '';
  let DROPDOWN_ICON_COLOR: string = '';
  let DROP_DOWN_TITLE_TEXT: string = '';
  let DROP_DOWN_TITLE_COLOR: string = '';
  let DROPDOWN_SHOWS_ICON: boolean = false;
  let DROP_DOWN_CHILD_ITEMS: Array<IApplicationStatusTypes> = [];

  switch (applicationStatus) {
    case EnumApplicationsType.PENDING:
      DROPDOWN_SHOWS_ICON = true;
      DROP_DOWN_BG_COLOR = 'bg-neutral600';
      DROPDOWN_ICON_COLOR = colors.textSecondary;
      DROP_DOWN_TITLE_COLOR = 'text-textSecondary';
      DROP_DOWN_CHILD_ITEMS = PENDING_DROPDOWN_LIST;
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.open`);
      break;

    case EnumApplicationsType.CONTACTED:
      DROPDOWN_SHOWS_ICON = true;
      DROP_DOWN_BG_COLOR = 'bg-skyBlue';
      DROPDOWN_ICON_COLOR = colors.textSecondary;
      DROP_DOWN_TITLE_COLOR = 'text-textSecondary';
      DROP_DOWN_CHILD_ITEMS = CONTACTED_DROPDOWN_LIST;
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.contacted`);
      break;

    case EnumApplicationsType.IN_REVIEW:
      DROPDOWN_SHOWS_ICON = true;
      DROP_DOWN_BG_COLOR = 'bg-orangeLight';
      DROP_DOWN_TITLE_COLOR = 'text-orangeDark';
      DROPDOWN_ICON_COLOR = colors.palette.accent800;
      DROP_DOWN_CHILD_ITEMS = IN_REVIEW_DROPDOWN_LIST;
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.inContact`);
      break;

    case EnumApplicationsType.ACCEPTED:
      DROPDOWN_SHOWS_ICON = false;
      DROP_DOWN_CHILD_ITEMS = [];
      DROP_DOWN_BG_COLOR = 'bg-greenLight';
      DROP_DOWN_TITLE_COLOR = 'text-green';
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.takingPart`);
      break;

    case EnumApplicationsType.REJECTED:
      DROPDOWN_SHOWS_ICON = false;
      DROP_DOWN_CHILD_ITEMS = [];
      DROP_DOWN_BG_COLOR = 'bg-pink';
      DROP_DOWN_TITLE_COLOR = 'text-error';
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.didntWork`);
      break;

    default:
      DROP_DOWN_BG_COLOR = 'bg-neutral600';
      DROP_DOWN_TITLE_COLOR = 'text-textSecondary';
      DROP_DOWN_CHILD_ITEMS = PENDING_DROPDOWN_LIST;
      DROP_DOWN_TITLE_TEXT = translate(`${i18n}.dropdown.open`);
      break;
  }

  return {
    DROP_DOWN_BG_COLOR,
    DROPDOWN_SHOWS_ICON,
    DROPDOWN_ICON_COLOR,
    DROP_DOWN_TITLE_TEXT,
    DROP_DOWN_TITLE_COLOR,
    DROP_DOWN_CHILD_ITEMS,
  };
};
