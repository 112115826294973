import React, { useState } from 'react';
import { translate } from 'i18n';
import { useSwitchTranslation } from 'utils';
import { LActText } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import { TABS_ITEMS } from './utils';

interface OffersTabsProps {
  className?: string;
  onClick: (query: string) => void;
}

const OffersTabs = (props: OffersTabsProps): React.ReactElement => {
  const { onClick, className } = props;
  useSwitchTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);

  const onTabItemClicked = (query: string, activeItem: number) => {
    setActiveTab(activeItem);
    onClick(query);
  };

  return (
    <div className={`flex ${className}`}>
      {TABS_ITEMS.map((item, index) => {
        const ACTIVE_TAB_BG_COLOR =
          activeTab === index ? 'bg-textSecondary rounded-full' : '';
        const ACTIVE_TAB_TEXT_COLOR =
          activeTab === index ? 'text-background' : 'text-neutral400';

        return (
          <span
            data-testid={item.testID}
            key={`Offer-Tabs-${index}`}
            onClick={() => onTabItemClicked(item?.key, index)}
            className={`px-4 py-2 cursor-pointer ${ACTIVE_TAB_BG_COLOR}`}
          >
            <LActText
              className="font-semibold"
              text={translate(item.name)}
              textColor={ACTIVE_TAB_TEXT_COLOR}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
            />
          </span>
        );
      })}
    </div>
  );
};

export default OffersTabs;
