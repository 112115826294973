import React from 'react';
import { EyeOutline, EyeOffOutline } from 'react-ionicons';
import { CFormInput, CFormTextarea } from '@coreui/react';
import { EnumErrorType, EnumTextFormatType } from 'constants/enums';
import LActText from '../LActText';

interface LActTextInputProps {
  ref?: any;
  style?: string;
  value?: string;
  testID?: string;
  message?: string;
  maxLength?: number;
  editable?: boolean;
  multiLine?: boolean;
  isPassword?: boolean;
  placeHolder?: string;
  containerStyle?: string;
  onBlur?: (e: any) => void;
  hasFloatingLabel?: boolean;
  onIconClicked?: () => void;
  messageType?: EnumErrorType;
  multiLineRowsNumber?: number;
  rightIcon?: React.ReactElement;
  onChange: (value: string) => void;
  type:
    | 'tel'
    | 'url'
    | 'text'
    | 'date'
    | 'week'
    | 'time'
    | 'month'
    | 'email'
    | 'search'
    | 'number'
    | 'password'
    | 'datetime-local'
    | undefined;
  focusBG?: string;
}

const LActTextInput = (props: LActTextInputProps): React.ReactElement => {
  const {
    ref,
    type,
    style,
    value,
    testID,
    onBlur,
    message,
    focusBG,
    editable,
    onChange,
    rightIcon,
    multiLine,
    maxLength,
    isPassword,
    messageType,
    placeHolder,
    onIconClicked,
    containerStyle,
    hasFloatingLabel,
    multiLineRowsNumber,
  } = props;

  let messageTextColor: string = 'text-neutral400';
  switch (messageType) {
    case EnumErrorType.WARNING:
      messageTextColor = 'text-orange';
      break;
    case EnumErrorType.ERROR:
      messageTextColor = 'text-error';
      break;
    case EnumErrorType.SUCCESS:
      messageTextColor = 'text-green';
      break;
    default:
      messageTextColor = 'text-neutral400';
      break;
  }

  const focusBGColor = focusBG ?? 'focus:bg-neutral300';

  return (
    <div>
      <div>
        {multiLine ? (
          <CFormTextarea
            ref={ref}
            defaultValue={value}
            maxLength={maxLength}
            placeholder={placeHolder}
            rows={multiLineRowsNumber}
            onChange={e => onChange(e.target.value)}
            floatingLabel={hasFloatingLabel ? placeHolder : undefined}
            className={`bg-neutral300 rounded-lg mt-6 w-full h-40 px-3 py-4 placeholder:-translate-y-4 ${focusBGColor}  ${style} ${containerStyle}`}
          />
        ) : (
          <CFormInput
            ref={ref}
            type={type}
            onBlur={onBlur}
            disabled={editable}
            data-testid={testID}
            defaultValue={value}
            maxLength={maxLength}
            placeholder={placeHolder}
            floatingLabel={placeHolder}
            onChange={e => onChange(e.target.value)}
            className={`border-2 bg-neutral300 focus:border-primary ${focusBGColor}  ${style} ${containerStyle}`}
          />
        )}

        {isPassword && (
          <div className="absolute desktop:w-3/5 right-8 -mt-10">
            {type === 'password' ? (
              <EyeOutline color={'#777E90'} onClick={onIconClicked} />
            ) : (
              <EyeOffOutline color={'#777E90'} onClick={onIconClicked} />
            )}
          </div>
        )}

        {rightIcon && (
          <div className="absolute desktop:w-3/5 right-8 -mt-10">
            {rightIcon}
          </div>
        )}
      </div>

      {message && (
        <LActText
          text={message}
          textColor={messageTextColor}
          className={'text-regular mt-1 mx-3'}
          textFormatType={EnumTextFormatType.FONT_SIZE_12}
        />
      )}
    </div>
  );
};

export default LActTextInput;
