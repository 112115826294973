import * as yup from 'yup';
import { translate } from 'i18n';

const i18n = 'screens.register';

export const useRegisterValidationShapeSchema = () => {
  const RegisterValidationSchema = yup.object().shape({
    email: yup
      .string()
      .lowercase()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        {
          message: translate(`${i18n}.errorMessage.invalidEmail`),
        },
      )
      .required(translate(`${i18n}.errorMessage.invalidEmail`)),

    organizationName: yup
      .string()
      .min(3, () => translate(`${i18n}.errorMessage.invalidOrgName`))
      .required(translate(`${i18n}.errorMessage.invalidOrgName`)),

    organizationHomePageUrl: yup
      .string()
      .matches(/^https:\/\//i, {
        message: translate(`${i18n}.errorMessage.invalidHomePageUrl`),
      })
      .min(10, () => translate(`${i18n}.errorMessage.invalidHomePageUrlLength`))
      .required(translate(`${i18n}.errorMessage.invalidHomePageUrl`)),

    password: yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
        message: translate(`${i18n}.errorMessage.invalidPasswordMatch`),
      })
      .min(8, ({ min }) =>
        translate(`${i18n}.errorMessage.invalidPassword`, { min: min }),
      )
      .required(translate(`${i18n}.errorMessage.invalidPassword`)),
  });

  return {
    RegisterValidationSchema: RegisterValidationSchema,
  };
};
