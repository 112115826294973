import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileTrayOutline } from 'react-ionicons';
import { LActText, LActButton } from 'components/index';
import {
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
} from 'constants/enums';
import { colors } from 'constants/colors';
import { LOGIN_SCREEN } from 'constants/route';
import { useSwitchTranslation } from 'utils';
import { translate } from 'i18n';

interface ResetPasswordCheckInboxScreenProps {
  email: string;
}

const ResetPasswordCheckInboxScreen = (
  props: ResetPasswordCheckInboxScreenProps,
): React.ReactElement => {
  const i18n = 'screens.resetPasswordCheckInbox';
  const { email } = props;
  useSwitchTranslation();
  const navigate = useNavigate();

  const onLoginButtonClicked = () => {
    navigate(LOGIN_SCREEN, {
      replace: true,
    });
  };

  return (
    <div className="desktop:w-3/5 mobile:w-full">
      <div className={'flex justify-center'}>
        <FileTrayOutline width="96px" height="96px" color={colors.tint} />
      </div>

      <LActText
        numberOfLines="line-clamp-3"
        text={translate(`${i18n}.title`)}
        className="text-textPrimary text-center mt-6"
        textFormatType={EnumTextFormatType.FONT_SIZE_40}
      />

      <LActText
        numberOfLines="line-clamp-5"
        text={translate(`${i18n}.description`, {
          email: email,
        })}
        textFormatType={EnumTextFormatType.FONT_SIZE_16}
        className="text-textSecondary text-center text-sm mt-2"
      />

      <div className="mt-16">
        <LActButton
          testID={'login-button'}
          type={EnumButtonType.NORMAL}
          onClick={onLoginButtonClicked}
          text={translate(`${i18n}.login`)}
          actionType={EnumButtonActionType.BUTTON}
        />
      </div>
    </div>
  );
};

export default memo(ResetPasswordCheckInboxScreen);
