import React, { useCallback, useEffect, useState } from 'react';
import {
  MailOutline,
  CallOutline,
  ChevronForwardOutline,
} from 'react-ionicons';
import { useFormik } from 'formik';
import { translate } from 'i18n';
import { Volunteer, OrganizationDetails } from 'api/schemas';
import { LActRow, LActText, LActAlert } from 'components/index';
import { colors } from 'constants/colors';
import { EnumTextFormatType } from 'constants/enums';
import {
  decryptData,
  parseBearerToken,
  openEmailAppComposer,
} from 'utils/global/functions';
import { ProfileContactInfoSchema } from './utils';
import { usePatchOrganizationContactInfoDetails } from 'api/endpoints/queries';
import {
  IUserInfoTypes,
  IAccessTokenTypes,
  IApplicationStorageTypes,
} from 'constants/types';
import {
  useUserStorage,
  useRefreshToken,
  useAccessTokenStorage,
  useApplicationStorage,
} from 'utils';

interface ContactInfoTemplateProps {
  userName?: string;
  lastName?: string;
  firstName?: string;
  userImage?: string;
  className?: string;
  phoneNumber?: string;
  refetch: () => void;
  shouldLinkContactInfo?: boolean;
  item?: Volunteer | OrganizationDetails;
  handleResponseMessage?: (message: string, type: string) => void;
}

const ContactInfoTemplate = (
  props: ContactInfoTemplateProps,
): React.ReactElement => {
  const i18n = 'templates.contactInfo';
  const {
    refetch,
    lastName,
    className,
    firstName,
    phoneNumber,
    shouldLinkContactInfo = true,
  } = props;
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  const item = applicationStorage?.application;

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  const formik = useFormik({
    initialValues: {
      email: item?.volunteer?.email,
      lastname: lastName,
      firstname: firstName,
      phoneNumber: phoneNumber,
    },
    validationSchema: ProfileContactInfoSchema,
    onSubmit: () => {
      onSaveClicked();
    },
  });

  useEffect(() => {
    if (item) {
      formik.setFieldValue('email', item?.volunteer?.email);
      formik.setFieldValue('lastname', item?.volunteer?.lastname);
      formik.setFieldValue('firstname', item?.volunteer?.firstname);
      formik.setFieldValue('phoneNumber', item?.volunteer?.phoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const PATCH_QUERY = usePatchOrganizationContactInfoDetails({
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });

  const CURSOR_BEHAVIOR = shouldLinkContactInfo
    ? 'cursor-pointer'
    : 'cursor-text';

  const onEmailClicked = (email: string) => {
    if (!shouldLinkContactInfo) return;
    openEmailAppComposer(email);
  };

  const Alert = useCallback(() => {
    return (
      <LActAlert
        dismissable
        duration={4000}
        message={message.message}
        type={message.type === 'success' ? 'success' : 'error'}
      />
    );
  }, [message.message, message.type]);

  const onSaveClicked = () => {
    if (
      formik.values.email &&
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formik.values.email,
      )
    ) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidEmail`),
        type: 'error',
      });
    } else if (formik.values.firstname && formik.values.firstname?.length < 3) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidFirstName`),
        type: 'error',
      });
    } else if (formik.values.lastname && formik.values.lastname?.length < 3) {
      setMessage({
        message: translate(`${i18n}.validationErrorMessages.invalidLastName`),
        type: 'error',
      });
    } else if (
      formik.values.phoneNumber &&
      !/^\+\d{1,16}$/.test(formik.values.phoneNumber)
    ) {
      setMessage({
        message: translate(
          `${i18n}.validationErrorMessages.invalidPhoneNumberLength`,
        ),
        type: 'error',
      });
    } else {
      PATCH_QUERY?.mutateAsync({
        organizationId: userStorage?.user?.organizationId?.toString(),
        organizationDetailId: item.volunteer.id?.toString() || '',
        data: {
          email: formik?.values?.email,
          lastname: formik?.values?.lastname,
          firstname: formik?.values?.firstname,
          phoneNumber: formik?.values?.phoneNumber,
        },
      })
        .then(response => {
          setMessage({
            message: translate(`${i18n}.successUpdateMessage`),
            type: 'success',
          });
          refetch();
        })
        .catch(error => {
          setMessage({
            message: translate(`${i18n}.unsuccessfulUpdateMessage`),
            type: 'error',
          });
        });
    }

    setTimeout(() => {
      setMessage({
        message: '',
        type: '',
      });
    }, 4000);
  };
  useRefreshToken({
    error: PATCH_QUERY?.error,
    refetch: onSaveClicked,
    hasError: PATCH_QUERY?.isError,
  });

  const EMAIL = formik?.values?.email
    ? formik?.values.email
    : item?.volunteer?.email;

  const PHONE_NUMBER = formik?.values?.phoneNumber
    ? formik?.values.phoneNumber
    : item?.volunteer?.phoneNumber;

  return (
    <div className={`bg-neutral300 p-4 rounded-3xl ${className}`}>
      <LActRow className={'pb-2 items-center'}>
        <div className={'ml-3'} data-testid={'username-text'}>
          <LActText
            testID={'name-text'}
            numberOfLines={'line-clamp-1'}
            text={translate(`${i18n}.name`)}
            textColor={'text-textSecondary'}
            textFormatType={EnumTextFormatType.FONT_SIZE_12}
          />

          <LActRow className="mt-1">
            <div>
              <LActText
                textColor={'text-textPrimary'}
                className={'text-medium ml-1'}
                text={formik?.values?.firstname}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
              />
            </div>

            <div>
              <LActText
                textColor={'text-textPrimary'}
                className={'text-medium ml-1'}
                text={formik?.values?.lastname}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
              />
            </div>
          </LActRow>
        </div>
      </LActRow>

      {EMAIL && (
        <LActRow
          spaceBetween
          className={`p-2 ${CURSOR_BEHAVIOR}`}
          onClick={() => onEmailClicked(EMAIL)}
        >
          <LActRow className={'mt-2 items-center'}>
            <MailOutline color={colors.tint} height="24px" width="24px" />

            <div className={'flex flex-col items-center'}>
              <LActText
                testID={'#email'}
                text={formik?.values?.email}
                textColor={'text-textPrimary'}
                className={'ml-3 text-medium mt-0.5'}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
              />
            </div>
          </LActRow>

          <div className={'desktop:flex-none desktop:hidden'}>
            <ChevronForwardOutline
              width={'16px'}
              color={colors.palette.grey300}
            />
          </div>
        </LActRow>
      )}

      {PHONE_NUMBER && (
        <LActRow spaceBetween className={'pt-4 pr-3 pl-2 mt-2'}>
          <LActRow className={'-mt-5'}>
            <CallOutline color={colors.tint} height="24px" width="24px" />

            <LActText
              textColor={'text-textPrimary'}
              text={formik?.values?.phoneNumber}
              className={'text-medium ml-2 pt-0.5'}
              textFormatType={EnumTextFormatType.FONT_SIZE_14}
            />
          </LActRow>

          {message?.message && <Alert />}
        </LActRow>
      )}
    </div>
  );
};

export default ContactInfoTemplate;
