import React from 'react';
import { translate } from 'i18n';
import { ChevronForwardOutline, PersonCircle } from 'react-ionicons';
import { ApplicationItems } from 'api/schemas/applicationItems';
import { EnumImageType, EnumTextFormatType } from 'constants/enums';
import { colors } from 'constants/colors';
import {
  LActRow,
  LActCard,
  LActText,
  LActImage,
  LActBadge,
} from 'components/index';
import ApplicationsAppliedForTemplate from './applications.template.appliedFor';
import ApplicationsAppliedOnTemplate from './applications.template.appliedOn';
import { useSwitchTranslation } from 'utils';

interface ApplicationsTemplateProps {
  isSeen?: boolean;
  onClick: () => void;
  item: ApplicationItems;
  onHover?: () => void;
}

const ApplicationsTemplate = (
  props: ApplicationsTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.home.applications';
  const { item, isSeen, onClick, onHover } = props;

  useSwitchTranslation();

  return (
    <LActCard
      onClick={onClick}
      onHover={onHover}
      key={`applications-${item?.id}`}
      className={'mobile:mb-3 desktop:mb-6 cursor-pointer'}
    >
      {!isSeen && (
        <LActBadge
          title={translate(`${i18n}.new`)}
          className={
            'bg-orange float-right desktop:-mt-7.5 mobile:-mt-7 desktop:-mr-11 font-bold mobile:absolute mobile:right-4 desktop:relative'
          }
        />
      )}

      <LActRow className={`mobile:justify-between mobile:items-center`}>
        <div className={'flex flex-row'}>
          {item?.volunteer?.imageUri ? (
            <LActImage
              imageType={EnumImageType.IMAGE}
              source={item?.volunteer?.imageUri}
              className={'w-14 h-14 rounded-full'}
            />
          ) : (
            <PersonCircle color={'#1F4043'} width={'62px'} height={'62px'} />
          )}

          <div className={'ml-3 items-center mobile:pt-2 desktop:pt-0'}>
            <LActText
              testID={'username-text'}
              numberOfLines={'line-clamp-1'}
              textColor={'text-textPrimary'}
              textFormatType={EnumTextFormatType.FONT_SIZE_16}
              text={`${
                !item?.volunteer?.firstname && !item?.volunteer?.lastname
                  ? 'Anonymous'
                  : item?.volunteer?.firstname
              } ${item?.volunteer?.lastname}`}
              className={
                'font-medium desktop:pt-3 desktop:text-2xl mobile:text-base mobile:semi-bold self-center font-medium'
              }
            />

            <ApplicationsAppliedOnTemplate item={item} />

            <ApplicationsAppliedForTemplate item={item?.offer} />
          </div>
        </div>

        <div className={'desktop:flex-none desktop:hidden'}>
          <ChevronForwardOutline
            width={'16px'}
            color={colors.palette.grey300}
          />
        </div>
      </LActRow>
    </LActCard>
  );
};

export default ApplicationsTemplate;
