import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { AddCircleOutline } from 'react-ionicons';
import { translate } from 'i18n';
import { useNavigate } from 'react-router-dom';
import {
  LActRow,
  LActText,
  LActScreen,
  LActButton,
  LActSidebar,
  LActDivider,
  ApplicationsSkeletonLoaderTemplate,
} from 'components/index';
import {
  EnumButtonType,
  EnumTextFormatType,
  EnumApplicationsType,
  EnumOrganizationStatusType,
} from 'constants/enums';
import {
  IOffersTypes,
  IUserInfoTypes,
  IAccessTokenTypes,
  IApplicationsIDsTypes,
  IOrganizationInfoTypes,
  IApplicationStorageTypes,
  ISeenNewApplicationsTypes,
} from 'constants/types';
import {
  useGetUserInfo,
  useUserStorage,
  useRefreshToken,
  useCheckAccessToken,
  useApplicationStorage,
  useOrganizationStorage,
  useApplicationsIDsStorage,
} from 'utils';
import { useAccessTokenStorage, useSeenNewApplications } from 'utils';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import {
  useGetApiV1Offers,
  useGetApiV1Applications,
} from 'api/endpoints/queries';
import { ApplicationItems } from 'api/schemas/applicationItems';
import { colors } from 'constants/colors';
import { APPLICATIONS_BOARD, OFFERS_CREATION_SCREEN } from 'constants/route';
import ApplicationsTemplate from '../../components/templates/applications/applications.list.template';
import ApplicationsEmptyState from './components/applications.empty';
import SidebarTemplate from './components/template/sidebar.index';
import OffersTemplate from './components/template/offers/offers.index';
import OffersSkeletonLoaderTemplate from './components/template/offers/components/offers.loading.skeleton';
import OffersEmptyState from './components/template/offers/components/offers.emptyState';
import useOffersStorage from 'utils/storage/useOffersStorage';
import VerificationInProgressComponent from './components/home.verification.progress';

const HomeScreen = (): React.ReactElement => {
  const i18n = 'screens.home';
  useCheckAccessToken();
  useGetUserInfo();
  const navigate = useNavigate();
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
  // const [load, setLoad] = useState<boolean>(false);

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );
  // @ts-ignore
  const seenApplicationsStorage: ISeenNewApplicationsTypes =
    useSeenNewApplications(
      (store: ISeenNewApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  // @ts-ignore
  const offersStorage: IOffersTypes = useOffersStorage(
    (store: IOffersTypes | unknown) => store,
  );

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  // @ts-ignore
  const applicationsIDsStorage: IApplicationsIDsTypes =
    useApplicationsIDsStorage(
      (store: IApplicationsIDsTypes | unknown) => store,
    );

  const applicationIDsCount = useRef(applicationsIDsStorage?.ids?.length);

  const API_QUERY_APPLICATIONS = useGetApiV1Applications(
    EnumApplicationsType.PENDING,
    {
      query: {
        retry: 1,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
      ...parseBearerToken(
        tokenStorage?.token?.access_token
          ? decryptData(tokenStorage?.token?.access_token)
          : '',
      ),
    },
  );
  useRefreshToken({
    error: API_QUERY_APPLICATIONS?.error,
    hasError: API_QUERY_APPLICATIONS?.isError,
    refetch: API_QUERY_APPLICATIONS?.refetch,
  });

  const API_QUERY_OFFERS = useGetApiV1Offers(
    userStorage?.user?.organizationId,
    {
      query: {
        retry: 2,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        enabled: !!API_QUERY_APPLICATIONS?.data,
      },
      ...parseBearerToken(
        tokenStorage?.token?.access_token
          ? decryptData(tokenStorage?.token?.access_token)
          : '',
      ),
    },
  );
  useRefreshToken({
    error: API_QUERY_OFFERS?.error,
    hasError: API_QUERY_OFFERS?.isError,
    refetch: API_QUERY_OFFERS?.refetch,
  });

  useEffect(() => {
    if (API_QUERY_OFFERS?.data) {
      offersStorage.storeOffers(API_QUERY_OFFERS?.data?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [API_QUERY_OFFERS?.data]);

  useLayoutEffect(() => {
    if (applicationIDsCount.current >= applicationsIDsStorage?.ids?.length) {
      API_QUERY_APPLICATIONS.refetch();
      applicationIDsCount.current = applicationsIDsStorage?.ids?.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationIDsCount.current]);

  const renderApplicationsTemplate = (
    item: ApplicationItems,
    index: number,
  ): React.ReactElement => {
    const onApplicationItemsClicked = (id: number) => {
      if (!id) return;
      else {
        if (!seenApplicationsStorage?.ids?.includes(id)) {
          seenApplicationsStorage.storeAppliedOffers(
            seenApplicationsStorage?.ids?.concat(id),
          );
        }
        applicationStorage.saveApplication(item);
        if (applicationStorage.application) {
          setIsSidebarVisible(true);
        }
      }
    };

    const APPLICATION_IS_SEEN = seenApplicationsStorage?.ids?.includes(
      item?.id,
    );

    return (
      <div key={`container-${index}-${index + 1}`}>
        {!applicationsIDsStorage.ids.includes(item?.id) && (
          <ApplicationsTemplate
            item={item}
            isSeen={APPLICATION_IS_SEEN}
            key={`application-${index}-${index + 1}`}
            onClick={() => onApplicationItemsClicked(item?.id)}
          />
        )}
      </div>
    );
  };

  const ApplicationsItems = useMemo(() => {
    const NUMBER_OF_COLUMNS =
      API_QUERY_APPLICATIONS?.data &&
      API_QUERY_APPLICATIONS?.data?.items.length < 4
        ? 'desktop:grid-rows-1'
        : 'desktop:grid-rows-2';

    return (
      <div
        className={`mt-6 desktop:grid ${NUMBER_OF_COLUMNS} desktop:gap-6 desktop:grid-container desktop:grid-cols-3`}
      >
        {API_QUERY_APPLICATIONS?.data?.items && (
          <>
            {API_QUERY_APPLICATIONS?.data?.items
              ?.slice(0, 6)
              .map((item: ApplicationItems, index: number) => {
                return renderApplicationsTemplate(item, index);
              })}
          </>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSidebarVisible,
    seenApplicationsStorage?.ids,
    applicationsIDsStorage?.ids,
    API_QUERY_APPLICATIONS?.data?.items,
  ]);

  const onCloseSidebar = () => {
    setIsSidebarVisible(false);
  };

  const onManageApplicationsClicked = () => {
    navigate(APPLICATIONS_BOARD);
  };

  const onCreateOfferClicked = () => {
    navigate(OFFERS_CREATION_SCREEN);
  };

  // const runNotificationsOffer = () => {
  //   setLoad(true);
  //   let config = {
  //     method: 'get',
  //     maxBodyLength: Infinity,
  //     url: 'https://api-v2.letsact.de/api/v1/notifications/saved_offers/notification',
  //     headers: {
  //       access_token: 'db2b94bd-3149-4972-a07c-eb074a787305',
  //       'Access-Control-Allow-Origin': '*',
  //       'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  //     },
  //   };

  //   axios
  //     .request({
  //       ...config,
  //       proxy: {
  //         host: 'http://localhost/',
  //         port: 3000,
  //       },
  //     })
  //     .then(response => {
  //       console.log(JSON.stringify(response.data));
  //       setLoad(false);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       setLoad(false);
  //     });
  // };

  const RenderOffers = useCallback(() => {
    return <OffersTemplate offers={offersStorage?.offers} />;
  }, [offersStorage?.offers]);

  const IS_PENDING_STATE: boolean =
    organizationStorage?.organization?.status !==
    EnumOrganizationStatusType.ACTIVE;

  return (
    <LActScreen>
      {organizationStorage &&
        IS_PENDING_STATE &&
        !API_QUERY_OFFERS?.isLoading &&
        !API_QUERY_APPLICATIONS?.isLoading && (
          <VerificationInProgressComponent />
        )}
      {/* Applications section */}
      <div
        className={
          'mt-6 desktop:grid desktop:grid-rows-2 desktop:gap-6 desktop:grid-container desktop:grid-cols-3'
        }
      ></div>

      <div className="mt-8">
        <LActRow className="items-center" spaceBetween>
          <LActText
            textFormatType={EnumTextFormatType.FONT_SIZE_32}
            text={translate(`${i18n}.applications.openApplications`)}
            className="font-semibold mobile:text-2xl desktop:text-3xl"
          />

          <span>
            <LActButton
              containerStyle="px-3.5"
              disabled={IS_PENDING_STATE}
              textStyle={'text-sm font-semibold'}
              onClick={onManageApplicationsClicked}
              text={translate(`${i18n}.applications.manageApplicationsButton`)}
            />
          </span>
        </LActRow>

        {API_QUERY_APPLICATIONS?.isLoading ? (
          <div
            className={
              'mt-6 desktop:grid desktop:grid-rows-2 desktop:gap-6 desktop:grid-container desktop:grid-cols-3'
            }
          >
            <ApplicationsSkeletonLoaderTemplate />
          </div>
        ) : (
          <>
            {/* Applications List Empty state */}
            {!API_QUERY_APPLICATIONS?.data?.items?.length ? (
              <ApplicationsEmptyState />
            ) : (
              <>{ApplicationsItems}</>
            )}
          </>
        )}
      </div>

      {/* Divider */}
      <LActDivider className="mt-9" />

      {/* Offers Section */}
      <div className="mt-9">
        <LActRow className="items-center" spaceBetween>
          <LActText
            text={translate(`${i18n}.offers.title`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_32}
            className="font-semibold mobile:text-2xl desktop:text-3xl"
          />

          <span>
            <LActButton
              containerStyle="px-3.5"
              type={EnumButtonType.OUTLINE}
              iconComponent={
                <AddCircleOutline
                  width="16px"
                  height="16px"
                  color={colors.tint}
                />
              }
              disabled={IS_PENDING_STATE}
              onClick={onCreateOfferClicked}
              textStyle={'text-sm font-semibold'}
              text={translate(`${i18n}.offers.createButton`)}
            />
          </span>
        </LActRow>

        {API_QUERY_OFFERS?.isLoading ? (
          <div
            className={
              'desktop:grid desktop:grid-rows-2 desktop:gap-14 desktop:grid-container desktop:grid-cols-3'
            }
          >
            <OffersSkeletonLoaderTemplate />
          </div>
        ) : (
          <>
            {!API_QUERY_OFFERS?.data?.items?.length ? (
              <OffersEmptyState />
            ) : (
              <RenderOffers />
            )}
          </>
        )}
      </div>

      <LActSidebar
        onClose={onCloseSidebar}
        visible={isSidebarVisible}
        children={
          <SidebarTemplate
            item={applicationStorage.application}
            onApplicationStatusChanged={() => setIsSidebarVisible(false)}
          />
        }
      />
    </LActScreen>
  );
};

export default HomeScreen;
