import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IUserInfoTypes } from 'constants/types';
import {
  LActRow,
  LActText,
  Breadcrumb,
  LActButton,
  LActLoader,
  LActScreen,
} from 'components/index';
import { useRefreshToken, useUserStorage } from 'utils';
import {
  useGetAdminOrganizationOffer,
  useGetAdminOrganizationDetails,
  useDeleteAdminOrganizationDetails,
  useAcceptAdminOrganizationDetails,
} from 'api/endpoints/queries';
import ManagementContactInfoTemplate from './template/management.contactInfo';
import {
  EnumButtonType,
  EnumTextFormatType,
  EnumOrganizationStatusType,
} from 'constants/enums';
import {
  TrashOutline,
  ChevronBackOutline,
  CheckmarkDoneCircleOutline,
} from 'react-ionicons';
import { colors } from 'constants/colors';
import OffersTemplate from 'screens/home/components/template/offers/offers.index';

const ManagementOrganizationDetailsScreen = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState({
    message: '',
    type: 'error',
  });
  const orgId = location?.state?.orgId;
  const orgName = location?.state?.orgName;
  const orgStatus = location?.state?.orgStatus;
  const orgWebPage = location?.state?.orgWebPage;
  const orgDescription = location?.state?.description;
  // const profilePic = location?.state?.profilePic;

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  useEffect(() => {
    if (userStorage?.user?.email !== process.env.REACT_APP_LETSACT_USER) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!orgId) navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  const DELETE_QUERY = useDeleteAdminOrganizationDetails({
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });

  const ACCEPT_QUERY = useAcceptAdminOrganizationDetails({
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });

  const API_QUERY = useGetAdminOrganizationDetails(orgId, {
    query: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });
  useRefreshToken({
    error: API_QUERY?.error,
    refetch: API_QUERY?.refetch,
    hasError: API_QUERY?.isError,
  });

  const API_QUERY_OFFERS = useGetAdminOrganizationOffer(orgId, {
    query: {
      retry: 2,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      enabled: !!API_QUERY?.data,
    },
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });
  useRefreshToken({
    error: API_QUERY_OFFERS?.error,
    hasError: API_QUERY_OFFERS?.isError,
    refetch: API_QUERY_OFFERS?.refetch,
  });

  const onDeleteOrganizationClicked = () => {
    DELETE_QUERY?.mutateAsync({
      email: API_QUERY?.data?.item?.email ?? '',
      organization_id: orgId,
    })
      .then(response => {
        if (response) {
          setMessage({
            message: 'Organization was deleted successfully',
            type: 'success',
          });
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        }
      })
      .catch(error => {
        setMessage({
          message: 'An error happened!',
          type: 'error',
        });
      });
  };
  useRefreshToken({
    error: DELETE_QUERY?.error,
    hasError: DELETE_QUERY?.isError,
    refetch: onDeleteOrganizationClicked,
  });

  const onAcceptOrganizationClicked = () => {
    ACCEPT_QUERY?.mutateAsync({
      organization_id: Number(orgId),
    })
      .then(response => {
        if (response) {
          setMessage({
            message: 'Organization was Accepted successfully',
            type: 'success',
          });
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        }
      })
      .catch(error => {
        setMessage({
          message: 'An error happened!',
          type: 'error',
        });
      });
  };
  useRefreshToken({
    error: DELETE_QUERY?.error,
    hasError: DELETE_QUERY?.isError,
    refetch: onDeleteOrganizationClicked,
  });

  const RenderOffers = () => {
    return (
      <OffersTemplate
        offers={
          API_QUERY_OFFERS?.data?.items ? API_QUERY_OFFERS?.data?.items : []
        }
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  if (API_QUERY?.isLoading) {
    return (
      <LActScreen>
        <div className={'flex h-screen justify-center items-center'}>
          <LActLoader color={'tint'} />
        </div>
      </LActScreen>
    );
  }

  return (
    <LActScreen>
      <>
        <LActRow spaceBetween className={'-mr-4 items-center mt-7'}>
          <div>
            <LActButton
              type={EnumButtonType.TEXT}
              onClick={() => navigate(-1)}
              textStyle={'text-tint text-sm'}
              text={'Back'}
              iconComponent={
                <ChevronBackOutline
                  width="14px"
                  height="14px"
                  style={{
                    marginTop: -1,
                    marginRight: -6,
                  }}
                  color={colors.palette.primaryDark}
                />
              }
            />
          </div>
          <div>
            <Breadcrumb routes={['Home', 'Management', 'Details']} />
          </div>
        </LActRow>

        <LActRow spaceBetween className={'items-center'}>
          <div className="mt-8">
            <LActText
              className={'mt-6'}
              text={orgName}
              textColor={
                'text-textPrimary text-regular desktop:flex desktop:flex-1 mobile:hidden mobile:text-2xl desktop:text-3xl'
              }
              textFormatType={EnumTextFormatType.FONT_SIZE_48}
            />
          </div>

          {orgStatus === EnumOrganizationStatusType.PENDING && (
            <div className="mt-8">
              <LActButton
                useLoading={false}
                testID="logout-button"
                type={EnumButtonType.TEXT}
                text={'Accept organization'}
                containerStyle={'text-md mt-6'}
                textStyle={'font-bold text-success'}
                onClick={onAcceptOrganizationClicked}
                iconComponent={
                  <CheckmarkDoneCircleOutline
                    color={colors.success}
                    width="24px"
                    height="24px"
                  />
                }
              />

              {DELETE_QUERY?.isLoading && (
                <div className="mt-10 ml-2 w-4 flex">
                  <LActLoader color={'tint'} size="sm" />
                </div>
              )}
            </div>
          )}

          <div className="mt-8 flex">
            <LActButton
              useLoading={false}
              testID="logout-button"
              type={EnumButtonType.TEXT}
              text={'Delete Organization'}
              containerStyle={'text-sm mt-6'}
              textStyle={'font-bold text-error'}
              onClick={onDeleteOrganizationClicked}
              iconComponent={
                <TrashOutline color={colors.error} width="24px" height="24px" />
              }
            />
            {DELETE_QUERY?.isLoading && (
              <div className="mt-10 ml-2 w-4 flex">
                <LActLoader color={'tint'} size="sm" />
              </div>
            )}
          </div>
        </LActRow>

        <LActText
          text={orgDescription}
          numberOfLines="line-clamp-6"
          textColor={'text-textSecondary'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          className={'leading-6 w-full text-textSecondary text-base mt-4'}
        />

        <LActText
          text={`🌐 ${orgWebPage} 🖥️`}
          numberOfLines="line-clamp-6"
          textColor={'text-textSecondary'}
          onClick={() => window.open(orgWebPage)}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          className={
            'leading-6 w-full text-orange text-base mt-4 cursor-pointer'
          }
        />

        {message?.message && (
          <LActText
            text={message?.message}
            numberOfLines="line-clamp-6"
            textFormatType={EnumTextFormatType.FONT_SIZE_16}
            className={'leading-6 w-full text-textSecondary text-base mt-4'}
            textColor={
              message?.type === 'success' ? 'text-success' : 'text-error'
            }
          />
        )}

        <ManagementContactInfoTemplate
          orgID={orgId}
          item={API_QUERY?.data}
          shouldLinkContactInfo={false}
          refetch={() => API_QUERY?.refetch()}
          email={API_QUERY?.data?.item?.email}
          lastName={API_QUERY?.data?.item?.lastname}
          firstName={API_QUERY?.data?.item?.firstname}
          phoneNumber={API_QUERY?.data?.item?.phoneNumber}
          className={'desktop:w-1/2 desktop:ml-3 desktop:self-end mobile:mt-6'}
          userName={
            API_QUERY?.data?.item?.firstname +
            ' ' +
            API_QUERY?.data?.item?.lastname
          }
        />

        <div className={'mt-8'}>{<RenderOffers />}</div>
      </>
    </LActScreen>
  );
};

export default ManagementOrganizationDetailsScreen;
