import React, { useEffect, useRef, useState } from 'react';
import { AddCircleOutline, CloseSharp } from 'react-ionicons';
import { LActButton, LActImage, LActRow, LActText } from 'components/index';
import { translate } from 'i18n';
import {
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
} from 'constants/enums';
import { Offer } from 'api/schemas/offer';
import { colors } from 'constants/colors';
import { useLanguageSwitchStorage, useRefreshToken } from 'utils';
import { usePostAPIV1OfferImage } from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';

interface ImageUploadComponentProps {
  image: string;
  offerID: number;
  accessToken: string;
  onItemUpdated: (item: Offer) => void;
}

const ImageUploadComponent = (
  props: ImageUploadComponentProps,
): React.ReactElement => {
  const i18n = 'screens.home.offers.details';
  useLanguageSwitchStorage();
  const { image, offerID, accessToken, onItemUpdated } = props;
  const inputFileRef = useRef<any>(null);
  const maxFileSizeInBytes = 2 * 1024 * 1024;
  const [file, setFile] = useState<any>();
  const [currentImage, setCurrentImage] = useState<any>(image);
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  const PATCH_QUERY = usePostAPIV1OfferImage({
    ...parseBearerToken(accessToken ? decryptData(accessToken) : ''),
  });

  useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
        });
      }, 3000);
    }
  }, [message.type]);

  const onPickImageClicked = () => {
    inputFileRef?.current?.click();
  };

  const handleImagePickerChanges = (event: any) => {
    const selectedFile = event?.target?.files[0];

    if (selectedFile.type === 'image/webp') {
      setMessage({
        message: translate(`${i18n}.severMessages.unsupportedContentType`),
        type: 'error',
      });
    } else {
      if (selectedFile?.size <= maxFileSizeInBytes) {
        // The selected file is 2MB or less
        setMessage({
          message: '',
          type: '',
        });
        if (selectedFile) {
          setFile(selectedFile);
          const reader = new FileReader();
          reader.onload = () => {
            setCurrentImage(reader?.result);
          };
          reader.readAsDataURL(selectedFile);
        }
      } else {
        setMessage({
          message: translate(`${i18n}.errorMessages.wrongImageSize`),
          type: 'error',
        });
      }
    }
  };

  const onCancelClicked = () => {
    setCurrentImage(image);
  };

  const onSaveClicked = () => {
    if (!currentImage) return;

    const formData = new FormData();
    formData.append('file', file);

    PATCH_QUERY.mutateAsync({
      offerId: offerID?.toString(),
      data: formData,
    })
      .then((data: any) => {
        onItemUpdated(data?.item);
        setMessage({
          message: translate(`${i18n}.severMessages.successfullyUpdated`),
          type: 'success',
        });
        setCurrentImage(data?.item?.picture);
      })
      .catch(error => {
        if (error?.message?.includes('500')) {
          setMessage({
            message: translate(`${i18n}.severMessages.serverError500`),
            type: 'error',
          });
        } else if (error?.message?.includes('415')) {
          setMessage({
            message: translate(`${i18n}.severMessages.unsupportedContentType`),
            type: 'error',
          });
        }
        setMessage({
          message: translate(`${i18n}.severMessages.serverGeneralError`),
          type: 'error',
        });
      });
  };

  useRefreshToken({
    refetch: onSaveClicked,
    error: PATCH_QUERY?.error,
    hasError: PATCH_QUERY?.isError,
  });

  const disabledSaveButton = currentImage === image;

  return (
    <>
      <LActRow className={'items-center'} spaceBetween>
        <div>
          <LActText
            className="font-semibold"
            text={translate(`${i18n}.imageSection`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
          />
        </div>

        <div>
          <LActButton
            testID={'login-button'}
            onClick={onSaveClicked}
            type={EnumButtonType.TEXT}
            disabled={disabledSaveButton}
            useLoading={PATCH_QUERY?.isLoading}
            text={translate(`${i18n}.saveButton`)}
            actionType={EnumButtonActionType.BUTTON}
            containerStyle={'items-end py-0 bg-transparent border-0'}
            textStyle={`text-tint ${disabledSaveButton && 'opacity-50'}`}
          />
        </div>
      </LActRow>

      <div>
        <LActImage
          source={currentImage}
          className={'mt-4 h-72 w-full object-cover'}
        />
      </div>

      <div
        className={'mt-3 flex desktop:flex-row mobile:flex-col justify-between'}
      >
        <LActRow>
          <span className={'cursor-pointer'}>
            <AddCircleOutline
              width="20px"
              height="20px"
              color={colors.tint}
              onClick={onPickImageClicked}
            />
            <input
              id={'file'}
              type={'file'}
              accept="image/*"
              ref={inputFileRef}
              style={{ display: 'none' }}
              onChange={handleImagePickerChanges}
            />
          </span>

          {currentImage !== image && (
            <span className={'cursor-pointer ml-2'}>
              <CloseSharp
                width="20px"
                height="20px"
                color={colors.warning}
                onClick={onCancelClicked}
              />
            </span>
          )}
        </LActRow>

        <LActText
          textColor={'text-tint'}
          textFormatType={EnumTextFormatType.FONT_SIZE_14}
          className={'font-semibold mobile:mt-2 desktop:mt-0'}
          text={translate(`${i18n}.infoMessage.imageSizeInfo`)}
        />
      </div>

      {message?.message && (
        <LActText
          text={message?.message}
          className={'font-semibold mt-6'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          textColor={message.type === 'error' ? 'text-error' : 'text-green'}
        />
      )}
    </>
  );
};

export default ImageUploadComponent;
