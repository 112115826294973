import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate } from 'i18n';
import { ORG_CONTACT_SCREEN } from 'constants/route';
import { LActButton, LActRow, LActText, LActTextInput } from 'components/index';
import AuthScreenContainer from 'screens/auth/container';
import {
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
} from 'constants/enums';
import ProfilePictureTemplate from 'screens/profile/template/profile.image';
import {
  IUserInfoTypes,
  IAccessTokenTypes,
  IOrganizationInfoTypes,
} from 'constants/types';
import {
  useUserStorage,
  useRefreshToken,
  useSwitchTranslation,
  useAccessTokenStorage,
  useOrganizationStorage,
} from 'utils';
import { usePatchOrganizationDetails } from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';

const RegisterOrgDetailsScreen = (): React.ReactElement => {
  const i18n = 'screens.organizationDetails';
  useSwitchTranslation();
  const navigate = useNavigate();
  const MAXIMUM_DESCRIPTION_LENGTH: number = 1500;
  const [description, setDescription] = useState<string>('');
  const [onSkipPressed, setOnSkipPressed] = useState<boolean>(false);
  const [message, setMessage] = useState({
    message: '',
    type: 'error',
  });

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  // @ts-ignore
  const organizationStorage: IOrganizationInfoTypes = useOrganizationStorage(
    (store: IOrganizationInfoTypes | unknown) => store,
  );

  useEffect(() => {
    return () => {
      setOnSkipPressed(false);
    };
  }, []);

  useEffect(() => {
    if (organizationStorage?.organization) {
      navigate(ORG_CONTACT_SCREEN);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PATCH_QUERY = usePatchOrganizationDetails({
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });

  const onDescriptionValueChange = (value: string) => {
    if (description?.length === MAXIMUM_DESCRIPTION_LENGTH) return;
    setDescription(value);
  };

  const onSkipButtonClicked = () => {
    onNextClicked();
    setOnSkipPressed(true);
  };

  const onNextClicked = () => {
    PATCH_QUERY?.mutateAsync({
      organizationId: userStorage?.user?.organizationId.toString(),
      data: {
        description: description ?? '',
      },
    })
      .then((data: any) => {
        organizationStorage?.storeOrgInfo(data?.item);
        setMessage({
          message: translate(`${i18n}.successUpdateMessage`),
          type: 'success',
        });
        setTimeout(() => {
          navigate(ORG_CONTACT_SCREEN);
        }, 700);
      })
      .catch(error => {
        if (error?.message?.includes('500')) {
          setMessage({
            message: translate(`${i18n}.unsuccessfulUpdateMessage`),
            type: 'error',
          });
        }
        setMessage({
          message: translate(`${i18n}.unsuccessfulUpdateMessage`),
          type: 'error',
        });
      });
  };
  useRefreshToken({
    refetch: onNextClicked,
    error: PATCH_QUERY?.error,
    hasError: PATCH_QUERY?.isError,
  });

  return (
    <AuthScreenContainer
      showProgress
      progressValue={50}
      style={`flex justify-center items-center mobile:mx-6 mobile:w-full flex-shrink-1`}
    >
      <div className="desktop:w-3/5 mobile:w-full">
        <LActText
          text={translate(`${i18n}.titleOne`)}
          className="text-textPrimary text-center mt-3"
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
        />
        <LActText
          text={translate(`${i18n}.titleTwo`)}
          className="text-textPrimary text-center"
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
        />
        <LActText
          text={translate(`${i18n}.titleThree`)}
          className="text-textPrimary text-center"
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
        />
        <div className="flex flex-col items-center mt-2">
          <ProfilePictureTemplate
            image={''}
            onUpdateClicked={() => console.log()}
            accessToken={tokenStorage?.token?.access_token}
            organizationID={userStorage?.user?.organizationId}
          />
        </div>
        <LActTextInput
          multiLine
          type={'text'}
          hasFloatingLabel
          containerStyle="h-40"
          multiLineRowsNumber={4}
          onChange={value => onDescriptionValueChange(value)}
          placeHolder={translate(`${i18n}.descriptionPlaceHolder`)}
          style={`bg-neutral300 focus:bg-neutral300 rounded-lg mt-6 w-full h-40 px-3 pt-5`}
        />

        <LActText
          className="text-neutral400 pl-4 pt-1"
          textFormatType={EnumTextFormatType.FONT_SIZE_12}
          text={`${description?.length}/${MAXIMUM_DESCRIPTION_LENGTH}`}
        />
        {message?.message && !onSkipPressed && (
          <LActText
            text={message?.message}
            className={`${
              message?.type === 'success' ? 'text-green' : 'text-error'
            } pt-1`}
            textFormatType={EnumTextFormatType.FONT_SIZE_12}
          />
        )}
        {/* Buttons container */}
        <LActRow className="mt-16">
          <div className={'desktop:w-1/4 mobile:w-1/2 mobile:mr-2'}>
            <LActButton
              testID={'register-button'}
              type={EnumButtonType.TEXT}
              onClick={onSkipButtonClicked}
              textStyle={'text-tint font-bold'}
              actionType={EnumButtonActionType.BUTTON}
              text={translate(`${i18n}.setupLaterButton`)}
            />
          </div>

          <div
            className={'desktop:w-3/4 desktop:ml-6 mobile:w-1/2 mobile:ml-2'}
          >
            <LActButton
              testID={'login-button'}
              onClick={onNextClicked}
              type={EnumButtonType.NORMAL}
              disabled={description?.length < 5}
              text={translate(`${i18n}.nextButton`)}
              actionType={EnumButtonActionType.BUTTON}
              useLoading={PATCH_QUERY?.isLoading && !onSkipPressed}
            />
          </div>
        </LActRow>
      </div>
    </AuthScreenContainer>
  );
};

export default RegisterOrgDetailsScreen;
