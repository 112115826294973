import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { CloseSharp, CheckmarkSharp, PencilSharp } from 'react-ionicons';
import { colors } from 'constants/colors';
import LActRow from '../LActRow';
import LActLoader from '../LActLoader';
import { delay } from 'utils/global/functions';
import LActText from '../LActText';
import { EnumTextFormatType } from 'constants/enums';

interface LActEditableTextInputProps {
  testID?: string;
  textColor?: string;
  className?: string;
  maxLength?: number;
  isLoading?: boolean;
  multiline?: boolean;
  containerStyle?: string;
  value: string | undefined;
  textNumberOfLines?: string;
  onUpdateClicked: () => void;
  successfullyUpdated?: boolean;
  onChange: (value: string) => void;
  textFormatType?: EnumTextFormatType;
  onCancelEditing: (isCancelled: boolean) => void;
}

const LActEditableTextInput = (
  props: LActEditableTextInputProps,
): React.ReactElement => {
  const {
    value,
    testID,
    onChange,
    isLoading,
    textColor,
    multiline,
    maxLength,
    className,
    textFormatType,
    containerStyle,
    onCancelEditing,
    onUpdateClicked,
    textNumberOfLines,
    successfullyUpdated,
  } = props;

  const [shouldShowEditButton, setShouldShowEditButton] =
    useState<boolean>(false);
  const [shouldShowActionButtons, setShouldShowActionButtons] =
    useState<boolean>(false);
  const [shouldShowMessage, setShouldShowMessage] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (successfullyUpdated) {
      setTimeout(() => {
        setShouldShowActionButtons(false);
        setShouldShowMessage(true);
      }, 500);
    }
  }, [successfullyUpdated]);

  useEffect(() => {
    if (shouldShowMessage) {
      setTimeout(() => {
        setShouldShowMessage(false);
      }, 2000);
    }
  }, [shouldShowMessage]);

  const onInputFocused = useCallback(() => {
    if (!shouldShowEditButton) setShouldShowEditButton(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputFocusEnded = useCallback(() => {
    delay(2000).then(() => setShouldShowEditButton(false));
  }, []);

  const onEditButtonClicked = () => {
    onCancelEditing(false);
    setShouldShowEditButton(false);
    setShouldShowActionButtons(true);
  };

  const onCloseButtonClicked = () => {
    onCancelEditing(true);
    setShouldShowActionButtons(false);
  };

  const INPUT_STYLE = shouldShowActionButtons
    ? 'border-2 bg-neutral300 focus:bg-neutral300 rounded-lg'
    : undefined;
  const ICONS_STYLE =
    'p-1 rounded-md items-center justify-center bg-neutral300';
  const ACTION_BTN_TOP_MARGIN = !multiline ? 'my-2' : '';

  return (
    <div>
      <>
        {!shouldShowActionButtons ? (
          <div
            className={'flex'}
            onMouseEnter={onInputFocused}
            onMouseLeave={onInputFocusEnded}
          >
            <LActText
              text={value}
              testID={testID}
              textColor={textColor}
              className={className}
              domID={'#lact-text-semibold'}
              textFormatType={textFormatType}
              numberOfLines={textNumberOfLines}
            />
          </div>
        ) : (
          <>
            {multiline ? (
              <textarea
                value={value}
                data-testid={testID}
                maxLength={maxLength ?? 1500}
                style={{ height: 155 }}
                onClick={() => setShouldShowActionButtons(true)}
                onChange={value => onChange(value?.target?.value)}
                className={`${INPUT_STYLE} ${containerStyle} ${className}`}
              />
            ) : (
              <input
                multiple
                type={'text'}
                value={value}
                data-testid={testID}
                maxLength={maxLength ?? 200}
                onMouseEnter={onInputFocused}
                onMouseLeave={onInputFocusEnded}
                onClick={() => setShouldShowActionButtons(true)}
                onChange={value => onChange(value?.target?.value)}
                className={`${INPUT_STYLE} ${containerStyle} ${className}`}
              />
            )}
          </>
        )}
      </>

      <div className={'inline-block'}>
        {shouldShowEditButton && !shouldShowActionButtons && (
          <div
            onClick={onEditButtonClicked}
            className={`${ICONS_STYLE} bg-greenLight cursor-pointer`}
          >
            <PencilSharp width="16px" height="16px" color={colors.tint} />
          </div>
        )}
      </div>

      {shouldShowActionButtons && (
        <div className={ACTION_BTN_TOP_MARGIN}>
          {isLoading ? (
            <LActLoader size={'sm'} color={colors.background} />
          ) : (
            <>
              <LActRow className={'cursor-pointer'}>
                <div
                  onClick={onUpdateClicked}
                  className={`${ICONS_STYLE} bg-greenLight`}
                >
                  <CheckmarkSharp
                    width="16px"
                    height="16px"
                    color={colors.tint}
                  />
                </div>

                <div
                  onClick={onCloseButtonClicked}
                  className={`${ICONS_STYLE} bg-pink ml-1`}
                >
                  <CloseSharp
                    width="16px"
                    height="16px"
                    color={colors.warning}
                  />
                </div>
              </LActRow>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default LActEditableTextInput;
