import React, { memo, useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronBackOutline } from 'react-ionicons';
import {
  DragDropContext,
  DragStart,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { translate } from 'i18n';
import {
  useRefreshToken,
  useSwitchTranslation,
  useAccessTokenStorage,
  useApplicationStorage,
  useSeenNewApplications,
  useApplicationAcceptStorage,
  useApplicationRejectStorage,
  useUpdateKanbanApplicationsStorage,
  useTriggerApplicationAcceptRejectStorage,
} from 'utils';
import {
  LActRow,
  LActText,
  Breadcrumb,
  LActScreen,
  LActButton,
} from 'components/index';
import {
  EnumButtonType,
  EnumApplicationsType,
  EnumTextFormatType,
} from 'constants/enums';
import ApplicationsColumns from './template/applications.columns';
import { APPLICATIONS_STATUS } from './utils';
import {
  IAccessTokenTypes,
  IKanbanApplicationsTypes,
  IAcceptApplicationsTypes,
  IRejectApplicationsTypes,
  IApplicationStorageTypes,
  ISeenNewApplicationsTypes,
  IApplicationAcceptRejectTypes,
} from 'constants/types';
import { ApplicationItems } from 'api/schemas/applicationItems';
import { decryptData, delay, parseBearerToken } from 'utils/global/functions';
import {
  useGetApiV1AllApplications,
  usePatchApiV1ApplicationStatus,
} from 'api/endpoints/queries';
import { colors } from 'constants/colors';

const ApplicationsScreen = (): React.ReactElement => {
  const i18n = 'screens.applications';
  useSwitchTranslation();
  const navigate = useNavigate();
  const [availableDroppable, setAvailableDroppable] = useState<Array<string>>(
    [],
  );
  const [dragObject, setDragObject] = useState<any>();
  const [dragStart, setDragStart] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState<number>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateTheAcceptReject, setUpdateTheAcceptReject] =
    useState<boolean>(false);
  const [applicationsDataSource, setApplicationsDataSource] = useState<
    Array<{
      key: string;
      title: string;
      applications: any;
    }>
  >([
    {
      key: '',
      title: '',
      applications: [],
    },
  ]);

  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  const API_QUERY_APPLICATION_STATUS = usePatchApiV1ApplicationStatus({
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });

  const API_QUERY = useGetApiV1AllApplications({
    query: {
      retry: 1,
      refetchOnReconnect: true,
    },
    ...parseBearerToken(
      tokenStorage?.token?.access_token
        ? decryptData(tokenStorage?.token?.access_token)
        : '',
    ),
  });
  useRefreshToken({
    error: API_QUERY?.error,
    hasError: API_QUERY?.isError,
    refetch: API_QUERY?.refetch,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // @ts-ignore
  const kanbanUpdateStorage: IKanbanApplicationsTypes =
    useUpdateKanbanApplicationsStorage(
      (store: IKanbanApplicationsTypes | unknown) => store,
    );

  //@ts-ignore
  const acceptApplicationStorage: IAcceptApplicationsTypes =
    useApplicationAcceptStorage(
      (store: IAcceptApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const rejectApplicationStorage: IRejectApplicationsTypes =
    useApplicationRejectStorage(
      (store: IRejectApplicationsTypes | unknown) => store,
    );

  // @ts-ignore
  const acceptRejectStorage: IApplicationAcceptRejectTypes =
    useTriggerApplicationAcceptRejectStorage(
      (store: IApplicationAcceptRejectTypes | unknown) => store,
    );

  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  // @ts-ignore
  const seenApplicationsStorage: ISeenNewApplicationsTypes =
    useSeenNewApplications(
      (store: ISeenNewApplicationsTypes | unknown) => store,
    );

  useEffect(() => {
    if (acceptRejectStorage?.status) {
      setUpdateTheAcceptReject(true);
      setTimeout(() => {
        setUpdateTheAcceptReject(false);
      }, 3000);
    }
  }, [acceptRejectStorage?.status]);

  const queryAPI = (status: string) => {
    if (!status) return;

    API_QUERY_APPLICATION_STATUS?.mutateAsync({
      offer_id: `${applicationStorage?.application?.offer?.id}`,
      application_id: `${applicationId}`,
      data: {
        status: status,
      },
    })
      .then(() => {})
      .catch(error => error);
  };

  useEffect(() => {
    if (acceptRejectStorage?.status) {
      const updatedDataSource = [...applicationsDataSource];

      const sourceColumn = updatedDataSource.find(
        column => column?.key === dragObject?.source?.droppableId,
      );

      const destinationColumn = updatedDataSource.find(
        column => column?.key === dragObject?.destination?.droppableId,
      );

      if (sourceColumn && destinationColumn) {
        // @ts-ignore
        const draggedApplication = sourceColumn?.applications?.find(
          (app: any) => app?.id === Number(dragObject?.draggableId),
        );

        if (draggedApplication) {
          draggedApplication.status = dragObject?.destination?.droppableId;
          // @ts-ignore
          sourceColumn.applications = sourceColumn?.applications?.filter(
            (app: any) => app?.id !== Number(dragObject?.draggableId),
          );

          // @ts-ignore
          destinationColumn?.applications?.push(draggedApplication);

          setApplicationsDataSource(updatedDataSource);
          queryAPI(acceptRejectStorage?.status);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptRejectStorage?.status]);

  const groupByApplications = () => {
    const GROUP_BY = API_QUERY?.data?.items?.reduce((group: any, product) => {
      const { status } = product;
      group[status] = group[status] ?? [];
      group[status].push(product);
      return group;
    }, {});

    if (GROUP_BY) {
      const KANBAN_BOARD_DATA_SOURCE = [
        {
          key: '',
          title: '',
          applications: [],
        },
      ];

      // eslint-disable-next-line array-callback-return
      APPLICATIONS_STATUS?.map(application => {
        KANBAN_BOARD_DATA_SOURCE.push({
          key: application,
          title: application,
          applications: GROUP_BY[application] ?? [],
        });
      });
      const filteredDataSource = KANBAN_BOARD_DATA_SOURCE?.filter(source => {
        return source.title.length > 0;
      });
      setApplicationsDataSource(filteredDataSource);

      delay(1000).then(() => {
        setIsLoading(false);
      });
    }
  };

  useLayoutEffect(() => {
    if (API_QUERY?.data) {
      setIsLoading(true);
      groupByApplications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [API_QUERY?.data]);

  useEffect(() => {
    if (kanbanUpdateStorage?.data?.applicationID) {
      // eslint-disable-next-line array-callback-return
      API_QUERY?.data?.items?.filter((item: ApplicationItems) => {
        if (kanbanUpdateStorage?.data?.applicationID === item?.id) {
          item['status'] = kanbanUpdateStorage?.data.updatedStatus;
          delay(1000).then(() => {
            groupByApplications();
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanUpdateStorage?.data]);

  const onDragEnded = (droppedObject: any) => {
    setAvailableDroppable([]);
    setDragStart(!dragStart);
    const result = droppedObject;

    if (!result?.destination) {
      return;
    }

    if (result?.source?.droppableId === result?.destination?.droppableId) {
      return;
    } else if (
      result.destination?.droppableId === EnumApplicationsType.ACCEPTED
    ) {
      acceptApplicationStorage.showAcceptDialog('KANBAN');
      setDragObject(droppedObject);
    } else if (
      result.destination?.droppableId === EnumApplicationsType.REJECTED
    ) {
      rejectApplicationStorage.showRejectDialog('KANBAN');
      setDragObject(droppedObject);
    } else {
      const updatedDataSource = [...applicationsDataSource];

      const sourceColumn = updatedDataSource.find(
        column => column.key === result.source?.droppableId,
      );
      const destinationColumn = updatedDataSource.find(
        column => column.key === result.destination?.droppableId,
      );

      if (sourceColumn && destinationColumn) {
        // @ts-ignore
        const draggedApplication = sourceColumn?.applications?.find(
          (app: any) => app.id === Number(result.draggableId),
        );

        if (draggedApplication) {
          draggedApplication.status = result.destination?.droppableId;

          // @ts-ignore
          sourceColumn.applications = sourceColumn?.applications?.filter(
            (app: any) => app.id !== Number(result.draggableId),
          );

          // @ts-ignore
          destinationColumn.applications.push(draggedApplication);

          setApplicationsDataSource(updatedDataSource);
          queryAPI(result.destination?.droppableId);
        }
      }
    }
  };

  const onDragUpdated = (start: DragStart, provided: ResponderProvided) => {
    if (!start) return;

    if (applicationId?.toString() !== start?.draggableId) {
      setApplicationId(Number(start?.draggableId));
    }
    if (!seenApplicationsStorage?.ids?.includes(Number(start?.draggableId))) {
      seenApplicationsStorage.storeAppliedOffers(
        seenApplicationsStorage?.ids?.concat(Number(start?.draggableId)),
      );
    }
    if (start?.source?.droppableId === EnumApplicationsType.IN_REVIEW) {
      const inReviewActions = [
        EnumApplicationsType.IN_REVIEW,
        EnumApplicationsType.ACCEPTED,
        EnumApplicationsType.REJECTED,
      ];
      setAvailableDroppable(inReviewActions);
    } else if (start?.source?.droppableId === EnumApplicationsType.CONTACTED) {
      const contactedActions = [
        EnumApplicationsType.CONTACTED,
        EnumApplicationsType.IN_REVIEW,
        EnumApplicationsType.ACCEPTED,
        EnumApplicationsType.REJECTED,
      ];
      setAvailableDroppable(contactedActions);
    } else if (start?.source?.droppableId === EnumApplicationsType.PENDING) {
      const pendingActions = [
        EnumApplicationsType.PENDING,
        EnumApplicationsType.CONTACTED,
        EnumApplicationsType.IN_REVIEW,
        EnumApplicationsType.ACCEPTED,
        EnumApplicationsType.REJECTED,
      ];
      setAvailableDroppable(pendingActions);
    } else {
      setAvailableDroppable([]);
    }
  };

  return (
    <div>
      <LActScreen>
        <LActRow spaceBetween className={'-mr-4 items-center mt-7'}>
          <div>
            <LActButton
              type={EnumButtonType.TEXT}
              onClick={() => navigate(-1)}
              textStyle={'text-tint text-sm'}
              text={translate(`${i18n}.back`)}
              iconComponent={
                <ChevronBackOutline
                  width="14px"
                  height="14px"
                  style={{
                    marginTop: -1,
                    marginRight: -6,
                  }}
                  color={colors.palette.primaryDark}
                />
              }
            />
          </div>
          <div>
            <Breadcrumb
              routes={[
                translate(`${i18n}.breadcrumb.home`),
                translate(`${i18n}.breadcrumb.applications`),
              ]}
            />
          </div>
        </LActRow>

        <LActRow spaceBetween className="items-center mt-8">
          <LActText
            testID={'application-title-text'}
            text={translate(`${i18n}.title`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_32}
            className="mt-6 font-semibold mobile:text-2xl desktop:text-3xl"
          />
        </LActRow>
      </LActScreen>

      <div
        style={{
          overflow: 'scroll',
          width: window.outerWidth,
        }}
        className={'desktop:pl-40 mobile:pl-6'}
      >
        <LActRow className={'mt-4'}>
          <DragDropContext
            onDragEnd={onDragEnded}
            onDragUpdate={onDragUpdated}
            data-testid={'draggable-context'}
            onDragStart={() => setDragStart(!dragStart)}
          >
            {applicationsDataSource?.map((item, index) => {
              return (
                <ApplicationsColumns
                  status={item?.title}
                  isLoading={isLoading}
                  onDragStart={dragStart}
                  key={`kanban-${index + 2}`}
                  item={!isLoading && item?.applications}
                  availableDroppableItems={availableDroppable}
                />
              );
            })}
          </DragDropContext>
        </LActRow>
      </div>
    </div>
  );
};

export default memo(ApplicationsScreen);
