import { APPLICATION_STORAGE_KEYS } from 'constants/storage.key';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const LOCAL_DB = createJSONStorage(() => localStorage);

const useApplicationsStorage = create(
  persist(
    set => ({
      token: undefined,
      purgeAccessToken: () => set({ token: undefined }),
    }),
    {
      name: APPLICATION_STORAGE_KEYS.APPLICANTS,
      storage: LOCAL_DB,
    },
  ),
);

export default useApplicationsStorage;
