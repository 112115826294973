import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSwitchTranslation, useUserStorage } from 'utils';
import {
  EnumErrorType,
  EnumButtonType,
  EnumTextFormatType,
  EnumButtonActionType,
} from 'constants/enums';
import { translate } from 'i18n';
import { usePostApiV1Users } from 'api/endpoints/queries';
import { LActText, LActButton, LActTextInput } from 'components';
import AuthScreenContainer from '../container';
import { useRegisterValidationShapeSchema } from './utils';
import { IUserInfoTypes } from 'constants/types';
import { VERIFY_EMAIL_SCREEN } from 'constants/route';

const RegisterScreen = (): React.ReactElement => {
  const i18n = 'screens.register';
  useSwitchTranslation();
  const navigate = useNavigate();
  const { currentLanguage } = useSwitchTranslation();
  const [inputMessage, setInputMessage] = useState({
    message: '',
    inputType: '',
  });
  const [isWrongInputResponse, setIsWrongInputResponse] =
    useState<boolean>(false);
  const [secureTextEntry, setSecureTextEntry] = useState<boolean>(true);
  const { RegisterValidationSchema } = useRegisterValidationShapeSchema();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      organizationName: '',
      organizationHomePageUrl: '',
    },
    validationSchema: RegisterValidationSchema,
    onSubmit: ({
      email,
      password,
      organizationName,
      organizationHomePageUrl,
    }) => {
      onRegisterClicked({
        email,
        password,
        organizationName,
        organizationHomePageUrl,
      });
    },
  });

  // @ts-ignore
  const userStorage: IUserInfoTypes = useUserStorage(
    (store: IUserInfoTypes | unknown) => store,
  );

  /**
   * @prop
   * Used:
   *  - mutateAsync
   */
  const API_QUERY = usePostApiV1Users();

  useEffect(() => {
    if (userStorage?.user) {
      navigate(VERIFY_EMAIL_SCREEN, {
        replace: true,
        state: {
          password: formik?.values?.password,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStorage?.user]);

  const onEmailChange = (value: string) => {
    if (isWrongInputResponse) {
      setIsWrongInputResponse(false);
    }
    if (inputMessage.message) {
      setInputMessage({
        message: '',
        inputType: '',
      });
    }
    formik.setFieldValue('email', value);
  };

  const onPasswordChange = (value: string) => {
    if (isWrongInputResponse) {
      setIsWrongInputResponse(false);
    }
    formik.setFieldValue('password', value);
  };

  const onOrgNameChange = (value: string) => {
    if (isWrongInputResponse) {
      setIsWrongInputResponse(false);
    }
    formik.setFieldValue('organizationName', value);
  };

  const onOrgHomePageUrlChange = (value: string) => {
    if (isWrongInputResponse) {
      setIsWrongInputResponse(false);
    }
    formik.setFieldValue('organizationHomePageUrl', value);
  };

  const onLoginClicked = () => {
    navigate(-1);
  };

  const onRegisterClicked = ({
    email,
    password,
    organizationName,
    organizationHomePageUrl,
  }: {
    email: string;
    password: string;
    organizationName: string;
    organizationHomePageUrl: string;
  }) => {
    API_QUERY?.mutateAsync({
      data: {
        role: 'ORG_USER',
        password: password,
        language: currentLanguage,
        email: email.toLowerCase(),
        organizationName: organizationName,
        organizationHomePageUrl: organizationHomePageUrl,
      },
    })
      // @ts-ignore
      .then((response: any) => {
        userStorage?.storeUserInfo(response?.item);

        setTimeout(() => {
          if (userStorage?.user) {
            navigate(VERIFY_EMAIL_SCREEN, {
              replace: true,
              state: {
                password: formik?.values?.password,
              },
            });
          }
        }, 2000);
      })
      .catch(error => {
        if (error?.message?.includes('409')) {
          setInputMessage({
            message: translate(`${i18n}.errorMessage.userEmailExists`),
            inputType: 'error',
          });
        } else if (error?.message?.includes('403')) {
          setIsWrongInputResponse(true);
        }
      });
  };

  const ENABLE_REGISTER_BUTTON =
    formik?.values?.email?.length > 0 &&
    formik?.values?.organizationName?.length > 0 &&
    formik?.values?.password?.length > 0 &&
    Object.keys(formik?.errors)?.length === 0
      ? false
      : true;

  const WRONG_INPUTS_ERROR =
    isWrongInputResponse &&
    !formik?.errors?.email &&
    !formik?.errors?.password &&
    !formik?.errors?.organizationName
      ? true
      : false;

  const EMAIL_MESSAGE_TYPE =
    formik?.errors?.email && formik?.touched?.email
      ? EnumErrorType.ERROR
      : inputMessage.message.length > 0 && inputMessage.inputType === 'error'
      ? EnumErrorType.ERROR
      : undefined;

  const PASSWORD_MESSAGE_TYPE =
    formik?.errors?.password && formik?.touched?.password
      ? EnumErrorType.ERROR
      : formik?.values?.password?.length > 0
      ? EnumErrorType.SUCCESS
      : undefined;

  const ORGANIZATION_NAME_MESSAGE_TYPE =
    formik?.errors?.organizationName && formik?.touched?.organizationName
      ? EnumErrorType.ERROR
      : undefined;

  const ORGANIZATION_HOME_PAGE_URL_MESSAGE_TYPE =
    formik?.errors?.organizationHomePageUrl &&
    formik?.touched?.organizationHomePageUrl
      ? EnumErrorType.ERROR
      : undefined;

  const EMAIL_LABEL_MESSAGE =
    inputMessage?.message?.length > 0
      ? inputMessage?.message
      : formik?.errors?.email && formik?.touched?.email
      ? formik?.errors?.email
      : undefined;

  const ORGANIZATION_NAME_LABEL_MESSAGE =
    formik?.errors?.organizationName && formik?.touched?.organizationName
      ? formik?.errors?.organizationName
      : undefined;

  const ORGANIZATION_HOME_PAGE_URL_MESSAGE =
    formik?.errors?.organizationHomePageUrl &&
    formik?.touched?.organizationHomePageUrl
      ? formik?.errors?.organizationHomePageUrl
      : translate(`${i18n}.homePageUrlMessage`);

  const PASSWORD_LABEL_MESSAGE =
    formik?.errors?.password && formik?.touched?.password
      ? formik?.errors?.password
      : formik?.values?.password?.length > 0
      ? translate(`${i18n}.passwordMatchMessage`)
      : translate(`${i18n}.passwordInfoMessage`);

  return (
    <AuthScreenContainer
      style={`flex justify-center items-center mobile:mx-6 mobile:w-full flex-shrink-1`}
    >
      <div className="desktop:w-3/5 mobile:w-full">
        <LActText
          text={translate(`${i18n}.titleOne`)}
          className="text-textPrimary text-center"
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
        />
        <LActText
          text={translate(`${i18n}.titleTwo`)}
          className="text-textPrimary text-center"
          textFormatType={EnumTextFormatType.FONT_SIZE_40}
        />

        <LActText
          text={translate(`${i18n}.description`)}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          className="text-textSecondary text-center text-sm mt-2"
        />

        <LActTextInput
          type={'text'}
          maxLength={250}
          containerStyle="mt-6"
          testID={'org-name-input'}
          message={ORGANIZATION_NAME_LABEL_MESSAGE}
          onChange={value => onOrgNameChange(value)}
          messageType={ORGANIZATION_NAME_MESSAGE_TYPE}
          onBlur={formik.handleBlur('organizationName')}
          placeHolder={translate(`${i18n}.orgNamePlaceHolder`)}
        />

        <LActTextInput
          type={'text'}
          maxLength={250}
          containerStyle="mt-6"
          testID={'org-url-input'}
          message={ORGANIZATION_HOME_PAGE_URL_MESSAGE}
          onChange={value => onOrgHomePageUrlChange(value)}
          messageType={ORGANIZATION_HOME_PAGE_URL_MESSAGE_TYPE}
          onBlur={formik.handleBlur('organizationHomePageUrl')}
          placeHolder={translate(`${i18n}.homePageUrlPlaceholder`)}
        />

        <LActTextInput
          type={'email'}
          maxLength={250}
          containerStyle="mt-6"
          testID={'email-input'}
          message={EMAIL_LABEL_MESSAGE}
          messageType={EMAIL_MESSAGE_TYPE}
          onBlur={formik.handleBlur('email')}
          onChange={value => onEmailChange(value)}
          placeHolder={translate(`${i18n}.emailPlaceHolder`)}
        />

        <LActTextInput
          isPassword
          maxLength={30}
          containerStyle="mt-3"
          testID={'password-input'}
          message={PASSWORD_LABEL_MESSAGE}
          messageType={PASSWORD_MESSAGE_TYPE}
          onBlur={formik.handleBlur('password')}
          onChange={value => onPasswordChange(value)}
          type={secureTextEntry ? 'password' : 'text'}
          placeHolder={translate(`${i18n}.passwordPlaceHolder`)}
          onIconClicked={() => setSecureTextEntry(!secureTextEntry)}
        />

        {WRONG_INPUTS_ERROR && (
          <LActText
            className={'mt-2 mx-3'}
            textColor={'text-error'}
            textFormatType={EnumTextFormatType.FONT_SIZE_12}
            text={translate(`${i18n}.errorMessage.invalidInputs`)}
          />
        )}

        {/* Buttons container */}
        <div className="mt-16">
          <LActButton
            testID={'login-button'}
            type={EnumButtonType.NORMAL}
            onClick={formik.handleSubmit}
            useLoading={API_QUERY?.isLoading}
            disabled={ENABLE_REGISTER_BUTTON}
            text={translate(`${i18n}.registerButton`)}
            actionType={EnumButtonActionType.BUTTON}
          />

          <LActButton
            onClick={onLoginClicked}
            testID={'register-button'}
            type={EnumButtonType.TEXT}
            textStyle={'text-tint font-bold'}
            text={translate(`${i18n}.loginButton`)}
            actionType={EnumButtonActionType.BUTTON}
          />
        </div>
      </div>
    </AuthScreenContainer>
  );
};

export default RegisterScreen;
