import React from 'react';
import { EnumImageType } from 'constants/enums';

export interface LActImageProps {
  source: any;
  testID?: string;
  className?: string;
  placeholder?: string;
  onClick?: () => void;
  bgImageWidth?: number;
  bgImageHeight?: number;
  imageType?: EnumImageType;
  backgroundImageChildren?: React.ReactNode;
}

const LActImage = (props: LActImageProps): React.ReactElement => {
  const {
    source,
    onClick,
    className,
    placeholder,
    bgImageWidth,
    bgImageHeight,
    testID = 'image',
    backgroundImageChildren,
    imageType = EnumImageType.IMAGE,
  } = props;

  const IMAGE_SOURCE = source ?? placeholder;

  return (
    <>
      {imageType === EnumImageType.BACKGROUND ? (
        <div
          data-testid="background-image"
          onClick={onClick}
          className={className}
          style={{
            resize: 'inherit',
            width: `${bgImageWidth}`,
            height: `${bgImageHeight}`,
            backgroundImage: `url(${IMAGE_SOURCE})`,
          }}
        >
          {backgroundImageChildren}
        </div>
      ) : (
        <img
          data-testid={testID}
          alt={'letsact!'}
          onClick={onClick}
          src={IMAGE_SOURCE}
          className={className}
          style={{
            resize: 'inherit',
          }}
        />
      )}
    </>
  );
};

export default LActImage;
