export const SKILLS_OPTIONS = [
  {
    id: 1,
    titleEN: 'Reliable',
    titleDE: 'Zuverlässig',
    key: 'reliable',
  },
  {
    id: 2,
    titleEN: 'Empathic',
    titleDE: 'Empathisch',
    key: 'empathic',
  },
  {
    id: 3,
    titleEN: 'Resilient',
    titleDE: 'Belastbar',
    key: 'resilient',
  },
  {
    id: 4,
    titleEN: 'Organizational skills',
    titleDE: 'Organisationstalent',
    key: 'organizational_skills',
  },
  {
    id: 5,
    titleEN: 'Hands-On',
    titleDE: 'Hands-On/ Kann zupacken',
    key: 'hands_on',
  },
  {
    id: 6,
    titleEN: 'Creative',
    titleDE: 'Kreativ',
    key: 'creative',
  },
  {
    id: 7,
    titleEN: 'Responsible',
    titleDE: 'Verantwortungsbewusst',
    key: 'responsible',
  },
  {
    id: 8,
    titleEN: 'Independent',
    titleDE: 'Selbstständig',
    key: 'independent',
  },
  {
    id: 9,
    titleEN: 'Patient',
    titleDE: 'Geduldig',
    key: 'patient',
  },
  {
    id: 10,
    titleEN: 'Team Player',
    titleDE: 'Teamfähig',
    key: 'team_player',
  },
  {
    id: 11,
    titleEN: 'Communicative',
    titleDE: 'Kommunikativ',
    key: 'communicative',
  },
  {
    id: 12,
    titleEN: 'Sensitive',
    titleDE: 'Einfühlsam',
    key: 'sensitive',
  },
  {
    id: 13,
    titleEN: 'Unprejudiced',
    titleDE: 'Vorurteilsfrei',
    key: 'unprejudiced',
  },
];
