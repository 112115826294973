import React from 'react';
import { ChevronForwardOutline } from 'react-ionicons';
import { EnumTextFormatType } from 'constants/enums';
import LActRow from '../LActRow';
import LActText from '../LActText';
import { colors } from 'constants/colors';

interface LActActionButtonProps {
  title: string;
  testID?: string;
  textColor?: string;
  className?: string;
  onClick: () => void;
  leftIcon?: React.ReactElement;
}

const LActActionButton = (props: LActActionButtonProps): React.ReactElement => {
  const {
    title,
    onClick,
    leftIcon,
    testID = 'action-button',
    textColor = 'text-neutral400',
  } = props;

  const TEXT_LEFT_MARGIN = leftIcon ? 'pl-2.5' : '';
  const TEXT_COLOR = textColor ?? 'text-neutral400';

  return (
    <LActRow
      spaceBetween
      onClick={onClick}
      data-testid={testID}
      className={'flex flex-1 items-center cursor-pointer py-3 pl-5 pr-3'}
    >
      <LActRow className={'items-center'}>
        {leftIcon && <>{leftIcon}</>}

        <LActText
          text={title}
          textColor={`${TEXT_COLOR} pt-1`}
          className={`font-semibold ${TEXT_LEFT_MARGIN}`}
          textFormatType={EnumTextFormatType.FONT_SIZE_14}
        />
      </LActRow>

      <span className={'pt-1'} data-testid={'chevron-forward-icon'}>
        <ChevronForwardOutline width={'24px'} color={colors.palette.grey700} />
      </span>
    </LActRow>
  );
};

export default LActActionButton;
