import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { USER_STORE_KEYS } from './keys';
import { IOrganizationInfoTypes } from 'constants/types';

const LOCAL_DB = createJSONStorage(() => localStorage);

const useOrganizationStorage = create(
  persist(
    set => ({
      organization: undefined,
      purgeOrgInfo: () => set({ organization: undefined }),
      storeOrgInfo: (organization: IOrganizationInfoTypes) =>
        set((state: IOrganizationInfoTypes) => ({ organization })),
    }),
    {
      name: USER_STORE_KEYS.ORGANIZATION,
      storage: LOCAL_DB,
    },
  ),
);

export default useOrganizationStorage;
