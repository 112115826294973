import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAccessTokenTypes } from 'constants/types';
import { useAccessTokenStorage } from 'utils';
import { LOGIN_SCREEN } from 'constants/route';

/**
 * This hook handles the browser router to stablish the authenticate user
 * If user is not authenticated and enters a route in searchbar then by accident
 * the URL exists, application automatically checks the access_token and manages the user's behavior.
 */
const useCheckAccessToken = () => {
  const navigate = useNavigate();
  // @ts-ignore
  const tokenStorage: IAccessTokenTypes = useAccessTokenStorage(
    (store: IAccessTokenTypes | unknown) => store,
  );

  useEffect(() => {
    if (!tokenStorage || !tokenStorage?.token?.access_token) {
      setTimeout(() => {
        navigate(LOGIN_SCREEN, {
          replace: true,
        });
      }, 700);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, tokenStorage?.token?.access_token]);
};

export default useCheckAccessToken;
