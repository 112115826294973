import React, { useState } from 'react';
import { CModal } from '@coreui/react';
import { CloseCircleSharp } from 'react-ionicons';
import { colors } from 'constants/colors';

interface LActModalProps {
  visible?: boolean;
  className?: string;
  onDismiss: () => void;
  size?: 'sm' | 'lg' | 'xl';
  children: React.ReactNode;
  showsExitButton?: boolean;
}

const LActModal = (props: LActModalProps): React.ReactElement => {
  const {
    children,
    className,
    onDismiss,
    size = 'lg',
    visible = false,
    showsExitButton,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(visible);

  const onCloseModal = () => {
    onDismiss();
    setIsVisible(false);
  };

  return (
    <CModal
      size={size}
      alignment="center"
      visible={isVisible}
      className={className}
      onClose={onCloseModal}
    >
      <>
        {showsExitButton && (
          <div
            data-testid={'close-button'}
            className="absolute right-3 top-3 cursor-pointer"
          >
            <CloseCircleSharp
              width="24px"
              height="24px"
              onClick={onCloseModal}
              color={colors.palette.grey700}
            />
          </div>
        )}
        {children}
      </>
    </CModal>
  );
};

export default LActModal;
