import React, { useEffect, useState } from 'react';
import { Category } from 'api/schemas/category';
import {
  useLanguageSwitchStorage,
  useRefreshToken,
  useSwitchTranslation,
} from 'utils';
import { ILanguageSwitchTypes } from 'constants/types';
import { CATEGORIES_OPTIONS } from './utils';
import {
  EnumButtonType,
  EnumLanguageType,
  EnumTextFormatType,
  EnumButtonActionType,
} from 'constants/enums';
import { LActButton, LActRow, LActText } from 'components/index';
import { translate } from 'i18n';
import { usePatchOfferDetails } from 'api/endpoints/queries';
import { decryptData, parseBearerToken } from 'utils/global/functions';
import { Offer } from 'api/schemas/offer';

interface CategoryComponentProps {
  offerID: number;
  category: Category;
  accessToken: string;
  onItemUpdated: (item: Offer) => void;
}

const CategoryComponent = (
  props: CategoryComponentProps,
): React.ReactElement => {
  const i18n = 'screens.home.offers.details';
  const { offerID, category, accessToken, onItemUpdated } = props;
  useSwitchTranslation();
  const [selectedItem, setSelectedItem] = useState<number>(category?.id);
  const [message, setMessage] = useState({
    message: '',
    type: '',
  });

  // @ts-ignore
  const languageSwitchStorage: ILanguageSwitchTypes = useLanguageSwitchStorage(
    (store: ILanguageSwitchTypes | unknown) => store,
  );

  const PATCH_QUERY = usePatchOfferDetails({
    ...parseBearerToken(accessToken ? decryptData(accessToken) : ''),
  });

  useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        setMessage({
          message: '',
          type: '',
        });
      }, 3000);
    }
  }, [message.type]);

  const onItemClicked = (category: number) => {
    if (message?.message && selectedItem) {
      setMessage({
        message: '',
        type: '',
      });
    }
    setSelectedItem(category);
  };

  const onSaveClicked = () => {
    if (!selectedItem) {
      setMessage({
        message: translate(`${i18n}.errorMessages.emptyCategorySelected`),
        type: 'error',
      });
    } else if (selectedItem !== category?.id) {
      PATCH_QUERY?.mutateAsync({
        offerId: offerID?.toString(),
        data: {
          categoryId: selectedItem,
        },
      })
        .then((data: any) => {
          setMessage({
            message: translate(`${i18n}.severMessages.successfullyUpdated`),
            type: 'success',
          });
          if (data?.item) {
            onItemUpdated(data?.item);
          }
        })
        .catch(error => {
          if (error?.message?.includes('500')) {
            setMessage({
              message: translate(`${i18n}.severMessages.serverError500`),
              type: 'error',
            });
          }
          setMessage({
            message: translate(`${i18n}.severMessages.serverGeneralError`),
            type: 'error',
          });
        });
    }
  };
  useRefreshToken({
    error: PATCH_QUERY?.error,
    refetch: onSaveClicked,
    hasError: PATCH_QUERY?.isError,
  });

  const disabledSaveButton = selectedItem === category?.id;

  return (
    <>
      <LActRow className={'items-center'} spaceBetween>
        <div>
          <LActText
            className="font-semibold"
            text={translate(`${i18n}.categorySection`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
          />
        </div>
        <div>
          <LActButton
            testID={'login-button'}
            onClick={onSaveClicked}
            type={EnumButtonType.TEXT}
            disabled={disabledSaveButton}
            useLoading={PATCH_QUERY?.isLoading}
            text={translate(`${i18n}.saveButton`)}
            actionType={EnumButtonActionType.BUTTON}
            containerStyle={`items-end py-0 bg-transparent border-0 `}
            textStyle={`text-tint  ${disabledSaveButton && 'opacity-50'}`}
          />
        </div>
      </LActRow>

      <div className="flex flex-wrap justify-center justify-evenly items-center desktop:px-20">
        {CATEGORIES_OPTIONS?.map((item, index) => {
          const BG_SELECTED =
            item?.id === selectedItem
              ? 'bg-tint text-white'
              : 'bg-white text-textSecondary';

          const TEXT_COLOR_SELECTED =
            item?.id === selectedItem
              ? 'text-white font-semibold'
              : 'text-tint';

          const title =
            languageSwitchStorage?.language === EnumLanguageType.GERMAN
              ? item?.valueDe
              : item?.valueEn;

          return (
            <span
              key={`category-badge-${index}`}
              onClick={() => onItemClicked(item?.id)}
              className={`cursor-pointer px-3 py-2 mx-1 rounded-full mt-3 ${BG_SELECTED} ${TEXT_COLOR_SELECTED}`}
            >
              {title}
            </span>
          );
        })}
      </div>

      {message?.message && (
        <LActText
          text={message?.message}
          className={'font-semibold mt-6'}
          textFormatType={EnumTextFormatType.FONT_SIZE_16}
          textColor={message.type === 'error' ? 'text-error' : 'text-green'}
        />
      )}
    </>
  );
};

export default CategoryComponent;
