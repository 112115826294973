import React from 'react';
import { PersonCircle } from 'react-ionicons';
import { translate } from 'i18n';
import { useApplicationStorage } from 'utils';
import { IApplicationStorageTypes } from 'constants/types';
import { LActRow, LActText, LActImage } from 'components/index';
import { EnumTextFormatType } from 'constants/enums';
import GIVING_HAND from 'assets/images/giving-hand.png';

interface NotifyDesktopViewItemsTemplateProps {
  userName: string;
}

const NotifyDesktopViewItemsTemplate = (
  props: NotifyDesktopViewItemsTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.home.applications.acceptModalNotify';
  const { userName } = props;
  // @ts-ignore
  const applicationStorage: IApplicationStorageTypes = useApplicationStorage(
    (store: IApplicationStorageTypes | unknown) => store,
  );

  return (
    <div
      data-testid={'desktop-view'}
      className={'flex flex-col items-center -mt-4'}
    >
      <LActRow className={'items-center'}>
        {applicationStorage?.application?.volunteer?.imageUri ? (
          <LActImage
            className={'w-20 h-20 justify-center rounded-full'}
            source={applicationStorage?.application?.volunteer?.imageUri}
          />
        ) : (
          <PersonCircle width={'93px'} height={'93px'} color={'#1F4043'} />
        )}
        <LActImage
          className={'w-20 h-20 justify-center rounded-full -ml-6'}
          source={
            applicationStorage?.application?.offer?.organization?.imageUri
          }
        />
      </LActRow>

      <LActText
        textColor={'text-textPrimary'}
        text={translate(`${i18n}.title`)}
        className={'font-semibold mt-3 text-center'}
        textFormatType={EnumTextFormatType.FONT_SIZE_48}
      />
      <LActText
        numberOfLines="line-clamp-4"
        textColor={'text-textSecondary'}
        text={translate(`${i18n}.description`, {
          name: userName,
        })}
        textFormatType={EnumTextFormatType.FONT_SIZE_16}
        className={'font-normal mt-2 text-center leading-6 px-1'}
      />

      <LActImage
        source={GIVING_HAND}
        className={'w-60 h-60 justify-center mt-6'}
      />
    </div>
  );
};

export default NotifyDesktopViewItemsTemplate;
