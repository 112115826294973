import * as yup from 'yup';
import { translate } from 'i18n';

const i18n = 'screens.login';

export const useValidationShapeSchema = () => {
  const LoginValidationSchema = yup.object().shape({
    email: yup
      .string()
      .lowercase()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        {
          message: translate(`${i18n}.errorMessage.invalidEmail`),
        },
      )
      .required(translate(`${i18n}.errorMessage.invalidEmail`)),

    password: yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
        message: translate(`${i18n}.errorMessage.invalidPasswordMatch`),
      })
      .min(8, ({ min }) =>
        translate(`${i18n}.errorMessage.invalidPassword`, { min: min }),
      )
      .required(translate(`${i18n}.errorMessage.invalidPassword`)),
  });

  return {
    LoginValidationSchema: LoginValidationSchema,
  };
};
