import {
  EnumSchedulingFrequency,
  EnumSchedulingWeekdays,
} from 'constants/enums';
import { translate } from 'i18n';

const i18n = 'screens.home.offers.details.availability';

export const WEEK_DAYS = [
  {
    id: 1,
    key: EnumSchedulingWeekdays.MON,
    name: translate(`${i18n}.weekDays.monday`),
  },
  {
    id: 2,
    key: EnumSchedulingWeekdays.TUE,
    name: translate(`${i18n}.weekDays.tuesday`),
  },
  {
    id: 3,
    key: EnumSchedulingWeekdays.WED,
    name: translate(`${i18n}.weekDays.wednesday`),
  },
  {
    id: 4,
    key: EnumSchedulingWeekdays.THU,
    name: translate(`${i18n}.weekDays.thursday`),
  },
  {
    id: 5,
    key: EnumSchedulingWeekdays.FRI,
    name: translate(`${i18n}.weekDays.friday`),
  },
  {
    id: 6,
    key: EnumSchedulingWeekdays.SAT,
    name: translate(`${i18n}.weekDays.saturday`),
  },
  {
    id: 7,
    key: EnumSchedulingWeekdays.SUN,
    name: translate(`${i18n}.weekDays.sunday`),
  },
];

export const AVAILABILITY_OPTIONS = [
  {
    id: 1,
    weekdays: WEEK_DAYS,
    key: EnumSchedulingFrequency.WEEKLY,
    frequency: translate(`${i18n}.weekly`),
  },
  {
    id: 2,
    weekdays: WEEK_DAYS,
    key: EnumSchedulingFrequency.MONTHLY,
    frequency: translate(`${i18n}.monthly`),
  },
  {
    id: 3,
    weekdays: undefined,
    key: EnumSchedulingFrequency.FLEXIBLE,
    frequency: translate(`${i18n}.flexible`),
  },
];
