import React from 'react';
import { TxKeyPath, translate } from 'i18n';
import {
  TimeOutline,
  RepeatOutline,
  CalendarOutline,
  LocationOutline,
} from 'react-ionicons';
import { Schedule } from 'api/schemas/schedule';
import { Offer } from 'api/schemas/offer';
import { LActText, LActRow } from 'components/index';
import { EnumSchedulingFrequency, EnumTextFormatType } from 'constants/enums';
import { colors } from 'constants/colors';
import { parseLocationType } from 'utils/global/functions';
import { WEEK_DAYS } from './utils';
import { useSwitchTranslation } from 'utils';

interface OfferSchedulingTemplateProps {
  item?: Schedule;
  offer?: Offer;
  className?: string;
}

const OfferSchedulingTemplate = (
  props: OfferSchedulingTemplateProps,
): React.ReactElement => {
  const i18n = 'screens.home.offers';
  useSwitchTranslation();
  const { item, offer, className } = props;

  return (
    <div className={`${className}`}>
      {item?.frequency === EnumSchedulingFrequency.FLEXIBLE ? (
        <LActRow>
          <CalendarOutline
            width="16px"
            height="16px"
            color={colors.palette.grey700}
          />

          <LActText
            textColor={'neutral400'}
            numberOfLines={'line-clamp-6'}
            className={'w-64 ml-2 font-normal'}
            text={translate(`${i18n}.flexible`)}
            textFormatType={EnumTextFormatType.FONT_SIZE_14}
          />
        </LActRow>
      ) : (
        <div>
          <LActRow>
            <LActRow className={'items-center w-28'}>
              <RepeatOutline
                width="16px"
                height="16px"
                color={colors.palette.grey700}
              />

              <LActText
                testID={'count-per-text'}
                textColor={'text-neutral400'}
                numberOfLines={'line-clamp-1'}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
                className={'w-64 ml-2 font-normal'}
                text={`1x ${translate(
                  item?.frequency === EnumSchedulingFrequency.WEEKLY
                    ? `${i18n}.perWeek`
                    : `${i18n}.perMonth`,
                )}`}
              />
            </LActRow>

            <LActRow className={'items-center w-28'}>
              <TimeOutline
                width="16px"
                height="16px"
                color={colors.palette.grey700}
              />

              <LActText
                textColor={'text-neutral400'}
                numberOfLines={'line-clamp-1'}
                className={' w-64 ml-1 mt-px font-normal'}
                textFormatType={EnumTextFormatType.FONT_SIZE_14}
                text={`${translate(`${i18n}.hours`, {
                  count: item?.minHours,
                })}`}
              />
            </LActRow>
          </LActRow>

          <LActRow className={'mt-1.5 items-center'}>
            <CalendarOutline
              width="16px"
              height="16px"
              color={colors.palette.grey700}
            />
            <>
              {WEEK_DAYS?.map((weekDays, index) => {
                const ACTIVE_DAY = item?.weekdays?.includes(weekDays.key)
                  ? 'text-neutral400'
                  : 'text-neutral200';

                const nameOfTheDay = translate(
                  `${i18n}.weekDays.${weekDays?.name}` as TxKeyPath,
                ) as string;

                const weekDayName = nameOfTheDay?.slice(0, 2)?.toLowerCase();

                const visibleText =
                  weekDayName?.charAt(0)?.toUpperCase() + weekDayName?.slice(1);

                return (
                  <LActText
                    text={visibleText}
                    key={index.toString()}
                    textColor={ACTIVE_DAY}
                    numberOfLines={`line-clamp-1`}
                    className={`ml-2 ${ACTIVE_DAY} mt-px font-normal`}
                    textFormatType={EnumTextFormatType.FONT_SIZE_14}
                  />
                );
              })}
            </>
          </LActRow>
        </div>
      )}

      <LActRow className={'mt-1.5 items-center'}>
        <LocationOutline
          width="16px"
          height="16px"
          color={colors.palette.grey700}
        />

        <LActText
          textColor={'text-neutral400'}
          numberOfLines={`line-clamp-1`}
          className={`ml-2 mt-px font-normal`}
          textFormatType={EnumTextFormatType.FONT_SIZE_14}
          text={parseLocationType(
            offer?.city,
            offer?.street,
            i18n,
            offer?.locationType,
          )}
        />
      </LActRow>
    </div>
  );
};

export default OfferSchedulingTemplate;
