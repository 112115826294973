import React from 'react';
import { LActCard, LActRow } from 'components/index';

interface ApplicationsSkeletonLoaderTemplateProps {
  className?: string;
}

const ApplicationsSkeletonLoaderTemplate = (
  props: ApplicationsSkeletonLoaderTemplateProps,
): React.ReactElement => {
  const { className } = props;
  const NUMBER_OF_SHOWN_ITEMS = [0, 1, 2, 3, 4, 5];

  return (
    <>
      {NUMBER_OF_SHOWN_ITEMS.map((item, index) => {
        return (
          <LActCard
            key={`skeleton-${item}+${index}`}
            className={`rounded-md p-4 max-w-sm w-full bg-background mobile:mt-3 ${className}`}
          >
            <LActRow className={`mobile:justify-between items-center `}>
              <LActRow>
                <div
                  className={
                    'animate-pulse rounded-full w-14 h-14 bg-skeletonItems'
                  }
                />

                <div className={'items-center ml-3'}>
                  <div
                    className={
                      'w-52 desktop:h-7 mobile:h-8 desktop:mt-2.5 mobile:mt-4 bg-skeletonItems animate-pulse rounded-md'
                    }
                  />

                  <div
                    className={
                      'mt-3 desktop:flex desktop:flex-col mobile:flex-none mobile:hidden'
                    }
                  >
                    <div
                      className={
                        'w-14 h-5 bg-skeletonItems animate-pulse rounded-md'
                      }
                    />
                    <div
                      className={
                        'w-24 h-5 bg-skeletonItems animate-pulse rounded-md'
                      }
                    />
                  </div>

                  <div
                    className={
                      'mt-2.5 mobile desktop:flex desktop:flex-col mobile:flex-none mobile:hidden'
                    }
                  >
                    <div
                      className={
                        'w-14 h-5 bg-skeletonItems animate-pulse rounded-md'
                      }
                    />
                    <div
                      className={
                        'w-24 h-5 bg-skeletonItems animate-pulse rounded-md'
                      }
                    />
                  </div>
                </div>
                <div
                  className={
                    'w-6 h-7 mt-2 bg-skeletonItems animate-pulse rounded-md ml-6 mobile:flex desktop:flex-none desktop:hidden self-center'
                  }
                />
              </LActRow>
            </LActRow>
          </LActCard>
        );
      })}
    </>
  );
};

export default ApplicationsSkeletonLoaderTemplate;
