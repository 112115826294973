import React, { useState } from 'react';
import {
  useGetAdminAppVersion,
  usePostAdminAppVersion,
} from 'api/endpoints/queries';
import {
  LActRow,
  LActText,
  LActButton,
  LActLoader,
  LActDivider,
  LActTextInput,
} from 'components/index';
import { EnumButtonActionType, EnumButtonType } from 'constants/enums';
import { colors } from 'constants/colors';
import { AddCircleOutline, TrashOutline } from 'react-ionicons';

const ManagementAppUpdateScreen = (): React.ReactElement => {
  const [iOSNewVersion, setIOSNewVersion] = useState<string>('');
  const [iOSForceUpdateNewVersion, setIOSForceUpdateNewVersion] =
    useState<string>('');
  const [androidNewVersion, setAndroidNewVersion] = useState<string>('');
  const [androidForceUpdateNewVersion, setAndroidForceUpdateNewVersion] =
    useState<string>('');
  const [changeLogEN, setChangeLogEN] = useState<Array<string>>([]);
  const [changeLogDE, setChangeLogDE] = useState<Array<string>>([]);
  const [changeLogDETemp, setChangeLogDETemp] = useState<string>('');
  const [changeLogENTemp, setChangeLogENTemp] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const API_QUERY = useGetAdminAppVersion({
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });
  const API_QUERY_UPDATE = usePostAdminAppVersion({
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });

  const onAddChangeLogDEClicked = () => {
    setChangeLogDE(changeLogDE.concat(changeLogDETemp));
  };

  const onDeleteChangeLogDEClicked = (index: number) => {
    const updatedArray = changeLogDE?.filter((_, i) => i !== index);
    setChangeLogDE(updatedArray);
  };

  const onAddChangeLogENClicked = () => {
    setChangeLogEN(changeLogEN.concat(changeLogENTemp));
  };

  const onDeleteChangeLogENClicked = (index: number) => {
    const updatedArray = changeLogEN?.filter((_, i) => i !== index);
    setChangeLogEN(updatedArray);
  };

  const onSaveClicked = () => {
    if (
      iOSNewVersion?.length !== 5 &&
      iOSForceUpdateNewVersion?.length !== 5 &&
      androidNewVersion?.length !== 5 &&
      androidForceUpdateNewVersion?.length !== 5 &&
      changeLogEN?.length === 0 &&
      changeLogDE?.length === 0
    ) {
      setErrorMessage('Please fill all the data');
    } else {
      API_QUERY_UPDATE?.mutateAsync({
        data: {
          iosVersion: iOSNewVersion,
          iosForceUpgradeLowerThan: iOSForceUpdateNewVersion,
          androidVersion: androidNewVersion,
          androidForceUpgradeLowerThan: androidForceUpdateNewVersion,
          changeLogEn: changeLogEN,
          changeLogDe: changeLogDE,
        },
      })
        .then((response: any) => {
          if (response) {
            API_QUERY?.refetch();
          }
        })
        .catch(error => error);
    }
  };

  if (API_QUERY?.isLoading) {
    return (
      <div>
        <div className={'flex mt-20 justify-center items-center'}>
          <LActLoader color={'tint'} />
        </div>
      </div>
    );
  }

  const DISABLED_SAVE_BUTTON =
    iOSNewVersion?.length !== 5 &&
    iOSForceUpdateNewVersion?.length !== 5 &&
    androidNewVersion?.length !== 5 &&
    androidForceUpdateNewVersion?.length !== 5 &&
    changeLogEN?.length === 0 &&
    changeLogDE?.length === 0;

  return (
    <div className="pl-6">
      <LActText
        textColor="text-orange"
        className={`mt-6 text-md`}
        numberOfLines={'line-clamp-1'}
        text={'Previous update overview'}
      />
      <LActRow spaceBetween className="mt-6">
        <LActRow className="items-center">
          <LActText
            className={`text-md`}
            text={'iOS App Version:'}
            textColor="text-neutral400"
            numberOfLines={'line-clamp-1'}
          />
          <LActText
            textColor="text-grayDark"
            numberOfLines={'line-clamp-1'}
            className={`text-xl ml-3 text-semibold`}
            text={API_QUERY?.data?.item?.iosVersion}
          />
        </LActRow>
        <LActRow className="items-center">
          <LActText
            className={`text-md`}
            textColor="text-neutral400"
            numberOfLines={'line-clamp-1'}
            text={'iOS Force Update Version:'}
          />
          <LActText
            textColor="text-grayDark"
            numberOfLines={'line-clamp-1'}
            className={`text-xl ml-3 text-semibold`}
            text={API_QUERY?.data?.item?.iosForceUpgradeLowerThan}
          />
        </LActRow>
      </LActRow>

      <LActRow spaceBetween className="mt-6">
        <LActRow className="items-center">
          <LActText
            className={`text-md`}
            text={'Android App Version:'}
            textColor="text-neutral400"
            numberOfLines={'line-clamp-1'}
          />
          <LActText
            textColor="text-grayDark"
            numberOfLines={'line-clamp-1'}
            className={`text-xl ml-3 text-semibold`}
            text={API_QUERY?.data?.item?.androidVersion}
          />
        </LActRow>
        <LActRow className="items-center">
          <LActText
            className={`text-md`}
            textColor="text-neutral400"
            numberOfLines={'line-clamp-1'}
            text={'Android Force Update Version:'}
          />
          <LActText
            textColor="text-grayDark"
            numberOfLines={'line-clamp-1'}
            className={`text-xl ml-3 text-semibold`}
            text={API_QUERY?.data?.item?.androidForceUpgradeLowerThan}
          />
        </LActRow>
      </LActRow>

      <div className="items-center mt-6">
        <LActText
          className={`text-md`}
          text={'Change-log DE:'}
          textColor="text-neutral400"
          numberOfLines={'line-clamp-1'}
        />
        {API_QUERY?.data?.item?.changeLogDe?.map(text => {
          return (
            <LActText
              text={`• ${text}`}
              textColor="text-grayDark"
              numberOfLines={'line-clamp-1'}
              className={`text-xl ml-3 text-semibold`}
            />
          );
        })}
      </div>

      <div className="items-center mt-6">
        <LActText
          className={`text-md`}
          text={'Change-log EN:'}
          textColor="text-neutral400"
          numberOfLines={'line-clamp-1'}
        />
        {API_QUERY?.data?.item?.changeLogEn?.map(text => {
          return (
            <LActText
              text={`• ${text}`}
              textColor="text-grayDark"
              numberOfLines={'line-clamp-1'}
              className={`text-xl ml-3 text-semibold`}
            />
          );
        })}
      </div>

      <LActDivider className="mt-10" />

      <LActRow spaceBetween className="mt-6 w-screen">
        <LActText
          className={`text-md`}
          textColor="text-orange"
          text={'New update overview'}
          numberOfLines={'line-clamp-1'}
        />

        <LActButton
          text={'Save'}
          testID={'login-button'}
          onClick={onSaveClicked}
          type={EnumButtonType.TEXT}
          disabled={DISABLED_SAVE_BUTTON}
          useLoading={API_QUERY_UPDATE?.isLoading}
          actionType={EnumButtonActionType.BUTTON}
          containerStyle={`items-end py-0 bg-transparent border-0 `}
          textStyle={`text-tint  ${DISABLED_SAVE_BUTTON && 'opacity-50'}`}
        />
      </LActRow>

      {errorMessage && (
        <LActText
          text={errorMessage}
          textColor="text-error"
          className={`text-md mt-4`}
          numberOfLines={'line-clamp-1'}
        />
      )}

      <LActRow spaceBetween className="mt-6">
        <div className="w-1/3">
          <LActTextInput
            type={'text'}
            placeHolder={'iOS new update Version'}
            onChange={(value: string) => setIOSNewVersion(value)}
          />
        </div>

        <div className="w-1/3">
          <LActTextInput
            type={'text'}
            placeHolder={'iOS force update Version'}
            onChange={(value: string) => setIOSForceUpdateNewVersion(value)}
          />
        </div>
      </LActRow>

      <LActRow spaceBetween className="mt-6">
        <div className="w-1/3">
          <LActTextInput
            type={'text'}
            placeHolder={'Android new update Version'}
            onChange={(value: string) => setAndroidNewVersion(value)}
          />
        </div>

        <div className="w-1/3">
          <LActTextInput
            type={'text'}
            placeHolder={'Android force update Version'}
            onChange={(value: string) => setAndroidForceUpdateNewVersion(value)}
          />
        </div>
      </LActRow>

      <LActRow spaceBetween className="mt-6">
        <div className="w-1/2 mr-3">
          <LActRow className="items-center">
            <div className="w-full">
              <LActTextInput
                type={'text'}
                containerStyle="w-[400px]"
                placeHolder={'Change-log DE:'}
                onChange={(value: string) => setChangeLogDETemp(value)}
              />
            </div>

            <div className="cursor-pointer">
              <AddCircleOutline
                width="24px"
                height="24px"
                color={colors.tint}
                onClick={onAddChangeLogDEClicked}
              />
            </div>
          </LActRow>

          {changeLogDE?.map((text: string, index: number) => {
            return (
              <LActRow className="items-center mt-3">
                <div className={'w-full px-1 py-1 bg-neutral200 rounded-full '}>
                  <LActText
                    text={text}
                    textColor="text-grayDark"
                    numberOfLines={'line-clamp-2'}
                    className={`text-sm ml-3  w-full text-semibold`}
                  />
                </div>

                <div className="cursor-pointer">
                  <TrashOutline
                    width="24px"
                    height="24px"
                    color={colors.error}
                    onClick={() => onDeleteChangeLogDEClicked(index)}
                  />
                </div>
              </LActRow>
            );
          })}
        </div>

        <div className="w-1/2 mr-3">
          <LActRow className="items-center">
            <div className="w-full">
              <LActTextInput
                type={'text'}
                containerStyle="w-[400px]"
                placeHolder={'Change-log EN:'}
                onChange={(value: string) => setChangeLogENTemp(value)}
              />
            </div>

            <div className="cursor-pointer">
              <AddCircleOutline
                width="24px"
                height="24px"
                color={colors.tint}
                onClick={onAddChangeLogENClicked}
              />
            </div>
          </LActRow>

          {changeLogEN?.map((text: string, index: number) => {
            return (
              <LActRow className="items-center mt-3">
                <div className={'w-full px-1 py-1 bg-neutral200 rounded-full '}>
                  <LActText
                    text={text}
                    textColor="text-grayDark"
                    numberOfLines={'line-clamp-2'}
                    className={`text-sm ml-3  w-full text-semibold`}
                  />
                </div>

                <div className="cursor-pointer">
                  <TrashOutline
                    width="24px"
                    height="24px"
                    color={colors.error}
                    onClick={() => onDeleteChangeLogENClicked(index)}
                  />
                </div>
              </LActRow>
            );
          })}
        </div>
      </LActRow>
    </div>
  );
};

export default ManagementAppUpdateScreen;
