import { EnumSchedulingWeekdays } from 'constants/enums';

export const WEEK_DAYS = [
  {
    key: EnumSchedulingWeekdays.MON,
    name: 'monday',
  },
  {
    key: EnumSchedulingWeekdays.TUE,
    name: 'tuesday',
  },
  {
    key: EnumSchedulingWeekdays.WED,
    name: 'wednesday',
  },
  {
    key: EnumSchedulingWeekdays.THU,
    name: 'thursday',
  },
  {
    key: EnumSchedulingWeekdays.FRI,
    name: 'friday',
  },
  {
    key: EnumSchedulingWeekdays.SAT,
    name: 'saturday',
  },
  {
    key: EnumSchedulingWeekdays.SUN,
    name: 'sunday',
  },
];
