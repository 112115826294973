import { create } from 'zustand';

const useApplicationAcceptStorage = create(set => ({
  scope: '',
  acceptDialogVisible: false,
  showAcceptDialog: (scope: string) =>
    set({ acceptDialogVisible: true, scope }),
  purgeAcceptDialog: () => set({ acceptDialogVisible: false, scope: '' }),
}));

export default useApplicationAcceptStorage;
