import {
  LActRow,
  LActText,
  Breadcrumb,
  LActButton,
  LActScreen,
} from 'components/index';
import { EnumButtonType, EnumManagementScreenType } from 'constants/enums';
import React, { useMemo, useState } from 'react';
import { ChevronBackOutline } from 'react-ionicons';
import { colors } from 'constants/colors';
import { useNavigate } from 'react-router-dom';
import { ManagementMenuItems } from './utils';
import ManagementOrganizationScreen from './pages/organizations.index';
import ManagementAppUpdateScreen from './pages/app.update.index';
import ManagementOfferScreen from './pages/offers.index';
import ManagementNotificationsScreen from './pages/notifications';
import ManagementEventsNotificationScreen from './pages/eventsNewsNotifications';

const ManagementScreen = (): React.ReactElement => {
  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState<EnumManagementScreenType>(
    EnumManagementScreenType.ORGANIZATIONS,
  );

  const CurrentMenuItemsTemplate = useMemo(() => {
    return (
      <>
        {ManagementMenuItems.map(item => {
          const ACTIVE_ITEM_COLOR =
            currentScreen === item.type
              ? 'text-orange font-bold text-xl'
              : 'text-textPrimary';

          return (
            <LActText
              text={item.name}
              numberOfLines={'line-clamp-1'}
              onClick={() => setCurrentScreen(item.type)}
              className={`cursor-pointer mt-6 ${ACTIVE_ITEM_COLOR}`}
            />
          );
        })}
      </>
    );
  }, [currentScreen]);

  const CurrentScreenTemplate = useMemo(() => {
    switch (currentScreen) {
      case EnumManagementScreenType.ORGANIZATIONS:
        return <ManagementOrganizationScreen />;

      case EnumManagementScreenType.OFFERS:
        return <ManagementOfferScreen />;

      case EnumManagementScreenType.APP_UPDATE:
        return <ManagementAppUpdateScreen />;

      case EnumManagementScreenType.LAST_OFFERS_NOTIFICATIONS:
        return <ManagementNotificationsScreen />;

      case EnumManagementScreenType.EVENT_NOTIFICATIONS:
        return <ManagementEventsNotificationScreen />;

      default:
        return <ManagementOfferScreen />;
    }
  }, [currentScreen]);

  return (
    <LActScreen>
      <LActRow spaceBetween className={'-mr-4 items-center mt-7'}>
        <div>
          <LActButton
            text={'Back'}
            type={EnumButtonType.TEXT}
            onClick={() => navigate(-1)}
            textStyle={'text-tint text-sm'}
            iconComponent={
              <ChevronBackOutline
                width="14px"
                height="14px"
                style={{
                  marginTop: -1,
                  marginRight: -6,
                }}
                color={colors.palette.primaryDark}
              />
            }
          />
        </div>
        <div>
          <Breadcrumb routes={['Home', 'Management']} />
        </div>
      </LActRow>
      <LActRow className="-mb-6">
        <div className="border-r-2 border-greenLight h-screen w-1/5">
          {CurrentMenuItemsTemplate}
        </div>

        <div className="h-screen w-full pr-4 pb-4 overflow-y-scroll overflow-x-hidden">
          {CurrentScreenTemplate}
        </div>
      </LActRow>
    </LActScreen>
  );
};

export default ManagementScreen;
