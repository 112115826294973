import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { OFFERS } from './keys';
import { Offer } from 'api/schemas/offer';

const LOCAL_DB = createJSONStorage(() => localStorage);

const useOfferCreationStorage = create(
  persist(
    set => ({
      offer: {},
      purgeOffers: () => set({ offer: {} }),
      storeOffers: (offer: Offer) => set(() => ({ offer })),
    }),
    {
      name: OFFERS.SINGLE_OFFER_CREATION,
      storage: LOCAL_DB,
    },
  ),
);

export default useOfferCreationStorage;
